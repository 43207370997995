<template>
    <div class="section-b-space pt-0 page-content loading-page content-loading p-relative">
        <vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
        <breadcrumbs type="dashboard" title="Change Password"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Change Password</h2>
        </div>
        <b-container class="p-4 mt-2 box-shadow">
            <b-row>
                <b-col cols="12" md="6" class="offset-md-3">
                    <form-password :email="getUserEmail" @updateLoading="updateLoading"></form-password>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    components: {
        vcLoading: () => import("../../../components/pages/loading.vue"),
        breadcrumbs: () => import("../../../components/pages/breadcrumbs.vue"),
        formPassword: () => import("../../../components/pages/form-password.vue")
    },
    data() {
        return {
            isLoading: true
        };
    },
    async mounted(){
        if( !this.getterLogin ){
            
            this.$router.push('/account/login');
        }
        this.isLoading = null;
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
            getterUser: 'getterUser',
        }),
        getUserEmail(){
            return this.getterUser.email;
        }
    },
    methods: {
        updateLoading(dataLoading){
            
            this.isLoading = dataLoading;
        },
    }
}
</script>