<template>
	<div class="container content-loading p-relative pt-4">
		<vc-loading :isLoading="isLoading"></vc-loading>
		<div class="checkout-page">
			<b-row>
				<b-col v-if="getterCustomerLanding" cols="12">
					<div class="title-default w-auto pb-4">
						<h3 class="title-inner-default font-color-dark mb-2 font-weight-normal">Pay Your Balance: {{ getterCustomerLanding.first_name }} {{ getterCustomerLanding.last_name }}</h3>
					</div>
				</b-col>
				<b-col cols="12" lg="7">
					<div class="billing-details p-0">
						<b-row>
							<b-col cols="12">
								<b-form-group label="Please select the amount you wish to credit to your balance" label-for="use_balance" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
									<b-form-radio-group id="use_balance" v-model="typeAmount" name="use_balance" class="accordion">
										<b-card no-body>
											<b-card-header header-tag="header" class="p-3 px-4 border-bottom-0" role="tab">
												<b-form-radio name="value-radios-pay" :value="'balance'" class="w-100" @change="changeAmountPay()">
													Total amount ({{formatCurrency(amountBalance).format}})
												</b-form-radio>
											</b-card-header>
											<div v-if="typeAmount == 'balance'"></div>
										</b-card>
										<b-card v-if="amountPastDue > 0" no-body>
											<b-card-header header-tag="header" class="p-3 px-4 border-bottom-0" role="tab">
												<b-form-radio name="value-radios-pay" :value="'pastdue'" class="w-100" @change="changeAmountPay()">
													Past due amount ({{formatCurrency(amountPastDue).format}})
												</b-form-radio>
											</b-card-header>
										</b-card>
										<b-card no-body>
											<b-card-header header-tag="header" class="p-3 px-4 border-bottom-0" role="tab">
												<b-form-radio name="value-radios-pay" :value="'custom-amount'" class="w-100" @change="changeAmountPay()">
													Custom amount
												</b-form-radio>
											</b-card-header>
											<div v-if="typeAmount == 'custom-amount'">
												<b-card-body class="bg-card">
													<b-form-group label="Type your custom amount" label-for="custom_amount" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="mb-0">
														<b-input-group>
															<input id="custom_amount" type="text" name="currency-field" pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" value="" data-type="currency" placeholder="$0.00" class="form-control" @keyup="formatAmount('custom_amount')"/>
														</b-input-group>
													</b-form-group>
												</b-card-body>
											</div>
										</b-card>
									</b-form-radio-group>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="Choose payment method" label-for="payment_methods" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
									<b-form-radio-group id="payment_methods" v-model="paymentMethod" name="payment_methods" class="accordion">
										<b-card no-body>
											<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
												<b-form-radio :disabled="disableOptionPay" name="some-radios-pay" :value="'credit-card'" class="w-100" @change="updateMethodPay">Credit card</b-form-radio>
											</b-card-header>
											<b-card-body v-if="paymentMethod == 'credit-card'" id="payment-method-body-credit-card" class="payment-method-body bg-card">
												<credit-card @changeCreditCard="changeCreditCard"></credit-card>
											</b-card-body>
										</b-card>
										<b-card no-body>
											<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
												<b-form-radio :disabled="disableOptionPay" name="some-radios-pay" :value="'paypal'" class="w-100" @change="updateMethodPay">Paypal</b-form-radio>
											</b-card-header>
											<b-card-body v-if="paymentMethod == 'paypal'" id="payment-method-body-paypal" class="payment-method-body bg-card">
												<div class="paypal">
													<div class="radio-wrapper content-box__row px-2">
														<div class="radio__label payment-method-wrapper">
															<label class="radio__label__primary content-box__emphasis">
																<img alt="PayPal" class="offsite-payment-gateway-logo" :src="require('@/assets/images/paypal.webp')"/>
															</label>
														</div>
													</div>
													<div class="radio-wrapper content-box__row content-box__row--secondary paypal-container">
														<div class="blank-slate" style="text-align: center">
															<i style=" font-size: 12rem; color: lightgray;" class="fa-solid fa-window-maximize"></i>
															<p>
																After clicking on "Pay Balance",
																you will be redirected to PayPal to
																complete your purchase safely.
															</p>
														</div>
													</div>
												</div>
											</b-card-body>
										</b-card>
									</b-form-radio-group>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="Billing Address" label-for="billing_address" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
									<b-form class="theme-form register-form">
										<small>Make sure the address matches your card payment method</small>
										<form-address v-if="getBillingAddress && getDataListCountries.length > 0" idForm="balance-billing-address" :dataAddress="getBillingAddress" :dataListCountries="getDataListCountries" :dataListStates="getDataListStates" :dataListPhoneCodes="getDataListPhoneCodes" @validFormAddress="validFormAddress" typeForm="pay-balance"></form-address>
									</b-form>
								</b-form-group>
							</b-col>
						</b-row>
					</div>
				</b-col>
				<b-col cols="12" lg="5">
					<b-form-group label="Balance Summary" label-for="balance-summary" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="mb-0">
						<div class="checkout-details">
							<div class="order-box">
								<b-row class="m-0">
									<b-col cols="6" class="p-0">
										<h4 class="font-color-dark font-weight-normal">Subtotal</h4>
									</b-col>
									<b-col cols="6" class="p-0 d-flex justify-content-end aling-items-center">
										<h4 class="font-color-dark font-weight-normal">{{ formatCurrency(amountValue).format }}</h4>
									</b-col>
								</b-row>
								<b-row class="m-0">
									<b-col cols="6" class="p-0">
										<h4 class="font-color-dark font-weight-normal">Total</h4>
									</b-col>
									<b-col cols="6" class="p-0 d-flex justify-content-end aling-items-center">
										<h4 class="font-color-dark font-weight-normal">{{ formatCurrency(amountValue).format }}</h4>
									</b-col>
								</b-row>
							</div>
						</div>
					</b-form-group>
				</b-col>
				<b-col cols="12" class="d-flex justify-content-end align-items-center mt-4">
					<div class="mb-0 text-right">
						<b-button class="btn-solid shadow-sm" @click="submitPayBalance" :disabled="disableOptionPay">pay balance</b-button>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../constants/config.js";
export default {
	components: {
		vcLoading	: () => import('../../components/pages/loading.vue' ),
		creditCard	: () => import('../../components/payment/credit-card.vue'),
		formAddress	: () => import("../../components/pages/form-address.vue"),
	},
	data() {
		return {
			
			typeAmount		: 'balance',
			validOption		: false,
			amountBalance	: 0,
			amountPastDue	: 0,
			amountValue		: 0,
			disableOptionPay: false,
			customer		: null,
			
			listPhoneCodes	: [],
			listCountries	: [],
			listStates		: [],
			
			paymentMethod	: "credit-card",
			
			billingAddress	: {
				id			: "new-address",
				first_name	: '',
				last_name	: '',
				name		: "",
				phone		: '',
				address_1	: '',
				address_2	: '',
				company		: '',
				country		: 'United States',
				country_code: 'US',
				state		: null,
				state_code	: null,
				city		: '',
				zip			: ''
			},
			
			creditCard		: null,
			
			isLoading		: true,
			typecart		: null,
		};
	},
	async mounted() {
		if ( this.$route.query.id ) {
			
			await this.getCountries().then( async (result_countries) => {
				
				this.listPhoneCodes = this.getterCountries.phone_codes;
				this.listCountries 	= this.getterCountries.list;
				this.listStates     = this.getterCountries.states;
				
				await this.getCustomer({ id_email: this.$route.query.id, origin: "landing" }).then( async (result_customer) => {
					
					await this.getStatementByCustomer({ id: this.getterCustomerLanding._id }).then( (result_statement) => {
						
						this.validOption 	= true;
						this.amountBalance 	= this.getterSatement.balance < 0 ? this.getterSatement.balance * -1 : 0; 
						this.amountValue 	= this.amountBalance;
						this.amountPastDue 	= this.getterSatement.balanceExpired;
						
						this.isLoading = false;
					});
				});
				this.$root.$emit('change-select-address', { id_form: "balance-billing-address", address: this.billingAddress });
			});
		}
		else{
			
			this.$router.push("/pages/401");
		}
	},
	computed: {
		...mapGetters({
			getterCustomerLanding	: "getterCustomerLanding",
			
			getterSatement			: "getterSatement",
			
			getterStatusPayBalance	: "getterStatusPayBalance",
			getterCountries			: "getterCountries",
		}),
		getBillingAddress(){
			return this.billingAddress ? this.billingAddress : this.defaultAddress;
		},
		getDataListCountries(){
			return this.listCountries;
		},
		getDataListStates(){
			return this.listStates;
		},
		getDataListPhoneCodes(){
			return this.listPhoneCodes;
		},
	},
	methods: {
		...mapActions({
			getCountries			: 'getCountries',
			getCustomer				: 'getCustomer',
			getStatementByCustomer	: 'getStatementByCustomer',
			payBalance				: 'payBalance',
			addNotification			: 'addNotification'
		}),
		async validFormAddress(data_form){
			
			if( data_form.id_form == "balance-billing-address" ){
				
				this.billingAddress 		= data_form.address;
				this.billingAddress.name	= `${ this.billingAddress.first_name } ${ this.billingAddress.last_name }`;
				if( this.amountValue > 0 && !this.disableOptionPay && data_form.valid && ( this.paymentMethod !== 'credit-card' || ( this.paymentMethod == 'credit-card' && this.creditCard ) ) ){
					
					await this.executePayBalance();
				}
				else{
					
					this.addNotification({ 
						content: { type: 'request' }, 
						type: "error", 
						data: { title: "Error: Payment and Billing fields", message: "There are fields that have not been validated" }
					});
				}
			}
		},
		changeAmountPay() {
			
			if(this.typeAmount == 'balance') {
				
				this.amountValue = this.amountBalance;
			} 
			else if(this.typeAmount == 'pastdue') {
				
				this.amountValue = this.amountPastDue;
			} 
			else if(this.typeAmount == 'custom-amount') {
				
				this.amountValue = 0;
			}
		},
		formatCurrency(price) {
			
			return formatData.currency(price);
		},
		isNumber(event, input_id){
			
			let keyCode = (event.keyCode ? event.keyCode : event.which);
			if ( ( (keyCode < 48 || keyCode > 57) && event.key != "." ) || parseInt(document.getElementById(input_id).value) == 0 && parseInt(event.key) == 0 ) {
				
				event.preventDefault();
			}
		},
		validNumber(number){
			return ( isNaN( parseInt(number) ) ? 0 : parseInt(number) );
		},
		formatNumber(n) {
			return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		formatAmount(input, blur) {
			input = document.getElementById(input);
			let input_val = input.value;
			
			if (input_val === "") { return; }
			
			let original_len = input_val.length;
			let caret_pos = input.selectionStart;
			
			if (input_val.indexOf(".") >= 0) {
				
				let decimal_pos = input_val.indexOf(".");
				let left_side = input_val.substring(0, decimal_pos);
				let right_side = input_val.substring(decimal_pos);
				
				left_side = this.formatNumber(left_side);
				right_side = this.formatNumber(right_side);
				if (blur === "blur") {
					right_side += "00";
				}
				
				right_side = right_side.substring(0, 2);
				
				input_val = "$" + left_side + "." + right_side;
				
			} 
			else {
				input_val = this.formatNumber(input_val);
				input_val = "$" + input_val;
				
				if (blur === "blur") {
					input_val += ".00";
				}
			}
			input.value = input_val;
			
			this.amountValue = parseFloat( input_val.replace('$', '').split(',').join("") );
			
			let updated_len = input_val.length;
			caret_pos = updated_len - original_len + caret_pos;
			input.setSelectionRange(caret_pos, caret_pos);
		},
		changeAmount(input_id){
			
			document.getElementById(input_id).value = this.validNumber(document.getElementById(input_id).value);
		},
		changeCreditCard(data_change){
			
			this.creditCard = data_change.valid ? data_change.credit_card : null;
		},
		processPayBalance() {
			
			this.isLoading = false;
			if (this.getterStatusPayBalance.result == "approved") {
				
				window.location.href = this.getterStatusPayBalance.url;
			}
			else{
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { title: "Error: Pay Balance", message: this.getterStatusPayBalance.message } 
				});
			}
		},
		async executePayBalance(){
			
			if (this.getterCustomerLanding) {
				
				this.isLoading = true;
				let bodyPay = {
					user_id						: this.getterCustomerLanding._id.toString(),
					customer_id					: this.getterCustomerLanding._id,
					customer_sh_id				: this.getterCustomerLanding.shopify_id,
					amount						: this.amountValue,
					"payment-method"			: this.paymentMethod,
					"card-number"				: ['credit-card'].includes( this.paymentMethod ) ? this.creditCard.cardNumber : null,
					"card-date"					: ['credit-card'].includes( this.paymentMethod ) ? `${ parseInt( this.creditCard.cardMonth ) > 9 ? this.creditCard.cardMonth : `0${ this.creditCard.cardMonth }` }/${ this.creditCard.cardYear.toString().slice(-2) }` : null,
					"card-verification-value"	: ['credit-card'].includes( this.paymentMethod ) ? this.creditCard.cardCvv : null,
					"card-name"					: ['credit-card'].includes( this.paymentMethod ) ? this.creditCard.cardName : null,
					"address-line"				: this.billingAddress.address_1,
					"address-country"			: this.billingAddress.country,
					"address-state"				: this.billingAddress.state,
					"address-city"				: this.billingAddress.city,
					"address-zipcode"			: this.billingAddress.zip,
					originPayment				: "Payment Balance Cata1og",
					resumeBuy					: null,
					product_origin				: 'balance'
				};
				await this.payBalance({ payment: bodyPay }).then( (result_pay) => {
					
					this.processPayBalance();
				}).catch( (error_pay) => {
					
					this.processPayBalance();
				});
			}
			else {
				
				this.$router.replace("/account/login");
			}
		},
		submitPayBalance(){
			
			this.$root.$emit('valid-address', { id_form: "balance-billing-address", action: "pay-balance" });
		}
	}
};
</script>