<template>
	<b-col cols="12" class="faq-section mb-4">
		<div class="accordion theme-accordion">
			<b-card no-body class="mb-1 box-shadow">
				<b-card-header header-tag="header" class="p-1" role="group">
					<b-button block v-b-toggle="'faq-1'">How to start doing business with us?</b-button>
				</b-card-header>
				<b-collapse id="faq-1" :visible="false" accordion="faq-accordion" role="tabpanel">
					<b-card-body>
						<b-card-text>In order to make a purchase, you must be registered in our system. We invite you to <nuxt-link :to="{ path: '/account/register' }" title="Start Selling Now" class="font-color-dark font-weight-bold">sign up here</nuxt-link></b-card-text>
						<b-card-text>Read carefully and accept our company terms and conditions in order to complete your registration.</b-card-text>
						<b-card-text>After receiving your information, one of our sales executives will reach out to you within the next 24 hours.</b-card-text>
						<b-card-text>Once your information is verified, your account will be approved and you will be able to see all our products and prices.</b-card-text>
					</b-card-body>
				</b-collapse>
			</b-card>
			<b-card no-body class="mb-1 box-shadow">
				<b-card-header header-tag="header" class="p-1" role="group">
					<b-button block v-b-toggle="'faq-2'">How long do you have to wait to receive your order? How do we handle shipping?</b-button>
				</b-card-header>
				<b-collapse id="faq-2" :visible="false" accordion="faq-accordion" role="tabpanel">
					<b-card-body>
						<b-card-text>After the payment of your order is verified, please allow between 2-5 business days to be delivered. (The package can be delivered in the mailbox, if this turns out to be your case, please be aware of your package's arrival).</b-card-text>
						<b-card-text>All the orders that are placed before 12:30 pm (Eastern time) will be shipped the same day the order was placed. We use UPS, but USPS can be considered.</b-card-text>
						<b-card-text>East coast: For orders under $300, the shipment fee is $12 USD. Orders over $300 will be shipped for free.</b-card-text>
						<b-card-text>West coast: For orders under $500, the shipment fee is $15 USD. Orders over $500 will be shipped for free.</b-card-text>
					</b-card-body>
				</b-collapse>
			</b-card>
			<b-card no-body class="mb-1 box-shadow">
				<b-card-header header-tag="header" class="p-1" role="group">
					<b-button block v-b-toggle="'faq-3'">What payment methods do we accept?</b-button>
				</b-card-header>
				<b-collapse id="faq-3" :visible="false" accordion="faq-accordion" role="tabpanel">
					<b-card-body>
						<b-card-text>Visa</b-card-text>
						<b-card-text>MasterCard</b-card-text>
						<b-card-text>American Express</b-card-text>
						<b-card-text>Credit Card WTC</b-card-text>
						<b-card-text>PayPal</b-card-text>
						<b-card-text>Wire Transfer</b-card-text>
						<b-card-text>Bank Deposits</b-card-text>
					</b-card-body>
				</b-collapse>
			</b-card>
			<b-card no-body class="mb-1 box-shadow">
				<b-card-header header-tag="header" class="p-1" role="group">
					<b-button block v-b-toggle="'faq-4'">If there has been an irregularity, How can you return the item and request an exchange?</b-button>
				</b-card-header>
				<b-collapse id="faq-4" :visible="false" accordion="faq-accordion" role="tabpanel">
					<b-card-body>
							<b-card-text>If for any reason you receive a <strong>defective product</strong>, you have 10 business days to notify and return the unused merchandise, with all the labels still attached.</b-card-text>
							<b-card-text>Please, remember that you have up to <strong>90 days</strong> to return the merchandise that complies with our return policies (unused garments with all labels attached). We will add the product's value as a credit to your account once we receive it in our warehouse, so you can place a new order.</b-card-text>
					</b-card-body>
				</b-collapse>
			</b-card>
			<b-card no-body class="mb-1 box-shadow">
				<b-card-header header-tag="header" class="p-1" role="group">
					<b-button block v-b-toggle="'faq-5'">What is the minimum order amount to work with us?</b-button>
				</b-card-header>
				<b-collapse id="faq-5" :visible="false" accordion="faq-accordion" role="tabpanel">
					<b-card-body>
						<b-card-text>To enjoy our exclusive wholesale prices, the minimum order amount for your first purchase is <strong>$1,000.00 USD</strong>. After the 1st investment, you will be able to place order for less than <strong>$1,000.00 USD</strong>.</b-card-text>
					</b-card-body>
				</b-collapse>
			</b-card>
			<b-card no-body class="mb-1 box-shadow">
				<b-card-header header-tag="header" class="p-1" role="group">
					<b-button block v-b-toggle="'faq-6'">If you have an issue with your order; where can you contact us?</b-button>
				</b-card-header>
				<b-collapse id="faq-6" :visible="false" accordion="faq-accordion" role="tabpanel">
					<b-card-body>
						<b-card-text><strong>You can contact us to the email:</strong></b-card-text>
						<b-card-text><a href="mailto:info.us@cata1og.com">info.us@cata1og.com</a></b-card-text>
						<b-card-text><strong>You can also call us at the following number:</strong></b-card-text>
						<b-card-text>For Calls only:</b-card-text>
						<b-card-text><a href="tel:+17865743472">+1 786 574 3472</a></b-card-text>
					</b-card-body>
				</b-collapse>
			</b-card>
		</div>
	</b-col>
</template>
<script>
export default {
}
</script>