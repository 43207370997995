<template>
	<section v-if="productslist.length > 0">
		<div class="container">
			<div class="col-12 product-related">
				<h2>{{ title }}</h2>
			</div>
			<b-row class="m-0">
				<b-col cols="6" md="4" xl="2" v-for="(item_product, index_product) in productslist.slice(-6)" :key="index_product">
					<product-collection class="viewed-product mb-5" :handleCollection="item_product.collection_data ? item_product.collection_data.handle : 'best-sellers'" :hideDescription="false" :product="item_product" :index="index_product"></product-collection>
				</b-col>
			</b-row>
		</div>
	</section>
</template>
<script>
export default {
	props: ["productId"],
	components: {
		productCollection: () => import( "../product/product-collection.vue" ),
	},
	data() {
		return {
			title: "Recently Viewed",
			products: [],
			showquickviewmodel: false,
			showcomparemodal: false,
			showcartmodal: false,
			quickviewproduct: {},
			comapreproduct: {},
			cartproduct: {},
		};
	},
	computed: {
		productslist() {
			return ( JSON.parse( localStorage.getItem("recentlyProd") ) || [] ).filter( (item) => item.id !== this.productId );
		},
	}
};
</script>
