<template>
    <section class="section-blog p-0">
        <breadcrumbs type="pages" title="Cata1og Blog"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Cata1og Blog</h2>
        </div>
        <b-container class="p-relative">
            <vc-loading :isLoading="isLoading"></vc-loading>
            <b-row v-for="(item_category, index_category) in getterListBlogs" :key="`category-${ index_category }`">
                <b-col cols="12" class="title-default my-4">
                    <h2 class="title-inner-default left-title">{{ item_category.name }}</h2>
                </b-col>
                <b-col cols="12">
                    <b-carousel v-if="!show_mobile_carousel" id="carousel-category-blog-desktop" :interval="6000" controls label-next="" label-prev="" class="mb-4">
                        <b-carousel-slide v-for="(item_group_blog, index_group_blog) in item_category.blogs.desktop" :key="`category-group-${ index_group_blog }`">
                            <template #img>
                                <b-row class="m-0 justify-content-center">
                                    <b-col v-for="(item_blog, index_blog) in item_group_blog" :key="`blog-${ index_category }-${ index_group_blog }-${ index_blog }`" cols="4">
                                        <div class="default-block blog-block box-shadow align-items-end">
                                            <div class="media">
                                                <nuxt-link :to="{ path: `/blog/${ item_blog.slug }` }" class="media-body">
                                                    <img class="preview-banner-blog mb-2" :src="`${ backCmsUrl }${ item_blog.preview_image.items[0]?._image[0]?.attachment._urls['one-half'] }`" alt="" width="400" height="400" onerror="this.className='preview-banner-blog mb-2 error'">
                                                    <h3 class="chunk-lines chunk-lines-2">{{ item_blog.main_title }}</h3>
                                                    <p class="chunk-lines chunk-lines-6 text-left mb-2" v-html="item_blog.preview_description.items[0]?.content"></p>
                                                    <p class="text-left mb-1">Date: <strong>{{ item_blog.date }}</strong></p>
                                                </nuxt-link>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                    <b-carousel v-else id="carousel-category-blog-mobile" :interval="6000" controls label-next="" label-prev="" class="mb-4">
                        <b-carousel-slide v-for="(item_blog, index_blog) in item_category.blogs.mobile" :key="`blog-${ index_blog }`">
                            <template #img>
                                <div class="default-block blog-block box-shadow align-items-end">
                                    <div class="media">
                                        <nuxt-link :to="{ path: `/blog/${ item_blog.slug }` }" class="media-body">
                                            <img class="preview-banner-blog mb-2" :src="`${ backCmsUrl }${ item_blog.preview_image.items[0]._image[0].attachment._urls['one-half'] }`" alt="" width="400" height="400" onerror="this.className='preview-banner-blog mb-2 error'">
                                            <h3 class="chunk-lines chunk-lines-2">{{ item_blog.main_title }}</h3>
                                            <p class="chunk-lines chunk-lines-6 text-left mb-2" v-html="item_blog.preview_description.items[0].content"></p>
                                            <p class="text-left mb-1">Date: <strong>{{ item_blog.date }}</strong></p>
                                        </nuxt-link>
                                    </div>
                                </div>
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
        vcLoading: () => import('../../components/pages/loading.vue')
    },
    data() {
        return {
            backCmsUrl          : process.env.VUE_APP_CMS_BACK_URL_FILE,
            blogsByCategory     : [],
            show_mobile_carousel: false,
            isLoading           : true
        }
    },
    async mounted(){
        window.addEventListener('load', this.resizePage);
        window.addEventListener('resize', this.resizePage);
        await this.getListBlogs().then( (result_blogs) => {
            
            this.isLoading = false;
        });
    },
    computed:{
        ...mapGetters({
            getterListBlogs: 'getterListBlogs'
        })
    },
    methods:{
        ...mapActions({
            getListBlogs: 'getListBlogs'
        }),
        resizePage(event){
            this.show_mobile_carousel = window.innerWidth <= 991;
        },
    }
}
</script>
