<template>
    <section class="section-b-space light-layout body-container d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="order-success">
                        <i class="fa-solid fa-check-circle"></i>
                        <h2>Thanks for your Purchase</h2>
                        <p class="text-transform-none font-color-dark">Payment is Successfully Processsed</p>
						<p class="font-color-dark">Your order has been placed successfully. We've sent you a confirmation email with your order number.</p>
                        <p class="font-color-dark">You can see your orders by clicking <a target="_blank" :href="'/account/dashboard'">here</a></p>x
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
}
</script>