<template>
	<div class="icon-nav">
		<ul :class="{ 'login-user': getterLogin }">
			<li class="onhover-div mobile-search">
				<div class="icon-search" @click="event => openWidgetSearch(event)">
					<i class="fa-solid fa-magnifying-glass"></i>
				</div>
				<search-mobile v-if="openSearch" type="mobile" :openSearchMobile="openSearch"></search-mobile>
			</li>
			<li v-if="layout != 'default' || ( layout == 'default' && getterLogin )" class="onhover-div mobile-cart p-relative">
				<nuxt-link :to="{ path: '/account/cart'}" class="view-cart">
					<div class="custom-content-icon">
						<svg v-if="getterCart.details?.count > 0" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 26 26" style="transform: scaleX(-1);">
							<g id="Grupo_190" data-name="Grupo 190" transform="translate(0.9 0.9)">
								<path id="Trazado_58" data-name="Trazado 58" d="M1475.32,1635.715q-.044,0-.088,0a2.819,2.819,0,0,1-1.377-.522,2.374,2.374,0,0,1-.75-1.237l-2.3-8.467a.9.9,0,0,1-.023-.364,2.3,2.3,0,0,1,.484-1.1,2.178,2.178,0,0,1,1.045-.615.9.9,0,0,1,.215-.026h17.245a.9.9,0,0,1,0,1.8h-17.095a.422.422,0,0,0-.057.031.319.319,0,0,0-.016.035l2.246,8.253c.005.02.006.02.01.037a.653.653,0,0,0,.13.256,1.1,1.1,0,0,0,.389.126l12.858-.06h.052a1.481,1.481,0,0,0,.626-.128,1.075,1.075,0,0,0,.309-.408l3.337-12.5a2.459,2.459,0,0,1,.719-1.269,2.247,2.247,0,0,1,1.405-.456h1.287a.9.9,0,1,1,0,1.8h-1.316a.732.732,0,0,0-.222.04,1.018,1.018,0,0,0-.118.284c0,.016-.007.031-.011.047l-3.358,12.576a.88.88,0,0,1-.029.091,2.855,2.855,0,0,1-1.052,1.32,3.2,3.2,0,0,1-1.634.4l-12.9.06Z" transform="translate(-1471.669 -1620)" fill="#FFFFFF"></path>
								<path id="Trazado_59" data-name="Trazado 59" d="M1486.6,1639.392c-4.506,0-10.556-.038-11.569-.045a.9.9,0,0,1,.006-1.8h.006c6.186.041,15.646.075,16.351,0,.417-.042,1.116-.223,1.114-.967,0-.673-.686-.956-1.087-1.021h-2.338a.9.9,0,0,1,0-1.8h2.457l.056.007a2.945,2.945,0,0,1,2.712,2.807,2.789,2.789,0,0,1-2.734,2.764C1491.2,1639.38,1489.149,1639.392,1486.6,1639.392Z" transform="translate(-1473.072 -1619.894)" fill="#FFFFFF"></path>
								<path id="Elipse_4" data-name="Elipse 4" d="M2.5,1.8a.7.7,0,1,0,.7.7.7.7,0,0,0-.7-.7M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" transform="translate(14.966 19)" fill="#FFFFFF"></path>
								<path id="Elipse_5" data-name="Elipse 5" d="M2.5,1.8a.7.7,0,1,0,.7.7.7.7,0,0,0-.7-.7M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" transform="translate(0.966 19)" fill="#FFFFFF"></path>
								<path id="Trazado_60" data-name="Trazado 60" d="M1492.119,1631.5h-9.111a.9.9,0,0,1,0-1.8h9.111a.9.9,0,1,1,0,1.8Z" transform="translate(-1477.48 -1619)" fill="#FFFFFF"></path>
							</g>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 26 26" style="transform: scaleX(-1);">
							<g id="Grupo_190" data-name="Grupo 190" transform="translate(0.9 0.9)">
								<path id="Trazado_58" data-name="Trazado 58" d="M1475.32,1635.715q-.044,0-.088,0a2.819,2.819,0,0,1-1.377-.522,2.374,2.374,0,0,1-.75-1.237l-2.3-8.467a.9.9,0,0,1-.023-.364,2.3,2.3,0,0,1,.484-1.1,2.178,2.178,0,0,1,1.045-.615.9.9,0,0,1,.215-.026h17.245a.9.9,0,0,1,0,1.8h-17.095a.422.422,0,0,0-.057.031.319.319,0,0,0-.016.035l2.246,8.253c.005.02.006.02.01.037a.653.653,0,0,0,.13.256,1.1,1.1,0,0,0,.389.126l12.858-.06h.052a1.481,1.481,0,0,0,.626-.128,1.075,1.075,0,0,0,.309-.408l3.337-12.5a2.459,2.459,0,0,1,.719-1.269,2.247,2.247,0,0,1,1.405-.456h1.287a.9.9,0,1,1,0,1.8h-1.316a.732.732,0,0,0-.222.04,1.018,1.018,0,0,0-.118.284c0,.016-.007.031-.011.047l-3.358,12.576a.88.88,0,0,1-.029.091,2.855,2.855,0,0,1-1.052,1.32,3.2,3.2,0,0,1-1.634.4l-12.9.06Z" transform="translate(-1471.669 -1620)" fill="#FFFFFF"></path>
								<path id="Trazado_59" data-name="Trazado 59" d="M1486.6,1639.392c-4.506,0-10.556-.038-11.569-.045a.9.9,0,0,1,.006-1.8h.006c6.186.041,15.646.075,16.351,0,.417-.042,1.116-.223,1.114-.967,0-.673-.686-.956-1.087-1.021h-2.338a.9.9,0,0,1,0-1.8h2.457l.056.007a2.945,2.945,0,0,1,2.712,2.807,2.789,2.789,0,0,1-2.734,2.764C1491.2,1639.38,1489.149,1639.392,1486.6,1639.392Z" transform="translate(-1473.072 -1619.894)" fill="#FFFFFF"></path>
								<path id="Elipse_4" data-name="Elipse 4" d="M2.5,1.8a.7.7,0,1,0,.7.7.7.7,0,0,0-.7-.7M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" transform="translate(14.966 19)" fill="#FFFFFF"></path>
								<path id="Elipse_5" data-name="Elipse 5" d="M2.5,1.8a.7.7,0,1,0,.7.7.7.7,0,0,0-.7-.7M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" transform="translate(0.966 19)" fill="#FFFFFF"></path>
							</g>
						</svg>
					</div>
					<span v-if="getterCart.details?.count > 0" class="cart-quantity">{{ getterCart.details?.count }}</span>
				</nuxt-link>
				<ul class="show-div shopping-cart box-shadow">
					<li :class="`content-loading loading-header-cart${ !isLoading ? ' hide' : '' }`">
						<vc-loading :isLoading="isLoading"/>
					</li>
					<li v-if="getterCart.products.length == 0" class="p-5">
						<img :src="require(`@/assets/images/icon-empty-cart_full.webp`)" class="img-fluid d-block mb-2 mx-auto" alt="empty cart" width="60" height="60"/>
						<h4 class="text-center">Your cart is currently empty.</h4>
					</li>
					<li v-if="getterCart.products?.length > 0" v-for="(item_product_cart, index_product_cart) in getterCart.products" :key="`product-cart-${ index_product_cart }`" :class="`product-item${ item_product_cart.all_discounts.stock.value > 0 && getterCart.details.apply_discounts.stock ? ' selected-discount-stock' : '' }`">
						<nuxt-link :to="{ path: `/products/${ item_product_cart.handle }` }">
							<div :class="`d-flex media p-relative`">
								<span class="quantity p-absolute">{{ item_product_cart.quantity }}</span>
								<img alt class="mr-2" :src="( item_product_cart.image ? item_product_cart.image.src : null ) || `${ back_url }/images/products/product-default_320.webp`">
								<div class="media-body">
									<h4 class="font-weight-normal mb-1">{{item_product_cart.brand}}</h4>
									<small class="font-weight-normal">{{item_product_cart.sku}}</small>
									<h4 class="font-weight-normal">
										<vc-format-price type="cart-header" :discount_brand="item_product_cart.all_discounts.brand" :discount_percentage="item_product_cart.all_discounts.coupon.percentage" :price="item_product_cart.price" :discount_price="item_product_cart.discount_price" :quantity="item_product_cart.quantity" :discount_stock="item_product_cart.all_discounts.stock" :apply_discount_stock="getterCart.details.apply_discounts.stock"></vc-format-price>
									</h4>
								</div>
							</div>
						</nuxt-link>
						<div class="close-circle" @click="removeCartItem(item_product_cart)">
							<i class="fa-solid fa-times"></i>
						</div>
					</li>
					<li v-if="getterCart.products.length > 0">
						<div class="total">
							<h5 class="font-color-dark d-flex justify-content-between">
								subtotal :
								<span class="font-color-dark font-weight-normal">{{ formatCurrency(getterCart.details?.total, true).format }}</span>
							</h5>
						</div>
					</li>
					<li v-if="getterCart.products.length > 0">
						<div class="d-flex justify-content-end pb-2">
							<nuxt-link :to="{ path: '/account/cart' }" :class="'btn btn-solid btn-secondary p-2 mr-2 view-cart'">
								view cart
							</nuxt-link>
						</div>
					</li>
				</ul>
			</li> 
		</ul>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { formatData } from '../../constants/config.js'
export default {
	props: ["layout"],
	components: {
		vcLoading		: () => import('../../components/pages/loading.vue' ),
		vcFormatPrice	: () => import('../../components/product/format-price.vue' ),
		searchMobile	: () => import('../../components/header/search-mobile.vue')
	},
	data() {
		return {
			back_url			: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
			isLoading		: false,
			openSearch		: false,
			cartProducts	: null,
			cartTotal		: 0,
			countItems		: 0
		}
	},
	computed: {
		...mapGetters({
			getterLogin		: 'getterLogin',
			getterCart		: 'getterCart'
		})
	},
	methods: {
		...mapActions({
			actionCart: 'actionCart'
		}),
		formatCurrency(price, totalAmount = null){
			
			return formatData.currency(price, totalAmount);
		},
		openWidgetSearch(event){
			this.openSearch = true;
			this.$emit('open-search-mobile', this.openSearch);
		},
		async removeCartItem(product) {
			this.isLoading = true;
			await this.actionCart({ action: 'remove', products: [{ product_id: product.product_id, variant_id: product.variant_id, quantity: parseInt(product.quantity) }] }).then( (result_cart) => {

				console.log( this.getterCart );
				this.isLoading = false;
			});
		},
	}
}
</script>
