<template>
	<div class="no-arrow carousel-logo-brand py-4">
		<b-carousel v-if="getSlideshow && !getShowMobileCarousel" id="carousel-desktop-logo-brands" :interval="3000">
			<b-carousel-slide v-for="(item_slide, index_slide) in getSlideshow.desktop" :key="`slide_${ index_slide }`">
				<template #img>
					<b-row>
						<b-col v-for="(item_brand, index_brand) in item_slide" :key="`slide_${ index_slide }_brand_${ index_brand }`" :cols="3" class="d-flex align-items-center justify-content-center">
							<div class="logo-block text-center">
								<nuxt-link :to="{ path: item_brand.background_link }">
									<img loading="lazy" :src="require(`@/assets/images/logo-brands/white/${ item_brand.image.alt }.webp`)" :alt="item_brand.image.alt" :title="item_brand.image.alt" onerror="this.onerror=null;this.src='';" width="150px" height="150px"/>
								</nuxt-link>
							</div>
						</b-col>
					</b-row>
				</template>
			</b-carousel-slide>
		</b-carousel>
		<b-carousel v-if="getSlideshow && getShowMobileCarousel" id="carousel-mobile-logo-brands" :interval="3000">
			<b-carousel-slide v-for="(item_slide, index_slide) in getSlideshow.mobile" :key="`slide_${ index_slide }`">
				<template #img>
					<b-row>
						<b-col v-for="(item_brand, index_brand) in item_slide" :key="`slide_${ index_slide }_brand_${ index_brand }`" :cols="6" class="d-flex align-items-center justify-content-center">
							<div class="logo-block text-center">
								<nuxt-link :to="{ path: item_brand.background_link }">
									<img loading="lazy" :src="require(`@/assets/images/logo-brands/white/${ item_brand.image.alt }.webp`)" :alt="item_brand.image.alt" :title="item_brand.image.alt" onerror="this.onerror=null;this.src='';" width="150px" height="150px"/>
								</nuxt-link>
							</div>
						</b-col>
					</b-row>
				</template>
			</b-carousel-slide>
		</b-carousel>
	</div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';
import { arrayHelpers } from '../../constants/config';
export default {
	data() {
		return {
			show_mobile_carousel: window.innerWidth <= 1200
		}
	},
	mounted(){
		
		window.addEventListener('load', this.resizewindow);
		window.addEventListener('resize', this.resizewindow);
	},
	computed:{
		...mapGetters({
			getterCompanyData	: 'getterCompanyData',
		}),
		getSlideshow(){
			
			let slideshow = this.getterCompanyData?.slideshows?.brands ? this.getterCompanyData : JSON.parse( localStorage.getItem('general-settings') );
			
			slideshow = slideshow ? slideshow.slideshows.brands : null;
			
			if( slideshow ){
				
				return {
					desktop: arrayHelpers.chunkArray( JSON.parse( JSON.stringify( slideshow.images ) ), 4 ),
					mobile: arrayHelpers.chunkArray( JSON.parse( JSON.stringify( slideshow.images ) ), 2 )
				}
			}
			else{
				
				return null;
			}
		},
		getShowMobileCarousel(){
			return this.show_mobile_carousel;
		}
	},
	methods: {
		resizewindow(e){
			this.show_mobile_carousel = window.innerWidth <= 1200;
		},
	}
}
</script>
