<template>
	<div class="content-loading content-collection p-relative">
		<breadcrumbs :type="getHandleCollection != 'price-catalog' ? 'collection' : 'pages'" subtitle="collections" :title="getHandleCollection != 'price-catalog' ? getterCollection?.title : 'Price Catalog'"></breadcrumbs>
		<vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
		<div v-if="!getterLogin && getHandleCollection != 'price-catalog'" class="section-dark">
			<b-container>
				<b-row>
					<b-col cols="12">
						<div class="content-text">
							<h3>Work with the best <strong class="font-color-light">Fajas Colombianas Wholesale</strong> in the US</h3>
							<nuxt-link :to="{ path:'/account/register' }" :class="'btn btn-solid'">Start earning with cata1og now</nuxt-link>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<collection-section-private-label v-if="getHandleCollection == 'private-label' && !$route.query.catalog"></collection-section-private-label>
		<div v-else-if="getterCollection?.products_paginate" class="top-banner-wrapper">
			<div class="bg-img bg-banner-collection">
				<div class="top-banner-content small-section container title-default">
					<h1 class="title-collection title-inner-default">{{ getterCollection?.title }}</h1>
					<p v-if="search">{{search ? `(${search})` : ''}}</p>
					<h4 v-if="getHandleCollection == 'price-catalog' && getterCollection" class="font-weight-normal mt-4 text-transform-none">This Price Catalog was created for <strong>{{ getterCollection.data_catalog.full_name || getterCollection.data_catalog.email }}</strong> and will be available for</h4>
					<b-row v-if="getHandleCollection == 'price-catalog' && getterCollection" class="countdown-timer">
						<b-col cols="3" class="countdown-timer-item">
							<div class="countdown-timer-item-number">{{ getCountDownCatalog.days }}</div>
							<div class="countdown-timer-item-text">Days</div>
						</b-col>
						<b-col cols="3" class="countdown-timer-item">
							<div class="countdown-timer-item-number">{{ getCountDownCatalog.hours }}</div>
							<div class="countdown-timer-item-text">Hours</div>
						</b-col>
						<b-col cols="3" class="countdown-timer-item">
							<div class="countdown-timer-item-number">{{ getCountDownCatalog.minutes }}</div>
							<div class="countdown-timer-item-text">Minutes</div>
						</b-col>
						<b-col cols="3" class="countdown-timer-item">
							<div class="countdown-timer-item-number">{{ getCountDownCatalog.seconds }}</div>
							<div class="countdown-timer-item-text">Seconds</div>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
		<section class="section-b-space">
			<content-collection :handleCollection="getHandleCollection" @initCountDownCatalog="initCountDownCatalog" @updateLoadingCollection="updateLoadingCollection" @changeAccessCatalog="changeAccessCatalog"></content-collection>
		</section>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	components: {
		vcLoading						: () => import("../../components/pages/loading.vue"),
		breadcrumbs						: () => import("../../components/pages/breadcrumbs.vue"),
		collectionSectionPrivateLabel	: () => import("../../components/product/collection-section-private-label.vue"),
		contentCollection   			: () => import( '../../components/product/collection-content.vue' )
	},
	data() {
		return {
			isLoading 			: true,
			access_catalog		: false,
			handleCollection	: null,
			time_left_catalog	: 0,
			count_down_catalog 	: { days: 0, hours: 0, minutes: 0, seconds: 0 },
		};
	},
	async mounted() {
		
		this.handleCollection = !this.$route.params.id ? "all-products" : this.$route.params.id;
		this.initCountDownCatalog();
		this.excuteCountDownCatalog();
		setInterval(this.excuteCountDownCatalog, 1000);
	},
	computed: {
		...mapGetters({
			getterLogin				: 'getterLogin',
			getterCollection		: "getterCollection"
		}),
		getAccessCatalog(){
			return this.access_catalog;
		},
		getHandleCollection(){
			return this.handleCollection;
		},
		getTimeLeftCatalog(){
			return this.time_left_catalog;
		},
		getCountDownCatalog(){
			return this.count_down_catalog;
		}
	},
	methods: {
		changeAccessCatalog(access) {
			this.access_catalog = access;
		},
		updateLoadingCollection(loading_collection){
			this.isLoading = loading_collection;
		},
		initCountDownCatalog(time_left){
			
			this.time_left_catalog = time_left;
			this.count_down_catalog = {
				days	: Math.floor( this.getTimeLeftCatalog / (60 * 60 * 24) ),
				hours	: Math.floor( (this.getTimeLeftCatalog % (60 * 60 * 24)) / (60 * 60) ),
				minutes	: Math.floor( (this.getTimeLeftCatalog % (60 * 60)) / 60 ),
				seconds	: this.getTimeLeftCatalog % 60
			};
		},
		excuteCountDownCatalog() {
			
			if( this.getTimeLeftCatalog >= 0 ){
				
				this.count_down_catalog = {
					days	: Math.floor( this.getTimeLeftCatalog / (60 * 60 * 24) ) > 9 ? Math.floor( this.getTimeLeftCatalog / (60 * 60 * 24) ) : `0${ Math.floor( this.getTimeLeftCatalog / (60 * 60 * 24) ) }`,
					hours	: Math.floor( (this.getTimeLeftCatalog % (60 * 60 * 24)) / (60 * 60) ) > 9 ? Math.floor( (this.getTimeLeftCatalog % (60 * 60 * 24)) / (60 * 60) ) : `0${ Math.floor( (this.getTimeLeftCatalog % (60 * 60 * 24)) / (60 * 60) ) }`,
					minutes	: Math.floor( (this.getTimeLeftCatalog % (60 * 60)) / 60 ) > 9 ? Math.floor( (this.getTimeLeftCatalog % (60 * 60)) / 60 ) : `0${ Math.floor( (this.getTimeLeftCatalog % (60 * 60)) / 60 ) }`,
					seconds	: this.getTimeLeftCatalog % 60 > 9 ? this.getTimeLeftCatalog % 60 : `0${ this.getTimeLeftCatalog % 60 }`
				};
				
				this.time_left_catalog--;
			}
			else if( this.getAccessCatalog ){
				
				window.location.reload();
			}
		}
	}
};
</script>
