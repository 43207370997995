<template>
	<section class="p-0">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="error-section">
						<h2>401</h2>
						<h3>Unauthorized access, please login</h3>
						<nuxt-link :to="{ path: '/account/login' }" class="btn btn-solid shadow-sm">go to login</nuxt-link>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
}
</script>
