<template>
	<div class="home">
		<slider></slider>
		<b-container fluid class="container">
			<b-row class="home-section section-info-register m-0">
				<b-col cols="12" md="6" lg="6" class="col-text justify-content-center p-0 my-4">
					<div class="content-text">
						<h2>We are the <strong>world‘s</strong> leading B2B Colombian shapewear sellers.</h2>
					</div>
				</b-col>
				<b-col cols="12" md="6" lg="6" class="d-flex justify-content-center image-product-home">
					<img src="/cata1og-home-banner.webp" loading="eager" width="980" height="980" alt="DAG-2396" class="product-image-info-register" sizes="(max-width: 600px) 980px, (max-width: 700px) 980px, (max-width: 800px) 980px, (max-width: 1000px) 980px, (max-width: 1200px) 980px, (max-width: 1400px) 980px, (max-width: 1600px) 980px, (max-width: 1800px) 980px, (max-width: 2000px) 980px, (max-width: 2200px) 980px, (max-width: 2400px) 980px, (max-width: 2600px) 980px, (max-width: 2800px) 980px, (max-width: 3000px) 980px" :srcset="`/cata1og-home-banner.webp 600w, /cata1og-home-banner.webp 700w, /cata1og-home-banner.webp 800w, /cata1og-home-banner.webp 1000w, /cata1og-home-banner.webp 1200w, /cata1og-home-banner.webp 1400w, /cata1og-home-banner.webp 1600w, /cata1og-home-banner.webp 1800w, /cata1og-home-banner.webp 2000w, /cata1og-home-banner.webp 2200w, /cata1og-home-banner.webp 2400w, /cata1og-home-banner.webp 2600w, /cata1og-home-banner.webp 2800w, /cata1og-home-banner.webp 3000w`"/>
				</b-col>
			</b-row>
			<b-row id="slider-services" class="services justify-content-center p-relative">
				<b-col id="item-carousel-services-1" cols="12" sm="6" lg="3" class="col-carousel align-items-center d-flex justify-content-center w-100 show-item-carousel">
					<div class="default-block w-100 px-2">
						<div class="media default-border service-border box-shadow">
							<div class="media-body">
								<div class="custom-content-icon">
									<i class="fa-solid fa-truck"></i>
								</div>
								<span class="title-block">Free shipping</span>
								<p>When you spend $300+ (Conditions Apply)</p>
							</div>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-services-2" cols="12" sm="6" lg="3" class="col-carousel align-items-center d-flex justify-content-center w-100 hide-item-carousel">
					<div class="default-block w-100 px-2">
						<div class="media default-border service-border box-shadow">
							<div class="media-body">
								<div class="custom-content-icon">
									<i class="fa-solid fa-phone"></i>
								</div>
								<span class="title-block">Give us a call</span>
								<p>
									<a href="tel:+17865743472">
										<strong>+1 786 574 3472</strong>
									</a>
								</p>
							</div>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-services-3" cols="12" sm="6" lg="3" class="col-carousel align-items-center d-flex justify-content-center w-100 hide-item-carousel">
					<div class="default-block w-100 px-2">
						<div class="media default-border service-border box-shadow">
							<div class="media-body">
								<div class="custom-content-icon">
									<i class="fa-solid fa-headset"></i>
								</div>
								<span class="title-block">Chat with us</span>
								<p>We offer Friendly chat support</p>
							</div>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-services-4" cols="12" sm="6" lg="3" class="col-carousel align-items-center d-flex justify-content-center w-100 hide-item-carousel">
					<div class="default-block w-100 px-2">
						<div class="media default-border service-border box-shadow">
							<div class="media-body">
								<div class="custom-content-icon">
									<i class="fa-solid fa-box"></i>
								</div>
								<span class="title-block">We offer</span>
								<p>Local Pickup in FL</p>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row class="home-section section-dark">
				<b-col cols="12">
					<div class="content-text">
						<span class="d-block w-100">Expand your sales portfolio selling <strong class="font-color-light">Fajas Colombianas Wholesale</strong></span>
						<span class="d-block w-100">Work with the best portfolio</span>
					</div>
				</b-col>
			</b-row>
			<div class="section-product-tabs">
				<div class="title-default section-t-space">
					<span>Special Offer</span>
					<h2 class="title-inner-default">Top Products</h2>
				</div>
				<section class="section-b-space pt-0 mt-5 p-relative">
					<b-row id="slider-top-products" class="p-relative justify-content-center">
						<b-col cols="12" lg="3" v-for="(item_product, index_product) in getterCollectionHome" :key="`product-${ index_product }`"  :id="`item-carousel-top-products-${ index_product + 1 }`" :class="`d-flex justify-content-center col-carousel ${ index_product == 0 ? 'show-item-carousel' : 'hide-item-carousel' }`">
							<product-collection :product="item_product" :hideDescription="true" :handleCollection="'best-sellers'" :id="`product-${ index_product }`" :class="`px-2`"></product-collection>
						</b-col>
					</b-row>
				</section>
			</div>
			<b-row id="slider-benefits" class="benefits my-4 justify-content-center p-relative">
				<b-col id="item-carousel-benefits-1" cols="12" sm="6" md="6" lg="3" class="default-block col-carousel show-item-carousel">
					<div class="media default-border benefit-border box-shadow">
						<div class="media-body">
							<div class="custom-content-icon">
								<i class="fa-solid fa-truck"></i>
							</div>
							<p>You dont have to import them by yourself, we do it for you.</p>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-benefits-2" cols="12" sm="6" md="6" lg="3" class="default-block col-carousel hide-item-carousel">
					<div class="media default-border benefit-border box-shadow">
						<div class="media-body">
							<div class="custom-content-icon">
								<i class="fa-solid fa-headset"></i>
							</div>
							<p>From the first moment, you will receive all the help, assistance, and support you need regarding the products.</p>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-benefits-3" cols="12" sm="6" md="6" lg="3" class="default-block col-carousel hide-item-carousel">
					<div class="media default-border benefit-border box-shadow">
						<div class="media-body">
							<div class="custom-content-icon">
								<i class="fa-solid fa-gem"></i>
							</div>
							<p>With us, you will earn from <strong>40% to 60%</strong> margin on sale.</p>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-benefits-4" cols="12" sm="6" md="6" lg="3" class="default-block col-carousel hide-item-carousel">
					<div class="media default-border benefit-border box-shadow">
						<div class="media-body">
							<div class="custom-content-icon">
								<i class="fa-solid fa-pencil"></i>
							</div>
							<p>Permament personalized consulting with our experts who Will support you at all times.</p>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row class="section-dark list-first-order-benefits">
				<b-col cols="12">
					<div class="content-text">
						<div class="title-default">
							<h2 class="title-inner-default title-info d-flex align-items-center">So, what would you receive with your 1k minimum first order ?</h2>
						</div>
					</div>
				</b-col>
				<b-col cols="12">
					<b-row id="slider-first-order" class="m-0 justify-content-center p-relative">
						<b-col id="item-carousel-first-order-1" cols="12" lg="6" class="p-0 col-carousel show-item-carousel">
							<b-row class="m-0">
								<b-col cols="12" class="item-dark-check d-flex p-0 mb-3">
									<i class="fa-solid fa-square-check p-relative"></i>
									<span class="d-flex align-items-center chunk-lines chunk-lines-3">Free shipping on your first order.</span>
								</b-col>
								<b-col cols="12" class="item-dark-check d-flex p-0 mb-3">
									<i class="fa-solid fa-square-check p-relative"></i>
									<span class="d-flex align-items-center chunk-lines chunk-lines-3">Digital and Physical Marketing Material for your business</span>
								</b-col>
								<b-col cols="12" class="item-dark-check d-flex p-0 mb-3">
									<i class="fa-solid fa-square-check p-relative"></i>
									<span class="d-flex align-items-center chunk-lines chunk-lines-3">Specialized training for the sale of the products to you and you team.</span>
								</b-col>
							</b-row>
						</b-col>
						<b-col id="item-carousel-first-order-2" cols="12" lg="6" class="p-0 col-carousel hide-item-carousel">
							<b-row class="m-0">
								<b-col cols="12" class="item-dark-check d-flex p-0 mb-3">
									<i class="fa-solid fa-square-check p-relative"></i>
									<span class="d-flex align-items-center chunk-lines chunk-lines-3">Our referral program: Earn $200 dollars for each person you refer.</span>
								</b-col>
								<b-col cols="12" class="item-dark-check d-flex p-0 mb-3">
									<i class="fa-solid fa-square-check p-relative"></i>
									<span class="d-flex align-items-center chunk-lines chunk-lines-3">Exclusive Access to permanent personalized consulting with our experts who will support you at all times.</span>
								</b-col>
								<b-col cols="12" class="item-dark-check d-flex p-0 mb-3">
									<i class="fa-solid fa-square-check p-relative"></i>
									<span class="d-flex align-items-center chunk-lines chunk-lines-3">New orders with no minimun quantity required.</span>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="12">
					<nuxt-link :to="{ path: '/account/register' }" title="Register with Cata1og now" :class="'btn btn-solid btn-light'">Register with Cata1og now</nuxt-link>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12" class="my-4">
					<div class="title-default">
						<h2 class="title-inner-default">Frequently asked questions</h2>
					</div>
				</b-col>
				<faq></faq>
                <logo-slider-black></logo-slider-black>
			</b-row>
		</b-container>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { effectSlider }  from '../constants/config.js';
export default {
	components: {
		slider			    : () => import( '../components/home/slider.vue' ),
		productCollection	: () => import('../components/product/product-collection.vue'),
		logoSliderBlack		: () => import( '../components/home/logo-slider-black.vue' ),
		faq					: () => import( '../components/account/faq.vue' )
	},
	data() {
		return {
			isLoading					: true,
			collection_products			: [],
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadPage);
		
		await this.getCollectionHome();
	},
	computed: {
		...mapGetters({
			getterCollectionHome: 'getterCollectionHome',
		}),
	},
	methods: {
		...mapActions({
			getCollectionHome: 'getCollectionHome'
		}),
		loadPage(event){
			setInterval(() => {
				if( this.getterCollectionHome ){
					
					effectSlider('slider-top-products', this.getterCollectionHome.length, 1500 );
				}
				effectSlider('slider-services', 4, 1500 );
				effectSlider('slider-benefits', 4, 1500 );
				effectSlider('slider-first-order', 2, 1500 );
			}, 3000);
		}
	}
}
</script>