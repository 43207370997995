<template>
	<section class="login-page section-b-space pt-0">
		<breadcrumbs type="account" title="Login"></breadcrumbs>
		<b-container class="pt-4">
			<b-row>
				<b-col cols="12" lg="6">
					<h3>Login</h3>
					<div class="theme-card shadow-sm">
						<validation-observer v-slot="{ handleSubmit }">
							<b-form class="register-form" @submit.prevent="handleSubmit(onSubmit)">
								<validation-provider rules="required" tag="div" v-slot="{ errors }" name="Email" class="valid-field">
									<b-form-group label="Email" label-for="email" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
										<b-form-input type="text" v-model="email" placeholder="Email" name="email"/>
										<span class="validate-error">{{ errors[0] }}</span>
									</b-form-group>
								</validation-provider>
								<validation-provider rules="required" tag="div" v-slot="{ errors }" name="Password" class="valid-field mb-4">
									<b-form-group label="Password" label-for="password" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
										<b-input-group>
											<b-form-input :type="`${ showPassword ? 'text' : 'password' }`" v-model="password" id="password" placeholder="Enter your password" name="password"/>
											<span class="validate-error">{{ errors[0] }}</span>
											<b-input-group-append>
												<b-button class="show-password-icon" @click="showPassword = !showPassword;">
													<i :class="`fa-solid ${ showPassword ? 'fa-eye-slash' : 'fa-eye' }`"></i>
												</b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</validation-provider>
								<div class="login-buttons d-flex justify-content-between align-items-center">
									<button type="submit" class="btn btn-solid shadow-sm" :disabled="isAvailableLogin">Login</button>
									<nuxt-link :to="{ path: '/account/forgot-password' }">Forgot your Password ?</nuxt-link>
								</div>
							</b-form>
						</validation-observer>
					</div>
				</b-col>
				<b-col cols="12" lg="6" class="right-login">
					<h3>New Customer</h3>
					<div class="theme-card authentication-right shadow-sm">
						<h6 class="title-font">Create A Account</h6>
						<p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
						<nuxt-link :to="{ path: '/account/register'}" class="btn btn-solid shadow-sm">Create an Account</nuxt-link>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<div class="section-dark mt-4">
			<b-container>
				<b-row>
					<b-col cols="12">
						<div class="content-text">
							<div class="icon-content">
								<i class="fa-solid fa-exclamation-circle"></i>
							</div>
							<h3 class="text-transform-none">With the launch of <strong class="font-color-light">Cata1og 2.0</strong>, we have made improvements to our system for <strong class="font-color-light">your security and better experience</strong>,</h3>
							<h3 class="text-transform-none">therefore it is possible that your current password does not work, so we invite you to change it at the <strong class="font-color-light">following link</strong></h3>
							<nuxt-link :to="{ path:'/account/forget-password'  }" :class="'btn btn-solid mt-0'">Reset your password</nuxt-link>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	components: {
		breadcrumbs: () => import( '../../components/pages/breadcrumbs.vue' )
	},
	data() {
		return {
			email: null,
			password: null,
			showPassword: false,
			availableLogin: false
		}
	},
	computed: {
		...mapGetters({
			getterLogin			: 'getterLogin',
			getterUser			: 'getterUser',
			getterProfileImage	: 'getterProfileImage',
			getterNotification	: 'getterNotification'
		}),
		isAvailableLogin(){
			return this.availableLogin;
		}
	},
	mounted(){
		if ( this.getterLogin ) {
			
			this.$router.push("/");
		}
	},
	methods: {
		...mapActions({
			loginUser: "loginUser"
		}),
		async onSubmit() {
			if(this.email && this.password) {
				this.availableLogin = true;
				await this.loginUser({ email: this.email, password: this.password }).then( (result_user) => {
					
					this.availableLogin = false;
					if(this.getterUser){
						
						this.$root.$emit('update-profile-image', this.getterProfileImage);
						this.$nuxt.$emit('login-user', true);
						this.$router.replace('/')
					}
				});
			}
		},
	}
}
</script>
