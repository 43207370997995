<template>
	<div class="section-b-space pt-0 dashboard-content content-loading p-relative">
		<breadcrumbs type="account" title="Dashboard"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">Dahsboard</h2>
		</div>
		<b-container>
			<b-row class="dashboard-options">
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/your-orders' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32.282 23.064">
										<g id="ico-orders" transform="translate(-385.832 -1235.991)">
											<g id="Group_35" data-name="Group 35" transform="translate(396.525 1236.491)">
												<path id="Path_54" data-name="Path 54" d="M111.982,72.2h21.09V59a1.873,1.873,0,0,0-1.871-1.871H113.854A1.873,1.873,0,0,0,111.982,59Zm20.472-.617H112.6V59a1.256,1.256,0,0,1,1.254-1.254H131.2A1.255,1.255,0,0,1,132.454,59Z" transform="translate(-111.982 -57.125)" fill="none" stroke="#303030" stroke-width="1"></path>
											</g>
											<g id="Group_36" data-name="Group 36" transform="translate(389.992 1250.793)">
												<path id="Path_55" data-name="Path 55" d="M148.032,88a3.881,3.881,0,1,0-3.881-3.881A3.886,3.886,0,0,0,148.032,88Zm0-7.144a3.263,3.263,0,1,1-3.264,3.263A3.267,3.267,0,0,1,148.032,80.858Z" transform="translate(-144.151 -80.241)" fill="none" stroke="#303030" stroke-width="1"></path>
											</g>
											<g id="Group_37" data-name="Group 37" transform="translate(406.534 1250.948)">
												<path id="Path_56" data-name="Path 56" d="M121.414,88.1a3.75,3.75,0,1,0-3.75-3.75A3.754,3.754,0,0,0,121.414,88.1Zm0-6.883a3.133,3.133,0,1,1-3.132,3.133A3.136,3.136,0,0,1,121.414,81.22Z" transform="translate(-117.664 -80.603)" fill="none" stroke="#303030" stroke-width="1"></path>
											</g>
											<g id="Group_38" data-name="Group 38" transform="translate(386.915 1241.537)">
												<path id="Path_57" data-name="Path 57" d="M149.736,72.409h7.391V65.3h-.617v6.493h-6.773Z" transform="translate(-149.736 -65.299)" fill="none" stroke="#303030" stroke-width="1"></path>
											</g>
											<g id="Group_39" data-name="Group 39" transform="translate(397.445 1254.334)">
												<rect id="Rectangle_21" data-name="Rectangle 21" width="9.339" height="0.617" fill="none" stroke="#303030" stroke-width="1"></rect>
											</g>
											<g id="Group_40" data-name="Group 40" transform="translate(413.928 1251.554)">
												<path id="Path_58" data-name="Path 58" d="M113.938,84.884h2.479v-3.36H115.8v2.743h-1.862Z" transform="translate(-113.938 -81.524)" fill="none" stroke="#303030" stroke-width="1"></path>
											</g>
											<g id="Group_41" data-name="Group 41" transform="translate(404.404 1251.443)">
												<rect id="Rectangle_22" data-name="Rectangle 22" width="0.617" height="3.051" fill="none" stroke="#303030" stroke-width="1"></rect>
											</g>
											<g id="Group_42" data-name="Group 42" transform="translate(399.642 1251.368)">
												<rect id="Rectangle_23" data-name="Rectangle 23" width="0.617" height="3.162" fill="none" stroke="#303030" stroke-width="1"></rect>
											</g>
											<g id="Group_45" data-name="Group 45" transform="translate(386.332 1241.006)">
												<path id="Path_61" data-name="Path 61" d="M148.552,78.945a8.266,8.266,0,0,0,1.1-.08l.342-.109-.2-.585-.284.1a3.327,3.327,0,0,1-2.582-.36.766.766,0,0,1-.3-.518V69.87l4.162-4.815h5.49v-.617h-5.772l-4.5,5.2v7.752a1.34,1.34,0,0,0,.489.964A3.027,3.027,0,0,0,148.552,78.945Z" transform="translate(-146.018 -64.438)" fill="none" stroke="#303030" stroke-width="1"></path>
											</g>
										</g>
									</svg>
								</div>
								<h4>Your Orders</h4>
								<p>Track your orders and order details</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/your-statement' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 21.5 25.514">
										<g id="ico-statements" transform="translate(-867.25 -299.25)">
											<path id="Path_332" data-name="Path 332" d="M2,0H18a2,2,0,0,1,2,2V19.243L15.828,24H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" transform="translate(868 300)" fill="none" stroke="#303030" stroke-width="1.5"></path>
											<line id="Line_47" data-name="Line 47" x2="11" transform="translate(872.5 305.398)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
											<line id="Line_48" data-name="Line 48" x2="11" transform="translate(872.5 309.5)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
											<line id="Line_49" data-name="Line 49" x2="11" transform="translate(872.5 313.602)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
											<line id="Line_50" data-name="Line 50" x2="6" transform="translate(872.5 317.704)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
											<path id="Path_333" data-name="Path 333" d="M362.723,405.53h-3.964a1.3,1.3,0,0,0-1.3,1.51c.049,1.556,0,4.185,0,4.185" transform="translate(525.277 -87.225)" fill="none" stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
										</g>
									</svg>
								</div>
								<h4>Your Statement</h4>
								<p>Check the status of your balance</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/sales-agent' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24.445" height="24.445" viewBox="0 0 24.445 24.445">
										<g data-name="ico-sale agents" transform="translate(-102.478 64.025)">
											<path id="Path_108" data-name="Path 108" d="M265.9,102.945a1.952,1.952,0,0,1-1.509-1.521,1.923,1.923,0,0,1,1.025-2.114,1.9,1.9,0,0,1,2.316.466.7.7,0,0,0,.64.3c.779-.016,1.558,0,2.337-.006a.978.978,0,0,0,1.089-1.1q0-4.842,0-9.683a.972.972,0,0,1,1.1-1.1c.58,0,1.161.013,1.741,0a3.835,3.835,0,0,1,3.793,3.01c.018.085.044.169.066.253v4.389c-.026.1-.051.2-.078.3a3.8,3.8,0,0,1-2.88,2.871,6.413,6.413,0,0,1-1.564.088c-.15,0-.227.029-.238.207a2.856,2.856,0,0,1-2.873,2.706c-.89,0-1.779,0-2.669,0-.12,0-.233-.005-.315.117a2.034,2.034,0,0,1-1.216.821Zm7.841-9.316c0,1.08.007,2.16-.005,3.24,0,.236.075.309.3.289.189-.016.381,0,.572,0a1.909,1.909,0,0,0,1.96-1.964c.014-1.032.012-2.065,0-3.1a1.9,1.9,0,0,0-1.44-1.9,4.472,4.472,0,0,0-1.067-.068c-.318,0-.319,0-.319.309Q273.74,92.033,273.74,93.629Z" transform="translate(-151.572 -142.525)" fill="#303030"></path>
											<path id="Path_109" data-name="Path 109" d="M102.479-48.882c0-.517,0-1.033,0-1.55a3.77,3.77,0,0,1,1.718-3.274.37.37,0,0,0,.2-.319,10.058,10.058,0,0,1,3.088-7.044,9.926,9.926,0,0,1,6.223-2.905,10.1,10.1,0,0,1,8.02,2.7,9.948,9.948,0,0,1,2.652,3.937.979.979,0,0,1-.345,1.2.968.968,0,0,1-1.461-.465,8.558,8.558,0,0,0-1.331-2.33,8.177,8.177,0,0,0-5.455-3.058,8.115,8.115,0,0,0-7.019,2.41,8.18,8.18,0,0,0-2.384,5c-.02.173.029.245.215.242.533-.009,1.065-.005,1.6,0a.949.949,0,0,1,1,1.006q0,4.448,0,8.9a.952.952,0,0,1-1.02,1.008q-.966,0-1.932,0a3.814,3.814,0,0,1-3.759-3.76C102.477-47.754,102.479-48.318,102.479-48.882Zm4.771.017c0-1.1,0-2.192,0-3.288,0-.186-.055-.259-.245-.246-.206.013-.413,0-.619,0a1.907,1.907,0,0,0-1.965,1.958c-.015,1.032-.012,2.065,0,3.1a1.887,1.887,0,0,0,1.717,1.947c.277.041.556,0,.832.027.223.019.284-.065.282-.283C107.244-46.721,107.25-47.794,107.25-48.866Z" transform="translate(0)" fill="#303030"></path>
										</g>
									</svg>
								</div>
								<h4>Sales Agent</h4>
								<p>Contact your sales agent, we are here to assist you</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/address-directory' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24.615" height="24.446" viewBox="0 0 24.615 24.446">
										<g id="ico-address" transform="translate(5165.448 15461.75)">
											<path id="Path_73" data-name="Path 73" d="M66.447,3.471a18.987,18.987,0,0,1-4.832-.552c-1.3-.359-2.019-.842-2.019-1.358C59.6.93,60.648.361,62.482,0l.011.055c-1.778.35-2.84.913-2.84,1.506,0,1.023,3.048,1.855,6.795,1.855s6.795-.832,6.795-1.855C73.242.968,72.18.405,70.4.055L70.413,0C72.246.361,73.3.93,73.3,1.561c0,.517-.717,1-2.019,1.358a18.987,18.987,0,0,1-4.832.552" transform="translate(-5219.588 -15445.269)" fill="#303030" stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
											<path id="Path_74" data-name="Path 74" d="M11.557,18.82A29.629,29.629,0,0,1,3.4,17.806C1.207,17.149,0,16.271,0,15.335c0-.689.659-1.353,1.906-1.922l.029.05C.711,14.021.063,14.669.063,15.335c0,1.891,5.156,3.429,11.494,3.429s11.494-1.538,11.494-3.429c0-.666-.647-1.314-1.872-1.872l.029-.05c1.247.569,1.906,1.233,1.906,1.922,0,.936-1.206,1.814-3.4,2.471a29.628,29.628,0,0,1-8.16,1.014" transform="translate(-5164.698 -15456.874)" fill="#303030" stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
											<g id="Group_337" data-name="Group 337" transform="translate(-5158.353 -15461.5)">
												<g id="Group_334" data-name="Group 334" transform="translate(0)">
													<g id="Group_333" data-name="Group 333" transform="translate(0)">
														<path id="Path_307" data-name="Path 307" d="M75.785,0a5.218,5.218,0,0,0-5.212,5.212c0,3.567,4.665,8.8,4.863,9.024a.469.469,0,0,0,.7,0C76.333,14.016,81,8.779,81,5.212A5.218,5.218,0,0,0,75.785,0Zm0,13.2c-1.57-1.865-4.274-5.544-4.274-7.992a4.274,4.274,0,1,1,8.547,0C80.059,7.66,77.356,11.339,75.785,13.2Z" transform="translate(-70.573)" fill="#303030" stroke="#000" stroke-width="0.5"></path>
													</g>
												</g>
												<g id="Group_336" data-name="Group 336" transform="translate(3.229 3.229)">
													<g id="Group_335" data-name="Group 335" transform="translate(0)">
														<path id="Path_308" data-name="Path 308" d="M164.691,92.134a1.983,1.983,0,1,0,1.983,1.983A1.985,1.985,0,0,0,164.691,92.134Zm0,3.256a1.273,1.273,0,1,1,1.273-1.273A1.275,1.275,0,0,1,164.691,95.39Z" transform="translate(-162.708 -92.134)" fill="#303030" stroke="#000" stroke-width="0.5"></path>
													</g>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<h4>Address Directory</h4>
								<p>Manage your shipping and billing addresses</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/price-catalog' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24.001">
										<g transform="translate(5229 15583)">
											<g id="Rectangle_315" data-name="Rectangle 315" transform="translate(-5229 -15583)" fill="none" stroke="#303030" stroke-width="1.5">
												<rect width="10.478" height="10.478" rx="2" stroke="none"></rect>
												<rect x="0.75" y="0.75" width="8.978" height="8.978" rx="1.25" fill="none"></rect>
											</g>
											<g id="Rectangle_318" data-name="Rectangle 318" transform="translate(-5229 -15569.478)" fill="none" stroke="#303030" stroke-width="1.5">
												<rect width="10.479" height="10.479" rx="2" stroke="none"></rect>
												<rect x="0.75" y="0.75" width="8.979" height="8.979" rx="1.25" fill="none"></rect>
											</g>
											<g id="Rectangle_316" data-name="Rectangle 316" transform="translate(-5215.478 -15583)" fill="none" stroke="#303030" stroke-width="1.5">
												<rect width="10.479" height="10.479" rx="2" stroke="none"></rect>
												<rect x="0.75" y="0.75" width="8.979" height="8.979" rx="1.25" fill="none"></rect>
											</g>
											<g id="Rectangle_317" data-name="Rectangle 317" transform="translate(-5215.478 -15569.478)" fill="none" stroke="#303030" stroke-width="1.5">
												<rect width="10.478" height="10.478" rx="2" stroke="none"></rect>
												<rect x="0.75" y="0.75" width="8.978" height="8.978" rx="1.25" fill="none"></rect>
											</g>
										</g>
									</svg>
								</div>
								<h4>Price Catalog</h4>
								<p>Review your price catalog and find new references</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/buy-again' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23.46 24.222">
										<g transform="translate(5248.061 15428.229)">
											<path id="Path_316" data-name="Path 316" d="M-5247.311-15422.924h21.7l-2.857-4.555H-5244.3Z" fill="none" stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
											<path id="Path_317" data-name="Path 317" d="M-5247.311-15422.573v17.781h21.96v-17.781" transform="translate(0 0.035)" fill="none" stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
											<line id="Line_38" data-name="Line 38" x2="8.059" transform="translate(-5243.491 -15411.381)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
											<line id="Line_39" data-name="Line 39" x2="6.044" transform="translate(-5243.491 -15408.36)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
										</g>
									</svg>
								</div>
								<h4>Buy Again</h4>
								<p>See the products you have already purchased and add them to your cart faster</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/preorders' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.723" viewBox="0 0 22.5 22.723">
										<g id="ico-preorder" transform="translate(5320.545 15429.69)">
											<line id="Line_40" data-name="Line 40" x2="21" transform="translate(-5319.295 -15420.718)" fill="none" stroke="#303030" stroke-width="1.5"></line>
											<g id="Group_348" data-name="Group 348" transform="translate(-5315.515 -15428.94)">
												<line id="Line_41" data-name="Line 41" y2="4.445" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
												<line id="Line_42" data-name="Line 42" y2="4.445" transform="translate(11.735)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"></line>
											</g>
											<rect id="Rectangle_354" data-name="Rectangle 354" width="21" height="19" rx="2" transform="translate(-5319.795 -15426.718)" fill="none" stroke="#303030" stroke-width="1.5"></rect>
										</g>
									</svg>
								</div>
								<h4>Preorder</h4>
								<p>Check your preorders and order the ones that are available</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/change-password' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="22.64" height="22.673" viewBox="0 0 22.64 22.673">
										<g transform="translate(-17.884 -12.94)">
											<path id="Path_326" data-name="Path 326" d="M43.036,46.759l1.895-1.895a6.487,6.487,0,1,0-4.255-4.255l-8.11,8.144a.585.585,0,0,0-.2.432l-.166,3.49a.676.676,0,0,0,.2.5.656.656,0,0,0,.465.2H32.9l3.49-.166a.6.6,0,0,0,.6-.5l.632-2.327,2.194-.532a.749.749,0,0,0,.5-.5l.5-2.161,1.861-.266A.534.534,0,0,0,43.036,46.759Z" transform="translate(-13.566 -18.51)" fill="none" stroke="#303030" stroke-width="1.5"></path>
											<path id="Path_327" data-name="Path 327" d="M72.454,46.983a.643.643,0,0,0,.931,0A3.6,3.6,0,0,0,68.3,41.9a.643.643,0,0,0,0,.931Z" transform="translate(-37.533 -24.285)" fill="none" stroke="#303030" stroke-width="1.5"></path>
										</g>
									</svg>
								</div>
								<h4>Change Password</h4>
								<p>Change your password, whenever you want</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
				<b-col cols="12" sm="6" lg="3" class="default-block">
					<div class="media default-border box-shadow">
						<div class="media-body">
							<nuxt-link :to="{ path: '/account/dashboard/your-account' }">
								<div class="custom-content-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20.003 24.445">
										<g id="ico-account" transform="translate(-1257 -396)">
											<path id="Elipse_29_-_Contorno" data-name="Elipse 29 - Contorno" d="M4.641-1A5.641,5.641,0,1,1-1,4.641,5.648,5.648,0,0,1,4.641-1Zm0,9.4A3.761,3.761,0,1,0,.88,4.641,3.765,3.765,0,0,0,4.641,8.4Z" transform="translate(1262.36 397)" fill="#303030"></path>
											<path id="Trazado_131" data-name="Trazado 131" d="M9-1a12.013,12.013,0,0,1,6.974,2.179C17.9,2.563,19,4.339,19,6.052a3.682,3.682,0,0,1-.928,2.454,6.2,6.2,0,0,1-2.292,1.586A17.639,17.639,0,0,1,9,11.223,17.639,17.639,0,0,1,2.22,10.092,6.2,6.2,0,0,1-.072,8.506,3.682,3.682,0,0,1-1,6.052C-1,4.339.1,2.563,2.028,1.179A12.013,12.013,0,0,1,9-1ZM9,9.342a15.793,15.793,0,0,0,5.941-.958c1.36-.586,2.05-1.37,2.05-2.332A4.645,4.645,0,0,0,14.751,2.67a10.128,10.128,0,0,0-11.5,0A4.645,4.645,0,0,0,1.01,6.052c0,.962.69,1.747,2.05,2.332A15.793,15.793,0,0,0,9,9.342Z" transform="translate(1258 409.223)" fill="#303030"></path>
										</g>
									</svg>
								</div>
								<h4>Your Account</h4>
								<p>Update the basic information of your account</p>
							</nuxt-link>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	components: {
		breadcrumbs: () => import("../../../components/pages/breadcrumbs.vue")
	},
	async mounted() {
		if (!this.getterLogin) {
			
			this.$router.push('/account/login');
		}
	},
	computed: {
		...mapGetters({
			getterLogin: 'getterLogin',
		})
	}
};
</script>
