<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Privacy Policy"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">Privacy Policy</h2>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="accordion theme-accordion">
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-1'" variant="info">SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-1" visible accordion="accordion-policy-1" role="tabpanel">
								<b-card-body>
									<p>When you buy an item in our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.</p>
									<p>When you browse our store, we also automatically receive your computer's internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system. Email marketing (if applicable): With your permission, we may send you emails about our store, new products, and other updates.</p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-2'" variant="info">SECTION 2 - CONSENT</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-2" visible accordion="accordion-policy-2" role="tabpanel">
								<b-card-body>
									<h3>How do you get my consent?</h3>
									<p>When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent or provide you with an opportunity to say no.</p>
									<h3>How do I withdraw my consent?</h3>
									<p>If after you opt-in, you change your mind, and you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at info.us@cata1og.com : or through mail: <span>Showmee US - Address: 2600 NW 87th Ave #4, Doral, FL 33172, USA.</span></p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-3'" variant="info">SECTION 3 - DIFFUSION</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-3" visible accordion="accordion-policy-3" role="tabpanel">
								<b-card-body>
									<p>We may diffuse your personal information if we are required by law to do so or if you violate our Terms of Service.</p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-4'" variant="info">SECTION 4 - SHOPIFY</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-4" visible accordion="accordion-policy-4" role="tabpanel">
								<b-card-body>
									<p>Our store is hosted on Shopify Inc. They provide us with an online e-commerce platform that allows us to sell our products and services to you.</p>
									<p>Your data is stored through Shopify’s data storage, databases, and the general Shopify application. They store your data on a secure server behind a firewall.</p>
									<h3>Payment:</h3>
									<p>If you choose a direct payment gateway to complete your purchase, then Shopify stores your credit card data. It is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is deleted. All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers. For more insight, you may also want to read Shopify’s Terms of Service (<a href="https://www.shopify.com/legal/terms)" target="_blank">https://www.shopify.com/legal/terms</a>) or Privacy Statement (<a href="https://www.shopify.com/legal/privacy)" target="_blank">https://www.shopify.com/legal/privacy</a>).</p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-5'" variant="info">SECTION 5 - THIRD-PARTY SERVICES</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-5" visible accordion="accordion-policy-5" role="tabpanel">
								<b-card-body>
									<p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.</p>
									<p>However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies concerning the information we are required to provide to them for your purchase-related transactions.</p>
									<p>In the case of any doubt regarding these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.</p>
									<p>In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located</p>
									<p>As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.</p>
									<p>Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</p>
									<h3>Links</h3>
									<p>When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-6'" variant="info">SECTION 6 - SECURITY</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-6" visible accordion="accordion-policy-6" role="tabpanel">
								<b-card-body>
									<p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL) and stored with a AES-256 encryption. Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted industry standards.</p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-7'" variant="info">SECTION 7 - COOKIES</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-7" visible accordion="accordion-policy-7" role="tabpanel">
								<b-card-body>
									<p>Here is a list of cookies that we use. We’ve listed them here so you that you can choose if you want to opt-out of cookies or not.</p>
									<p>_session_id, unique token, sessional, Allows Shopify to store information about your session (referrer, landing page, etc).</p>
									<p>_shopify_visit, no data held, Persistent for 30 minutes from the last visit, Used by our website provider’s internal stats tracker to record the number of visits.</p>
									<p>_shopify_uniq, no data held, expires midnight (relative to the visitor) of the next day, Counts the number of visits to a store by a single customer. cart, unique token, persistent for 2 weeks, Stores information about the contents of your cart.</p>
									<p>_secure_session_id, unique token, sessional.</p>
									<p>storefront_digest, unique token, indefinite If the shop has a password, this is used to determine if the current visitor has access.</p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-8'" variant="info">SECTION 8 - AGE OF CONSENT</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-8" visible accordion="accordion-policy-8" role="tabpanel">
								<b-card-body>
									<p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-policy-9'" variant="info">SECTION 9 - CHANGES TO THIS PRIVACY POLICY</b-button>
							</b-card-header>
							<b-collapse id="accordion-policy-9" visible accordion="accordion-policy-9" role="tabpanel">
								<b-card-body>
									<p>We reserve the right to modify this privacy policy at any time, so please review it frequently.</p>
									<p>Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>
									<p>If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.</p>
									<h3>QUESTIONS AND CONTACT INFORMATION</h3>
									<p>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer at info.us@cata1og.com</p>
								</b-card-body>
							</b-collapse>
						</b-card>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	}
}
</script>
