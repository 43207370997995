<template>
    <div class="section-b-space page-content loading-page content-loading p-relative">
        <vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
        <breadcrumbs type="dashboard" title="Sales Agent"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Sales Agent</h2>
        </div>
        <b-container class="p-4 mt-2 box-shadow">
            <b-row v-if="agent">
                <b-col cols="12" md="4" class="p-0">
                    <div class="content-customer-insigne justify-content-center agent-image">
                        <img :class="`profile-image rounded-circle${ hide_profile ? ' hide' : '' }`" :alt="`${ agent?.name || 'agent-image' }`" :src="profile_image"/>
                    </div>
                </b-col>
                <b-col cols="12" md="8">
                    <b-row>
                        <b-col cols="12" md="6" class="form-group">
                            <div class="field-label mb-2 label-info">Name</div>
                            <span class="form-control show-data">{{ agent?.name || '' }}</span>
                        </b-col>
                        <b-col cols="12" md="6" class="form-group">
                            <div class="field-label mb-2 label-info">Email</div>
                            <span class="form-control show-data">{{ agent?.email || '' }}</span>
                        </b-col>
                        <b-col cols="12" md="6" class="form-group">
                            <div class="field-label mb-2 label-info">Phone</div>
                            <span class="form-control show-data">{{ agent?.phone || '' }}</span>
                        </b-col>
                        <b-col cols="12" md="6" class="form-group" v-if="agent?.phone">
                            <div class="field-label mb-2 label-info whatsapp-agent">
                                <a :href="agent.whatsappLink">Click here! and Contact us on WhatsApp<i class="fa-brands fa-whatsapp"></i></a>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="form-group">
                            <div class="field-label mb-2 label-info">Languages</div>
                            <span v-for="(item_language, index_language) in ( agent?.language || [] )" :key="index_language" class="form-control show-data">{{ item_language.name }}</span>
                        </b-col>
                        <b-col cols="12" md="6" class="form-group">
                            <div class="field-label mb-2 label-info">Specialist in</div>
                            <span v-for="(item_category, index_category) in ( agent?.categoryProduct || [] )" :key="index_category" class="form-control show-data">{{ item_category.name }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12" class="text-center">
                    <i class="fa-solid fa-exclamation-circle fa-3x"></i>
                    <h4 class="text-transform-none my-4">At this moment you do not have a sales agent assigned, please contact us to assign one and you can enjoy personalized attention</h4>
                    <nuxt-link :to="{ path:'/pages/contact-us'  }" :class="'btn btn-solid mt-0'">Contact with us</nuxt-link>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue")
    },
    data() {
        return {
            isLoading       : true,
            agent           : null,
            profile_image   : '',
            hide_profile    : true
        };
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
            getterCustomerAgent: 'getterCustomerAgent'
        }),
    },
    methods:{
        ...mapActions({
            getCustomerAgent: "getCustomerAgent"
        })
    },
    async mounted(){
        if( !this.getterLogin ){
            
            this.$router.push('/account/login');
        }
        else{
            
            await this.getCustomerAgent().then( (result_agent) => {
                
                this.agent = this.getterCustomerAgent;
                if( this.agent == null || this.agent.photo == '' ){
                    
                    this.profile_image = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }/images/svg-store/svg-profile-image/svg-secondary.svg`;
                }
                else{
                    
                    this.profile_image = this.agent.photo;
                }
                this.hide_profile = false;
                this.agent.phone = this.agent.phone ? this.agent.phone : '';
                this.agent.whatsappLink = this.agent.phone != '' ? `https://api.whatsapp.com/send?phone=${ this.agent.phone.replace(/ +/g, '').replace('+', '') }` : '';
                this.isLoading = null;
            });
        }
    }
}
</script>