<template>
	<section v-if="getterLogin && originProducts" class="section-b-space checkout-page p-relative pt-0">
		<breadcrumbs type="checkout" subtitle="Checkout" :title="showAddress ? 'Add Shipping Details' : showShipping ? 'Select Shipping Method' : 'Add Billing Details'"></breadcrumbs>
		<b-container class="checkout-form mt-4 content-loading">
			<vc-loading v-if="isLoading" :isLoading="isLoading"/>
			<b-row>
				<b-col cols="12" class="d-flex justify-content-start align-items-center mb-4">
					<a v-if="!showAddress" class="back-checkout font-color-dark d-inline-flex" @click="executeBackStep">
						<i class="fa-solid fa-arrow-left"></i>
					</a>
					<div class="checkout-title d-inline-flex m-0">
						<h3 class="m-0">{{ getTitleCheckout }}</h3>
					</div>
				</b-col>
				<b-col cols="12" xl="7">
					<b-form-group v-if="!showAddress" label="Shipping Address" label-for="shipping_address" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="register-form">
						<b-input-group>
							<div class="content-preview-shipping-address form-control h-100">
								<p class="m-0 font-color-dark font-weight-normal">{{ getShippingAddress.first_name }} {{ getShippingAddress.last_name }}</p>
								<p class="m-0 font-color-dark">{{ getShippingAddress.company || ( getShippingAddress.company && getShippingAddress.company.trim() != "" ) ? getShippingAddress.company : '' }}</p>
								<p class="m-0 font-color-dark font-weight-normal">{{ getShippingAddress.address_1 }}</p>
								<p class="m-0 font-color-dark font-weight-normal">{{ getShippingAddress.address_2 }}</p>
								<p class="m-0 font-color-dark">{{ getShippingAddress.city }} {{ getShippingAddress.state_code != null ? `${ getShippingAddress.state_code }` : '' }} {{ getShippingAddress.zip }}</p>
								<p class="m-0 font-color-dark font-weight-normal">{{ getShippingAddress.country }}</p>
							</div>
							<template #append>
								<b-button @click="editShippingAddress" class="btn-solid d-flex align-items-center justify-content-center">
									<i class="fa-solid fa-pencil mr-2"></i>
									Edit
								</b-button>
							</template>
						</b-input-group>
					</b-form-group>
					<b-form-group v-if="showPayment" label="Shipping Method" label-for="shipping_address" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="register-form">
						<b-input-group>
							<div class="content-preview-shipping-address form-control h-100">
								<p class="m-0 pt-2 pb-2 font-color-dark"><strong>{{ shipping_selected.method }}</strong> - {{ formatCurrency( shipping_selected.price ).format }}</p>
							</div>
							<template #append>
								<b-button @click="editShippingMethod" class="btn-solid d-flex align-items-center justify-content-center">
									<i class="fa-solid fa-pencil mr-2"></i>
									Edit
								</b-button>
							</template>
						</b-input-group>
					</b-form-group>
					<transition :name="effect" :mode="mode">
						<section v-if="getterCustomer && getShippingAddress && listAddresses.length > 0" id="step-1" :class="`shipping-details p-0 p-relative${ showAddress ? '' : ' d-none' }`">
							<b-row>
								<b-col cols="12" class="register-form mb-2">
									<b-form-group label="Select Shipping Address" label-for="select_shipping_address" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="mb-0 select-address-list">
										<v-select id="select_shipping_address" name="select_shipping_address" @input="changeShippingAddress" :options="listAddresses" :reduce="(item) => item.id" v-model="selectShippingAddressId" :searchable="false">
											<template #selected-option="{ id, label, value }">
												<b-row v-if="id != 'new-address'" class="option-address-template m-0 selected-option">
													<b-col cols="9" class="p-0">
														<p class="font-weight-normal">{{ value.first_name }} {{ value.last_name }}</p>
														<p>{{ value.company }}</p>
														<p class="font-weight-normal">{{ value.address_1 }}</p>
														<p>{{ value.city }} {{ value.state_code != null ? `${ value.state_code }` : '' }} {{ value.zip }}</p>
														<p class="font-weight-normal">{{ value.country }}</p>
													</b-col>
													<b-col cols="3" class="d-flex align-items-center default-option p-0">
														<p class="text-center">{{ value.default ? "Default Address" : '' }}</p>
													</b-col>
												</b-row>
												<b-row v-else class="option-address-template m-0 selected-option">
													<b-col cols="12" class="p-0">
														<p class="font-weight-normal py-4">{{ label }}</p>
													</b-col>
												</b-row>
											</template>
											<template #option="{ id, label, value }">
												<b-row v-if="id != 'new-address'" class="option-address-template m-0">
													<b-col cols="9" class="p-0">
														<p class="font-weight-normal">{{ value.first_name }} {{ value.last_name }}</p>
														<p>{{ value.company }}</p>
														<p class="font-weight-normal">{{ value.address_1 }}</p>
														<p>{{ value.city }} {{ value.state_code != null ? `${ value.state_code }` : '' }} {{ value.zip }}</p>
														<p class="font-weight-normal">{{ value.country }}</p>
													</b-col>
													<b-col cols="3" class="d-flex align-items-center default-option p-0">
														<p class="text-center">{{ value.default ? "Default Address" : '' }}</p>
													</b-col>
												</b-row>
												<b-row v-else class="option-address-template m-0">
													<b-col cols="12" class="p-0">
														<p class="font-weight-normal py-4">{{ label }}</p>
													</b-col>
												</b-row>
											</template>
										</v-select>
									</b-form-group>
								</b-col>
								<b-col cols="12" class="mb-2">
									<form-address v-if="getShippingAddress && getDataListCountries.length > 0" idForm="use-shipping-address" :dataAddress="getShippingAddress" :dataListCountries="getDataListCountries" :dataListStates="getDataListStates" :dataListPhoneCodes="getDataListPhoneCodes" @validFormAddress="validFormAddress" typeForm="address"></form-address>
								</b-col>
							</b-row>
						</section>
					</transition>
					<transition :name="effect" :mode="mode">
						<validation-observer v-if="getterShippingData" tag="section" v-slot="{ handleSubmit, valid }"  id="step-2" :class="`shipping-method p-0 p-relative${ showShipping ? '' : ' d-none' }`">
							<b-row>
								<b-col cols="12">
									<form class="register-form" @submit.prevent="valid ? handleSubmit( validShippingMethod(true) ) : validShippingMethod(false)" autocomplete="off">
										<validation-provider rules="required" tag="div" v-slot="{ errors }" name="Shipping Method" class="valid-field">
											<b-form-group v-if="getterShippingData.rates" label="Select a Shipping Method" label-for="shipping_methods" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`list-radio-group${ !errors[0] ? '' : ' field-error' }`">
												<b-form-radio-group id="shipping_methods" v-model="shipping_selected" name="shipping_methods">
													<b-list-group-item v-for="(item, index) in getterShippingData.rates" :key="`shipping_method_${ index }`" :class="`d-flex justify-content-between align-items-center${ shipping_selected && shipping_selected.method == item.name ? ' active' : '' }`">
														<b-form-radio name="shipping_method" :value="{ method: item.name, price: item.price, need_payment: item.need_payment, group: 0 }" :id="item.name">
															<span class="font-weight-normal">{{ item.name }}</span>
															<span class="font-weight-normal">{{ item.price == 0 ? "Free" : formatCurrency(item.price).format }}</span>
														</b-form-radio>
													</b-list-group-item>
												</b-form-radio-group>
												<span class="validate-error">{{ errors[0] }}</span>
											</b-form-group>
										</validation-provider>
									</form>
								</b-col>
							</b-row>
						</validation-observer>
					</transition>
					<transition :name="effect" :mode="mode">
						<validation-observer tag="section" id="step-3" :class="`billing-details p-0 p-relative${ showPayment ? '' : ' d-none' }`">
							<b-row>
								<b-col v-if="validUseBalance" cols="12">
									<b-form-group label="We note that you have balance in favor, Do you want to use it?" label-for="use_balance" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
										<b-card no-body>
											<b-card-header class="p-3 px-4 border-bottom-0 card-use-balance" role="tab">
												<b-form-checkbox v-model="useBalance" name="use_balance" value="use-balance" unchecked-value="not-use-balance" @change="changeBalancePay()" class="checkbox-full-width mb-0 font-weight-bold">
													Available Balance ( {{ formatCurrency( originalBalance ).format }} )
												</b-form-checkbox>
											</b-card-header>
										</b-card>
									</b-form-group>
								</b-col>
								<b-col cols="12">
									<b-form-group label="Choose payment method" label-for="payment_methods" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
										<b-form-radio-group id="payment_methods" v-model="paymentMethod" name="payment_methods" class="accordion">
											<b-card v-if="orderNeedPayment" no-body>
												<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
													<b-form-radio :disabled="availableOptionPay" name="some-radios-pay" :value="'credit-card'" class="w-100" @change="updateMethodPay">Credit card</b-form-radio>
												</b-card-header>
												<b-card-body v-if="paymentMethod == 'credit-card'" id="payment-method-body-credit-card" class="payment-method-body bg-card">
													<credit-card @changeCreditCard="changeCreditCard"></credit-card>
												</b-card-body>
											</b-card>
											<b-card v-if="orderNeedPayment" no-body>
												<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
													<b-form-radio :disabled="availableOptionPay" name="some-radios-pay" :value="'paypal'" class="w-100" @change="updateMethodPay">Paypal</b-form-radio>
												</b-card-header>
												<b-card-body v-if="paymentMethod == 'paypal'" id="payment-method-body-paypal" class="payment-method-body bg-card">
													<div class="paypal">
														<div class="radio-wrapper content-box__row" style=" padding-left: 10px; padding-right: 10px; ">
															<div class="radio__label payment-method-wrapper">
																<label class="radio__label__primary content-box__emphasis">
																	<img alt="PayPal" class="offsite-payment-gateway-logo" :src="require('@/assets/images/paypal.webp')"/>
																</label>
															</div>
														</div>
														<div class="radio-wrapper content-box__row content-box__row--secondary paypal-container">
															<div class="blank-slate" style="text-align: center">
																<i style=" font-size: 12rem; color: lightgray;" class="fa-solid fa-window-maximize"></i>
																<p>
																	After clicking on "Pay Now",
																	you will be redirected to PayPal to
																	complete your purchase safely.
																</p>
															</div>
														</div>
													</div>
												</b-card-body>
											</b-card>
											<b-card v-if="orderNeedPayment" no-body>
												<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
													<b-form-radio :disabled="availableOptionPay" name="some-radios-pay" :value="'deposit-bank'" class="w-100" @change="updateMethodPay">Bank Deposit</b-form-radio>
												</b-card-header>
											</b-card>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
								<b-col cols="12">
									<b-form-group label="Billing Address" label-for="billing_address" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
										<small>Make sure the address matches your card payment method</small>
										<b-form-radio-group id="billing_address" v-model="billingAddressType" name="billing_address" role="tablist" class="accordion" @change="selectUseBillingAddress">
											<b-card no-body>
												<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
													<b-form-radio name="some-radios" :value="'same'">Same as shipping address</b-form-radio>
												</b-card-header>
											</b-card>
											<b-card no-body class="mb-3">
												<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
													<b-form-radio name="some-radios" :value="'different'">Use a different billing address</b-form-radio>
												</b-card-header>
												<div v-if="billingAddressType == 'different'" id="accordion-2" accordion="my-accordion" role="tabpanel">
													<b-card-body class="bg-card">
														<b-row class="register-form">
															<b-col cols="12" class="mb-2">
																<b-form-group label="Select Billing Address" label-for="select_billing_address" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="mb-0 select-address-list">
																	<v-select id="select_billing_address" name="select_billing_address" @input="changeBillingAddress" :options="listAddresses" :reduce="(item) => item.id" v-model="selectBillingAddressId" :searchable="false">
																		<template #selected-option="{ id, label, value }">
																			<b-row v-if="id != 'new-address'" class="option-address-template m-0 selected-option">
																				<b-col cols="9" class="p-0">
																					<p class="font-weight-normal">{{ value.first_name }} {{ value.last_name }}</p>
																					<p>{{ value.company }}</p>
																					<p class="font-weight-normal">{{ value.address_1 }}</p>
																					<p>{{ value.city }} {{ value.state_code != null ? `${ value.state_code }` : '' }} {{ value.zip }}</p>
																					<p class="font-weight-normal">{{ value.country }}</p>
																				</b-col>
																				<b-col cols="3" class="d-flex align-items-center default-option p-0">
																					<p class="text-center">{{ value.default ? "Default Address" : '' }}</p>
																				</b-col>
																			</b-row>
																			<b-row v-else class="option-address-template m-0 selected-option">
																				<b-col cols="12" class="p-0">
																					<p class="font-weight-normal py-4">{{ label }}</p>
																				</b-col>
																			</b-row>
																		</template>
																		<template #option="{ id, label, value }">
																			<b-row v-if="id != 'new-address'" class="option-address-template m-0">
																				<b-col cols="9" class="p-0">
																					<p class="font-weight-normal">{{ value.first_name }} {{ value.last_name }}</p>
																					<p>{{ value.company }}</p>
																					<p class="font-weight-normal">{{ value.address_1 }}</p>
																					<p>{{ value.city }} {{ value.state_code != null ? `${ value.state_code }` : '' }} {{ value.zip }}</p>
																					<p class="font-weight-normal">{{ value.country }}</p>
																				</b-col>
																				<b-col cols="3" class="d-flex align-items-center default-option p-0">
																					<p class="text-center">{{ value.default ? "Default Address" : '' }}</p>
																				</b-col>
																			</b-row>
																			<b-row v-else class="option-address-template m-0">
																				<b-col cols="12" class="p-0">
																					<p class="font-weight-normal py-4">{{ label }}</p>
																				</b-col>
																			</b-row>
																		</template>
																	</v-select>
																</b-form-group>
															</b-col>
															<b-col cols="12" class="mb-2">
																<form-address v-if="getBillingAddress && getDataListCountries.length > 0" idForm="use-billing-address" :dataAddress="getBillingAddress" :dataListCountries="getDataListCountries" :dataListStates="getDataListStates" :dataListPhoneCodes="getDataListPhoneCodes" @validFormAddress="validFormAddress" typeForm="address"></form-address>
															</b-col>
														</b-row>
														
													</b-card-body>
												</div>
											</b-card>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
								<b-col cols="12">
									<b-form-group label="Do you want to include the invoice in your shipment?" label-for="need_invoice" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
										<b-form-radio-group id="need_invoice" v-model="needInvoice" name="need_invoice" role="tablist" class="accordion">
											<b-card no-body>
												<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
													<b-form-radio name="invoice-radios" :value="true">Invoice</b-form-radio>
												</b-card-header>
											</b-card>
											<b-card no-body class="mb-3">
												<b-card-header class="p-3 px-4 border-bottom-0" role="tab">
													<b-form-radio name="invoice-radios" :value="false">No invoice</b-form-radio>
												</b-card-header>
											</b-card>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
							</b-row>
						</validation-observer>
					</transition>
				</b-col>
				<b-col cols="12" xl="5">
					<div v-if="originProducts == 'cart' && getterCoupon && getterCoupon != null" class="coupon">
						<div class="title-coupon text-center font-color-dark font-weight-normal">Coupon Applied</div>
						<div class="coupon-content d-flex justify-content-between">
							<div class="coupon-name d-inline-flex justify-content-center align-items-center text-center">
								<label class="m-0">{{ getterCoupon.name }}</label>
							</div>
							<div class="coupon-value d-inline-flex justify-content-center align-items-center"> 
								<label v-if="getterCoupon.typeCoupon == 'percentage'" class="m-0 type-percentage">-{{ getterCoupon.discount }}%</label>
								<label v-if="getterCoupon.typeCoupon == 'fixed amount'" class="m-0 type-fixed-amount">-{{ formatCurrency( getterCoupon.fixedMount ).format }}</label>
								<label v-if="getterCoupon.typeCoupon == 'free shipping'" class="m-0 type-message">Free Shipping</label>
							</div>
						</div>
					</div>
					<b-form-group label="Product List" label-for="product-list" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="mb-0">
						<div class="checkout-details">
							<div class="product-list">
								<b-row v-for="item in products" :key="item._id" class="checkout-product my-3 m-0">
									<b-col class="d-flex p-relative">
										<span class="product-quantity p-absolute">{{ item.quantity }}</span>
										<img class='product-image d-inline-block img-fluid bg-img' width="70px" height="70px" :alt="item.title_product" :src="`${ item.image ? item.image.src_collection : '/images/products/product-default_320.webp' }`" :onerror="`this.onerror=null;this.src='${ back_url }/images/products/product-default_320.webp';this.alt='product-default';`">
										<div class="product-details d-inline-block pl-3">
											<label class="font-weight-normal font-color-dark chunk-lines chunk-lines-2 mb-0" :title="item.title">{{ item.title }}</label>
											<label class="font-color-dark font-weight-bold d-block m-0">{{ item.sku }}</label>
										</div>
										<div class="product-subtotal pl-3">
											<label class="font-color-dark mb-0">
												<del v-if="item.all_discounts.brand > 0" class="d-block">{{ formatCurrency( item.price * item.quantity ).format }}</del>
												<strong>{{ formatCurrency( ( getDiscountPrice( item.price, item.all_discounts.brand, item.quantity ) * item.quantity ), false ).format }}</strong>
											</label>
										</div>
									</b-col>
								</b-row>
							</div>
							<b-row class="m-0 mt-2">
								<b-col cols="6" class="p-0">
									<h4 class="font-color-dark font-weight-normal">Subtotal</h4>
								</b-col>
								<b-col cols="6" class="p-0 d-flex justify-content-end aling-items-center">
									<h4 class="font-color-dark font-weight-bold">{{ formatCurrency(subtotalCart.subtotal, true).format }}</h4>
								</b-col>
								<b-col v-if="subtotalCart.coupon_discount > 0" cols="6" class="p-0">
									<h4 class="font-color-dark font-weight-normal">Coupon Discount</h4>
								</b-col>
								<b-col v-if="subtotalCart.coupon_discount > 0" cols="6" class="p-0 d-flex justify-content-end aling-items-center">
									<h4 class="font-color-dark font-weight-bold">-{{ formatCurrency(subtotalCart.coupon_discount, true).format }}</h4>
								</b-col>
								<b-col v-if="showShipping || showPayment || shipping_selected" cols="6" class="p-0">
									<h4 class="font-color-dark font-weight-normal">Taxes</h4>
								</b-col>
								<b-col v-if="showShipping || showPayment || shipping_selected" cols="6" class="p-0 d-flex justify-content-end aling-items-center">
									<h4 class="font-color-dark font-weight-bold">{{ formatCurrency(totalTaxes, true).format }}</h4>
								</b-col>
								<b-col v-if="showShipping || showPayment || shipping_selected" cols="6" class="p-0">
									<h4 class="font-color-dark font-weight-normal">Shipping</h4>
								</b-col>
								<b-col v-if="showShipping || showPayment || shipping_selected" cols="6" class="p-0 d-flex justify-content-end aling-items-center">
									<h4 class="font-color-dark font-weight-bold">{{ formatCurrency(shipping_selected ? shipping_selected.price : 0).format }}</h4>
								</b-col>
								<b-col v-if="amountBalance > 0" cols="6" class="p-0">
									<h4 class="font-color-dark font-weight-normal">Used Balance</h4>
								</b-col>
								<b-col v-if="amountBalance > 0" cols="6" class="p-0 d-flex justify-content-end aling-items-center">
									<h4 class="font-color-dark font-weight-bold">-{{ formatCurrency(amountBalance).format }}</h4>
								</b-col>
								<b-col v-if="!showAddress || shipping_selected || subtotalCart.coupon_discount > 0" cols="6" class="p-0">
									<h4 class="font-color-dark font-weight-normal">Total</h4>
								</b-col>
								<b-col v-if="!showAddress || shipping_selected || subtotalCart.coupon_discount > 0" cols="6" class="p-0 d-flex justify-content-end aling-items-center">
									<h4 class="font-color-dark font-weight-bold">{{ formatCurrency(getTotal, true).format }}</h4>
								</b-col>
							</b-row>
						</div>
					</b-form-group>
					<div class="order-note register-form mt-4">
						<div class="order-box">
							<b-form-group label="Order Note" label-for="order_note" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="mb-0">
								<b-input-group class="checkout-section">
									<b-form-textarea v-model="order_note" placeholder="Note" rows="6" name="order_note"/>
								</b-input-group>
							</b-form-group>
						</div>
					</div>
				</b-col>
				<b-col cols="12" class="d-flex justify-content-end align-items-center mt-4">
					<div class="mb-0 text-right">
						<b-button class="btn-solid shadow-sm" :disabled="showPayment ? disabledCheckout : false" @click="executeNextStep">{{ getBtnTextCheckout }}</b-button>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { formatData, calcDiscountPrice } from "../../constants/config.js";
export default {
	components: {
		breadcrumbs		: () => import("../../components/pages/breadcrumbs.vue"),
		vcLoading		: () => import('../../components/pages/loading.vue'),
		formAddress		: () => import("../../components/pages/form-address.vue"),
		creditCard		: () => import("../../components/payment/credit-card.vue"),
	},
	data() {
		return {
			back_url					: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
			originProducts			: null,
			
			showAddress				: true,
			showShipping			: false,
			showPayment				: false,
			
			currentStep				: 1,
			effect					: "out-in-translate-fade",
			mode					: "out-in",
			
			products				: [],
			dataCart				: { products: [], details: null },
			subtotalCart			: { subtotal: 0, coupon_discount: 0 },
			
			shippingAddress			: null,
			billingAddress			: null,
			
			listAddresses			: [],
			
			defaultAddress			: {
				id			: "new-address",
				first_name	: '',
				last_name	: '',
				name		: "",
				phone		: '',
				address_1	: '',
				address_2	: '',
				company		: '',
				country		: 'United States',
				country_code: 'US',
				state		: null,
				state_code	: null,
				city		: '',
				zip			: ''
			},
			selectShippingAddressId	: "new-address",
			selectBillingAddressId	: "new-address",
			
			listPhoneCodes			: [],
			listCountries			: [],
			listStates				: [],
			
			shippingSelected		: null,
			
			creditCard				: null,
			
			shipping_selected		: null,
			totalTaxes				: 0,
			
			validUseBalance			: false,
			useBalance				: 'not-use-balance',
			amountBalance			: 0,
			originalBalance			: 0,
			availableOptionPay		: false,
			
			billingAddressType		: "same",
			
			paymentMethod			: "credit-card",
			needInvoice				: true,
			
			loadCheckout			: false,
			resumePay				: null,
			
			
			order_note				: "",
			
			isLoading				: true,
			disabledCheckout		: false
		};
	},
	async mounted() {
		
		this.originProducts 		= this.$route.params.type;
		
		if (!this.getterLogin) {
			
			this.$router.push("/pages/401");
		}
		else {
			await this.getCountries().then( async (result_countries) => {
				
				this.listPhoneCodes = this.getterCountries.phone_codes;
				this.listCountries 	= this.getterCountries.list;
				this.listStates     = this.getterCountries.states;
				await this.getCustomer({ id_email: this.getterUser.email }).then( async (result_customer) => {
					
					this.listAddresses 				= [{ label: `New Address`, id: this.defaultAddress.id, value: {...this.defaultAddress} }].concat(this.getterCustomer.addresses.map((item) => {
						return { 
							label: `${ item.default ? '(Default)' : '' } ${ item.first_name } ${ item.last_name } - ${ item.address_1 }, ${ item.city }, ${ item.state }, ${ item.country }, ${ item.zip }`, 
							id: item.id, 
							value: item 
						};
					}));
					this.shippingAddress 			= this.getterCustomer.addresses.find((item) => item.default );
					
					this.shippingAddress 			= this.shippingAddress && !this.shippingAddress.country && !this.shippingAddress.state ? this.getterCustomer.addresses.find((item) => item.country && item.state) : this.shippingAddress;
					
					this.shippingAddress 			= this.shippingAddress ? this.shippingAddress : {...this.defaultAddress};
					
					this.selectShippingAddressId 	= this.shippingAddress.id;
					
					this.billingAddress 			= {...this.defaultAddress};
					
					await this.getCart().then( async (result_cart) => {
						
						this.dataCart = this.originProducts == 'cart' ? this.getterCart : this.getterCartSaveLater;
						this.products = this.dataCart.products;
						this.subtotalCart = this.calcSubtotal(this.dataCart);
						
						await this.getStatementByCustomer().then( (result_statement) => {
							
							this.validUseBalance 	= this.getterSatement.balance > 0;
							this.originalBalance 	= this.validUseBalance ? this.getterSatement.balance : 0;
							this.amountBalance		= 0;
							
							this.validLoadCheckout( this.getterSatement.balance, this.getterSatement.preApprovedCredit, this.getterSatement.expiredDays, parseFloat(this.subtotalCart.subtotal) - parseFloat(this.subtotalCart.coupon_discount), this.getterCustomer.first_order, this.dataCart.details.count, this.getterCustomer.valid_moa );
							this.getBusinesses();
						});
					});
					
					this.$root.$emit('change-select-address', { id_form: "use-shipping-address", address: this.shippingAddress });
					this.isLoading = null;
				});
			});
		}
		this.resetValidCheckout(null);
		this.resetShippingData(null);
	},
	computed: {
		...mapGetters({
			getterUser			: 'getterUser',
			getterLogin			: 'getterLogin',
			getterCart			: "getterCart",
			getterCartSaveLater	: "getterCartSaveLater",
			
			getterCustomer		: "getterCustomer",
			getterShippingData	: "getterShippingData",
			
			messageCoupon		: "getterMessageCoupon",
			getterCoupon		: "getterCoupon",
			
			getterValidCheckout	: "getterValidCheckout",
			getterSatement		: "getterSatement",
			
			getterStatusPay		: "getterStatusPay",
			getterCheckoutOrder	: "getterCheckoutOrder",
			getterBusinesses	: "getterBusinesses",
			getterNotification	: "getterNotification",
			getterCountries		: "getterCountries",
		}),
		getTotal() {
			
			let total = parseFloat(this.totalTaxes) + ( parseFloat(this.shipping_selected ? this.shipping_selected.price : 0) )  + parseFloat(this.subtotalCart.subtotal) - parseFloat(this.subtotalCart.coupon_discount) - ( parseFloat(this.amountBalance) );
			
			return total;
		},
		getShippingAddress(){
			return this.shippingAddress ? this.shippingAddress : this.defaultAddress;
		},
		getBillingAddress(){
			return this.billingAddress ? this.billingAddress : this.defaultAddress;
		},
		getDataListCountries(){
			return this.listCountries;
		},
		getDataListStates(){
			return this.listStates;
		},
		getDataListPhoneCodes(){
			return this.listPhoneCodes;
		},
		orderNeedPayment() {
			return this.shipping_selected ? this.shipping_selected.need_payment : true;
		},
		getBtnTextCheckout(){
			if( this.showAddress && this.getterCustomer && this.getShippingAddress ){
				return "Shipping Method";
			}
			else if( this.showShipping && this.getterShippingData ){
				return "Place Order";
			}
			else if( this.showPayment ){
				return "Pay Now!";
			}
		},
		getTitleCheckout(){
			
			if( this.showAddress ){
				
				return "Add Shipping Details";
			}
			else if( this.showShipping ){
				
				return "Select Shipping Method";
			}
			else{
				return "Add Billing Details";
			}
		}
	},
	methods: {
		// ------- VUEX ----------------
		...mapActions({
			setValidCheckout		: "setValidCheckout",
			setShippingData			: "setShippingData",
			getCountries			: "getCountries",
			getCustomer				: "getCustomer",
			getCart					: "getCart",
			getStatementByCustomer	: "getStatementByCustomer",
			getBusinesses			: "getBusinesses",
			resetValidCheckout		: "resetValidCheckout",
			resetShippingData		: "resetShippingData",
			addNotification			: "addNotification",
			calculateShipping		: "calculateShipping",
			validCheckout			: "validCheckout",
			payOrder				: "payOrder",
			createOrder				: "createOrder",
		}),
		
		changeShippingAddress(address_id) {
			
			if (this.getterCustomer) {
				
				this.shippingAddress 	= this.listAddresses.find((item) => item.id == address_id )?.value;
				
				if (this.shippingAddress) {
					
					this.selectShippingAddressId = address_id;
					this.$root.$emit('change-select-address', { id_form: "use-shipping-address", address: this.shippingAddress });
				}
			}
		},
		changeBillingAddress(address_id) {
			
			if (this.getterCustomer) {
				
				this.billingAddress 	= this.listAddresses.find((item) => item.id == address_id )?.value;
				
				if (this.billingAddress) {
					
					this.selectBillingAddressId = address_id;
					this.$root.$emit('change-select-address', { id_form: "use-billing-address", address: this.billingAddress });
				}
			}
		},
		async validFormAddress(data_form){
			
			if( data_form.id_form == "use-shipping-address" ){
				
				if( data_form.valid ){
					
					this.shippingAddress 		= data_form.address;
					this.shippingAddress.name 	= `${ this.shippingAddress.first_name } ${ this.shippingAddress.last_name }`;
					await this.processShippingAddress();
				}
				else{
					
					this.addNotification({ 
						content: { type: 'request' }, 
						type: "error", 
						data: { title: "Error: Shipping address fields", message: "There are fields that have not been validated" }
					});
				}
			}
			else if( data_form.id_form == "use-billing-address" ){
				
				this.billingAddress 		= data_form.address;
				this.billingAddress.name	= `${ this.billingAddress.first_name } ${ this.billingAddress.last_name }`;
				if( !this.disabledCheckout && data_form.valid && ( this.paymentMethod !== 'credit-card' || ( this.paymentMethod == 'credit-card' && this.creditCard ) ) ){
					
					await this.executePayOrder();
				}
				else{
					
					this.addNotification({ 
						content: { type: 'request' }, 
						type: "error", 
						data: { title: "Error: Payment and Billing fields", message: "There are fields that have not been validated" }
					});
					this.disabledCheckout = false;
				}
			}
		},
		async processShippingAddress(){
			
			this.isLoading 		= true;
			this.billingAddress = this.billingAddressType == 'same' ? {...this.shippingAddress} : this.billingAddress;
			let body_request 	= {
				line_items		: JSON.stringify(this.dataCart.products),
				country_code	: this.shippingAddress.country_code,
				state_code		: this.shippingAddress.state_code,
				zip_code		: this.shippingAddress.zip,
				product_origin	: this.originProducts,
				shipping_address: {...this.shippingAddress},
				billing_address	: this.billingAddress
			};
			await this.calculateShipping(body_request).then( (result_shipping) => {
				
				if(this.getterShippingData != null){
					
					this.isLoading 		= null;
					this.subtotalCart 	= this.calcSubtotal(this.dataCart);
					this.totalTaxes 	= this.calculateTaxes();
					this.showAddress 	= false;
					this.showShipping 	= true;
					
					this.transitionNextStep();
				}
			});
		},
		async validShippingMethod(valid_form){
			
			if( valid_form ){
				
				this.isLoading 			= true;
				this.billingAddressType = this.shipping_selected.method != "Dropshipping" ? 'same' : 'different';
				
				this.needInvoice 		= !this.shipping_selected.method == "Dropshipping";
				
				this.billingAddress 	=  this.billingAddressType == "different" ? {...this.defaultAddress} : {...this.shippingAddress};
				
				this.shippingSelected 	= JSON.stringify({ products: this.getterShippingData.products, rates: this.getterShippingData.rates.filter((a) => a.name == this.shipping_selected.method ), taxes: this.getterShippingData.taxes });
				
				let body_request 		= {
					shipping_address: this.shippingAddress,
					billing_address	: this.billingAddress,
					country_code	: this.shippingAddress.country_code,
					state_code		: this.shippingAddress.state_code,
					zip_code		: this.shippingAddress.zip,
					shipping_rates	: this.shippingSelected,
					product_origin	: this.originProducts,
					apply_discounts	: this.dataCart.details.apply_discounts
				};
				await this.validCheckout(body_request).then( (result_checkout) => {
					
					this.isLoading = null;
					if( this.getterValidCheckout != null && this.getterValidCheckout ){
						
						this.showShipping 	= false;
						this.showPayment 	= true;
						this.transitionNextStep();
					}
					else if( this.getterValidCheckout != null && !this.getterValidCheckout ){
						
						this.addNotification({ 
							content: { type: 'request' }, 
							type: "error", 
							data: {	title: "Error: Validte Checkout", message: "Changes have been detected in your cart, we ask you to please verify the changes and carry out the checkout process again" }
						});
						this.$router.push(`/account/${ this.originProducts == 'cart' ? 'cart' : 'cart-save-later' }`);
					}
				});
			}
			else{
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: "error", 
					data: { title: "Error: Shipping Method", message: "It is necessary to select a Shipping Method" }
				});
			}
		},
		changeCreditCard(data_change){
			
			this.creditCard = data_change.valid ? data_change.credit_card : null;
		},
		
		transitionBackStep(step){
			
			if( document.getElementById(`step-${ this.currentStep }`) ){
				
				document.getElementById(`step-${ this.currentStep }`).classList.add("p-absolute");
				document.getElementById(`step-${ this.currentStep }`).classList.remove("p-relative");
			}
			
			this.mode = "out-in";
			this.effect = "out-in-translate-fade";
			
			if( step ){
				
				this.currentStep = step;
			}
			else{
				
				this.currentStep--;
			}
			setTimeout(() => {
				
				if( document.getElementById(`step-${ this.currentStep }`) ){
					
					document.getElementById(`step-${ this.currentStep }`).classList.add("p-relative");
					document.getElementById(`step-${ this.currentStep }`).classList.remove("p-absolute");
				}
				
				this.mode = "in-out";
				this.effect = "in-out-translate-fade";
			}, 120);
		},
		transitionNextStep(){
			
			if( document.getElementById(`step-${ this.currentStep }`) ){
				
				document.getElementById(`step-${ this.currentStep }`).classList.add("p-absolute");
				document.getElementById(`step-${ this.currentStep }`).classList.remove("p-relative");
			}
			this.mode = "in-out";
			this.effect = "in-out-translate-fade";
			
			this.currentStep++;
			setTimeout(() => {
				
				if( document.getElementById(`step-${ this.currentStep }`) ){
					
					document.getElementById(`step-${ this.currentStep }`).classList.add("p-relative");
					document.getElementById(`step-${ this.currentStep }`).classList.remove("p-absolute");
				}
				this.mode = "out-in";
				this.effect = "out-in-translate-fade";
			}, 120);
		},
		async executeNextStep(){
			window.scrollTo(0, 0);
			if( this.showAddress ){
				
				this.$root.$emit('valid-address', { id_form: "use-shipping-address", action: "calculate-shiping" });
			}
			else if( this.showShipping ){
				
				this.validShippingMethod(this.shipping_selected != null);
			}
			else{
				
				if( this.billingAddressType != 'same' ){
					
					this.$root.$emit('valid-address', { id_form: "use-billing-address", action: "pay-order" });
				}
				else{
					
					await this.validFormAddress({ id_form: "use-billing-address", action: "pay-order", valid: true, address: {...this.shippingAddress} })
				}
			}
		},
		executeBackStep() {
			
			this.resetValidCheckout(null);
			if( this.showShipping ){
				
				this.showAddress 	= true;
				this.showShipping 	= false;
				this.resetShippingData(null);
			}
			else if( this.showPayment ){
				
				this.showPayment 	= false;
				this.showShipping 	= true;
			}
			this.transitionBackStep();
		},
		editShippingAddress(){
			
			this.resetValidCheckout(null);
			this.resetShippingData(null);
			
			this.showAddress = true;
			this.showShipping = false;
			this.showPayment = false;
			
			this.transitionBackStep(1);
		},
		editShippingMethod(){
			
			this.resetValidCheckout(null);
			
			this.showShipping = true;
			this.showAddress = false;
			this.showPayment = false;
			
			this.transitionBackStep(2);
		},
		formatCurrency(price, totalAmount = null) {
			
			return formatData.currency(price, totalAmount);
		},
		getDiscountPrice(price, discount, quantity){
			
			return calcDiscountPrice(price, discount, quantity);
		},
		
		
		validLoadCheckout(balance_customer, pre_credit, expired_days, totalCart, firstOrder, qtyItems, validMoa) {
			
			let urlCart = this.originProducts == 'cart' ? 'cart' : 'cart-save-later';
			this.loadCheckout = false;
			if ( ( qtyItems > 0 ) && ( totalCart >= 0 ) && ( ( pre_credit + balance_customer ) >= totalCart && expired_days == 0 ) && ( ( firstOrder == null && ( totalCart > 1000 || validMoa ) ) || ( firstOrder != null ) ) ) {
				
				this.loadCheckout = true;
			}
			else if ( ( qtyItems > 0 ) && ( totalCart >= 0 ) && ( ( ( pre_credit + balance_customer ) >= totalCart && expired_days == 0 ) || !( ( pre_credit + balance_customer ) >= totalCart && expired_days == 0 ) ) ) {
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: {	title: "Error: Checkout Order", message: ( ( firstOrder == null ) && !( totalCart > 1000 || validMoa ) ) ? "The total amount of the order does not meet the condition of the minimum amount required" : "The balance is not enough and/or you have overdue days" }
				});
			}
			else {
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: {	title: "Error: Checkout Order", message: "This is strange but I notice that you have no products in your cart"}
				});
			}
			if( !this.loadCheckout ){
				
				this.$router.push(`/account/${ urlCart }`);
			}
		},
		calcSubtotal( cart ){
			
			return { 
				subtotal: cart.details.subtotal, 
				total_products: cart.details.count, 
				coupon_discount: cart.details.subtotal_coupon, 
				is_percentage: cart.products.some( (item) => item.all_discounts.coupon.percentage > 0 ) 
			};
		},
		selectUseBillingAddress(value){
			
			this.billingAddress = value == "same" ? {...this.shippingAddress} : {...this.defaultAddress};
			this.selectBillingAddressId = this.billingAddress.id;
			this.$root.$emit('change-select-address', { id_form: "use-billing-address", address: this.billingAddress });
		},
		changeBalancePay() {
			
			if (this.useBalance == 'use-balance') {
				
				let total 			= parseFloat(this.subtotalCart.subtotal) - parseFloat(this.subtotalCart.coupon_discount) + parseFloat(this.totalTaxes) + parseFloat(this.shipping_selected.price);
				
				if( this.originalBalance >= 0 && total > this.originalBalance ){
					
					this.availableOptionPay = false;
					this.amountBalance 		= this.originalBalance;
				}
				else if( this.originalBalance >= 0 ){
					
					this.availableOptionPay = true;
					this.amountBalance 		= total;
					this.paymentMethod 		= 'balance';
				}
				else{
					
					this.availableOptionPay = false;
					this.paymentMethod 		= 'credit-card';
					this.amountBalance = 0;
				}
			}
			else {
				
				this.availableOptionPay = false;
				this.paymentMethod 		= 'credit-card';
				this.amountBalance = 0;
			}
		},
		updateMethodPay() {
			
			if(this.paymentMethod !== 'credit-card' ) {
				
				this.$root.$emit('clean-credit-card');
			}
		},
		processPayOrder(){
			
			this.isLoading = false;
			this.disabledCheckout = false;
			if ( !this.getterStatusPay?.error && this.getterStatusPay.result == "approved" ) {
				
				window.location.href = this.getterStatusPay.url
			}
			else if( !this.getterStatusPay?.error ){
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { title: "Error: Pay Order", message: this.getterStatusPay.message } 
				});
			}
		},
		processCreateOrder(){
			
			this.isLoading = false;
			this.$router.push(`/order/success?status=${ this.getterCheckoutOrder.status }&order=${ this.getterCheckoutOrder.order_name ? this.getterCheckoutOrder.order_name : 'not-found' }`);
		},
		getTimeOutOrder(){
			let now_date_minutes 	= moment().get("minutes");
			let now_date_seconds 	= moment().get("seconds");
			let get_timeout 		= 0;
			
			if( ( now_date_minutes == 0 || ( now_date_minutes > 0 && ( now_date_minutes + 1 ) % 2 == 0 ) ) && ( now_date_seconds >= 45 ) ){
				
				get_timeout = ( 60 - now_date_seconds + 5 ) * 1000;
			}
			else if( ( now_date_minutes == 0 || ( now_date_minutes > 0 && now_date_minutes % 2 == 0 ) ) && ( ( now_date_seconds ) <= 10 ) ){
				
				get_timeout = ( now_date_seconds < 3 ? 7 : 5 ) * 1000;
			}
			return get_timeout;
		},
		async executePayOrder() {
			
			let valid_credit_card = ( this.paymentMethod != "credit-card" ) || ( this.paymentMethod == "credit-card" && this.creditCard != null );
			
			if( valid_credit_card ){
				
				this.isLoading 			= true;
				let get_timeout 		= this.getTimeOutOrder();
				this.disabledCheckout 	= true;
				
				if (this.getterLogin) {
					
					let dataPay 	= {
						customer		: this.getterCustomer._id,
						cart			: this.getterShippingData.products,
						shipping		: this.shipping_selected ? this.shipping_selected.price : 0,
						tax				: this.formatCurrency( this.totalTaxes, true ).number,
						subtotal		: this.formatCurrency( this.subtotalCart.subtotal - this.subtotalCart.coupon_discount, true ).number,
						coupon			: this.getterCoupon && this.getterCoupon._id ? this.getterCoupon : null,
						total			: this.formatCurrency( this.getTotal, true ).number,
						shipping_address: this.shippingAddress,
						billing_address	: {
							"address-line"		: this.billingAddress.address_1,
							"address-country"	: this.billingAddress.country,
							"address-state"		: this.billingAddress.state,
							"address-city"		: this.billingAddress.city,
							"address-zipcode"	: this.billingAddress.zip
						},
						financial_status: "pending",
						country_code	: this.shippingAddress.country_code,
						state_code		: this.shippingAddress.state_code,
						zip_code		: this.shippingAddress.zip,
						shipping_rates	: this.shippingSelected,
						useBalance 		: this.amountBalance,
						product_origin	: this.originProducts,
					};
					let bodyPay 	= {
						user_id						: this.getterUser.id,
						customer_id					: this.getterCustomer._id,
						customer_sh_id				: this.getterCustomer.shopify_id,
						amount						: dataPay.total,
						useBalance 					: this.amountBalance,
						"payment-method"			: this.paymentMethod,
						"card-number"				: ['credit-card'].includes( this.paymentMethod ) ? this.creditCard.cardNumber : null,
						"card-date"					: ['credit-card'].includes( this.paymentMethod ) ? `${ parseInt( this.creditCard.cardMonth ) > 9 ? this.creditCard.cardMonth : `0${ this.creditCard.cardMonth }` }/${ this.creditCard.cardYear.toString().slice(-2) }` : null,
						"card-verification-value"	: ['credit-card'].includes( this.paymentMethod ) ? this.creditCard.cardCvv : null,
						"card-name"					: ['credit-card'].includes( this.paymentMethod ) ? this.creditCard.cardName : null,
						"address-line"				: this.billingAddress.address_1,
						"address-country"			: this.billingAddress.country,
						"address-state"				: this.billingAddress.state,
						"address-city"				: this.billingAddress.city,
						"address-zipcode"			: this.billingAddress.zip,
						originPayment				: "Payment Order Cata1og",
						resumeBuy					: dataPay,
						product_origin				: this.originProducts
					};
					dataPay.business			= this.getterBusinesses;
					dataPay.customer 			= { 
						_id				: this.getterCustomer._id, 
						shopify_id		: this.getterCustomer.shopify_id, 
						is_dropshipping	: this.getterCustomer.is_dropshipping 
					};
					dataPay.billing_address 	= this.billingAddress;
					dataPay.shipping_rates		= JSON.parse( dataPay.shipping_rates );
					dataPay.payment_method 		= this.paymentMethod == 'deposit-bank' ? 'bank-deposit' : this.paymentMethod;
					dataPay.need_invoice 		= this.needInvoice ? 'invoice' : 'no-invoice';
					dataPay.note 				= `${ this.order_note } ${ this.needInvoice ? "" : "Automatic Note: Please don't include invoice" }`;
					
					let format_draft_order 	= formatData.draftOrder(dataPay, this.dataCart.details.apply_discounts);
					
					let valid_checkout_data	= {
						shipping_address: this.shippingAddress,
						billing_address	: this.billingAddress,
						country_code	: this.shippingAddress.country_code,
						state_code		: this.shippingAddress.state_code,
						zip_code		: this.shippingAddress.zip,
						shipping_rates	: this.shippingSelected,
						product_origin	: this.originProducts,
						apply_discounts	: this.dataCart.details.apply_discounts
					};
					if ( ["credit-card", "paypal"].includes(this.paymentMethod) ) {
						
						setTimeout(async () => {
							
							await this.payOrder({ draft_order: format_draft_order, payment: bodyPay, checkout: valid_checkout_data }).then( (result_pay) => {
								
								this.processPayOrder();
							}).catch( (error_pay) => {
								
								this.processPayOrder();
							});
						}, get_timeout);
					} 
					else {
						
						setTimeout(async () => {
							
							await this.createOrder({ 
								checkout	: valid_checkout_data, 
								draft_order	: format_draft_order 
							}).then( (result_order) => {
								
								this.processCreateOrder();
							}).catch( (error_order) => {
								
								this.processCreateOrder();
							});
							
						}, get_timeout);
					}
				}
				else {
					
					this.$router.replace("/account/login");
				}
			}
		},
		calculateTaxes(){
			
			let mountProducts 		= this.subtotalCart.subtotal - this.subtotalCart.coupon_discount;
			let country_taxes		= this.getterShippingData.taxes.country ? ( parseFloat( this.getterShippingData.taxes.country.percentage ) / 100 )	: 0;
			let state_taxes 		= this.getterShippingData.taxes.state 	? ( parseFloat( this.getterShippingData.taxes.state.percentage ) / 100 )		: 0;
			let state_taxes_type 	= this.getterShippingData.taxes.state 	? this.getterShippingData.taxes.state.type : 'normal';
			
			if( state_taxes_type == 'normal' ){
				
				return this.formatCurrency( mountProducts * ( state_taxes + country_taxes ), true ).number;
			}
			else {
				
				return this.formatCurrency( ( mountProducts * ( country_taxes == 0 ? 1 : country_taxes ) ) * ( state_taxes == 0 ? 1 : state_taxes ), true ).number;
			}
		}
	}
};
</script>
