<template>
    <div id="search-overlay" class="search-overlay" :class="{ 'open-search': openSearch, 'show-results': showResults != null }">
        <div class="overlay-content d-flex justify-content-center">
            <div class="container">
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-end">
                        <span class="close-search-overlay" @click="closeSearch()" title="Close Overlay">×</span>
                    </b-col>
                    <b-col xl="12" class="content-widget-search">
                        <div class="form-group mb-0">
                            <input type="text" class="form-control" v-model="searchText" @keyup="searchProductWidget" placeholder="Search a Product">
                            <b-button @click="selectSearchOption">search</b-button>
                        </div>
                        <search-result v-if="openSearch" type="mobile" @closeSearch="closeSearch" @showResultsSearch="showResultsSearch"></search-result>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props:["openSearchMobile"],
    data() {
        return {
            showResults	: null,
            openSearch	: this.openSearchMobile,
            searchText	: '',
            show_navbar : false
        }
    },
    async mounted(){
        
        this.$parent.$on('open-search-mobile', this.isOpenSearch);
    },
    components: {
        searchResult	: () => import('../../components/header/search-result.vue')
    },
    computed: {
        ...mapGetters({
            getterSearchProduct		: 'getterSearchProduct'
        }),
        getSearchText(){
            return this.searchText;
        }
    },
    methods: {
        ...mapActions({
            searchProduct	: 'searchProduct',
        }),
        closeSearch() {
            this.showResults = null;
            this.searchText = '';
            this.openSearch = false;
        },
        showResultsSearch(dataResults){
            this.showResults = dataResults;
        },
        searchProductWidget(event) {
            if( event.keyCode == 13 ){
                
                this.closeSearch();
                window.location.replace(`/collections/search?result=${ encodeURI(this.getSearchText) }`);
            }
            else{
                
                this.searchProduct({ search: this.getSearchText }).then( (result_search) => {
                    
                    this.$root.$emit('charge-search-result', { list: this.getterSearchProduct, search_text: this.getSearchText });
                });
            }
        },
        isOpenSearch(dataOpen){
            
            this.closeSearch();
            this.openSearch = dataOpen;
        },
        selectSearchOption(){

            this.closeSearch();
            window.location.replace(`/collections/search?result=${ encodeURI(this.getSearchText) }`);
        },
    }
}
</script>