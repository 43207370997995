<template>
	<b-container>
		<b-row class="justify-content-center">
			<b-col v-for="(item_brand, index_brand) in getterBrandsElement" :key="index_brand" cols="6" sm="4" md="3" lg="2" class="item-brand mb-0 p-0 text-center">
				<nuxt-link :to="{ path: item_brand.background_link }">
					<img class="img-brands-circle" :src="`${ back_url }${ item_brand.image.url }`" :alt="item_brand.image.alt" width="230" height="230"/>
				</nuxt-link>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
	data() {
		return {
			back_url: process.env.VUE_APP_CATALOG_BACK_URL_FILE
		}
	},
	async mounted() {
		await this.getBrandsElment();
	},
	computed: {
		...mapGetters({
			getterBrandsElement: 'getterBrandsElement'
		})
	},
	methods: {
		...mapActions({
			getBrandsElment: 'getBrandsElment'
		})
	}
}
</script>