<template>
    <ul v-if="searchResults != null" class="search-results">
        <li v-if="searchResults == false" class="search-loading">
            <vc-loading :isLoading="!searchResults"></vc-loading>
        </li>
        <li v-else-if="searchResults == true" v-for="(item_product, index_product) in getSearchItems" :key="index_product" class="product-box">
            <nuxt-link :to="{ path: `/products/${item_product.handle}`}" class="d-flex" :custom="true" v-slot="{href, navigate}">
                <a v-if="type == 'mobile'" :href="href" class="text-left" @click="selectSearchOption($event, navigate)">
                    <img :src="`${ item_product.images.length > 0 ? item_product.images[0].src_collection : `${ back_url }/images/products/product-default_320.webp` }`" class="img-fluid h-100" :key="index_product" width="80px" height="80px" :alt="item_product.title"/>
                    <div class="product-detail">
                        <h6 class="chunk-lines chunk-lines-2">{{ item_product.title }}</h6>
                        <vc-format-price type="collection" :discount_brand="item_product.discounts.brand" :discount_percentage="0" :price="item_product.price" :discount_price="item_product.discount_price" :discount_stock="null" :apply_discount_stock="false"></vc-format-price>
                    </div>
                </a>
                <a v-else :href="href" class="text-left" @click="selectSearchOption($event, navigate)">
                    <div class="img-wrapper col-3">
                        <img :src="`${ item_product.images.length > 0 ? item_product.images[0].src_collection : `${ back_url }/images/products/product-default_320.webp` }`" :alt="item_product.title" class="img-fluid" :key="index_product"/>
                    </div>
                    <div class="product-detail col-9">
                        <h6 class="mb-2 chunk-lines chunk-lines-2">{{ item_product.title }}</h6>
                        <h6 class="pb-2 font-weight-normal">{{ item_product.brand }}</h6>
                        <vc-format-price type="collection" :discount_brand="item_product.discounts.brand" :discount_percentage="0" :price="item_product.price" :discount_price="item_product.discount_price" :discount_stock="null" :apply_discount_stock="false"></vc-format-price>
                    </div>
                </a>
            </nuxt-link>
        </li>
        <li v-if="getSearchItems?.length > 0" class="view-more d-flex justify-content-center">
            <nuxt-link :to="{ path: `/collections/search?result=${ encodeURI(getSearchText) }` }" :custom="true" v-slot="{href, navigate}">
                <a :href="href" @click="selectSearchOption($event, navigate)">
                    View More
                </a>
            </nuxt-link>
        </li>
    </ul>
</template>
<script>
export default {
    props:["type"],
    data() {
        return {
            back_url		: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
            isLoading		: true,
            searchItems		: [],
            searchResults	: null,
            openSearch		: false,
            search_text	    : ''
        }
    },
    async mounted(){

        this.$root.$on('charge-search-result', this.getSearchResult);
    },
    components: {
        vcLoading		: () => import('../../components/pages/loading.vue' ),
        vcFormatPrice	: () => import('../../components/product/format-price.vue' )
    },
    computed: {
        getSearchItems(){
            return this.searchItems;
        },
        getSearchText(){
            return this.search_text;
        }
    },
    methods: {
        selectSearchOption(event, navigate){
            
            this.$emit("closeSearch");
            navigate(event);
        },
        getSearchResult(serch_result){

            this.$emit("showResultsSearch", serch_result.list );
            this.searchResults = serch_result.list?.length > 0 || null;
            this.searchItems = serch_result.list || [];
            this.search_text = serch_result.search_text;
        }
    }
}
</script>