<template>
	<div class="container-page">
		<register-advertising-page :banner="'banners/banner-form-leads-fajas_full.webp'" :title="'The Best Fajas Colombianas Wholesale'" :message= "'Sign up now and find out how to start a faja business!'" :source="'ADS-fajas-colombianas'"></register-advertising-page>
	</div>
</template>
<script>
export default {
	components:{
		registerAdvertisingPage: () => import('../../components/account/register-page.vue')
	}
}
</script>