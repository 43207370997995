<template>
    <section class="section-b-space page-content loading-page content-loading pt-0 p-relative">
        <vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
    </section>
</template>
<script>
export default {
    components: {
        vcLoading   : () => import("../../components/pages/loading.vue"),
    },
    data() {
        return {
            isLoading: true,
        }
    },
    mounted(){
        
		window.location.href = 'https://pal-negocio.myshopify.com/admin';
    }
}
</script>