<template>
    <section class="section-b-space">
        <balance></balance>
    </section>
</template>
<script>
export default {
    components: {
        balance: () => import('../../components/payment/balance.vue')
    }
};
</script>