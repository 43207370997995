<template>
    <div v-if="content.type == 'request'" class="container-notification request-api">
        <h3 class="notification-title">{{ title }}</h3>
        <span class="notification-subtitle">{{ message }}</span>
    </div>
    <div v-else-if="content.type == 'add-cart-product'" class="container-notification cart-notification">
        <b-row class="m-0">
            <b-col cols="4" class="p-0 d-flex justify-content-center align-items-center">
                <div :class="`notification-icon-cart d-flex align-items-center justify-content-center p-absolute rounded-circle ${ content.status }`">
                    <i v-if="content.status =='success'" class="fa-solid fa-check-circle"></i>
                    <i v-if="content.status =='info'" class="fa-solid fa-info-circle"></i>
                    <i v-if="content.status =='warning'" class="fa-solid fa-exclamation-circle"></i>
                    <i v-if="content.status =='error'" class="fa-solid fa-exclamation-triangle"></i>
                </div>
                <img v-if="content.image" :src="content.image.src_collection" :alt="content.title" class="notification-image" width="120px" height="120px">
            </b-col>
            <b-col cols="8" class="p-0 pl-3">
                <h3 class="notification-title">{{ title }}</h3>
                <span class="notification-subtitle mb-3">{{ message }}</span>
                <span class="notification-subtitle mb-2">Brand: <strong>{{ content.brand }}</strong></span>
                <span v-for="(item_product, index_product) in content.products" class="notification-subtitle mb-1">SKU: <strong>{{ item_product.sku }}</strong></span>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    props: ['title', 'message', 'content']
}
</script>