<template>
    <div class="section-b-space pt-0 page-content loading-page content-loading p-relative">
        <vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
        <breadcrumbs type="dashboard" title="Address Directory"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Address Directory</h2>
        </div>
        <b-container class="p-4 mt-2 box-shadow">
            <b-row class="register-form">
                <b-col cols="12" lg="5" class="mb-2">
                    <b-form-group label="Select Address" label-for="select_address" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="d-lg-none d-block mb-0 select-address-list">
                        <v-select id="select_address" name="select_address" @input="changeAddress" :options="getListAddresses" :reduce="(item) => item.id" v-model="selectAddressId" :searchable="false">
                            <template #selected-option="{ id, label, value }">
                                <b-row v-if="id != null" class="option-address-template m-0 selected-option py-2">
                                    <b-col cols="9" class="p-0">
                                        <p class="font-weight-normal">{{ value.first_name }} {{ value.last_name }}</p>
                                        <p>{{ value.company }}</p>
                                        <p class="font-weight-normal">{{ value.address_1 }}</p>
                                        <p>{{ value.city }} {{ value.state_code != null ? `${ value.state_code }` : '' }} {{ value.zip }}</p>
                                        <p class="font-weight-normal">{{ value.country }}</p>
                                    </b-col>
                                    <b-col cols="3" class="d-flex align-items-center default-option p-0">
                                        <p class="text-center">{{ value.default ? "Default Address" : '' }}</p>
                                    </b-col>
                                </b-row>
                                <b-row v-else class="option-address-template m-0 selected-option py-2">
                                    <b-col cols="12" class="p-0">
                                        <p class="font-weight-normal py-4">{{ label }}</p>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #option="{ id, label, value }">
                                <b-row v-if="id != null" class="option-address-template m-0">
                                    <b-col cols="9" class="p-0">
                                        <p class="font-weight-normal">{{ value.first_name }} {{ value.last_name }}</p>
                                        <p>{{ value.company }}</p>
                                        <p class="font-weight-normal">{{ value.address_1 }}</p>
                                        <p>{{ value.city }} {{ value.state_code != null ? `${ value.state_code }` : '' }} {{ value.zip }}</p>
                                        <p class="font-weight-normal">{{ value.country }}</p>
                                    </b-col>
                                    <b-col cols="3" class="d-flex align-items-center default-option p-0">
                                        <p class="text-center">{{ value.default ? "Default Address" : '' }}</p>
                                    </b-col>
                                </b-row>
                                <b-row v-else class="option-address-template m-0">
                                    <b-col cols="12" class="p-0">
                                        <p class="font-weight-normal py-4">{{ label }}</p>
                                    </b-col>
                                </b-row>
                            </template>
                        </v-select>
                    </b-form-group>
                    <b-form-group v-if="getListAddresses.length > 2" label="Select Address" label-for="select_address_list" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" class="d-lg-block d-none mb-0 select-address-list">
                        <b-form-radio-group id="select_address_list" v-model="selectAddressId" name="select_address_list">
                            <b-list-group-item v-for="(item, index) in getListAddresses" :key="`select_address_list_${ index }`" :class="`${ item.id != null ? 'd-flex' : 'd-none' } justify-content-between align-items-center`">
                                <b-form-radio name="select_address_list" :value="item.id" :id="item.id ? item.id.toString() : null" class="d-flex w-100" @input="changeAddress">
                                    <b-row class="option-address-template selected-option">
                                        <b-col cols="9">
                                            <p class="m-0 font-weight-normal">{{ item.value.first_name }} {{ item.value.last_name }}</p>
                                            <p class="m-0">{{ item.value.company }}</p>
                                            <p class="m-0 font-weight-normal">{{ item.value.address_1 }}</p>
                                            <p class="m-0">{{ item.value.city }} {{ item.value.state_code != null ? `${ item.value.state_code }` : '' }} {{ item.value.zip }}</p>
                                            <p class="m-0 font-weight-normal">{{ item.value.country }}</p>
                                        </b-col>
                                        <b-col cols="3" class="d-flex align-items-center">
                                            <p class="m-0 font-color-light text-center">{{ item.default ? "Default Address" : '' }}</p>
                                        </b-col>
                                    </b-row>
                                </b-form-radio>
                            </b-list-group-item>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="7">
                    <form-address v-if="getAddressForm && getDataListCountries.length > 0" idForm="register-shipping-address" :dataAddress="getAddressForm" :dataListCountries="getDataListCountries" :dataListStates="getDataListStates" :dataListPhoneCodes="getDataListPhoneCodes" @validFormAddress="validFormAddress" typeForm="address"></form-address>
                    <b-row v-if="getListAddresses.length >= 2">
                        <b-col cols="12">
                            <b-form-checkbox v-model="isDefaultAddress" name="check-button" inline @change="changeDefaultAddress"> is default address? ({{ isDefaultAddress ? ' YES ' : ' NO ' }})</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" lg="5" class="d-lg-flex d-none justify-content-end">
                    <b-button type="button" class="btn-solid d-flex align-items-center justify-content-center mr-1 shadow-sm" @click="addAddress">
                        <i class="fa-solid fa-plus mr-2"></i>
                        Add New
                    </b-button>
                </b-col>
                <b-col cols="12" lg="7">
                    <div class="w-100 d-flex justify-content-end">
                        <b-button type="button" class="btn-solid d-flex align-items-center justify-content-center mr-1 shadow-sm" @click="deleteAddress" :disabled="!(getAddressForm.id != null && listAddresses.length > 2)">
                            <i class="fa-solid fa-trash mr-2"></i>
                            Delete
                        </b-button>
                        <b-button class="btn-solid d-flex align-items-center justify-content-center mr-1 shadow-sm" @click="saveAddress">
                            <i class="fa-solid fa-bookmark mr-2"></i>
                            {{ getAddressForm.id == null ? 'Save' : 'Update' }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue"),
        formAddress : () => import("../../../components/pages/form-address.vue")
    },
    data() {
        return {
            isLoading       : true,
            
            addressForm     : null,
            listAddresses   : [],
            listCountries   : [],
            listPhoneCodes  : [],
            listStates      : [],
            
            defaultAddress  : {
                id          : null,
                customer_id : null,
                default     : false,
                first_name  : '',
                last_name   : '',
                name        : '',
                phone       : '',
                address_1   : '',
                address_2   : '',
                company     : '',
                country     : 'United States',
                country_code: 'US',
                state       : null,
                state_code  : null,
                city        : '',
                zip         : ''
            },
            selectAddressId : null,
            
            validForm       : false
        };
    },
    async mounted(){
        
        if( !this.getterLogin ){
            
            this.$router.push('/account/login');
        }
        else{
            
            await this.getCountries().then( async (result_countries) => {
                
                this.listPhoneCodes = this.getterCountries.phone_codes;
                this.listCountries 	= this.getterCountries.list;
                this.listStates     = this.getterCountries.states;
                await this.getCustomer({ id_email: this.getterUser.email }).then( (result_customer) => {
                    
                    this.defaultAddress.customer_id = this.getterCustomer.shopify_id;
                    this.formatListAddress();
                    
                    this.isLoading = null;
                });
                this.$root.$emit('change-select-address', { id_form: "register-shipping-address", address: this.addressForm });
            });
        }
    },
    computed: {
        ...mapGetters({
            getterLogin             : 'getterLogin',
            getterUser              : 'getterUser',
            getterCustomer          : "getterCustomer",
            getterCustomerAddresses : "getterCustomerAddresses",
            getterCountries         : "getterCountries"
        }),
        getAddressForm(){
            return this.addressForm ? this.addressForm : this.defaultAddress;
        },
        isDefaultAddress(){
            return this.getAddressForm.default || false;
        },
        getDataListCountries(){
            return this.listCountries;
        },
        getDataListStates(){
            return this.listStates;
        },
        getDataListPhoneCodes(){
            return this.listPhoneCodes;
        },
        getListAddresses(){
            return this.listAddresses;
        }
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault()
                    }
                    return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    methods: {
        ...mapActions({
            getCountries            : 'getCountries',
            getCustomer             : 'getCustomer',
            saveCustomerAddress     : 'saveCustomerAddress',
            deleteCustomerAddress   : 'deleteCustomerAddress',
        }),
        async validFormAddress(data_form){
            this.validForm      = data_form.valid;
            this.addressForm    = data_form.address;
            
            if( this.validForm ){
                
                this.isLoading = true;
                await this[data_form.action](this.addressForm).then( (result_address) => {
                    
                    this.formatListAddress();
                });
                this.validForm = false;
            }
            this.isLoading = null;
        },
        async saveAddress() {
            
            this.$root.$emit('valid-address', { id_form: "register-shipping-address", action: "saveCustomerAddress" });
        },
        async deleteAddress() {
            
            this.$root.$emit('valid-address', { id_form: "register-shipping-address", action: "deleteCustomerAddress" });
        },
        addAddress(){
            this.changeAddress(null);
        },
        validPhoneNumber(phone){
            
            if( phone ){
                
                phone = phone.replace(/([ +])+/g, "");
                
                let code = phone.replace(phone.slice(-10), "");
                
                let index_country = code == "" ? -1 : this.listPhoneCodes.findIndex( (item) => item.phone_code == parseInt(code) );
                
                let country_code = ( code != "" && code == "1" ) || index_country < 0 ? "US-1" : this.listPhoneCodes[index_country].id;
                
                return {
                    code: country_code,
                    number: phone.slice(-10),
                    format: `+${ country_code.split("-")[1] }${ phone.slice(-10) }`
                };
            }
            else{
                
                return {
                    code: "US-1",
                    number: "",
                    format: ""
                };
            }
        },
        changeDefaultAddress(isDefault){
            
            this.addressForm.default = isDefault;
        },
        changeAddress(address_id) {
            
            if (this.getterCustomer) {
                
                this.addressForm 	= this.listAddresses.find((item) => item.id == address_id )?.value;
                
                if (this.addressForm) {
                    
                    this.selectAddressId = address_id;
                    this.$root.$emit('change-select-address', { id_form: "register-shipping-address", address: this.addressForm });
                }
            }
        },
        formatListAddress(){
            
            this.listAddresses      = this.getterCustomerAddresses.map((item) => {
                
                return { 
                    label   : `${ item.first_name } ${ item.last_name } - ${ item.address_1 } ${ item.city } ${ item.zip } ${ item.country } ${ item.state }`, 
                    id      : item.id, 
                    default : item.default, 
                    value   : item 
                };
            });
            this.listAddresses      = [{ label: `New Address`, id: this.defaultAddress.id, value: this.defaultAddress }].concat( this.listAddresses.filter( (item) => item.value.default ), this.listAddresses.filter( (item) => !item.value.default ) );
            
            this.addressForm        = this.listAddresses.find((item) => item.default );
            
            this.addressForm        = this.addressForm && !this.addressForm.country && !this.addressForm.state ? this.getterCustomerAddresses.find( (item) => item.country && item.state ) : this.addressForm;
            
            this.addressForm        = this.addressForm ? this.addressForm : {...this.defaultAddress};
            
            this.selectAddressId    = this.addressForm.id;
        }
    }
}
</script>