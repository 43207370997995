<template>
	<b-container class="private-label">
		<b-row>
			<b-col cols="12">
				<div class="title-default section-t-space">
					<h2 class="title-inner-default">Private label</h2>
				</div>
				<div class="part-one-card-private-label d-flex aling-items-center justify-content-center mt-5">
					<b-row>
						<b-col md="6">
							<img class="img-brands-circle" :src="require('@/assets/images/collection/private-label-01_700.webp')" :alt="'image-description-private-label'" width="100%" height="500"/>
						</b-col>
						<b-col md="6" class="mt-5">
							<h2 class="mb-2"> WELCOME TO Cata1og.com</h2>
							<p class="text-private-label">
								The place where wholesale shopping is as easy and professional as you've ever seen before. Do you want to start the best business partnership for your store or website?
							</p>
						</b-col>
					</b-row>
				</div> 
			</b-col>
			<b-col cols="12">
				<div class="title-default section-t-space">
					<h2 class="title-inner-default mt-5">LET US GUIDE YOU THROUGH THE PROCESS</h2>
				</div>
				<b-row>
					<div class="content-img-private-label d-flex aling-items-center justify-content-center mb-5">
						<b-row class="mb-5">
							<b-col cols="12">
								<p class="text-private-label mr-5 ml-5 mb-5">
									MOQ project is the golden opportunity to finally have your very own clothing brand made in Colombia. Buy +120 in 5 diffrent styles high-quality products of our Private Label collection and we’ll print out your brand’s name in every label and tag. Your products, your brand!
								</p>
							</b-col>
							<b-col cols="12" sm="6" md="4" class="text-center">
								<img class="img-brands-circle" :src="require('@/assets/images/collection/private-label-jeans_600.webp')" :alt="'image-description-private-label'" width="280" height="400"/>
							</b-col>
							<b-col cols="12" sm="6" md="4" class="text-center">
								<img class="img-brands-circle" :src="require('@/assets/images/collection/private-label-fajas_600.webp')" :alt="'image-description-private-label'" width="280" height="400"/>
							</b-col>
							<b-col cols="12" sm="12" md="4" class="text-center">
								<img class="img-brands-circle" :src="require('@/assets/images/collection/private-label-leggins_600.webp')" :alt="'image-description-private-label'" width="280" height="400"/>
							</b-col>
						</b-row>
					</div>
				</b-row>
				<b-row class="row row-steps-buy mb-5">
					<b-col cols="12">
						<ul class="steps-buy mb-5">
							<li class="content-step">
								<div class="number-step box-shadow d-flex align-items-center justify-content-center text-center">1</div>
								<div class="ml-3 content-text-step box-shadow">
									<span class="name-step">Choose your own brand</span>
									<span class="text-step">- Your brand will be identified with your brand's name on the tags.</span>
								</div>
							</li>
							<li class="content-step">
								<div class="number-step box-shadow d-flex align-items-center justify-content-center text-center">2</div>
								<div class="ml-3 content-text-step box-shadow">
									<span class="name-step">Select the styles you want</span>
									<span class="text-step">- Choose from the variety set up for you.</span>
								</div>
							</li>
							<li class="content-step">
								<div class="number-step box-shadow d-flex align-items-center justify-content-center text-center">3</div>
								<div class="ml-3 content-text-step box-shadow">
									<span class="name-step">Ask for product samples</span>
									<span class="text-step">- You will be able to look, feel and test the product's quality.</span>
								</div>
							</li>
							<li class="content-step">
								<div class="number-step box-shadow d-flex align-items-center justify-content-center text-center">4</div>
								<div class="ml-3 content-text-step box-shadow">
									<span class="name-step"
									>Customize your order by choosing the size, color and style</span>
									<span class="text-step">- You can now satisfy your client's need and preferences.</span>
								</div>
							</li>
							<li class="content-step">
								<div class="number-step box-shadow d-flex align-items-center justify-content-center text-center">5</div>
								<div class="ml-3 content-text-step box-shadow">
									<span class="name-step">Recieve your products</span>
									<span class="text-step">- They will be delivered on your business or boutique doorsteps.</span>
								</div>
							</li>
							<li class="content-step">
								<div class="number-step box-shadow d-flex align-items-center justify-content-center text-center">6</div>
								<div class="ml-3 content-text-step box-shadow">
									<span class="name-step">Our prices have products, shipping and customs clearence included</span>
									<span class="text-step">- You buy and we handle the rest. *It applies for any payment method (credit card, checks or electronic transfer)</span>
								</div>
							</li>
						</ul>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
};
</script>
