<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Brands"></breadcrumbs>
		<div class="title-default pt-4">
			<h2 class="title-inner-default">Brands</h2>
		</div>
		<brands></brands>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
		brands: () => import('../../components/pages/brands-grid.vue')
	}
}
</script>
