<template>
    <div class="section-table">
        <b-row>
            <b-col cols="12" class="register-form mb-2">
                <b-input-group size="sm">
                    <b-form-input id="filter-input" type="text" v-model="searchField" @change="searchTable" placeholder="Search"></b-form-input>
                    <b-input-group-append>
                        <b-button class="btn-solid px-3" :disabled="searchField == ''" @click="cleanSearchTable">
                            <i class="fa-solid fa-trash"></i>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col v-if="type_table != 'price-catalog'" cols="12" lg="8" class="register-form mb-2">
                <b-form-group label="Range Dates:" label-align-sm="top" label-size="sm" class="mb-0">
                    <b-row>
                        <b-col cols="12" lg="3" class="pt-2 text-center">
                            <b-form-select v-model="selectDateFilter" @change="changeDateFilter" class="mr-2">
                                <b-form-select-option  v-for="(item_option, index_option) in dateFilterOptions" :key="index_option" :value="item_option" class="text-center">{{ item_option == "todo" ? "⚠️" : item_option }}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" lg="9" class="col-range-dates-orders text-center pt-2 d-flex align-items-center">
                            <b-form-datepicker class="d-inline-flex" v-model="range_dates.min_date" locale="en-US" :max="max_date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" @input="updateRangeDate"></b-form-datepicker>
                            <span class="d-inline-flex mr-2 ml-2">-</span>
                            <b-form-datepicker class="d-inline-flex" v-model="range_dates.max_date" locale="en-US" :min="min_date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" @input="updateRangeDate"></b-form-datepicker>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>
            <b-col cols="12" :lg="type_table == 'price-catalog' ? 12 : 4" class="mb-2 d-flex align-items-end">
                <b-row class="w-100 m-0">
                    <b-col cols="5" class="p-0">
                        <div v-if="['transactions', 'price-catalog'].includes( type_table )" class="content-downoload-file d-flex justify-content-center ml-2">
                            <a id="download_file" title="not-title" href="" class="d-none"></a>
                            <b-button class="btn-solid btn-file px-3" v-b-tooltip.hover :title="getTitleExport" @click="createExportTable">
                                <i class="fa-solid fa-file-arrow-down"></i>
                            </b-button>
                        </div>
                    </b-col>
                    <b-col cols="7" class="p-0">
                        <div class="page-size-table d-flex align-items-center w-100 justify-content-end">
                            <span class=" d-flex justify-content-end w-50 text-muted text-small pr-2">{{ paginateDetails.fromPage }}-{{ paginateDetails.toPage }} of {{ paginateDetails.totalPages }}</span>
                            <b-form-select v-model="paginateDetails.perPage" @change="changePageSize" class="w-50">
                                <b-form-select-option  v-for="(item_size, index_size) in pageSizes" :key="index_size" :value="item_size" class="text-center">{{ item_size == "todo" ? "⚠️" : item_size }}</b-form-select-option>
                            </b-form-select>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-table v-if="type_table == 'transactions'" id="table_data" ref="table_data" :busy="isTableBusy" :items="getListItems" :fields="table_fields" :per-page="paginateDetails.perPage" :current-page="paginateDetails.currentPage" responsive show-empty small>
            <template #table-busy>
                <div class="d-flex align-items-center justify-content-center p-relative spinner-container text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="p-absolute">Loading...</strong>
                </div>
            </template>
            <template #cell(amount)="data">
                <span :class="data.item.invoice ? 'font-color-red' : ''">{{ formatCurrency(data.value).format }}</span>
            </template>
            <template #cell(balance)="data">
                <span :class="(data.value != 0 ? data.value * -1 : 0) < 0 ? 'font-color-red' : ''">{{ formatCurrency(data.value != 0 ? data.value * -1 : 0).format }}</span>
            </template>
            <template #cell(status_name)="data">
                <b-badge v-if="data.item.status_document == 0" class="status-error">{{ data.value }}</b-badge>
                <b-badge v-if="data.item.status_document == 1" class="status-success">{{ data.value }}</b-badge>
                <b-badge v-if="data.item.status_document == 2" class="status-warning">{{ data.value }}</b-badge>
                <b-badge v-if="data.item.status_document == 3" class="status-dark">{{ data.value }}</b-badge>
            </template>
            <template #cell(document)="data">
                <b-badge v-if="data.item.type == 0 || data.item.type == 2" class="input-document">{{ data.value }}</b-badge>
                <b-badge v-if="data.item.type == 1 || data.item.type == 3" class="output-document">{{ data.value }}</b-badge>
            </template>
            <template #cell(actions)="data">
                <a v-if="data.item.voided === false" title="Print Invoice" variant="link" :href="`${ billingFileUrl }/document/print/${data.item.document.toLowerCase().replace(' ', '-')}/${data.item.document_id}`" :target="'_blank'">
                    <i class="fa-solid fa-print fa-2x"></i>
                </a>
            </template>
        </b-table>
        <b-table v-else-if="type_table == 'orders'" id="table_data" ref="table_data" :busy="isTableBusy" :items="getListItems" :fields="table_fields" :per-page="paginateDetails.perPage" :current-page="paginateDetails.currentPage" responsive show-empty small>
            <template #table-busy>
                <div class="d-flex align-items-center justify-content-center p-relative spinner-container text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="p-absolute">Loading...</strong>
                </div>
            </template>
            <template #cell(items)="data">
                <label class="d-block text-center">{{ data.value }}</label>
            </template>
            <template #cell(total_price)="data">
                <label class="d-block text-center">{{ data.value }}</label>
            </template>
            <template #cell(financial_status)="data">
                <label class="d-block text-center text-capitalize">{{ data.value }}</label>
            </template>
            <template #cell(fulfillments)="data">
                <a v-if="data.value.length > 0" class="p-2 d-flex justify-content-center align-items-center text-center" v-b-tooltip.top :title="`${ data.value[0].tracking_company ? data.value[0].tracking_company : '' } Tracking Number: ${ data.value[0].tracking_number }`" target="_blank" :href="data.value[0].tracking_url">
                    {{ data.value[0].tracking_company ? data.value[0].tracking_company : '' }}<i class="fa-solid fa-truck ml-1"></i>
                </a>
                <label v-else-if="!['refunded', 'voided'].includes( data.item.financial_status ) && data.item.cancelled_at == null" class="d-block text-center">
                    ...waiting
                </label>
                <label v-else class="d-block text-center">
                    Shipment Canceled
                </label>
            </template>
            <template #cell(actions)="data">
                <a @click="showOrderDetails(data.item)" title="Order Details" class="d-inline-block p-1">
                    <i class="fa-solid fa-eye"></i>
                </a>
            </template>
        </b-table>
        <b-table v-else-if="type_table == 'price-catalog'" id="table_data" ref="table_data" :busy="isTableBusy" :items="getListItems" :fields="table_fields" :per-page="paginateDetails.perPage" :current-page="paginateDetails.currentPage" responsive show-empty small>
            <template #table-busy>
                <div class="d-flex align-items-center justify-content-center p-relative spinner-container text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="p-absolute">Loading...</strong>
                </div>
            </template>
            <template #cell(sku_parent)="data">
                <nuxt-link v-if="data.value" class="d-block m-0" :title="data.item.title_product" :to="{ path:`/products/${ data.item.handle }` }">{{ data.value }}</nuxt-link>
            </template>
            <template #cell(options)="data">
                <label class="d-block m-0" :title="data.item.variant_title">{{ data.value }}</label>
            </template>
        </b-table>
        <div class="custom-pagination row m-0 justify-content-center">
            <b-pagination v-model="paginateDetails.currentPage" :total-rows="paginateDetails.totalPages" :per-page="paginateDetails.perPage" :align="'center'" aria-controls="table_orders" @change="onChangePage">
                <template #first-text>
                    <span aria-hidden='true'>
                        <i class="fa-solid fa-angle-double-left"></i>
                    </span>
                </template>
                <template #prev-text>
                    <span aria-hidden='true'>
                        <i class="fa-solid fa-chevron-left"></i>
                    </span>
                </template>
                <template #next-text>
                    <span aria-hidden='true'>
                        <i class="fa-solid fa-chevron-right"></i>
                    </span>
                </template>
                <template #last-text>
                    <span aria-hidden='true'>
                        <i class="fa-solid fa-angle-double-right"></i>
                    </span>
                </template>
            </b-pagination>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../constants/config.js";
export default {
    props: ["type_table", "table_fields"],
    data() {
        return {
            isLoading           : true,
            
            listItems           : [],
            billingFileUrl      : `${ process.env.VUE_APP_CATALOG_BACK_URL }${ process.env.VUE_APP_BILING_BACK_URL }`,
            
            min_date			: moment().startOf("month").startOf("day").format("YYYY-MM-DD"),
            max_date			: moment().endOf("month").endOf("day").format("YYYY-MM-DD"),
            searchField			: "",
            paginateDetails	    : {
                perPage     : 20,
                currentPage : 1,
                lastPage    : 0,
                fromPage    : 0,
                toPage      : 0,
                totalPages  : 0
            },
            pageSizes	        : [10, 20, 50, 100],
            dateFilterOptions	: ["Custom Range", "This Month", "Last Month", "This Year", "Last Year"],
            selectDateFilter	: "This Month",
            range_dates			: {
                min_date: new Date( moment().startOf("month").startOf("day") ),
                max_date: new Date( moment().endOf("month").endOf("day") )
            },
            isTableBusy	        : true,
        };
    },
    async mounted(){
        
        if( this.getterLogin ){
            
            await this.updateTableData();
        }
    },
    computed: {
        ...mapGetters({
            getterLogin                     : 'getterLogin',
            getterUser                      : 'getterUser',
            getterSatement                  : "getterSatement",
            getterTransactions              : "getterTransactions",
            getterOrders                    : "getterOrders",
            getterPriceCatalog              : "getterPriceCatalog",
            getterDownloadTransactionsFile  : "getterDownloadTransactionsFile",
            getterDownloadPriceCatalog      : "getterDownloadPriceCatalog",
        }),
        getListItems(){
            
            return this.listItems;
        },
        getTitleExport(){
            let title_export = "";
            if( this.type_table == 'transactions' ){
                title_export = `Download Transactions File ${ this.momentFormat( this.range_dates.min_date ) } - ${ this.momentFormat( this.range_dates.max_date ) }`;
            }
            else if( this.type_table == 'price-catalog' ){
                title_export = `Download Price Catalog File - ${ moment().format("YYYY-MM-DD") }`;
            }
            return title_export;
        }
    },
    methods:{
        ...mapActions({
            getTransactions                 : "getTransactions",
            getOrders                       : "getOrders",
            getPriceCatalog                 : "getPriceCatalog",
            getStatementByCustomer          : "getStatementByCustomer",
            downloadExportTransactionsFile  : "downloadExportTransactionsFile",
            deleteExportTransactionsFile    : "deleteExportTransactionsFile",
            downloadPriceCatalog            : "downloadPriceCatalog",
            deletePriceCatalog              : "deletePriceCatalog"
        }),
        formatCurrency(price){
            return formatData.currency(price);
        },
        momentFormat(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        async updateTableData(){
            this.isTableBusy = true;
            this.paginateDetails.currentPage = 1;
            this.onChangePage(1);
            this.updatePaginateTable([]);
            
            let request_body = { page: 1, search: this.searchField, min_date: this.momentFormat( this.range_dates.min_date ), max_date: this.momentFormat( this.range_dates.max_date ) };
            
            if( this.type_table == "transactions" ){
                
                await this.getTransactions(request_body).then( (result_transactions) => {
                    
                    this.listItems = this.getterTransactions;
                    this.resultListItems();
                });
            }
            else if( this.type_table == "orders" ){
                
                await this.getOrders(request_body).then( (result_orders) => {
                    
                    this.listItems = this.getterOrders;
                    this.resultListItems();
                });
            }
            else if( this.type_table == "price-catalog" ){
                
                await this.getPriceCatalog().then( (result_price_catalog) => {
                    
                    this.listItems = this.getterPriceCatalog;
                    this.resultListItems();
                });
            }
        },
        resultListItems(){
            
            this.updatePaginateTable(this.getListItems);
            this.$refs["table_data"]?.refresh();
            this.isTableBusy = false;
        },
        async updateRangeDate(){
            this.selectDateFilter = "Custom Range";
            await this.updateTableData();
        },
        async changeDateFilter(filter_option){
            
            this.range_dates = { min_date: moment(), max_date: moment() }
            
            if( filter_option == "Last Month"){
                
                this.range_dates.min_date = this.range_dates.min_date.subtract(1, "months");
                this.range_dates.max_date = this.range_dates.max_date.subtract(1, "months");
            }
            else if( filter_option == "This Year"){
                
                this.range_dates.min_date = this.range_dates.min_date.startOf("year");
                this.range_dates.max_date = this.range_dates.max_date.endOf("year");
            }
            else if( filter_option == "Last Year"){
                
                this.range_dates.min_date = this.range_dates.min_date.subtract(1, "years").startOf("year");
                this.range_dates.max_date = this.range_dates.max_date.subtract(1, "years").endOf("year");
            }
            this.range_dates.min_date = new Date( this.range_dates.min_date.startOf("month").startOf("day") );
            this.range_dates.max_date = new Date( this.range_dates.max_date.endOf("month").endOf("day") );
            
            await this.updateTableData();
        },
        updatePageinfo( page ){
            this.paginateDetails.toPage = ( this.paginateDetails.perPage * page ) > this.paginateDetails.totalPages ? this.paginateDetails.totalPages : ( this.paginateDetails.perPage * page );
            this.paginateDetails.fromPage = ( this.paginateDetails.perPage * ( page - 1 ) ) + 1;
        },
        updatePaginateTable(rows){
            this.paginateDetails.currentPage = 1;
            this.paginateDetails.totalPages = rows.length;
            this.updatePageinfo( this.paginateDetails.currentPage );
        },
        onChangePage(page) {
            this.updatePageinfo( page );
        },
        changePageSize(perPage) {
            this.paginateDetails.perPage = perPage;
            this.updatePageinfo( this.paginateDetails.currentPage );
        },
        async searchTable(search_value) {
            this.searchField = search_value;
            await this.updateTableData();
        },
        async cleanSearchTable(){
            this.searchField = "";
            await this.updateTableData();
        },
        downloadExportFile( data_file ) {
            
            if( ![null, ""].includes( data_file ) ){
                
                document.getElementById("download_file").href = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data_file }`;
                document.getElementById("download_file").download = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data_file }`.substring( `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data_file }`.lastIndexOf('/') + 1 );
                document.getElementById("download_file").click();
                setTimeout(async () => {
                    if( this.type_table == 'transactions' ){
                        
                        await this.deleteExportTransactionsFile({ url_file: data_file });
                    }
                    else if( this.type_table == 'price-catalog' ){
                        
                        await this.deletePriceCatalog({ url_file: data_file });
                    }
                }, 3000);
            }
        },
        async createExportTable(){
            
            if( this.type_table == 'transactions' ){
                
                await this.downloadExportTransactionsFile({ page: 1, min_date: this.momentFormat( this.range_dates.min_date ), max_date: this.momentFormat( this.range_dates.max_date ) }).then( (result_file) => {
                    
                    this.downloadExportFile( this.getterDownloadTransactionsFile );
                });
            }
            else if( this.type_table == 'price-catalog' ){
                
                await this.downloadPriceCatalog().then( (result_file) => {
                    
                    this.downloadExportFile( this.getterDownloadPriceCatalog );
                });
            }
        },
        showOrderDetails(dataOrder){
            
            this.$emit('openSideBar', dataOrder);
        }
    }
}
</script>