*
<template>
	<div class="register-page">
		<div class="title-default primary-title">
			<h2 class="title-inner-default">{{title}}</h2>
			<h4>{{message}}</h4>
			<p>(for wholesalers only)</p>
			<b-button @click="moveScrollRegisterForm(true)" class="btn-solid d-md-none" title="Start Now">Start now</b-button>
		</div>
		<b-container>
			<b-row class="m-0">
				<b-col cols="12" md="6" lg="6" class="col-register">
					<div class="container-text-register">
						<h3>Maker leaders of Fajas Colombianas wholesale for 10 years in USA, we are our bridge to comercial success in the U.S.</h3>
						<img loading="eager" width="860px" height="730px" :src="require(`@/assets/images/form/wholesale-ecommerce-cata1og.webp`)" :srcset="require(`@/assets/images/form/wholesale-ecommerce-cata1og.webp`)" alt="Start Cata1og" class="img-start-cata1og w-100 h-auto"/>
					</div>
				</b-col>
				<b-col cols="12" md="6" lg="6" class="col-register" id="register_form" ref="register_form">
					<step-register :initial-state="{source: source}"></step-register>
				</b-col>
			</b-row>
		</b-container>
		<div class="section-dark mt-4">
			<b-container>
				<b-row>
					<b-col cols="12">
						<h3 class="font-color-light">Cata1og is the largest Colombian fajas wholesaler in the U.S.</h3>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<b-container>
			<b-row class="d-flex justify-content-center">
				<b-col cols="12">
					<div class="title-default mt-5 mb-5">
						<h4 class="text-slider-brands p-0 text-transform-none">Cata1og is a multi-brand company specialized in distributing compression garments, all made in Colombia and designed with US needs in mind, to bring you the most reliable and highest quality products in the market.</h4>
					</div>
				</b-col>
				<b-col cols="12">
					<logo-slider-black></logo-slider-black>
				</b-col>
			</b-row>
			<b-row id="slider-register-benefits" class="p-relative justify-content-center mx-0">
				<b-col id="item-carousel-register-benefits-1" cols="12" sm="6" lg="4" class="col-carousel show-item-carousel align-items-center d-flex justify-content-center w-100">
					<div class="default-block w-100 px-2">
						<div class="media default-border p-0">
							<div class="media-body">
								<img loading="lazy" :src="require(`@/assets/images/form/cata1og-logistic-process.webp`)" :srcset="require(`@/assets/images/form/cata1og-logistic-process.webp`)" alt="Cata1og Logistic Process" class="mb-4"/>
								<h3 class="font-weight-normal title-block chunk-lines chunk-lines-2 my-4">Do not worry about the logistic process</h3>
								<p class="chunk-lines chunk-lines-4 mt-2 mb-4">Simplify your journey from Colombia to your doorstep. We offer a Complete Marketing Package with branding, logos, and hassle-free dropshipping.</p>
								<b-button @click="moveScrollRegisterForm(true)" class="btn-solid btn-light w-100 letter-spacing-0">Join Now</b-button>
							</div>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-register-benefits-2" cols="12" sm="6" lg="4" class="col-carousel hide-item-carousel align-items-center d-flex justify-content-center w-100">
					<div class="default-block w-100 px-2">
						<div class="media default-border p-0">
							<div class="media-body">
								<img loading="lazy" :src="require(`@/assets/images/form/cata1og-margin-sales.webp`)" :srcset="require(`@/assets/images/form/cata1og-margin-sales.webp`)" alt="Cata1og Margin Sales" class="mb-4"/>
								<h3 class="font-weight-normal title-block chunk-lines chunk-lines-2 my-4">From 40% up to 60% margin on sales.</h3>
								<p class="chunk-lines chunk-lines-4 mt-2 mb-4">At cata1og you have the highest margins in the market, earn from 40% to 60% on sales and get benefits such as POP material and personalized marketing training.</p>
								<b-button @click="moveScrollRegisterForm(true)" class="btn-solid btn-light w-100 letter-spacing-0">Scale your business now</b-button>
							</div>
						</div>
					</div>
				</b-col>
				<b-col id="item-carousel-register-benefits-3" cols="12" sm="6" lg="4" class="col-carousel hide-item-carousel align-items-center d-flex justify-content-center w-100">
					<div class="default-block w-100 px-2">
						<div class="media default-border p-0">
							<div class="media-body">
								<img loading="lazy" :src="require(`@/assets/images/form/cata1og-top-services.webp`)" :srcset="require(`@/assets/images/form/cata1og-top-services.webp`)" alt="Cata1og Top Services" class="mb-4"/>
								<h3 class="font-weight-normal title-block chunk-lines chunk-lines-2 my-4">Years of Excellence, Top Service.</h3>
								<p class="chunk-lines chunk-lines-4 mt-2 mb-4">With 10+ years of experience and a track record of 1500+ satisfied clients, our top-notch customer service is both fast and attentive.</p>
								<b-button @click="moveScrollRegisterForm(true)" class="btn-solid btn-light w-100 letter-spacing-0">Get to now our service today</b-button>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<div class="section-dark mt-4">
			<b-container>
				<b-row class="m-0">
					<b-col cols="12" class="mb-5 p-0">
						<h2 class="font-color-white text-transform-none">Our <strong class="font-color-light">investment</strong> plans</h2>
					</b-col>
					<b-col cols="12" class="mt-lg-5 p-0">
						<b-row id="slider-cata1og-plans" class="p-relative justify-content-center align-items-start mt-5 mx-0">
							<b-col id="item-carousel-cata1og-plans-1" cols="12" lg="4" class="col-carousel show-item-carousel align-items-center d-flex justify-content-center p-0 px-xl-3">
								<div class="default-block plan-block w-100 px-2 mt-lg-4">
									<div class="media default-border px-4 py-4">
										<div class="media-body">
											<h3 class="font-weight-normal title-block my-lg-4 h-auto">Newbee Plan</h3>
											<h4 class="plan-offer-title font-color-light title-block my-4">300 - 599 USD</h4>
											<h4 class="font-weight-normal mt-2 mb-4">For Beginners</h4>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">10% OFF</span>
											</div>
											<b-button @click="moveScrollRegisterForm(true)" class="btn-solid btn-light w-100 letter-spacing-0">Sign me up</b-button>
										</div>
									</div>
								</div>
							</b-col>
							<b-col id="item-carousel-cata1og-plans-2" cols="12" lg="4" class="col-carousel hide-item-carousel align-items-center d-flex justify-content-center p-0 px-xl-3">
								<div class="default-block plan-block w-100 px-2 p-relative">
									<div class="top-label-block plan-top-label p-absolute">
										<span class="font-color-white text-transform-upper p-3 d-block">Most Popular</span>
									</div>
									<div class="media default-border px-4 py-4">
										<div class="media-body">
											<h3 class="font-weight-normal title-block my-lg-4 h-auto">Champ Plan</h3>
											<h4 class="plan-offer-title font-color-light title-block my-4">Over 1000 USD</h4>
											<h4 class="font-weight-normal mt-2 mb-4">For Advanced</h4>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">From 40% to 50% OFF.</span>
											</div>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">Free Training on our products and brands.</span>
											</div>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">Personalized attention from our sales executives.</span>
											</div>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">Free shipping on your 1st order.</span>
											</div>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">Digital and physical POP material suport for your business.</span>
											</div>
											<b-button @click="moveScrollRegisterForm(true)" class="btn-solid btn-light w-100 letter-spacing-0">Sign me up</b-button>
										</div>
									</div>
								</div>
							</b-col>
							<b-col id="item-carousel-cata1og-plans-3" cols="12" lg="4" class="col-carousel hide-item-carousel align-items-center d-flex justify-content-center p-0 px-xl-3">
								<div class="default-block plan-block w-100 px-2 mt-lg-4">
									<div class="media default-border px-4 py-4">
										<div class="media-body">
											<h3 class="font-weight-normal title-block my-lg-4 h-auto">Master Plan</h3>
											<h4 class="plan-offer-title font-color-light title-block my-4">600 - 999 USD</h4>
											<h4 class="font-weight-normal mt-2 mb-4">For Pro</h4>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">30% OFF.</span>
											</div>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">Free Training on our products and brands.</span>
											</div>
											<div class="item-dark-check d-flex p-0 mb-3">
												<i class="fa-solid fa-square-check p-relative"></i>
												<span class="d-flex align-items-center font-color-dark chunk-lines chunk-lines-2">Free shipping on your 1st order.</span>
											</div>
											<b-button @click="moveScrollRegisterForm(true)" class="btn-solid btn-light w-100 letter-spacing-0">Sign me up</b-button>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<b-container class="mt-5 pt-5">
			<b-row>
				<b-col cols="12">
					<div class="title-default">
						<h3 class="title-review pb-4 font-weight-bold">Hear from our clients</h3>
						<h4>Our clients love working with us, just read what they have to say!</h4>
					</div>
				</b-col>
			</b-row>
			<b-row id="slider-reviews" class="p-relative justify-content-center mx-0 mt-3 mb-5 pb-5">
				<b-col v-for="(item_review, index_review) in listReviews" :key="`review-${ index_review + 1 }`"  :id="`item-carousel-reviews-${ index_review + 1 }`" cols="12" lg="3" :class="`col-carousel ${ index_review + 1 == 1 ? 'show-item-carousel' : 'hide-item-carousel' }`">
					<div class="default-block review-block box-shadow">
						<div class="media default-border box-shadow">
							<div class="media-body">
								<b-row class="m-0">
									<b-col cols="3" class="p-0">
										<a class="link-review" title="View on Google" aria-label="View on Google" :href="item_review.url" target="_blank" rel="noopener noreferrer nofollow">
											<img class="author-avatar" loading="lazy" width="60px" height="60px" :src="`${ back_file }${ item_review.author.avatar.src }`" :srcset="`${ back_file }${ item_review.author.avatar.srcset }`" :alt="item_review.author.avatar.alt"/>
										</a>
									</b-col>
									<b-col cols="9" class="text-left">
										<a class="link-review p-relative" :title="`Review by ${ item_review.author.name }`" :aria-label="`Review by ${ item_review.author.name }`" :href="item_review.url" target="_blank" rel="noopener noreferrer nofollow">
											<span class="author-name chunk-lines chunk-lines-2 font-color-default">{{ item_review.author.name }}</span>
											<div class="container-verified-author p-absolute">
												<i class="fa-solid fa-check p-absolute font-color-white"></i>
												<i class="fa-solid fa-certificate"></i>
											</div>
											<div class="container-rating-bar">
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
											</div>
										</a>
									</b-col>
								</b-row>
								<div class="container-content text-left mt-2">
									<span class="content-review chunk-lines chunk-lines-4 font-color-default">{{ item_review.content }}</span>
									<span class="date-review font-color-default d-none">{{ item_review.date }}</span>
									<a class="link-review mt-2 d-block" title="View on Google" aria-label="View on Google" :href="item_review.url" target="_blank" rel="noopener noreferrer nofollow">
										<img class="logo-review" loading="lazy" width="120px" height="48px" :src="require(`@/assets/images/form/logo-google-review.webp`)" :srcset="require(`@/assets/images/form/logo-google-review.webp`)" alt="Google Logo"/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<div class="section-banner p-relative text-center mt-4">
			<img class="p-absolute background-banner" width="1920px" height="972px" :src="require(`@/assets/images/form/banner-colombian-fajas.webp`)" alt="BAnner Colombian Fajas Cata1og"/>
			<b-container class="container-fluid">
				<b-row class="m-0">
					<b-col cols="12" class="p-0">
						<div class="content-text m-auto">
							<p class="font-color-white">Register now</p>
							<div class="title-default">
								<h3 class="font-color-white h-auto">IT'S TIME!</h3>
								<h3 class="title-inner-default font-color-white h-auto mb-5">SKYROCKET YOUR BUSINESS</h3>
							</div>
							<p class="font-color-white">Discover top-quality Colombian Fajas wholesale at ubeateable prices! Our extensive range of premium Colombian Fajas is perfect for resellers seeking reliable, high-margin products. Elevate your inventory with our authentic Colombian Fajas wholesale - your Gateway to a thriving resell business. Bulk orders welcome!</p>
							<b-button @click="moveScrollRegisterForm(true)" class="btn-solid btn-light w-50" title="Join Now">Join now</b-button>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { effectSlider, randomNumber }  from '../../constants/config.js';
export default {
	props:["banner", "title", "message", "source"],
	components: {
		logoSliderBlack : () => import( '../../components/home/logo-slider-black.vue' ),
		stepRegister	: () => import( '../../components/account/step-register.vue' ),
		faq         	: () => import( '../../components/account/faq.vue' ),
	},
	data() {
		return {
			products: [],
			category: [],
			showquickviewmodel: false,
			showcomparemodal: false,
			showcartmodal: false,
			quickviewproduct: {},
			comapreproduct: {},
			cartproduct: {},
			imagePath: require(`@/assets/images/${ this.banner }`),
			position_form: 0,
			back_file: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
			list_reviews: [],
			number_group_review: 0
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadPage);
		this.$root.$on('move-register-form', this.moveScrollRegisterForm );
		
		await this.getReviews().then( (result_reviews) => {
			
			this.number_group_review = randomNumber(1, 20);
			this.list_reviews = this.getterReviews[this.number_group_review - 1].list;
		});
	},
	computed: {
		...mapGetters({
			getterReviews: "getterReviews"
		}),
		listReviews(){
			return this.list_reviews;
		}
	},
	methods: {
		...mapActions({
			getReviews: "getReviews"
		}),
		loadPage(event){
			setInterval(() => {
				effectSlider('slider-register-benefits', 3, 3000 );
			}, 6000);
			
			let count_reviews = 1;
			setInterval(() => {
				effectSlider('slider-cata1og-plans', 3, 4000 );
				if( count_reviews == 4 ){
					
					this.number_group_review = randomNumber(1, 20, this.number_group_review);
					this.list_reviews = this.getterReviews[this.number_group_review - 1].list;
					count_reviews = 1;
				}
				count_reviews += 1;
				effectSlider('slider-reviews', 4, 4000 );
			}, 8000);
		},
		moveScrollRegisterForm(valid_move) {
			
			this.position_form = window.scrollY + document.getElementById('register_form').getBoundingClientRect().top - ( window.innerWidth <= 768 ? 89 : 150 );
			
			this.$nextTick(() => {
				
				setTimeout(() => {
					window.scrollTo({
						top: Math.round( this.position_form >= 0 ? this.position_form : ( this.position_form ) * -1 ),
						left: 0,
						behavior: "smooth"
					});
				}, 300);
			});
		}
	}
}
</script>