<template>
	<section class="section-b-space light-layout body-container d-flex align-items-center p-relative">
		<div class="container">
			<b-row>
				<b-col cols="12">
					<div class="order-success">
						<i class="fa-solid fa-check-circle"></i>
						<h2>Thanks for your Purchase</h2>
						<p class="font-color-dark">Your order has been placed successfully. We've sent you a confirmation email with your order number.</p>
					</div>
				</b-col>
			</b-row>
		</div>
	</section>
</template>
<script>
export default {
}
</script>
