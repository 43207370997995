<template>
	<section class="section-b-space light-layout body-container d-flex align-items-center">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="order-error">
						<i class="fa-solid fa-times"></i>
						<h2>{{ result || 'An error has occurred' }}</h2>
						<h5 class="font-color-dark">{{ title || "Please return to cart and try again" }}</h5>
						<p class="font-color-dark">{{ message }}</p>
						<nuxt-link :to="{ path: '/account/cart'}" class="btn btn-solid shadow-sm">go to cart</nuxt-link>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	data() {
		return {
			result  : null, 
			title   : null, 
			message : null, 
			error   : null
		}
	},
	mounted(){
	},
	created(){
		this.result   = decodeURI( this.$route.query.result );
		this.title    = decodeURI( this.$route.query.title );
		this.message  = decodeURI( this.$route.query.message );
		this.error    = decodeURI( this.$route.query.error );
	}
}
</script>