<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Dropshipping"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">The <strong>Drop Shipping</strong> service depends on the following <strong>Policies</strong>
			</h2>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="accordion theme-accordion mt-4" id="accordionExample">
						<b-card no-body class="box-shadow bg-white" style="border: unset">
							<b-card-body>
								<b-card-text>
									<div class="row row-about-us text-show">
										<p>To make use of the service you must follow the next steps:</p>
										<strong><span>In the notes section, you must indicate that it is a</span>Drop Ship<span>.</span></strong>
										<ul>
											<li>
												<span>Place the complete address information you wish the package to be sent to by entering the data in the application form titled "Shipping Address".</span>
												<span>In the payments and billing form, you must state the address of the person who is going to make the order's payment.</span>
												<span>By entering the information correctly, you will receive the bill and you will be able to cancel the order for the following delivery to your client. You must verify the information anytime you perform the transaction to avoidshipping errors. Remember, you are responsible for the information that goes into the system during the issuing of orders.</span>
												
												<span>The service price of the drop shipping is $11 for the US territory.</span>
												<span>If you enter wrong data, contact immediately the Customer Service Department to adjust the information before the package is sent and to avoid your client from receiving billing information.</span>
												<span>Changes to third parties are not part of our return policies. The conditions for making exchanges to our wholesale customers are the next:</span>
											</li>
										</ul>
										<ul>
											<span>If by any reason you are not satisfied with your purchase and you need to exchange or return it, you must send an email to </span><a target="_blank" class="c-link" href="mailto:info.us@cata1og.com" rel="noopener noreferrer">info.us@cata1og.com</a><span> and then, specifying your order number for our agents to be able to provide you with the best support and service.</span>
											<span>To change your order you have 30 Free Shipping Return days to send back the merchandise, with all tags still attached. All items must be unworn and free of scents or marks, and they must be returned in their original packaging.</span>
											<span>Your product should be approved for exchange by our returns department and fulfill the criteria and conditions of the new state of the products to be received as a change.</span>
											<span>All returns and exchanges are subjected to quality approval from our warehouse.</span>
											<span>Our warehouse must confirm the item’s arrival in order to proceed with an exchange.</span>
										</ul> 	
									</div>
								</b-card-text>
							</b-card-body>
						</b-card>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	},
}
</script>
