<template>
	<product></product>
</template>
<script>
export default {
	components: {
		product: () => import('../../../../components/product/product.vue')
	}
};
</script>
