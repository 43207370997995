<template>
    <validation-observer tag="div" v-slot="{ handleSubmit, valid, reset, errors }" class="register-form">
        <b-form :id="idForm" @submit.prevent="handleSubmit( preSubmit(valid) )" @reset.prevent="reset" autocomplete="off" :data-erros="JSON.stringify(errors)">
            <b-row>
                <b-col v-if="['address', 'register-lead'].includes(typeForm)" cols="12" lg="${ ['register-lead'].includes(typeForm) ? 12 : 6 }" class="mb-2">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="First Name" class="valid-field">
                        <b-form-group label="First name" label-for="first_name" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <b-form-input type="text" v-model="getAddress.first_name" placeholder="First name" name="first_name"/>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col v-if="['address', 'register-lead'].includes(typeForm)" cols="12" lg="${ ['register-lead'].includes(typeForm) ? 12 : 6 }" class="mb-2">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Last Name" class="valid-field">
                        <b-form-group label="Last Name" label-for="last_name" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <b-form-input type="text" v-model="getAddress.last_name" placeholder="Last Name" name="last_name"/>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col v-if="['register-lead'].includes(typeForm)" cols="12" lg="${ ['register-lead'].includes(typeForm) ? 12 : 6 }" class="mb-2">
                    <validation-provider rules="required|email" tag="div" v-slot="{ errors }" name="Email" class="valid-field">
                        <b-form-group label="Email" label-for="email" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <b-form-input type="email" v-model="getAddress.email" placeholder="Email" name="email"/>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col v-if="['address', 'register-lead'].includes(typeForm)" cols="12" lg="${ ['register-lead'].includes(typeForm) ? 12 : 6 }" class="mb-2">
                    <b-form-group label="Phone Number" label-for="phone" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
                        <b-input-group class="custom-input-group" id="group-phone-code">
                            <template #prepend>
                                <v-select class="select-phone-code" name="Country Code" required :options="listPhoneCodes" :reduce="(item) => item.id" v-model="countryPhoneCode" :clearable="false" :searchable="false">
                                    <template #selected-option="{ id, label, phone_code, flag }">
                                        <img :src="`${ back_url }${ flag }`" :title="label" :alt="label" width="20px" height="14px" class="d-inline-flex mr-2"/>
                                        <strong>+{{ phone_code }}</strong>
                                    </template>
                                    <template #option="{ id, label, phone_code, flag }">
                                        <img :src="`${ back_url }${ flag }`" :title="label" :alt="label" width="20px" height="14px" class="d-inline-flex"/>
                                        <span class="mr-2 country-name">{{ label }}: <strong>+{{ phone_code }}</strong></span>
                                    </template>
                                </v-select>
                            </template>
                            <validation-provider rules="required|length:10" tag="div" v-slot="{ errors }" name="Phone Number" class="valid-field valid-custom-input">
                                <b-form-input type="text" v-number-only v-model="phoneNumber" placeholder="Phone Number" name="phone" :class="`${ !errors[0] ? '' : 'field-error' }`" @change="validPhone" @input="validPhone"/>
                                <span class="validate-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col v-if="['address'].includes(typeForm)" cols="12" lg="6" class="mb-2">
                    <b-form-group label="Company Name" label-for="company" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
                        <b-form-input type="text" v-model="getAddress.company" placeholder="Company Name" name="company"/>
                    </b-form-group>
                </b-col>
                <b-col v-if="!['register-lead'].includes(typeForm)" cols="12" class="mb-2">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Address" class="valid-field">
                        <b-form-group label="Address" label-for="address_1" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <b-form-input type="text" v-model="getAddress.address_1" placeholder="Address" name="address_1"/>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col v-if="['address'].includes(typeForm)" cols="12" class="mb-2">
                    <b-form-group label="Apartament, suite, etc" label-for="address_2" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
                        <b-form-input type="text" v-model="getAddress.address_2" placeholder="Apartament, suite, etc" name="address_2"/>
                    </b-form-group>
                </b-col>
                <b-col v-if="!['register-lead'].includes(typeForm)" cols="12" lg="${ ['register-lead'].includes(typeForm) ? 12 : 6 }" class="mb-2">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Country" class="valid-field">
                        <b-form-group label="Country" label-for="country" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <v-select ref="country" name="country" placeholder="Select Country" :options="listCountries" :reduce="(item) => item.id" @input="filterShippingStates" v-model="getAddress.country_code" :clearable="false">
                                <template #selected-option="{ label, flag }">
                                    <img :src="`${ back_url }${ flag }`" :title="label" :alt="label" width="20px" height="14px" class="d-inline-flex mr-2"/>
                                    <strong>{{ label }}</strong>
                                </template>
                                <template #option="{ id, label, flag }">
                                    <img :src="`${ back_url }${ flag }`" :title="label" :alt="label" width="20px" height="14px" class="d-inline-flex"/>
                                    <span class="mr-2 country-name">({{ id }}) <strong>{{ label }}</strong></span>
                                </template>
                            </v-select>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12" :lg="!['register-lead'].includes(typeForm) ? 6 : 12" :xl="6" class="mb-2">
                    <validation-provider v-if="listStatesByCountry.length > 0" rules="required" tag="div" v-slot="{ errors }" name="State" class="valid-field">
                        <b-form-group label="State" label-for="state" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <v-select ref="state" name="state" placeholder="Select State" :options="listStatesByCountry" :reduce="(item) => item.id" v-model="getAddress.state_code" @input="selectShippingState" :clearable="!['register-lead'].includes(typeForm)" :searchable="!['register-lead'].includes(typeForm)"></v-select>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col v-if="!['register-lead'].includes(typeForm)" cols="12" lg="6" class="mb-2">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="City" class="valid-field">
                        <b-form-group label="City / Town" label-for="city" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <b-form-input type="text" v-model="getAddress.city" placeholder="City" name="city"/>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12" :lg="!['register-lead'].includes(typeForm) ? 6 : 12" :xl="6" class="mb-2">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Postal Code" class="valid-field">
                        <b-form-group label="Postal Code" label-for="zip" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <b-form-input type="text" v-model="getAddress.zip" placeholder="Postal Code" name="zip"/>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-button :id="`btn-${ idForm }`" type="submit" class="btn-solid mr-1 shadow-sm d-none"></b-button>
            <b-button :id="`reset-form-${ idForm }`" type="reset" class="d-none">Reset Form</b-button>
        </b-form>
        <div  :data-error="(!['register-lead'].includes(typeForm)).toString()"></div>
    </validation-observer>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: ["idForm", "dataAddress", "dataListCountries", "dataListStates", "dataListPhoneCodes", "typeForm"],
    data() {
        return {
            back_url             : process.env.VUE_APP_CATALOG_BACK_URL_FILE,
            addressForm         : this.dataAddress,
            listCountries       : [],
            listStates          : [],
            listPhoneCodes      : [],
            listStatesByCountry : [],
            
            phoneNumber         : "",
            countryPhoneCode    : "US-1",
            
            actionForm          : null
        }
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault()
                    }
                    return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    async mounted(){
        this.listStates     = this.dataListStates;
        this.listCountries  = this.dataListCountries;
        this.listPhoneCodes = this.dataListPhoneCodes;
        
        this.filterShippingStates( this.addressForm.country_code );
        
        this.$root.$on('change-select-address', this.updateSelectedAddress);
        this.$root.$on('valid-address', this.executeValidAddress);
        this.$root.$on('reset-address', this.resetAddress);
    },computed: {
        ...mapGetters({
            getterCustomer          : "getterCustomer",
        }),
        getAddress(){
            return this.addressForm;
        },
        getPhoneNumber(){
            return this.phoneNumber;
        },
        getPhoneCode(){
            return this.countryPhoneCode;
        }
    },
    methods: {
        updateSelectedAddress( data_select ){
            
            if( this.idForm == data_select.id_form ){
                
                this.addressForm                = data_select.address;
                
                let currentCountry              = this.listCountries.find( (item) => item.id == this.addressForm.country_code );
                
                this.addressForm.country 		= currentCountry ? currentCountry.label : this.addressForm.country;
                this.addressForm.country_code 	= currentCountry?.id ? currentCountry.id : this.addressForm.country_code;
                
                let phone 				        = this.validPhoneNumber( this.addressForm.phone == '' ? this.getterCustomer?.phone : this.addressForm.phone );
                
                this.phoneNumber 		        = phone.number;
                this.countryPhoneCode 	        = phone.code;
                this.addressForm.phone          = phone.format;

                this.filterShippingStates( this.addressForm.country_code );
            }
        },
        filterShippingStates(country_id) {
            
            let itemCountry = this.listStates.find( (item) => item.id == country_id );
            
            let exist_state = itemCountry ? itemCountry.states.some( (item) => item.id == this.addressForm.state_code) : false;
            
            this.listStatesByCountry        = itemCountry ? itemCountry.states: [];
            
            this.addressForm.state          = !exist_state ? null : this.addressForm.state;
            this.addressForm.state_code     = !exist_state ? null : this.addressForm.state_code;
            
            this.addressForm.country_code   = itemCountry ? itemCountry.id      : null;
            this.addressForm.country        = itemCountry ? itemCountry.label   : null;
        },
        selectShippingState(val) {
            
            let itemState = this.listStatesByCountry.find( (item) => item.id == val );
            
            this.addressForm.state      = itemState ? itemState.label   : null;
            this.addressForm.state_code = itemState ? itemState.id      : null;
        },
        validPhone(input_value){
            
            this.addressForm.phone      = `+${ this.countryPhoneCode.split("-")[1] }${ this.phoneNumber }`;
        },
        validPhoneNumber(phone){
            
            if( phone ){
                
                phone = phone.replace(/([ +])+/g, "");
                
                let code = phone.replace(phone.slice(-10), "");
                
                let index_country = code == "" ? -1 : this.listPhoneCodes.findIndex( (item) => item.phone_code == parseInt(code) );
                
                let country_code = ( code != "" && code == "1" ) || index_country < 0 ? "US-1" : this.listPhoneCodes[index_country].id;
                
                return {
                    code    : country_code,
                    number  : phone.slice(-10),
                    format  : `+${ country_code.split("-")[1] }${ phone.slice(-10) }`
                };
            }
            else{
                
                return {
                    code    : "US-1",
                    number  : "",
                    format  : ""
                };
            }
        },
        executeValidAddress(data_form){
            
            if( data_form.id_form == this.idForm ){
                
                this.actionForm = data_form.action;
                document.getElementById(`btn-${ data_form.id_form }`).click();
            }
        },
        resetAddress(data_form){
            
            if( data_form.clean ){
                
                this.filterShippingStates("US");
                this.addressForm = {
                    id          : null,
                    customer_id : null,
                    default     : false,
                    first_name  : '',
                    last_name   : '',
                    name        : '',
                    phone       : '',
                    address_1   : '',
                    address_2   : '',
                    company     : '',
                    country     : 'United States',
                    country_code: 'US',
                    state       : null,
                    state_code  : null,
                    city        : '',
                    zip         : ''
                };
                let phone 				        = this.validPhoneNumber( this.addressForm.phone );
                
                this.phoneNumber 		        = phone.number;
                this.countryPhoneCode 	        = phone.code;
                this.addressForm.phone          = phone.format;
            }
            document.getElementById(`reset-form-${ data_form.id_form }`).click();
        },
        preSubmit(is_valid){
            
            if( this.actionForm ){
                
                this.$emit('validFormAddress', { id_form: this.idForm, address: this.getAddress, valid: is_valid, action: this.actionForm });
            }
            this.actionForm = null;
        }
    }
}
</script>