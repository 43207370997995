<template>
    <b-container class="collection-content">
        <collection-sidebar @addFilter="addFilter" @removeFilter="removeFilter"></collection-sidebar>
        <b-row class="justify-content-center">
            <b-col v-if="getHandleCollection == 'price-catalog' && !getAccessCatalog" cols="12" md="6">
                <h3 class="d-block text-center">Access Price Catalog</h3>
                <div class="theme-card shadow-sm p-4">
                    <validation-observer v-slot="{ handleSubmit }">
                        <b-form class="register-form" @submit.prevent="handleSubmit(requestAccessCatalog)">
                            <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Email" class="valid-field">
                                <b-form-group label="Email" label-for="email" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                                    <b-form-input type="text" v-model="email_catalog" placeholder="Email" name="email"/>
                                    <span class="validate-error">{{ errors[0] }}</span>
                                </b-form-group>
                            </validation-provider>
                            <div class="login-buttons d-flex justify-content-between align-items-center w-100 mt-4">
                                <button type="submit" class="btn btn-solid shadow-sm m-auto">Request Access</button>
                            </div>
                        </b-form>
                    </validation-observer>
                </div>
            </b-col>
            <b-col v-if="getterCollection?.products_paginate" cols="12">
                <div class="top-banner-wrapper">
                    <div class="top-banner-content">
                        <p class="d-none" v-html="getterCollection?.description"></p>
                    </div>
                </div>
            </b-col>
            <b-col v-if="getterCollection?.products_paginate" cols="9">
                <b-row v-if="getListFilters.length > 0" class="product-filter-tags">
                    <b-col v-for="(item_filter, index_filter) in getListFilters" :key="index_filter" class="col-filter d-flex mb-2">
                        <div v-if="item_filter.handle != 'price'" class="filter-tag d-flex align-items-center" @click="removeFilter(item_filter)">
                            {{ item_filter.sub_filter ? item_filter.sub_filter.name : item_filter.name }}: 
                            <div v-if="item_filter.sub_filter && item_filter.sub_filter == 'color'" class="color-variant mx-1 mt-0">
                                <a :class="item_filter.value.handle" :title="item_filter.value.name">
                                    <img :src="`${ back_url }/images/products/colors/color-${ item_filter.value.handle }.webp`" onerror="this.onerror=null;this.src='';this.alt='';this.style='display:none;';" :alt="item_filter.value.name" width="20" height="20"/>
                                </a>
                            </div>
                            <strong class="ml-2">{{ item_filter.value.name }}</strong>
                            <i class="fa-solid fa-xmark d-flex align-items-center justify-content-center"></i>
                        </div>
                        <div v-else class="filter-tag d-flex align-items-center" @click="removeFilter(item_filter)">
                            {{ item_filter.name }}: 
                            {{ formatCurrency( item_filter.value[0] ).format }} - {{ formatCurrency( item_filter.value[1] ).format }}
                            <i class="fa-solid fa-xmark d-flex align-items-center justify-content-center"></i>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col v-if="getterCollection?.products_paginate" cols="3" class="d-flex align-items-center justify-content-end">
                <b-row v-if="getListFilters.length > 0" class="product-filter-tags">
                    <b-col class="col-filter col-clean-filter d-flex justify-content-end">
                        <div class="clean-filters" @click="removeAllFilters()">
                            Clear All
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12">
                <div class="collection-product-wrapper">
                    <div class="product-top-filter mb-3">
                        <div class="container-top-filter">
                            <div v-if="['price-catalog', 'customer-price-catalog'].includes(getHandleCollection) && getAccessCatalog && getterCollection?.products_paginate" class="left-side-top-filter">
                                <div class="d-inline-flex sort-product-filter register-form w-100">
                                    <b-form-group class="mb-0 w-100" label="Search:" label-for="search_catalog" label-align-sm="left" label-size="sm" label-class="font-color-dark mb-0 font-weight-normal">
                                        <b-input-group size="sm">
                                            <b-form-input id="search_catalog" ref="search_catalog" type="text" v-model="search_catalog" @keyup="searchCatalog" placeholder="Search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button class="btn-solid px-3" :disabled="search_catalog == ''" @click="searchCatalog">
                                                    <i class="fa-solid fa-magnifying-glass"></i>
                                                </b-button>
                                                <b-button class="btn-solid px-3" :disabled="search_catalog == ''" @click="cleanSearchCatalog">
                                                    <i class="fa-solid fa-trash"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                            </div>
                            <div v-if="getterCollection?.products_paginate" class="right-side-top-filter">
                                <div class="d-inline-flex sort-product-filter register-form w-100">
                                    <b-form-group class="mb-0 w-100" label="Sort By:" label-for="select_sort" label-align-sm="left" label-size="sm" label-class="font-color-dark mb-0 font-weight-normal">
                                        <v-select id="select_sort" name="select_sort" class="select-sort" :options="options_sort" v-model="sort_products" placeholder="Select Sort Option" @input="onChangeSort" :searchable="false" :clearable="false"></v-select>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="product-grid" class="product-wrapper-grid">
                        <b-row>
                            <b-col v-if="!['price-catalog', 'customer-price-catalog'].includes(getHandleCollection) && getterCollection?.products_paginate && getListProducts.length == 0" cols="12" class="d-flex align-items-center justify-content-center text-center section-t-space section-b-space">
                                <h3 class="mt-3">Sorry! Couldn't find the product you were looking For!!!</h3>
                            </b-col>
                            <b-col v-if="!['price-catalog', 'customer-price-catalog'].includes(getHandleCollection) && getterCollection?.products_paginate && getListProducts.length == 0" cols="12" class="d-flex align-items-center justify-content-center mt-3">
                                <nuxt-link :to="{ path: '/' }" class="btn btn-solid shadow-sm">continue shopping</nuxt-link>
                            </b-col>
                            <b-col cols="12" md="6" lg="3" class="col-grid-box" v-for="(item_product, index_product) in getListProducts" :key="index_product" :id="`product-${ index_product + 1 }`">
                                <product-collection :product="item_product" :index="index_product" :handleCollection="getHandleCollection" :titleCollection="getterCollection?.title" :hideDescription="false" @showModalVariants="showModalVariants"></product-collection>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal v-if="['price-catalog', 'customer-price-catalog'].includes(getHandleCollection) && getAccessCatalog" id="modal-variants" ref="modal-variants" size="lg" :title="getModalProduct?.title" centered :hide-footer="true">
            <div class="p-0">
                <b-row v-if="getModalProduct">
                    <b-col cols="12" md="4" class="col-product-image d-flex justify-content-center align-items-center">
                        <div class="container-product-image">
                            <div class="d-flex justify-content-between align-items-start flex-wrap">
                                <img loading="eager" class='img-fluid product-image' width="320px" height="320px" :alt="`${ getModalProduct.handle }_1`" :src="getCollectionImage01" :onerror="`this.onerror=null;this.src='${ back_url }/images/products/product-default_320.webp';this.alt='product-default';`" sizes="(max-width: 576px) 180px, (max-width: 767px) 320px, (max-width: 991px) 320px, (max-width: 1399px) 320px, (max-width: 3820px) 320px" :srcset="`${ getCollectionImage01 } 300w, ${ getCollectionImage01 } 300w, ${ getCollectionImage01 } 960w, ${ getCollectionImage01 } 1180w, ${ getCollectionImage01 } 1400w`"/> 
                                <img loading="lazy" class="img-fluid hover-product-image" width="320px" height="320px" :alt="`${ getModalProduct.handle }_2`" :src="getCollectionImage02" :onerror="`this.onerror=null;this.src='${ back_url }/images/products/product-default_320.webp';this.alt='product-default';`" sizes="(max-width: 576px) 180px, (max-width: 767px) 320px, (max-width: 991px) 320px, (max-width: 1399px) 320px, (max-width: 3820px) 320px" :srcset="`${ getCollectionImage02 } 300w, ${ getCollectionImage02 } 300w, ${ getCollectionImage02 } 960w, ${ getCollectionImage02 } 1180w, ${ getCollectionImage02 } 1400w`"/> 
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="8">
                        <b-tabs card nav-wrapper-class="bg-white">
                            <b-tab v-for="(row_color, index_color) in getModalProduct.variants" :key="`${ row_color.sku }-${ index_color }`" :active="index_color == 0">
                                <template #title>
                                    <ul v-if="row_color.name" class="color-variant">
                                        <li class="align-items-center">
                                            <div :class="[row_color.handle, 'color-default']" :title="row_color.name">
                                                <img :src="`${ back_url }${ row_color.url }`" onerror="this.onerror=null;this.src='';this.alt='';this.style='display:none;';" :alt="row_color.name" width="20" height="20"/>
                                            </div>
                                            <span>{{ row_color.name }}</span>
                                        </li>
                                    </ul>
                                    <span v-else>Variants</span>
                                </template>
                                <div class="product-variants">
                                    <div class="m-0 header-variants">
                                        <b-col cols="4" class="font-weight-normal p-2 d-flex align-items-center justify-content-center">SKU</b-col>
                                        <b-col cols="4" class="font-weight-normal p-2 d-flex align-items-center justify-content-center">{{ row_color.variants.length > 0 ? row_color.variants[0].options.reduce( (previous_item, current_item) => { if( current_item.name != "Color" ){ previous_item.push( current_item.name ) } return previous_item; }, []).join(" / ") : '' }}</b-col>
                                        <b-col cols="4" class="font-weight-normal p-2 d-flex align-items-center justify-content-center">Price</b-col>
                                    </div>
                                    <div class="body-variants">
                                        <b-row v-for="(row_variant, index_variant) in row_color.variants" :key="`row-${ row_variant.sku }`" class="m-0">
                                            <b-col cols="4" class="font-weight-normal p-2 d-flex align-items-center justify-content-start">
                                                {{ row_variant.sku }}
                                            </b-col>
                                            <b-col cols="4" class="font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                                {{ row_variant.options.reduce( (previous_item, current_item) => { if( current_item.name != "Color" ){ previous_item.push( current_item.value ) } return previous_item; }, []).join(" / ") }}
                                            </b-col>
                                            <b-col cols="4" class="font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                                <vc-format-price type="product-catalog" :discount_brand="getModalProduct.discounts.brand" :price="row_variant.price" :discount_price="row_variant.discount_price" :discount_percentage="0" :discount_stock="null" :apply_discount_stock="false"></vc-format-price>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </b-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatData, arrayHelpers } from "../../constants/config.js";
export default {
    props: ['handleCollection', 'accessCatalog'],
    components: {
        productCollection				: () => import("../../components/product/product-collection.vue"),
        collectionSidebar				: () => import("../../components/product/collection-sidebar.vue"),
        vcFormatPrice   				: () => import( '../../components/product/format-price.vue' )
    },
    data() {
        return {
            back_url				: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
            handle_collection	    : null,
            page_products			: [],
            list_products			: [],

            access_catalog			: false,
            search_catalog			: "",

            options_sort            : [
                { id: "default"		, value: 'best-selling'	, label: "Default" },
                { id: "price-1"		, value: 'price-asc'	, label: "Low to High Price" },
                { id: "price-2"		, value: 'price-desc'	, label: "High to Low Price" },
                { id: "date"		, value: 'created'		, label: "News to Olds Products" },
            ],
            sort_products			: { id: "default", value: 'best-selling', label: "Default" },
            
            isScroll			    : false,
            position_grid_prdocuts	: 0,
            
            product_data_filter		: [],
            original_filter_price	: null,
            
            list_filters			: [],
            
            current_page			: 1,
            per_page				: 48,
            total_pages				: 1,
            
            modal_product			: null,
            default_images          : [
                { width: 180, alt: "", src: '/images/products/product-default_320.webp' },
                { width: 320, alt: "", src: '/images/products/product-default_320.webp' }
            ]
        };
    },
    async mounted() {
        if( !this.handleCollection && !this.$route.params.id ){

            this.handle_collection = null;
        }
        else if( this.handleCollection && !this.$route.params.id ){

            this.handle_collection = this.handleCollection;
        }
        else{

            this.handle_collection = this.$route.params.id;
        }
        this.resetCollection();
        
        if( this.handle_collection == 'price-catalog' ){
            
            this.access_catalog = false;
            this.$emit("changeAccessCatalog", false);
        }
        else{
            this.access_catalog = this.accessCatalog;
        }
        window.scrollTo(0, 0);
        if( this.getHandleCollection && this.getHandleCollection != 'price-catalog' ){
            
            await this.requestCollection();
        }
        else{
            
            this.$emit('updateLoadingCollection', null);
        }
        this.position_grid_prdocuts = document.getElementById("product-grid")?.getBoundingClientRect().top;
        window.addEventListener('load', (event) => {
            
            this.position_grid_prdocuts = document.getElementById("product-grid")?.getBoundingClientRect().top;
        });
        window.addEventListener('scroll', (event) => {
            
            if( document.getElementById("product-grid") && this.isScroll && window.scrollY >= this.position_grid_prdocuts && ( ( document.getElementById("product-grid")?.offsetHeight - 1350 ) <= window.scrollY && ( document.getElementById("product-grid")?.offsetHeight - 1350 ) > 0 ) ) {
                
                this.isScroll = false;
                this.loadMorePageProducts();
            }
        });
    },
    computed: {
        ...mapGetters({
            getterLogin				: 'getterLogin',
            getterCollection		: "getterCollection",
            getterFiltersCollection	: "getterFiltersCollection",
        }),
        getAccessCatalog(){
            return this.access_catalog;
        },
        getHandleCollection(){
            return this.handle_collection;
        },
        getSearchField() { 
            return this.$route.query.result || null;
        },
        getListProducts(){
            return this.list_products;
        },
        getListFilters(){
            return this.list_filters;
        },
        getModalProduct(){
            return this.modal_product;
        },
        getCollectionImage01(){
            
            return this.getModalProduct.images?.length > 0 ? this.getModalProduct.images[0].src_collection : `${ this.back_url }${ this.default_images[0].src }`;
        },
        getCollectionImage02(){
            
            return this.getModalProduct.images?.length > 1 ? this.getModalProduct.images[1].src_collection : `${ this.back_url }${ this.default_images[1].src }`;
        }
    },
    methods: {
        ...mapActions({
            resetCollection			: 'resetCollection',
            getCollection			: 'getCollection',
            updateFilterCollection	: 'updateFilterCollection'
        }),
        formatCurrency(price, totalAmount = null){
            
            return formatData.currency(price, totalAmount);
        },
        async requestCollection() {
            this.$emit('updateLoadingCollection', true);
            let body_request 	= {
                handle_collection	: this.getHandleCollection,
                page				: this.current_page,
                limit				: 0, //this.per_page,
                per_page			: this.per_page,
                filters				: true,
                sort				: {
                    type		: null,
                    by_category	: true
                },
                price				: null, // { min_price: 0, max_price: 0 },
                brands				: [],
                product_categories	: [],
                variants			: [],
                search				: this.getSearchField || null
            };
            if( this.$route.query.catalog ){
                body_request.email_catalog 	= this.email_catalog;
                body_request.id_catalog 	= this.$route.query.catalog;
            }
            await this.getCollection(body_request).then( (result_collection) => {
                
                this.$emit('updateLoadingCollection', null);
                if( this.getterCollection ){
                    
                    let format_filter				= this.formatFilter();
                    
                    this.product_data_filter 		= format_filter.all_filters;
                    this.original_filter_price		= format_filter.original_filter_price;
                    
                    this.updateFilterCollection( this.formatSidebarFilters( JSON.parse( JSON.stringify( format_filter.all_filters ) ) ) );
                    
                    this.total_pages 				= this.getterCollection.products_paginate.docs.length > 0 	? Math.ceil( this.getterCollection.products_paginate.docs.length / this.per_page ) : [];
                    this.page_products 				= this.getterCollection.products_paginate.docs.length > 0 	? arrayHelpers.chunkArray(  JSON.parse( JSON.stringify( this.getterCollection.products_paginate.docs ) ), this.per_page ) : [];
                    this.list_products 				= this.getterCollection.products_paginate.docs.length > 0 	? [...this.page_products[0]] : [];
                    this.isScroll 					= true;
                    this.current_page 				= 1;
                    if( this.getHandleCollection == 'price-catalog' ){
                        this.access_catalog = this.$route.query.catalog ? this.getterCollection.access_catalog : false;
                        this.$emit("changeAccessCatalog",  this.access_catalog);
                        this.$emit("initCountDownCatalog", this.getterCollection.data_catalog?.time_left || 0);
                    }
                    if( this.getterCollection.products_paginate.filters ){
                        
                        this.addFilterQuery();
                    }
                }
            });
        },
        addFilterQuery(){
            
            let main_filters = ['product-types', 'brands', 'variants', 'tags'];
            
            for (const [key_item, value_item] of Object.entries( this.$route.query )) {
                
                let index_main_filter = main_filters.findIndex( (item) => key_item.indexOf( item ) >= 0 );
                if( key_item == 'price' ){
                    
                    this.product_data_filter['price'].values = {
                        min_price: parseFloat( decodeURI(value_item).split("-")[0] ),
                        max_price: parseFloat( decodeURI(value_item).split("-")[1] )
                    };
                    this.list_filters.push({ id: 'filter-price', name: 'Price', handle: 'price', sub_filter: null, value: [ parseFloat( decodeURI(value_item).split("-")[0] ), parseFloat( decodeURI(value_item).split("-")[1] )] });
                }
                else if( index_main_filter > 0 && index_main_filter <= 1 && this.product_data_filter[main_filters[index_main_filter]] ){
                    
                    this.product_data_filter[main_filters[index_main_filter]].values[value_item].checked = true;
                    let exist_filter = this.product_data_filter[main_filters[index_main_filter]];
                    for (const item_filter of value_item.split(',')) {
                        
                        if( exist_filter.values[item_filter] ){
                            
                            this.list_filters.push({ 
                                id: `filter-${ main_filters[index_main_filter] }`, 
                                name: exist_filter.name, 
                                handle: exist_filter.handle, 
                                sub_filter: null, 
                                value: { name: exist_filter.values[item_filter].name, handle: exist_filter.values[item_filter].handle, checked: exist_filter.values[item_filter].checked } 
                            });
                        }
                    }
                }
                else if( index_main_filter > 1 && index_main_filter <= 3 && this.product_data_filter[main_filters[index_main_filter]]?.values[key_item.replace(`${ main_filters[index_main_filter] }-`, '')] ){
                    
                    this.product_data_filter[main_filters[index_main_filter]].values[key_item.replace(`${ main_filters[index_main_filter] }-`, '')].values[value_item].checked = true;
                    let exist_filter = this.product_data_filter[main_filters[index_main_filter]];
                    let exist_sub_filter = exist_filter.values[key_item.replace(`${ main_filters[index_main_filter] }-`, '')];
                    for (const item_filter of value_item.split(',')) {
                        
                        if( exist_filter.values[item_filter] ){
                            
                            this.list_filters.push({ 
                                id: `filter-${ main_filters[index_main_filter] }`, 
                                name: exist_filter.name, 
                                handle: exist_filter.handle, 
                                sub_filter: { name: exist_sub_filter.name, handle: exist_sub_filter.handle }, 
                                value: { name: exist_sub_filter.values[item_filter].name, handle: exist_sub_filter.values[item_filter].handle, checked: exist_sub_filter.values[item_filter].checked } 
                            });
                        }
                    }
                }
            }
            this.excecuteFilter();
            this.current_page = 1;
        },
        async onChangeSort() {
            
            this.current_page 		= 1
            await this.excecuteFilter();
        },
        formatFilter(){
            
            return ( this.getterCollection.products_paginate.filters != null ?  this.getterCollection.products_paginate.filters : []).reduce( (previous_item_filter, current_item_filter) => {
                
                let data_filter = {
                    name    : current_item_filter.name,
                    handle  : current_item_filter.handle,
                    field   : current_item_filter.field,
                    show    : current_item_filter.show,
                    values  : []
                };
                
                if( current_item_filter.handle == 'price' ){
                    
                    data_filter.values = {
                        min_price: current_item_filter.values.min_price,
                        max_price: current_item_filter.values.max_price,
                    };
                    previous_item_filter.original_filter_price = {
                        min_price: current_item_filter.values.min_price,
                        max_price: current_item_filter.values.max_price,
                    };
                }
                else if( !['variants', 'tags'].includes( current_item_filter.handle ) ){
                    
                    data_filter.values = current_item_filter.values.reduce( (previous_item, current_item) => { previous_item[current_item.handle] = current_item; return previous_item; }, {});
                }
                else{
                    
                    data_filter.values = current_item_filter.values.reduce( (previous_item_subfilter, current_item_subfilter) => { 
                        
                        previous_item_subfilter[current_item_subfilter.handle] 			= current_item_subfilter;
                        previous_item_subfilter[current_item_subfilter.handle].values 	= current_item_subfilter.values.reduce( (previous_item, current_item) => { previous_item[current_item.handle] = current_item; return previous_item; }, {});
                        return previous_item_subfilter; 
                    }, {});
                }
                
                previous_item_filter.all_filters[current_item_filter.handle]	= JSON.parse( JSON.stringify( data_filter ) );
                previous_item_filter.select_filters[current_item_filter.handle] = JSON.parse( JSON.stringify( data_filter ) );
                
                if( current_item_filter.handle != 'price' && !['variants', 'tags'].includes( current_item_filter.handle ) ){
                    
                    previous_item_filter.select_filters[current_item_filter.handle].values 	= {};
                }
                
                return previous_item_filter;
                
            }, { all_filters: {}, select_filters: {}, original_filter_price: null });
        },
        formatSidebarFilters( data_filters ){
            
            data_filters = Object.entries( data_filters ).map( ([key_item_filter, value_item_filter]) => {
                
                let new_item_filter = value_item_filter;
                
                if( key_item_filter != 'price' ){
                    
                    new_item_filter.values = Object.entries( new_item_filter.values ).map( ([key_item_subfilter, value_item_subfilter]) => {
                        
                        let new_item_subfilter = value_item_subfilter;
                        
                        if( ['variants', 'tags'].includes( key_item_filter ) ){
                            
                            new_item_subfilter.values = Object.entries( new_item_subfilter.values ).map( ([key_item_value, value_item_value]) => {
                                
                                return value_item_value;
                            }, []);
                            new_item_subfilter.count = JSON.parse( JSON.stringify( new_item_subfilter.values ) ).reduce( (previous_item, current_item) => {
                                
                                previous_item.isShow += current_item.show ? 1 : 0;
                                previous_item.isChecked += current_item.checked ? 1 : 0;
                                return previous_item;
                            }, { isShow: 0, isChecked: 0 });
                        }
                        return new_item_subfilter;
                    });
                    if( !['variants', 'tags'].includes( key_item_filter ) ){
                        
                        new_item_filter.count = JSON.parse( JSON.stringify( new_item_filter.values ) ).reduce( (previous_item, current_item) => {
                            
                            previous_item.isShow += current_item.show ? 1 : 0;
                            previous_item.isChecked += current_item.checked ? 1 : 0;
                            return previous_item;
                        }, { isShow: 0, isChecked: 0 });
                    }
                }
                return new_item_filter;
            });
            return data_filters;
        },
        async removeFilter(item_selected) {
            
            this.list_filters.splice( this.list_filters.findIndex( (item) => item.handle == "price" || ( item.handle == item_selected.handle && item.value.handle == item_selected.value.handle ) ), 1 );
            
            if( !item_selected.sub_filter && item_selected.handle != 'price' ){
                
                this.product_data_filter[item_selected.handle].values[item_selected.value.handle].checked = false;
            }
            else if( item_selected.handle != 'price' ){
                
                this.product_data_filter[item_selected.handle].values[item_selected.sub_filter.handle].values[item_selected.value.handle].checked = false;
            }
            else if( item_selected.handle == 'price' ){
                
                this.product_data_filter[item_selected.handle].values = {
                    min_price: this.original_filter_price.min_price,
                    max_price: this.original_filter_price.max_price
                };
            }
            await this.excecuteFilter();
            this.current_page = 1;
        },
        async removeAllFilters() {
            
            for (const item_filter of this.list_filters) {
                
                if( !item_filter.sub_filter && item_filter.handle != 'price' ){
                    
                    this.product_data_filter[item_filter.handle].values[item_filter.value.handle].checked = false;
                }
                else if( item_filter.handle != 'price' ){
                    
                    this.product_data_filter[item_filter.handle].values[item_filter.sub_filter.handle].values[item_filter.value.handle].checked = false;
                }
                else if( item_filter.handle == 'price' ){
                    
                    this.product_data_filter[item_filter.handle].values = {
                        min_price: this.original_filter_price.min_price,
                        max_price: this.original_filter_price.max_price
                    };
                }
            }
            this.list_filters = [];
            await this.excecuteFilter();
            this.current_page = 1;
        },
        async addFilter(item_selected) {
            
            this.list_filters.push( item_selected );
            
            if( !item_selected.sub_filter && item_selected.handle != 'price' ){
                
                this.product_data_filter[item_selected.handle].values[item_selected.value.handle].checked = true;
            }
            else if( item_selected.handle != 'price' ){
                
                this.product_data_filter[item_selected.handle].values[item_selected.sub_filter.handle].values[item_selected.value.handle].checked = true;
            }
            else if( item_selected.handle == 'price' ){
                this.product_data_filter[item_selected.handle].values = {
                    min_price: item_selected.value[0],
                    max_price: item_selected.value[1]
                };
            }
            await this.excecuteFilter();
            this.current_page = 1;
        },
        updateShowFilterSimpleValues( item_product , typeFilter, reset_show_filter ){
            
            const main_filter = this.product_data_filter[typeFilter];
            
            for (const [key_variant_filter, value_variant_filter] of Object.entries( main_filter.values ) ) {
                
                if( reset_show_filter || !item_product || main_filter.values[key_variant_filter].checked ) {
                    
                    this.product_data_filter[typeFilter].values[key_variant_filter].show = true;
                }
                else{
                    
                    this.product_data_filter[typeFilter].values[key_variant_filter].show = item_product[main_filter.field].findIndex( (item) => item.handle == main_filter.values[key_variant_filter].handle ) >= 0;
                }
            }
        },
        updateShowFilterComplexValues(item_product, typeFilter, reset_show_filter){
            
            const main_filter = this.product_data_filter[typeFilter];
            
            for (const [key_variant_filter, value_variant_filter] of Object.entries( main_filter.values ) ) {
                
                for (const [key_variant_value, value_variant_value] of Object.entries( value_variant_filter.values )) {
                    
                    if( reset_show_filter || !item_product || main_filter.values[key_variant_filter].values[key_variant_value].checked ) {
                        
                        this.product_data_filter[typeFilter].values[key_variant_filter].values[key_variant_value].show = true;
                    }
                    else{
                        
                        this.product_data_filter[typeFilter].values[key_variant_filter].values[key_variant_value].show = item_product[main_filter.field].findIndex( (item_value) => item_value.handle == key_variant_value ) >= 0;
                    }
                }
            }
        },
        updateFilterData( item_product, reset_show_filter = false ){
            
            this.updateShowFilterSimpleValues(item_product, 	'brands', 			reset_show_filter);
            this.updateShowFilterSimpleValues(item_product, 	'product-types', 	reset_show_filter);
            this.updateShowFilterComplexValues(item_product, 	'variants', 		reset_show_filter);
            this.updateShowFilterComplexValues(item_product, 	'tags', 			reset_show_filter);
        },
        filterProductSearch( item_product ){
            
            return new RegExp(this.search_catalog, 'i').test( item_product.search_field );
        },
        filterProductPrice( item_product ){
            
            return item_product.filters[this.product_data_filter['price'].field].min_price >= this.product_data_filter['price'].values.min_price && item_product.filters[this.product_data_filter['price'].field].max_price <= this.product_data_filter['price'].values.max_price;
        },
        filterSimpleProduct( item_product, filterType ){
            
            let filterSimple = true;
            for (const [key_item_filter, value_item_filter] of Object.entries( this.product_data_filter[filterType].values )) {
                
                if( value_item_filter.checked ){
                    
                    filterSimple = filterSimple && item_product.filters[this.product_data_filter[filterType].field].name == value_item_filter.name;
                }
            }
            return filterSimple;
        },
        filterComplexProduct( item_product, filterType ){
            
            let filterComplex = true;
            for (const [key_item_filter, value_item_filter] of Object.entries( this.product_data_filter[filterType].values )) {
                
                for (const [key_item_value, value_item_value] of Object.entries( value_item_filter.values )) {
                    
                    if( value_item_value.checked ){
                        
                        filterComplex = filterComplex && item_product.filters[this.product_data_filter[filterType].field].findIndex( (item) => item.handle == key_item_value ) >= 0;
                    }
                }
            }
            return filterComplex;
        },
        async excecuteFilter(){
            
            if( document.getElementsByClassName("page-item")[0] ){
                
                document.getElementsByClassName("page-item")[0].firstChild.click();
            }
            this.updateFilterData( null, true );
            
            let sort_categories 	= JSON.parse( JSON.stringify( this.getterCollection.products_paginate.sort_categories ) );
            let paginate_results 	= JSON.parse( JSON.stringify( this.getterCollection.products_paginate.docs ) ).reduce( (previous_item, current_item, current_index) => {
                
                let isFilterProduct 	= true;
                isFilterProduct = isFilterProduct && ( this.getHandleCollection == 'price-catalog' && this.search_catalog != "" ? this.filterProductSearch( current_item ) : true ) && this.filterProductPrice( current_item ) && this.filterSimpleProduct( current_item, 'brands' ) && this.filterSimpleProduct( current_item, 'product-types' ) && this.filterComplexProduct( current_item, 'variants' ) && this.filterComplexProduct( current_item, 'tags' );
                
                if( isFilterProduct ){
                    
                    previous_item.products[ this.getterCollection.sort_by_category ? current_item.sort_category : 0 ].push( current_item );
                    previous_item.count += 1;
                    
                    previous_item.filters.brand.push( current_item.filters.brand );
                    previous_item.filters.product_category.push( current_item.filters.product_category);
                    previous_item.filters.options.push( current_item.filters.options );
                    previous_item.filters.tags.push( current_item.filters.tags );
                }
                if( current_index == this.getterCollection.products_paginate.docs.length - 1 ){
                    
                    previous_item.products = arrayHelpers.chunkArray( arrayHelpers.sortArrayProducts( previous_item.products, this.sort_products.value ), this.per_page );
                    
                    previous_item.filters.brand 		    = previous_item.filters.brand.filter( (item, index, array) => array.findIndex( (item_f) => item_f.handle == item.handle ) === index );
                    previous_item.filters.product_category	= previous_item.filters.product_category.filter( (item, index, array) => array.findIndex( (item_f) => item_f.handle == item.handle ) === index );
                    previous_item.filters.options 		    = previous_item.filters.options.flat().filter( (item, index, array) => array.findIndex( (item_f) => item_f.handle == item.handle ) === index );
                    previous_item.filters.tags 			    = previous_item.filters.tags.flat().filter( (item, index, array) => array.findIndex( (item_f) => item_f.handle == item.handle ) === index );
                    
                    this.updateFilterData( previous_item.filters );
                }
                return previous_item;
            }, { products: JSON.parse( JSON.stringify( sort_categories.map( (item) => item.products ) ) ), count: 0, filters: { brand: [], product_category: [], options: [], tags: [] } } );
            
            this.updateFilterCollection( this.formatSidebarFilters( JSON.parse( JSON.stringify( this.product_data_filter ) ) ) );
            
            this.current_page 		= 1;
            this.total_pages 		= paginate_results.count > 0 ? Math.ceil( paginate_results.count / this.per_page ) 		: 1;
            this.page_products 		= paginate_results.count > 0 ? paginate_results.products 								: [];
            this.list_products 		= paginate_results.count > 0 ? JSON.parse( JSON.stringify( this.page_products[0] ) ) 	: [];
            this.isScroll 			= paginate_results.count > 0;
        },
        async searchCatalog(event){
            
            if( !event.keyCode || event.keyCode == 13 ){
                
                this.current_page = 1;
                await this.excecuteFilter();
            }
        },
        async cleanSearchCatalog(){
            
            this.search_catalog = "";
            this.current_page = 1;
            await this.excecuteFilter();
        },
        loadMorePageProducts(){
            
            if( this.current_page < this.total_pages ){
                
                for (const item_product of this.page_products[this.current_page]) {
                    
                    this.list_products.push( item_product );
                }
                if( this.current_page + 1 < this.total_pages ){
                    
                    this.isScroll = true;
                }
                else{
                    
                    this.isScroll = false;
                }
                this.current_page++;
            }
        },
        async requestAccessCatalog() {
            
            await this.requestCollection();
        },
        showModalVariants(item_product) {
            this.modal_product = item_product;
            this.default_images[0].alt = `${ item_product.title } - 1`;
            this.default_images[1].alt = `${ item_product.title } - 2`;
            this.$refs["modal-variants"].show();
        }
    }
};
</script>
