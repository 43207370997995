<template>
    <validation-observer tag="div" v-slot="{ handleSubmit, valid, reset, errors }" class="pb-0"> 
        <b-form :id="idForm" @submit.prevent="handleSubmit( preSubmit(valid) )" @reset.prevent="reset" autocomplete="off" class="register-form" :data-erros="JSON.stringify(errors)">
            <b-row>
                <b-col cols="12">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Language" class="valid-field">
                        <b-form-group label="Language of Preference" label-for="language" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-bold" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <v-select :options="options_languages" v-model="businessData.language" placeholder="Select Language"></v-select>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="`Do you have experience in selling shapewear?`" label-for="have-experience" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-bold" class="mb-0">
                        <b-input-group class="custom-input-group">
                            <b-form-radio-group v-model="businessData.have_experience" name="have-experience" class="d-flex align-items-center">
                                <b-form-radio :value="true">Yes</b-form-radio>
                                <b-form-radio :value="false">No</b-form-radio>
                            </b-form-radio-group>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Target Sell" class="valid-field">
                        <b-form-group label="How do you primarily sell your products?" label-for="target-sell" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-bold" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <v-select :options="options_targets" v-model="businessData.target_sell" name="target-sell"></v-select>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Do you have a website for your store?" label-for="comapny-webside" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-bold">
                        <b-input-group class="custom-input-group" id="group-comapny-webside">
                            <b-input-group-prepend>
                                <b-form-radio-group v-model="businessData.have_company_webside" name="have-comapny-webside" class="d-flex align-items-center">
                                    <b-form-radio :value="true">Yes</b-form-radio>
                                    <b-form-radio :value="false">No</b-form-radio>
                                </b-form-radio-group>
                            </b-input-group-prepend>
                            <div v-if="businessData.have_company_webside" class="valid-field valid-custom-input">
                                <b-form-input type="text" v-model="businessData.company_webside" placeholder="Company Webside" name="comapny-webside"/>
                            </div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="`Do you currently have a physical store?`" label-for="physical-store" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-bold" class="mb-0">
                        <b-input-group class="custom-input-group">
                            <b-form-radio-group v-model="businessData.physical_store" name="physical-store" class="d-flex align-items-center">
                                <b-form-radio :value="true">Yes</b-form-radio>
                                <b-form-radio :value="false">No</b-form-radio>
                            </b-form-radio-group>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Business Size" class="valid-field">
                        <b-form-group label="What is the size of your business?" label-for="business-size" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-bold" :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <v-select :options="options_business_size" v-model="businessData.business_size" name="business-size"></v-select>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12" class="mt-4">
                    <b-form-group :label="`On which social media platforms is your store present?`" label-for="social-media" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-bold" class="mb-0">
                        <b-row class="m-0">
                            <b-col v-for="(item_social, index_social) in options_social_networks" :key="`social-${ item_social.type }`" cols="12" class="social-media-item pl-0">
                                <b-input-group class="custom-input-group mb-4">
                                    <b-input-group-prepend :class="`${ item_social.active ? 'show-user': '' }`">
                                        <b-form-checkbox v-model="item_social.active" :class="`d-flex align-items-center mx-2 mb-0`">
                                            <i :class="`${ item_social.icon } fa-2x`"></i>
                                        </b-form-checkbox>
                                    </b-input-group-prepend>
                                    <div class="valid-field valid-custom-input">
                                        <b-form-input type="text" v-model="item_social.user" class="social-media-user" :disabled="!item_social.active" placeholder="@" :name="`user-${ item_social.type }`" :class="`${ item_social.active ? 'show-user': '' }`"/>
                                    </div>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Terms and Conditions" class="mt-4 valid-field">
                        <b-form-group :class="`${ !errors[0] ? '' : 'field-error' }`">
                            <b-form-checkbox v-model="businessData.terms_and_conditions" name="accept-conditions" id="accept-conditions" class="font-weight-bold" @change="showModalTermsAndConditions">Agree to Terms and Conditions?</b-form-checkbox>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-button :id="`btn-${ idForm }`" type="submit" class="btn-solid mr-1 shadow-sm d-none"></b-button>
            <b-button :id="`reset-form-${ idForm }`" type="reset" class="d-none">Reset Form</b-button>
        </b-form>
        <b-modal id="modal_terms_and_conditions" ref="modal_terms_and_conditions" modal-class="modal-terms-and-conditions" size="lg" title="Cata1og - Terms and Conditions">
            <terms-and-conditions></terms-and-conditions>
            <template slot="modal-footer" class="text-center">
                <div class="w-100 text-center">
                    <b-button type="button" class="btn-solid shadow-sm" @click="agreeTermsAndConditions('modal_terms_and_conditions')">Agree</b-button>
                    <b-button type="button" class="btn-solid shadow-sm" @click="hideModal('modal_terms_and_conditions')">Decline</b-button>
                </div>
            </template>
        </b-modal>
    </validation-observer>
</template>
<script>
let defaultBusinessData = {
    have_experience		: true,
    physical_store 		: true,
    target_sell 		: null,
    company_webside		: null,
    have_company_webside: true,
    social_networks 	: [
    { type: "facebook"	, user: "", value: 0 },
    { type: "instagram"	, user: "", value: 0 },
    { type: "tiktok"	, user: "", value: 0 }
    ],
    business_size		: null,
    terms_and_conditions: null,
    language			: null,
    note				: null
};
import { mapGetters } from 'vuex';
export default {
    props: ["idForm"],
    components:{
        termsAndConditions	: () => import("../../components/pages/terms-and-conditions.vue")
    },
    data() {
        return {
            businessData            : {...defaultBusinessData},
            options_languages		: [],
            options_targets			: [
            { label: "Physical"	, value: "Physical" },
            { label: "Virtual"	, value: "Virtual" },
            { label: "Both"		, value: "Both" }
            ],
            options_social_networks	: [
            { type: "facebook"	, icon: "fab fa-facebook-f"	, active: false, user: "", value: 0 },
            { type: "instagram"	, icon: "fab fa-instagram"	, active: false, user: "", value: 0 },
            { type: "tiktok"	, icon: "fab fa-tiktok"		, active: false, user: "", value: 0 }
            ],
            options_business_size	: [
            { label: "Only me"				, value: "1" },
            { label: "From 2 to 5 people"	, value: "2-5" },
            { label: "From 5 to 10 people"	, value: "5-10" },
            { label: "More than 10 people"	, value: "10" }
            ],
            
            defaultInvest		: null,
            isDefaultInvest		: true,
            actionForm          : null
        };
    },
    async mounted() {
        
        this.options_languages = this.getterLanguages.map((item) => {
            return { label: item.name, value: item._id };
        });
        this.businessData.language = this.options_languages.find( (item) => item.label == "English" );
        this.$root.$on('change-business-data', this.updateLeadData);
        this.$root.$on('valid-business-data', this.executeValidLeadData);
        this.$root.$on('reset-business-data', this.resetLeadData);
    },
    computed: {
        ...mapGetters({
            getterLanguages: 'getterLanguages'
        })
    },
    methods: {
        async updateLeadData(data_form){
            
            this.businessData = data_form;
            
            this.options_social_networks = this.businessData.social_networks;
        },
        executeValidLeadData(data_form){
            
            if( data_form.id_form == this.idForm ){
                
                this.actionForm = data_form.action;
                document.getElementById(`btn-${ data_form.id_form }`).click();
            }
        },
        resetLeadData(data_form){
            
            if( data_form.clean ){
                
                this.businessData = {...defaultBusinessData};
            }
            document.getElementById(`reset-form-${ data_form.id_form }`).click();
        },
        preSubmit(is_valid){
            
            if( this.actionForm ){
                
                this.businessData.social_networks = this.options_social_networks;
                this.$emit('validFormBusiness', { id_form: this.idForm, lead: this.businessData, valid: is_valid, action: this.actionForm });
            }
            this.actionForm = null;
        },
        showModalTermsAndConditions(){
            
            if( this.businessData.terms_and_conditions ){
                
                this.$refs['modal_terms_and_conditions'].show();
            }
            this.businessData.terms_and_conditions = null;
        },
        agreeTermsAndConditions(refName){
            
            this.businessData.terms_and_conditions = true;
            this.$refs[refName].hide();
        },
        hideModal(refName){
            
            this.$refs[refName].hide();
        }
    }
};
</script>