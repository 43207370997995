<template>
    <b-row class="pb-4 register-form">
        <b-col cols="12" class="mb-3">
            <vue-paycard class="white" :inputFields="cardInputs" :valueFields="cardFields" :labels="cardLabels" :isCardNumberMasked="isCardNumberMasked" :hasRandomBackgrounds="hasRandomBackgrounds" :backgroundImage="backgroundImage" :setType="setType" @get-type="changeCardType"/>
        </b-col>
        <b-col cols="12" class="mb-3">
            <validation-provider :rules="`required|length:${ cardNumberMaxNumber }`" tag="div" v-slot="{ errors }" name="Card Number" class="valid-field">
                <b-form-group label="Card Number" label-for="v-card-number" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal"  :class="`${ !errors[0] && !errorCardNumber ? '' : 'field-error' }`">
                    <b-form-input v-number-only type="text" name="v-card-number" placeholder="Card Number" :id="cardInputs.cardNumber" v-model="cardFields.cardNumber" @input="changeCardNumber" autocomplete="off"/>
                    <span class="validate-error">{{ errors[0] }}{{ errorCardNumber }}</span>
                </b-form-group>
            </validation-provider>
        </b-col>
        <b-col cols="12" class="mb-3">
            <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Card Name" class="valid-field">
                <b-form-group label="Card Name" label-for="v-card-name" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal"  :class="`${ !errors[0] ? '' : 'field-error' }`">
                    <b-form-input type="text" name="v-card-name" placeholder="Card Name" :id="cardInputs.cardName" v-letter-only v-model="cardFields.cardName" @input="changeCardName" autocomplete="off"></b-form-input>
                    <span class="validate-error">{{ errors[0] }}</span>
                </b-form-group>
            </validation-provider>
        </b-col>
        <b-col cols="12" md="8">
            <label class="font-color-dark mb-2 font-weight-normal w-100 col-form-label-sm">Expire Date</label>
            <b-row>
                <b-col cols="12" md="6">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Month" class="valid-field">
                        <b-form-group :class="`${ !errors[0] && !errorCardDate ? '' : 'field-error' }`">
                            <v-select :id="cardInputs.cardMonth" name="Card Month" placeholder="MM" :options="optionMonths" :reduce="(item) => item.id" @input="changeCardMonth" v-model="cardFields.cardMonth" :clearable="false" :searchable="false" autocomplete="off">
                                <template #selected-option="{ id, label }">
                                    <strong>{{ label }}</strong>
                                </template>
                                <template #option="{ id, label }">
                                    <strong>{{ label }}</strong>
                                </template>
                            </v-select>
                            <span class="validate-error">{{ errors[0] }}{{ errorCardDate }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Year" class="valid-field">
                        <b-form-group :class="`${ !errors[0] && !errorCardDate ? '' : 'field-error' }`">
                            <v-select :id="cardInputs.cardYear" name="Card Year" placeholder="YYYY" :options="optionYears" :reduce="(item) => item.id" @input="changeCardYear" v-model="cardFields.cardYear" :clearable="false" :searchable="false" autocomplete="off">
                                <template #selected-option="{ id, label }">
                                    <strong>{{ label }}</strong>
                                </template>
                                <template #option="{ id, label }">
                                    <strong>{{ label }}</strong>
                                </template>
                            </v-select>
                            <span class="validate-error">{{ errors[0] }}{{ errorCardDate }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" md="4">
            <validation-provider :rules="`required|length:${ cardCcvMaxNumber }`" tag="div" v-slot="{ errors }" name="CVV" class="valid-field">
                <b-form-group label="CVV" label-for="v-card-cvv" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal"  :class="`${ !errors[0] ? '' : 'field-error' }`">
                    <b-form-input type="text" title="CVV" placeholder="CVV" v-number-only :id="cardInputs.cardCvv" v-model="cardFields.cardCvv" @input="changeCardCvv" autocomplete="off"></b-form-input>
                    <span class="validate-error">{{ errors[0] }}</span>
                </b-form-group>
            </validation-provider>
        </b-col>
    </b-row>
</template>
<script>
import moment from "moment";
export default {
	components: {
	},
	data() {
		return {
			back_url					: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
			
			cardFields				: { 
				cardName	: '', 
				cardNumber	: '', 
				cardMonth	: null, 
				cardYear	: null, 
				cardCvv		: null 
			},
			cardInputs				: { 
				cardName	: 'v-card-name', 
				cardNumber	: 'v-card-number', 
				cardMonth	: 'v-card-month', 
				cardYear	: 'v-card-year', 
				cardCvv		: 'v-card-cvv'
			},
			cardLabels				: { 
				cardName	: 'Full Name', 
				cardHolder	: 'Holder Name', 
				cardMonth	: 'MM', 
				cardYear	: 'YY', 
				cardCvv		: 'CVV', 
				cardExpires	: 'Expires' 
			},
			
			isCardNumberMasked		: false,
			hasRandomBackgrounds	: true,
			backgroundImage			: require( '@/assets/images/background-gray_full.webp' ),
			setType					: '',
			
			mainCardNumber			: '',
			cardNumberMaxNumber		: 19,
			generatedType			: '',
			cardCcvMaxNumber		: 3,

			errorCardDate			: null,
			errorCardNumber			: null,
		};
	},
	directives: {
		'number-only': {
			bind (el) {
				el.addEventListener('keypress', (event) => {
					event.target.value = event.target.value.replace(/[^0-9]/g, '')
					if (event.charCode >= 48 && event.charCode <= 57) {
						return true
					}
					event.preventDefault()
				})
			}
		},
		'letter-only': {
			bind (el) {
				el.addEventListener('keypress', (event) => {
					if (event.charCode >= 48 && event.charCode <= 57) {
						event.preventDefault()
					}
					return true
				})
			}
		}
	},
	async mounted() {
		
		this.$root.$on('clean-credit-card', this.cleanFields);
	},
	computed: {
		optionMonths() {
			
			let listMonths 		= [];
			
			for (let item_month = 1; item_month <= 12; item_month++) {
				
				listMonths.push({ 
					id: item_month, 
					label: item_month > 9 ? (item_month).toString() : `0${ item_month }`, 
					value: item_month
				});
			}
			return listMonths;
		},
		optionYears() {
			
			let currentYear = parseInt( moment().format("YYYY") );
			let listYears 	= [{ id: currentYear, label: currentYear, value: currentYear }];
			
			for (let item_year = 1; item_year <= 14; item_year++) {
				
				listYears.push({ id: currentYear + item_year, label: (currentYear + item_year).toString(), value: (currentYear + item_year).toString() });
			}
			return listYears;
		},
	},
	methods: {
		cleanFields(){

			this.cardFields = { 
				cardName	: '', 
				cardNumber	: '', 
				cardMonth	: null, 
				cardYear	: null, 
				cardCvv		: null 
			};
		},
		changeCardName (input_value) {
			
			this.cardFields.cardName = input_value;
			
			this.$emit('input-card-name', this.cardFields.cardName);
            this.$emit("changeCreditCard", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardType (val) {
			
			this.generatedType = val;
			
			if (this.cardFields.cardNumber != "" && val == "") {
				
				this.errorCardNumber = 'Invalid Card Number';
			} 
			else{
				
				this.errorCardNumber = null;
			}
		},
		changeCardNumber () {
			
			const value = this.cardFields.cardNumber.replace(/\D/g, '')
			// american express, 15 digits
			this.cardCcvMaxNumber = 3;
			if ( /^3[47]\d{0,13}$/.test(value) ) {
				this.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
				this.cardCcvMaxNumber = 4;
				this.cardNumberMaxNumber = 17;
			} else if ( /^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value) ) {
				// diner's club, 14 digits
				this.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
				this.cardNumberMaxNumber = 16;
			} else if ( /^62[0-9]\d*/.test(value) ) {
				
				this.cardFields.cardNumber = value.replace(/(\d{6})/, '$1 ').replace(/(\d{6}) (\d{7})/, '$1 $2 ').replace(/(\d{6}) (\d{7}) (\d{6})/, '$1 $2 $3 ').replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, '$1 $2 $3 $4');
				this.cardNumberMaxNumber = 21;
			} else if ( /^\d{0,16}$/.test(value) ) {
				// regular cc number, 16 digits
				this.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
				this.cardNumberMaxNumber = 19;
			}
			
			this.$emit('input-card-number', this.cardFields.cardNumber);
            this.$emit("changeCreditCard", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardMonth (input_value) {
			
			this.cardFields.cardMonth = input_value;
			
			if( this.cardFields.cardYear != null && this.cardFields.cardMonth != null && parseInt( this.cardFields.cardMonth ) < new Date().getMonth() + 1 && parseInt( this.cardFields.cardYear ) == new Date().getFullYear() ){
				
				this.errorCardDate = "Invalid Card Date";
			}
			else{
				
				this.errorCardDate = null;
			}
			this.$emit('input-card-month', this.cardFields.cardMonth);
            this.$emit("changeCreditCard", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardYear (input_value) {
			
			this.cardFields.cardYear = input_value;
			
			if(  this.cardFields.cardYear != null && this.cardFields.cardMonth != null && parseInt( this.cardFields.cardMonth ) < new Date().getMonth() + 1 && parseInt( this.cardFields.cardYear ) == new Date().getFullYear() ){
				
				this.errorCardDate = "Invalid Card Date";
			}
			else{
				
				this.errorCardDate = null;
			}
			this.$emit('input-card-year', this.cardFields.cardYear);
            this.$emit("changeCreditCard", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardCvv (input_value) {
			
			this.cardFields.cardCvv = input_value;
			
			this.$emit('input-card-cvv', this.cardFields.cardCvv);
            this.$emit("changeCreditCard", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		}
	}
};
</script>