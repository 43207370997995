<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Shipping Policies"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">MINIMUM ADVERTISED PRICE POLICY</h2>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="accordion theme-accordion mt-4" id="accordionExample">
						<b-card no-body class="box-shadow bg-white" style="border: unset" >
							<b-card-body>
								<ul>
									<span>Cata1og as a commercial partner of our affiliated brands provides advertising and sales material for its national sales representatives with minimal or no cost. Giving effectiveness dates and MAP policies in all offered products for Cata1og</span>
									<span>This policy applies to sales representatives and wholesalers. Therefore, we have implemented the following MAP policy in order to preserve the good company's reputation, the professionalism, a guaranteed service and stable prices, in this way we offer our clients valuable products fostering adequate prices competition with the best sales, keeping a good image and the value of each affiliate on the sales channel.</span>
									<span>This MAP policy will be conducted under the following guidelines:</span>
								</ul>
								<ul>
									<li><span>Cata1og, according to its exclusive criteria, reserves the right to interrupt your commercial activity with any retailer that announces whatever product covered by this MAP policy at a lower price than the one we listed ("MAP PLN products"). Cata1og will not perform business with any distributor that compromise the price it earns from its products. Cata1og, at its own discretion, will apply the MAP policy according to these guidelines. </span></li>
									<li><span>This policy applies only to the announced prices and not the price which products are sold or offered for an individual client on a distributor's site. The prices listed on Internet are considered as an announced price and they need to fulfill this MAP policy.</span></li>
									<li><span>The MAP policy applies to all announcements made by affiliated brands with Cata1og in each and every one of the means of communications, in which we include, flyers, banners, coupons, emails, insertions, newspapers, magazines, catalogs, email requested catalogs, email bulletins, email requests, post-sale advertising, Internet or similar electronic means, like, television, radio, and public signs.</span></li>
									<li><span>The following MAP Policy does not apply to the physical store's advertising that doesn't distribute to clients and customers.</span></li>
									<li><span>This MAP policy does not establish the maximum prices announced. The distributors can offer brand's products affiliate to Cata1og at any price above the MAP has set.</span></li>
									<li><span>The distributors could announce their products with phrases such as "The best price", "The lower price" o analog phrases as long as the prices won't be lower than the ones listed on ("MAP PLN Products").</span></li>
									<li><span>It won't be considered as an infraction of this MAP policy the announcement that a client can "Call to know the price" or "Send an email to know the price" nor the use of a similar language, in particular, the ones related to offered products by Cata1og, as long as clients don't facilitate any price using the channels mentioned on the 3rd point.</span></li>
									<li><span>The distributor or retailer associated accepts to maintain all affiliates brands to Cata1og as the property of each brand and the use of advertising materials provided by Cata1og or associated brands only in an authorized way. The incorrect use will be determined at Cata1og  discretion.</span></li>
									<li>
										<span>The "group" of products offered by Cata1og for their sale among others will be considered as an infraction of this MAP policy when:</span><br>
										<ul>
											<li><span>The announced price on the package represents a superior discount up to 15 % of the MAP policy.</span></li>
											<li><span>The products in the package with MAP items inside include words, wrapping, graphics and other indications that can create confusion to the client or the brand of those products.</span></li>
										</ul>
									</li>
									<li><span>Cata1og reserves the right to modify, suspend or interrupt this MAP policy at any moment, introduce or remove products with a MAP policy, as well as participate in sales on certain products.</span></li>
								</ul>
								<br>
								<ul>
									<p><span><strong>About its compliance.</strong></span></p>
									<br>
									<li><span>Intended or repeated infringement of this policy can incur in the termination of the distribution, commercial relation or representation. Cata1og does not have the intention of performing business with sales representatives or distributors that degrade Cata1og image, associates, and products. Cata1og will not warn nor emit prior notifications before taking any action while under this policy.</span></li>
									<li>
										<span>Cata1og reserves the right to cancel any pending order, restraining future orders or suspend any affiliate account, commercial agents, among others, if Cata1og deems necessary that:</span><br>
										<ul>
											<li><span>If any infringement according to the current MAP policy.</span></li>
											<li><span>If there is any intention that may be proven of an infringement in this commercial policy.</span></li>
										</ul>
									</li>
									<li><span>Distributors and retailers have no right to apply current MAP policy. Infringements of this policy may result in sanctions previously mentioned, including the termination of our commercial business, as well as any redress provided by law.</span></li>
									<li><span>It will be attributed to Cata1og MAP administrator if any of the parties have incurred in an infringement of MAP policies, it also determinates appropriated sanctions according to the current policies, obligations, and the current regulation warranties. It is Cata1og's responsibility to monitor announced prices from distributors or sales representatives, either directly or through the use of other agencies or web tool.</span></li>
									<p><span>This MAP policy has been established by Cata1og to help ensure Cata1og and its associates legacy as goods and high-value products with responsibility and transparency to protect its name's reputation and its affiliates. The MAP policy is also designed to avoid damaging Cata1og image and to keep a solid reputation among final customers.</span></p>
									<p>If you have any query or suggestion about the points determined here, please reach us through the following email  <a href="mailto:info.us@cata1og.com">info.us@cata1og.com</a> or our phone number +18554257140</p>
									
									<a href="#" target="_blank">List: MAP PLN Products </a>
								</ul>
							</b-card-body>
						</b-card>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs:  () => import('../../components/pages/breadcrumbs.vue')
	}
}
</script>
