<template>
    <div class="page-content pt-0 p-relative">
        <vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
        <breadcrumbs type="dashboard" title="Your Account"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Your Account</h2>
        </div>
        <b-container class="p-4 mt-2 box-shadow">
            <b-row v-if="customer" class="customer-account">
                <b-col cols="12" md="5">
                    <div class="content-customer-insigne justify-content-center">
                        <profile-image position="sidebar" :getterLevel="getterLevel"></profile-image>
                        <div v-if="showFormInfo" class="change-profile-image d-flex align-items-center justify-content-center" @click="searchImage">
                            <i class="fa-solid fa-camera fa-2x font-color-white"></i>
                            <b-form-file id="profile_image" ref="profile_image" name="profile_image" accept="image/*" class="d-none" @change="chargeImage()"></b-form-file>
                        </div>
                    </div>
                    <b-row class="level-details">
                        <b-col cols="12" class="d-flex justify-content-start align-items-center">
                            <div class="content-level">
                                <span class="level-title d-block">Current Level: <strong>{{ getterLevel?.level.name }}</strong></span>
                                <span :class="`level-amount background-level-range-${ getterLevel?.level.range || 1 } my-2 d-inline-block font-weight-bold`">{{ formatCurrency( getterLevel?.level.lowerLimit ).format }}</span>
                            </div>
                        </b-col>
                        <b-col cols="12" class="my-2">
                            <span class="level-status align-items-center d-flex justify-content-center text-align-center font-weight-bold">
                                <span class="level-progress" :class="`level-range-${ getterLevel?.level.range || 1 }`" :style="`width: calc(${ percent_range }% - 20px)`"></span>
                                <span class="level-current-total">{{ formatCurrency( getterLevel?.total_fact ).format }}</span>
                            </span>
                        </b-col>
                        <b-col cols="12" class="d-flex justify-content-end align-items-center">
                            <div class="content-next-level text-right">
                                <span :class="`next-level-amount background-level-range-${ getterLevel?.nextLevel.range || 1 } my-2 d-inline-block font-weight-bold`">{{ formatCurrency( getterLevel?.nextLevel.lowerLimit ).format }}</span>
                                <span class="next-level-title d-block">Next Level: <strong>{{ getterLevel?.nextLevel.name }}</strong></span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" md="7" class="p-relative">
                    <div class="actions-account-info d-flex justify-content-end">   
                        <b-button @click="hideInfo()" :class="`btn-solid shadow-sm${ showFormInfo ? '' : ' hide' }`">
                            <i class="fa-solid fa-arrow-left"></i>
                            Back
                        </b-button>  
                        <b-button  @click="editInfo()" :class="`btn-solid shadow-sm${ showResumeInfo ? '' : ' hide' }`">
                            <i class="fa-solid fa-pencil"></i>
                            Edit
                        </b-button>   
                    </div>
                    <validation-observer tag="div" v-slot="{ handleSubmit, reset }" class="register-form">
                        <b-form @submit.prevent="handleSubmit( saveChangeInfo )" @reset.prevent="reset" autocomplete="off">
                            <b-row>
                                <b-col cols="12" lg="6" class="mb-2">
                                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="First Name" class="valid-field">
                                        <b-form-group label="First Name" label-for="first_name" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                                            <b-form-input v-if="showFormInfo" type="text" v-model="getCustomerInfo.first_name" placeholder="First Name" name="first_name"/>
                                            <span v-if="showResumeInfo" class="form-control show-data d-flex align-items-center">{{getCustomerInfo.first_name}}</span>
                                            <span v-if="showFormInfo" class="validate-error">{{ errors[0] }}</span>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="12" lg="6" class="mb-2">
                                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Last Name" class="valid-field">
                                        <b-form-group label="Last Name" label-for="last_name" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                                            <b-form-input v-if="showFormInfo" type="text" v-model="getCustomerInfo.last_name" placeholder="Last Name" name="last_name"/>
                                            <span v-if="showResumeInfo" class="form-control show-data d-flex align-items-center">{{getCustomerInfo.last_name}}</span>
                                            <span v-if="showFormInfo" class="validate-error">{{ errors[0] }}</span>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="12" lg="6" class="mb-2">
                                    <validation-provider rules="required" tag="div" v-slot="{ errors }" name="Email" class="valid-field">
                                        <b-form-group label="Email" label-for="email" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal" :class="`${ !errors[0] ? '' : 'field-error' }`">
                                            <b-form-input v-if="showFormInfo" type="email" v-model="getCustomerInfo.email" placeholder="Email" name="email"/>
                                            <span v-if="showResumeInfo" class="form-control show-data d-flex align-items-center">{{getCustomerInfo.email}}</span>
                                            <span v-if="showFormInfo" class="validate-error">{{ errors[0] }}</span>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="12" md="6" class="mb-2">
                                    <b-form-group label="Phone Number" label-for="phone" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2 font-weight-normal">
                                        <b-input-group v-if="showFormInfo" class="custom-input-group" id="group-phone-code">
                                            <template #prepend>
                                                <v-select class="select-phone-code" name="Country Code" required :options="listPhoneCodes" :reduce="(item) => item.id" v-model="countryPhoneCode" :clearable="false" :searchable="false">
                                                    <template #selected-option="{ id, label, phone_code, flag }">
                                                        <img :src="`${ back_url }${ flag }`" :title="label" :alt="label" width="20px" height="14px" class="d-inline-flex mr-2"/>
                                                        <strong>+{{ phone_code }}</strong>
                                                    </template>
                                                    <template #option="{ id, label, phone_code, flag }">
                                                        <img :src="`${ back_url }${ flag }`" :title="label" :alt="label" width="20px" height="14px" class="d-inline-flex"/>
                                                        <span class="mr-2 country-name">{{ label }}: <strong>+{{ phone_code }}</strong></span>
                                                    </template>
                                                </v-select>
                                            </template>
                                            <validation-provider rules="required|length:10" tag="div" v-slot="{ errors }" name="Phone Number" class="valid-field valid-custom-input">
                                                <b-form-input type="text" v-number-only v-model="phoneNumber" placeholder="Phone Number" name="phone" :class="`${ !errors[0] ? '' : 'field-error' }`" @change="validPhone" @input="validPhone"/>
                                                <span class="validate-error">{{ errors[0] }}</span>
                                            </validation-provider>
                                        </b-input-group>
                                        <span v-if="showResumeInfo" class="form-control show-data d-flex align-items-center">{{getCustomerInfo.phone}}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" class="d-flex justify-content-end">
                                    <b-button type="submit" :class="`btn-solid shadow-sm${ showFormInfo ? '' : ' hide' }`">
                                        save changes
                                    </b-button>
                                    <b-button @click="logout()" :class="`btn-solid shadow-sm${ !showFormInfo ? '' : ' hide' }`">
                                        Logout
                                    </b-button>
                                    <b-button type="reset" class="d-none" id="reset-form">Reset Form</b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../../constants/config.js"
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue"),
        profileImage: () => import("../../../components/account/profile-image.vue")
    },
    data() {
        return {
            back_url         : process.env.VUE_APP_CATALOG_BACK_URL_FILE,
            isLoading       : true,
            percent_range   : 0,
            showResumeInfo  : true,
            showFormInfo    : false,
            customer        : null,
            
            listPhoneCodes  : [],
            phoneNumber     : "",
            countryPhoneCode: "US-1",
        };
    },
    directives: {
        'number-only': {
            bind (el) {
                el.addEventListener('keypress', (event) => {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                });
            }
        }
    },
    async mounted(){
        if( !this.getterLogin ){
            
            this.$router.push('/account/login');
        }
        else{
            
            await this.getCountries().then( async (result_countries) => {
                
                this.listPhoneCodes = this.getterCountries.phone_codes;
                await this.getCustomer({ id_email: this.getterUser.email }).then( async (result_customer) => {
                    
                    this.customer   = this.getterCustomer;
                    let data_phone  = this.validPhoneNumber(this.getterCustomer.phone);
                    
                    this.phoneNumber        = data_phone.number;
                    this.countryPhoneCode   = data_phone.code;
                    this.customer.phone     = data_phone.format;
                    
                    await this.getLevelCustomer().then( (result_level) => {
                        
                        let num_range = this.getterLevel != null ? ( this.getterLevel.total_fact * 100 ) / this.getterLevel.nextLevel.lowerLimit : 0;
                        
                        this.percent_range = num_range > 100 ? 100 : num_range;
                        this.$root.$emit('update-profile-image', this.getterProfileImage);
                        this.isLoading = null;
                    });
                });
            });
        }
    },
    computed: {
        ...mapGetters({
            getterLogin         : 'getterLogin',
            getterUser          : 'getterUser',
            getterLevel         : 'getterLevel',
            getterProfileImage  : 'getterProfileImage',
            getterCustomer      : 'getterCustomer',
            getterCountries     : 'getterCountries', 
        }),
        getDataListPhoneCodes(){
            return this.listPhoneCodes;
        },
        getCustomerInfo(){
            return this.customer;
        }
    },
    methods: {
        ...mapActions({
            updateCustomer          : 'updateCustomer', 
            updateCustomerAddress   : 'updateCustomerAddress',
            getCountries            : 'getCountries',
            getCustomer             : 'getCustomer',
            getLevelCustomer        : 'getLevelCustomer',
            changeProfileImage      : 'changeProfileImage',
            logoutUser              : 'logoutUser',
        }),
        editInfo() {
            this.showResumeInfo = false;
            this.showFormInfo = true;
        },
        hideInfo() {
            this.showResumeInfo = true;
            this.showFormInfo = false;
        },
        async saveChangeInfo() {
            let body_request = {
                old_email   : this.getterCustomer.email,
                email       : this.customer.email,
                agent_email : this.getterCustomer.agent_email,
                first_name  : this.customer.first_name,
                last_name   : this.customer.last_name,
                phone       : this.customer.phone,
                note        : this.customer.note
            };
            await this.updateCustomer(body_request).then( (result_customer) => {
                
                let data_phone          = this.validPhoneNumber(this.customer.phone);
                
                this.phoneNumber        = data_phone.number;
                this.countryPhoneCode   = data_phone.code;
                this.customer.phone     = data_phone.format;
            });
        },
        searchImage(){
            
            document.getElementById("profile_image").click();
        },
        async chargeImage(){
            let body_request = { 
                file: document.getElementById("profile_image").files[0],
                input_name: "profile_image",
                max_size: 512000,
                old_path_file: this.getterUser ? this.getterUser.profile_image.replace(`${ this.back_url }/`, '') : "", 
                extra_data: {
                    category_file: "image",
                    body:[
                    { name: "base_path", value: "/images/profiles" },
                    { name: "max_dimension", value: JSON.stringify( { "width": 2000, "height": 2000 } ) },
                    { name: "default_file_name", value: "" }
                    ]
                }
            };
            await this.changeProfileImage(body_request);
            this.$refs["profile_image"].reset();
        },
        validPhone(input_value){
            
            this.customer.phone      = `+${ this.countryPhoneCode.split("-")[1] }${ this.phoneNumber }`;
        },
        validPhoneNumber(phone){
            
            if( phone ){
                
                phone = phone.replace(/([ +])+/g, "");
                
                let code = phone.replace(phone.slice(-10), "");
                
                let index_country = code == "" ? -1 : this.listPhoneCodes.findIndex( (item) => item.phone_code == parseInt(code) );
                
                let country_code = ( code != "" && code == "1" ) || index_country < 0 ? "US-1" : this.listPhoneCodes[index_country].id;
                
                return {
                    code    : country_code,
                    number  : phone.slice(-10),
                    format  : `+${ country_code.split("-")[1] }${ phone.slice(-10) }`
                };
            }
            else{
                
                return {
                    code    : "US-1",
                    number  : "",
                    format  : ""
                };
            }
        },
        formatCurrency( amount_value ){

            return formatData.currency( amount_value );
        },
        logout() {
            this.logoutUser({});
            this.$router.push('/account/login');
        },
    }
}
</script>