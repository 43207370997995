<template>
	<div>
		<breadcrumbs type="account" title="cart for later"></breadcrumbs>
		<section class="cart-section pb-5">
			<div class="container">
				<cart-table :product_origins="'cart_later'"></cart-table>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	components: {
		breadcrumbs:  () => import( "../../components/pages/breadcrumbs.vue" ),
		cartTable:  () => import( "../../components/pages/cart-table.vue" )
	},
};
</script>
