<template>
	<div :class="`box-shadow d-stepper w-100 step-${ currentStep }`">
		<div class="d-stepper-top text-center pb-4">
			<p>After submitting your information, one of our consultants Will contact you within the next few minutes.</p>
		</div>
		<div class="d-stepper-header d-flex justify-content-around">
			<div :class="`step-number-content text-center${ currentStep == 1 ? 'active' : '' }`">
				<div class="step-number align-items-center justify-content-center mx-auto" :class="stepNumberClasses(1)">
					<i v-if="currentStep > 1" class="fa-solid fa-check"></i>
					<i v-else-if="currentStep === 1 && stepError" class="fa-solid fa-exclamation-circle"></i>
					<i v-else class="fa-solid fa-user"></i>
				</div>
				<div class="mt-1 small"><span>User Information</span></div>
			</div>
			<div :class="`step-number-content text-center${ currentStep == 2 ? 'active' : '' }`">
				<div class="step-number align-items-center justify-content-center mx-auto" :class="stepNumberClasses(2)">
					<i v-if="currentStep > 2" class="fa-solid fa-check"></i>
					<i v-else-if="currentStep === 2 && stepError" class="fa-solid fa-exclamation-circle"></i>
					<i v-else class="fa-solid fa-building"></i>
				</div>
				<div class="mt-1 small"><span>Business Information</span></div>
			</div>
		</div>
		<div class="d-stepper-body content-loading mt-4 p-relative">
			<vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
			<div class="register-form step-form">
				<b-card class="container-step-form bg-white p-relative border-0" bg-variant="transparent" no-body>
					<b-card-body id="step-1" class="step-register pb-0 step-form-animation-in">
						<b-row>
							<b-col id="step-1-1" cols="12" :class="`${ currentStep == 1 ? '' : 'd-none' }`">
								<form-address v-if="getLeadAddress && getDataListCountries.length > 0" idForm="register-lead-address" :dataAddress="getLeadAddress" :dataListCountries="getDataListCountries" :dataListStates="getDataListStates" :dataListPhoneCodes="getDataListPhoneCodes" @validFormAddress="validFormAddress" typeForm="register-lead"></form-address>
							</b-col>
						</b-row>
						<validation-observer tag="div" v-slot="{ handleSubmit, valid, reset, errors }" class="row" id="valid-step-1">
							<b-form id="step-1-2"  :class="`${ currentStep == 1 ? '' : 'd-none ' }col-12`" @submit.prevent="handleSubmit( async () => { await preSubmitSetp1(valid) } )" @reset.prevent="reset" autocomplete="off" >
								<validation-provider rules="required" tag="div" v-slot="{ errors }" name="Type of Business" class="valid-field">
									<b-form-group label="Type of Business" label-for="business-type" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2" :class="`${ !errors[0] ? '' : 'field-error' }`">
										<v-select :options="options_business_type" v-model="lead.business_type" name="business-type" placeholder="Select Type of Business"></v-select>
										<span class="validate-error">{{ errors[0] }}</span>
									</b-form-group>
								</validation-provider>
								<validation-provider rules="required" tag="div" v-slot="{ errors }" name="Invest Range" class="valid-field">
									<b-form-group label="How much would you like to invest?" label-for="invest-range" label-align-sm="top" label-size="sm" label-class="font-color-dark mb-2" :class="`${ !errors[0] ? '' : 'field-error' }`">
										<v-select :options="options_invest_range" v-model="lead.invest_range" name="invest-range" placeholder="Select Invest Range"></v-select>
										<span class="validate-error">{{ errors[0] }}</span>
									</b-form-group>
								</validation-provider>
								<b-button id="submit-form-step-1" type="submit" class="d-none"></b-button>
								<b-button :id="`reset-form-step-1`" type="reset" class="d-none">Reset Form</b-button>
							</b-form>
						</validation-observer>
					</b-card-body>
					<b-card-body id="step-2" class="step-register pb-0 step-form-animation-in"> 
						<form-business :class="`${ currentStep == 2 ? '' : 'd-none' }`" idForm="register-business-data" @validFormBusiness="validFormBusiness"></form-business>
					</b-card-body>
					<b-modal id="modal_agent" ref="modal_agent" size="lg" centered :hide-footer="true" :hide-header="true">
						<div class="modal-body modal1">
							<div class="container-fluid p-0">
								<b-row>
									<b-col cols="12">
										<div class="default-block w-100 px-2">
											<div class="media default-border">
												<div class="media-body">
													<div class="custom-content-icon">
														<i class="fa-solid fa-check"></i>
													</div>
													<h3 class="title-block">Your registration was successful:</h3>
													<p v-if="!getAgent">One of our Sales Agents will contact you to complete the process</p>
												</div>
											</div>
										</div>
									</b-col>
								</b-row>
								<b-row v-if="getAgent">
									<b-col cols="12" class="title-default mb-4">
										<h3 class="title-inner-default">Congratulations <strong>{{ getAgent?.name }}</strong> is your assigned agent</h3>
									</b-col>
									<b-col cols="12" lg="4" class="p-0">
										<div class="content-customer-insigne justify-content-center agent-image">
											<img :class="`profile-image rounded-circle${ hide_profile ? ' hide' : '' }`" :alt="`${ getAgent?.name || 'agent-image' }`" :src="profile_image"/>
										</div>
									</b-col>
									<b-col cols="12" lg="8">
										<b-row>
											<b-col cols="12" md="6" class="form-group">
												<div class="field-label mb-2 label-info">Name</div>
												<span class="form-control show-data">{{ getAgent?.name || '' }}</span>
											</b-col>
											<b-col cols="12" md="6" class="form-group">
												<div class="field-label mb-2 label-info">Email</div>
												<span class="form-control show-data">{{ getAgent?.email || '' }}</span>
											</b-col>
											<b-col cols="12" md="6" class="form-group">
												<div class="field-label mb-2 label-info">Phone</div>
												<span class="form-control show-data">{{ getAgent?.phone || '' }}</span>
											</b-col>
											<b-col cols="12" md="6" class="form-group" v-if="getAgent?.phone">
												<div class="field-label mb-2 label-info whatsapp-agent">
													<a :href="getAgent?.whatsappLink">Click here! and Contact us on WhatsApp<i class="fa-brands fa-whatsapp"></i></a>
												</div>
											</b-col>
											<b-col cols="12" md="6" class="form-group" v-if="( getAgent?.language || [] ).length > 0">
												<div class="field-label mb-2 label-info">Languages</div>
												<span v-for="(item_language, index_language) in ( getAgent?.language || [] )" :key="index_language" class="form-control show-data">{{ item_language.name }}</span>
											</b-col>
											<b-col cols="12" md="6" class="form-group" v-if="( getAgent?.categoryProduct || [] ).length > 0">
												<div class="field-label mb-2 label-info">Specialist in</div>
												<span v-for="(item_category, index_category) in ( getAgent?.categoryProduct || [] )" :key="index_category" class="form-control show-data">{{ item_category.name }}</span>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</div>
						</div>
					</b-modal>
					<b-modal id="modal_step_1" ref="modal_step_1" size="lg" centered title="Your registration was Successful" :hide-footer="true">
						<div class="modal-body p-0">
							<div class="container-fluid p-0">
								<b-row>
									<b-col cols="12" md="6">
										<div class="default-block w-100 px-2">
											<div class="media default-border p-0">
												<div class="media-body text-center">
													<p class="title-block">A commercial agent will contact you today</p>
													<img width="240px" height="240px" :src="require(`@/assets/images/form/register-ready.webp`)" alt="Register Ready Cata1og" class="p-2"/>
												</div>
											</div>
										</div>
									</b-col>
									<b-col cols="12" md="6">
										<div class="default-block w-100 px-2">
											<div class="media default-border p-0">
												<div class="media-body text-center">
													<p class="title-block">If you want to enjoy the best wholesale discounts right now</p>
													<img width="240px" height="240px" :src="require(`@/assets/images/form/complete-bussines-information.webp`)" alt="Complete Business Information Cata1og" class="p-2"/>
												</div>
											</div>
										</div>
									</b-col>
									<b-col cols="12">
										<div class="default-block w-100 px-2">
											<div class="media default-border p-0">
												<div class="media-body text-center">
													<p class="title-block">complete your business information and contact your Commercial Agent directly</p>
													<b-button class="btn-solid btn-light mt-2" @click="goInitForm">Start Now</b-button>
												</div>
											</div>
										</div>
									</b-col>
								</b-row>
							</div>
						</div>
					</b-modal>
					<b-card-footer class="bg-white border-0 d-flex justify-content-end">
						<b-button-group>
							<b-button type="button" v-if="currentStep > 1" :class="`btn-solid shadow-sm${ currentStep > 1 ? ' btn-back-step' : '' } mr-2`" title="Back Step" @click="goBackStep">
								<i class="fa-solid fa-angle-double-left"></i>
							</b-button>
							<b-button :class="`btn-solid btn-light shadow-sm${ currentStep > 1 ? ' btn-first-step' : '' }`" @click="goNextStep">
								<strong v-if="currentStep < 2">SEND</strong>
								<strong v-else>Add Business Info</strong>
							</b-button>
							<b-button type="reset" class="d-none" id="reset-form-lead">Reset Form</b-button>
						</b-button-group>
					</b-card-footer>
				</b-card>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
let leadData = {
	_id 				: null,
	email				: null,
	first_name			: null,
	last_name			: null,
	phone				: null,
	address				: {
		first_name	: null,
		last_name	: null,
		name		: null,
		phone		: null,
		address_1	: null,
		address_2	: null,
		company		: null,
		country		: 'United States',
		country_code: 'US',
		state		: null,
		state_code	: null,
		city		: null,
		zip			: null
	},
	invest_range		: null,
	have_experience		: true,
	physical_store 		: true,
	target_sell 		: null,
	company_webside		: null,
	have_company_webside: true,
	social_networks 	: [
	{ type: "facebook"	, user: "", value: 0 },
	{ type: "instagram"	, user: "", value: 0 },
	{ type: "tiktok"	, user: "", value: 0 }
	],
	business_size		: null,
	business_type		: null,
	terms_and_conditions: null,
	language			: null,
	note				: null
};
export default {
	props: ["initialState"],
	components:{
		vcLoading	: () => import('../../components/pages/loading.vue' ),
		formAddress	: () => import("../../components/pages/form-address.vue"),
		formBusiness: () => import("../../components/account/form-business.vue")
	},
	data() {
		return {
			isLoading				: null,
			currentStep				: 1,
			stepError				: false,
			effect					: "out-in-translate-fade",
			mode					: "out-in",
			
			listCountries			: [],
			listPhoneCodes			: [],
			listStates				: [],
			addressForm				: {...leadData.address, email: ""},
			defaultLead				: {...leadData},
			lead					: {...leadData},
			agent					: null,
			profile_image			: null,
			hide_profile 			: false,
			
			optionPhoneCodes		: [],
			leadPhoneCode			: "US-1",
			lead_phone				: "",
			valid_lead_phone		: "",
			
			dataCountries			: [],
			optionAddressCountries	: [],
			optionAddressStates		: [],
			
			db_country				: null,
			db_state				: null,
			
			options_invest_range	: [
			{ label: "$300 to $599 (10% off)"	, value: "$300 to $599" },
			{ label: "$600 to $999 (30% off)"	, value: "$600 to $999" },
			{ label: "$1000 or more (up to 40% off)"	, value: "$1000" }
			],
			options_business_type 	: [],
			step_1_data				: null
		};
	},
	async mounted() {
		
		this.lead = {...this.defaultLead};
		this.options_business_type = this.getterBusinessTypes.map((item) => {
			return { label: item.name, value: item._id };
		});
		await this.getCountries().then( async (result_countries) => {
			
			this.listPhoneCodes = this.getterCountries.phone_codes;
			this.listCountries 	= this.getterCountries.list;
			this.listStates     = this.getterCountries.states;
		});
	},
	computed: {
		...mapGetters({
			getterLead			: "getterLead",
			getterCountries		: "getterCountries",
			getterBusinessTypes	: "getterBusinessTypes"
		}),
		getLeadAddress(){
			return this.addressForm;
		},
		getDataListCountries(){
			return this.listCountries;
		},
		getDataListStates(){
			return this.listStates;
		},
		getDataListPhoneCodes(){
			return this.listPhoneCodes;
		},
		getAgent(){
			return this.agent;
		}
	},
	methods: {
		...mapActions({
			getCountries	: 'getCountries',
			addNotification	: 'addNotification',
			createLead		: 'createLead',
			updateLead		: 'updateLead',
		}),
		async validFormAddress(data_form){
			
			this.step_1_data = data_form;
			document.getElementById('submit-form-step-1').click();
		},
		async preSubmitSetp1(is_valid){
			
			if( this.step_1_data.valid && this.lead.invest_range ){
				
				this.addressForm    	= this.step_1_data.address;
				
				this.lead.email 		= this.addressForm.email;
				this.lead.first_name 	= this.addressForm.first_name;
				this.lead.last_name 	= this.addressForm.last_name;
				this.lead.address.name 	= `${ this.lead.first_name } ${ this.lead.last_name }`;
				this.lead.phone 		= this.addressForm.phone;
				
				this.lead.address = {...this.addressForm};
				delete this.lead.address.email;
				let data_lead = {
					data: {
						firstName		: this.lead.first_name,
						lastName		: this.lead.last_name,
						email			: this.lead.email,
						phoneNumber		: this.lead.phone,
						addresses		: [this.lead.address],
						db_country		: { iso_code: this.lead.address.country_code },
						db_state		: { iso_code: this.lead.address.state_code },
						initialBudget	: this.lead.invest_range.value,
						typeOfBusiness	: this.lead.business_type.value,
						idGoogleAdd		: localStorage.getItem('gclid') ? JSON.parse( localStorage.getItem('gclid') ).value : null,
						origin			: localStorage.getItem('add_origin') ? localStorage.getItem('add_origin') : null,
						utmSource		: localStorage.getItem('utmSource') ? localStorage.getItem('utmSource') : null,
						utmMedium		: localStorage.getItem('utmMedium') ? localStorage.getItem('utmMedium') : null,
						utmCampaign		: localStorage.getItem('utmCampaign') ? localStorage.getItem('utmCampaign') : null,
						utmTerm			: localStorage.getItem('utmTerm') ? localStorage.getItem('utmTerm') : null,
						source			: this.initialState.source
					},
					step: 1,
					finalStep: false
				};
				this.agent = null;
				this.isLoading = true;
				await this.createLead(data_lead).then( (result_lead) => {
					
					this.stepError = false;
					this.isLoading = null;
					let exist_lead = !!this.lead._id;
					if( this.getterLead.valid ){
						
						this.lead._id = this.getterLead.data.lead_id;
						document.getElementById(`step-${ this.currentStep }`).classList.add("step-form-animation-out");
						document.getElementById(`step-${ this.currentStep }`).classList.remove("step-form-animation-in");
						
						setTimeout(() => {
							this.currentStep++;
							
							document.getElementById(`step-${ this.currentStep }`).classList.add("step-form-animation-in");
							document.getElementById(`step-${ this.currentStep }`).classList.remove("step-form-animation-out");
						}, 120);
						document.getElementById('reset-form-step-1').click();
						if( !exist_lead ){
							
							this.showModal('modal_step_1');
						}

						localStorage.removeItem("gclid");
						localStorage.removeItem("origin_add"); 
						localStorage.removeItem("utmSource"); 
						localStorage.removeItem("utmMedium");
						localStorage.removeItem("utmCampaign");
						localStorage.removeItem("utmTerm");
					}
					else{
						
						this.stepError = true;
						this.addNotification({ 
							content: { type: 'request' }, 
							type: "error", 
							data: { title: "Error: Register fields", message: "There are fields that have not been validated" }
						});
					}
				});
			}
			else{
				
				this.stepError = true;
				this.addNotification({ 
					content: { type: 'request' }, 
					type: "error", 
					data: { title: "Error: Register fields", message: "There are fields that have not been validated" }
				});
			}
		},
		async validFormBusiness(data_form){
			
			if( data_form.valid ){
				
				this.lead.have_experience		= data_form.lead.have_experience;
				this.lead.physical_store 		= data_form.lead.physical_store;
				this.lead.target_sell 			= data_form.lead.target_sell;
				this.lead.company_webside		= data_form.lead.company_webside;
				this.lead.have_company_webside	= data_form.lead.have_company_webside;
				this.lead.social_networks 		= data_form.lead.social_networks;
				this.lead.business_size			= data_form.lead.business_size;
				this.lead.terms_and_conditions	= data_form.lead.terms_and_conditions;
				this.lead.language				= data_form.lead.language;
				this.lead.note					= data_form.lead.note;
				
				let data_lead = {
					data		: {
						id						: this.lead._id,
						email					: this.lead.email,
						firstName				: this.lead.first_name,
						lastName				: this.lead.last_name,
						phoneNumber				: this.lead.phone,
						addresses				: [this.lead.address],
						initialBudget			: this.lead.invest_range.value,
						haveExperience 			: this.lead.have_experience,
						physicalStore			: this.lead.physical_store,
						companyWebsite			: this.lead.company_webside,
						socialNetwork			: this.lead.social_networks.map( (item) => { return { type: item.type, user: item.user, value: item.value } }),
						targetSell				: this.lead.target_sell 	? this.lead.target_sell.value.toLowerCase() : null,
						businessSize			: this.lead.business_size	? this.lead.business_size.value 			: null,
						language				: this.lead.language		? this.lead.language.label 					: null,
						checkTermsAndConditions	: this.lead.terms_and_conditions,
					},
					step: 2,
					finalStep: true
				};
				await this.updateLead(data_lead).then( (result_lead) => {
					
					if( this.getterLead.valid ){
						
						this.currentStep = 1;
						this.lead = {...this.defaultLead};
						this.agent = this.getterLead.data.agent;
						if( this.agent ){
							
							if( this.agent == null || this.agent.photo == '' ){
								
								this.profile_image = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }/images/svg-store/svg-profile-image/svg-secondary.svg`;
							}
							else{
								
								this.profile_image = `${ process.env.VUE_APP_AGENT_API_URL.replace('/api', '') }/${ this.agent.photo.replace('public/', '') }`;
							}
							this.hide_profile = false;
							this.agent.phone = this.agent.phone ? this.agent.phone : '';
							this.agent.whatsappLink = this.agent.phone != '' ? `https://api.whatsapp.com/send?phone=${ this.agent.phone.replace(/ +/g, '').replace('+', '') }` : '';
						}
						this.$root.$emit('reset-business-data', { id_form: "register-business-data", clean: true });
						
						this.showModal("modal_agent");
						
						this.$root.$emit('reset-address', { id_form: "register-lead-address", clean: true });
					}
					else{
						
						this.stepError = true;
						this.addNotification({ 
							content: { type: 'request' }, 
							type: "error", 
							data: { title: "Error: Register fields", message: "There are fields that have not been validated" }
						});
					}
				});
			}
			else{
				
				this.stepError = true;
				this.addNotification({ 
					content: { type: 'request' }, 
					type: "error", 
					data: { title: "Error: Register fields", message: "There are fields that have not been validated" }
				});
			}
		},
		stepNumberClasses(this_step) {
			return {
				"step-orange text-white"	: ( this.currentStep === this_step && !this.stepError ),
				"step-success text-white"	: ( this.currentStep > this_step && !this.stepError ) || ( this.stepError && this.currentStep > this_step ),
				"step-error text-white" 	: this.stepError && this.currentStep === this_step
			};
		},
		goBackStep() {
			
			document.getElementById(`step-${ this.currentStep }`).classList.add("step-form-animation-in");
			document.getElementById(`step-${ this.currentStep }`).classList.remove("step-form-animation-out");
			
			setTimeout(() => {
				this.currentStep--;
				
				document.getElementById(`step-${ this.currentStep }`).classList.add("step-form-animation-out");
				document.getElementById(`step-${ this.currentStep }`).classList.remove("step-form-animation-in");
				
			}, 120);
			
			this.$root.$emit('change-select-address', { id_form: "register-lead-address", address: {...this.lead.address, email: this.lead.email}, type: "lead-fields" });
			this.$root.$emit('move-register-form', true);
		},
		goNextStep() {
			
			if( this.currentStep == 1 ){
				
				this.$root.$emit('valid-address', { id_form: "register-lead-address", action: "register-lead-address" });
			}
			else if( this.currentStep == 2 ){
				
				this.$root.$emit('valid-business-data', { id_form: "register-business-data", action: "register-business-data" });
			}
		},
		showModal(refName) {
			
			this.$refs[refName].show();
		},
		hideModal(refName) {
			
			this.$refs[refName].hide();
		},
		goInitForm(){
			this.hideModal('modal_step_1');
			this.$root.$emit('move-register-form', true);
		}
	}
};
</script>
