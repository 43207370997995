<template>
    <div class="section-b-space pt-0 page-content content-loading p-relative">
        <breadcrumbs type="dashboard" title="Price Catalog"></breadcrumbs>
        <vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Price Catalog</h2>
        </div>
        <div class="content-downoload-file d-flex align-items-center justify-content-end px-4">
            <a id="download_file" href="" class="d-none"></a>
            <span class="mr-4">Download File:</span>
            <b-button class="btn-solid btn-file px-3" v-b-tooltip.hover :title="`Download Price Catalog File - ${ current_date }`" @click="createExportTable" :disabled="disabled_button">
                <i class="fa-solid fa-file-arrow-down"></i>
            </b-button>
        </div>
        <section class="section-b-space">
            <content-collection :handleCollection="'customer-price-catalog'" :accessCatalog="access_catalog" @updateLoadingCollection="updateLoadingCollection" @changeAccessCatalog="changeAccessCatalog"></content-collection>
        </section>
    </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        vcLoading           : () => import("../../../components/pages/loading.vue"),
        breadcrumbs         : () => import("../../../components/pages/breadcrumbs.vue"),
        contentCollection   : () => import( '../../../components/product/collection-content.vue' )
    },
    data() {
        return {
            isLoading 			: true,
            access_catalog		: true,
            handleCollection	: null,
            current_date        : moment().format('YYYY-MM-DD'),
            disabled_button     : false
        };
    },
    async mounted(){
        if( !this.getterLogin ){
            
            this.$router.push('/account/login');
        }
    },
    computed: {
        ...mapGetters({
            getterLogin                     : 'getterLogin',
            getterDownloadTransactionsFile  : "getterDownloadTransactionsFile",
            getterDownloadPriceCatalog      : "getterDownloadPriceCatalog"
        })
    },
    methods:{
        ...mapActions({
            downloadPriceCatalog: 'downloadPriceCatalog',
            deletePriceCatalog  : 'deletePriceCatalog'
        }),
        changeAccessCatalog(access) {
            this.access_catalog = access;
        },
        updateLoadingCollection(loading_collection){
            this.isLoading = loading_collection;
        },
        downloadExportFile( data_file ) {
            
            if( ![null, ""].includes( data_file ) ){
                
                document.getElementById("download_file").href = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data_file }`;
                document.getElementById("download_file").download = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data_file }`.substring( `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data_file }`.lastIndexOf('/') + 1 );
                document.getElementById("download_file").click();
                setTimeout(async () => {
                    
                    await this.deletePriceCatalog({ url_file: data_file });
                }, 3000);
            }
        },
        async createExportTable(){
            this.disabled_button = true;
            await this.downloadPriceCatalog().then( (result_file) => {
                
                this.disabled_button = false;
                this.downloadExportFile( this.getterDownloadPriceCatalog );
            });
        },
    }
}
</script>