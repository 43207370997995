<template>
    <div class="section-b-space pt-0 page-content p-relative">
        <breadcrumbs type="dashboard" title="Buy Again"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Buy Again</h2>
        </div>
        <b-container class="cart-section">
            <cart-table :product_origins="'buy_again'"></cart-table>
        </b-container>
    </div>
</template>
<script>
export default {
    components: {
        breadcrumbs: () => import("../../../components/pages/breadcrumbs.vue"),
        cartTable: () => import("../../../components/pages/cart-table.vue")
    }
}
</script>