<template>
	<section :class="`default-slider p-0`">
		<b-carousel v-if="getHomeSlider" id="carousel-home" :interval="2000">
			<b-carousel-slide v-for="(item_slide, index_slide) in getPreloadImages" :key="`slide-${ index_slide }`">
				<template #img>
					<nuxt-link v-if="item_slide.background_link != null" :to="{ path: item_slide.background_link }" class="align-items-top d-flex justify-content-center w-100">
						<img :loading="item_slide.loading" :src="`${ item_slide.image.replace('_full.', '_3000.') }`" :alt="item_slide.alt" width="3000" height="1440" class="img-fluid" sizes="(max-width: 576px) 300px, (max-width: 767px) 420px, (max-width: 991px) 960px, (max-width: 1399px) 1180px, (max-width: 3820px) 1400px" :srcset="`${ item_slide.image.replace('_full.', '_600.') } 600w, ${ item_slide.image.replace('_full.', '_700.') } 700w, ${ item_slide.image.replace('_full.', '_800.') } 800w, ${ item_slide.image.replace('_full.', '_1000.') } 1000w, ${ item_slide.image.replace('_full.', '_1200.') } 1200w, ${ item_slide.image.replace('_full.', '_1400.') } 1400w, ${ item_slide.image.replace('_full.', '_1600.') } 1600w, ${ item_slide.image.replace('_full.', '_1800.') } 1800w, ${ item_slide.image.replace('_full.', '_2000.') } 2000w, ${ item_slide.image.replace('_full.', '_2200.') } 2200w, ${ item_slide.image.replace('_full.', '_2400.') } 2400w, ${ item_slide.image.replace('_full.', '_2600.') } 2600w, ${ item_slide.image.replace('_full.', '_2800.') } 2800w, ${ item_slide.image.replace('_full.', '_3000.') } 3000w`"/>
					</nuxt-link>
					<div v-else class="align-items-top d-flex justify-content-center w-100">
						<img :loading="item_slide.loading" :src="`${ item_slide.image.replace('_full.', '_3000.') }`" :alt="item_slide.alt" width="3000" height="1440" class="img-fluid" sizes="(max-width: 576px) 300px, (max-width: 767px) 420px, (max-width: 991px) 960px, (max-width: 1399px) 1180px, (max-width: 3820px) 1400px" :srcset="`${ item_slide.image.replace('_full.', '_600.') } 600w, ${ item_slide.image.replace('_full.', '_700.') } 700w, ${ item_slide.image.replace('_full.', '_800.') } 800w, ${ item_slide.image.replace('_full.', '_1000.') } 1000w, ${ item_slide.image.replace('_full.', '_1200.') } 1200w, ${ item_slide.image.replace('_full.', '_1400.') } 1400w, ${ item_slide.image.replace('_full.', '_1600.') } 1600w, ${ item_slide.image.replace('_full.', '_1800.') } 1800w, ${ item_slide.image.replace('_full.', '_2000.') } 2000w, ${ item_slide.image.replace('_full.', '_2200.') } 2200w, ${ item_slide.image.replace('_full.', '_2400.') } 2400w, ${ item_slide.image.replace('_full.', '_2600.') } 2600w, ${ item_slide.image.replace('_full.', '_2800.') } 2800w, ${ item_slide.image.replace('_full.', '_3000.') } 3000w`"/>
					</div>
				</template>
			</b-carousel-slide>
		</b-carousel>
		<img v-else :loading="preload_image[0].loading" :src="`${ preload_image[0].image.replace('_full.', '_3000.') }`" :alt="preload_image[0].alt" width="3000" height="1440" class="img-fluid" sizes="(max-width: 576px) 300px, (max-width: 767px) 420px, (max-width: 991px) 960px, (max-width: 1399px) 1180px, (max-width: 3820px) 1400px" :srcset="`${ preload_image[0].image.replace('_full.', '_600.') } 600w, ${ preload_image[0].image.replace('_full.', '_700.') } 700w, ${ preload_image[0].image.replace('_full.', '_800.') } 800w, ${ preload_image[0].image.replace('_full.', '_1000.') } 1000w, ${ preload_image[0].image.replace('_full.', '_1200.') } 1200w, ${ preload_image[0].image.replace('_full.', '_1400.') } 1400w, ${ preload_image[0].image.replace('_full.', '_1600.') } 1600w, ${ preload_image[0].image.replace('_full.', '_1800.') } 1800w, ${ preload_image[0].image.replace('_full.', '_2000.') } 2000w, ${ preload_image[0].image.replace('_full.', '_2200.') } 2200w, ${ preload_image[0].image.replace('_full.', '_2400.') } 2400w, ${ preload_image[0].image.replace('_full.', '_2600.') } 2600w, ${ preload_image[0].image.replace('_full.', '_2800.') } 2800w, ${ preload_image[0].image.replace('_full.', '_3000.') } 3000w`"/>
	</section>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			company_data	: null,
			preload_image	: [
				{
					image			: `/slide-image-01_full.webp`,
					alt				: 'slide-image-01',
					background_link	: "/account/register",
					loading			: "eager",
					show			: true
				}
			]
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadSlider);
	},
	computed: {
		...mapGetters({
			getterCompanyData	: 'getterCompanyData',
			getterLogin			: 'getterLogin'
		}),
		getPreloadImages(){
			return this.preload_image;
		},
		getHomeSlider(){
			
			return this.company_data?.slideshows?.home ? this.company_data.slideshows.home : null;
		}
	},
	methods:{
		loadSlider(){
			setTimeout(() => {
				this.company_data = this.getterCompanyData?.slideshows?.home ? this.getterCompanyData : JSON.parse( localStorage.getItem('general-settings') );
				this.preload_image = (this.company_data?.slideshows?.home ? this.company_data.slideshows.home.images : []).reduce( (previous_item, current_item, current_index) => {
					if( (current_index == 0 && !this.getterLogin) || (current_index > 0 && current_item.show ) ){

						previous_item.push({
							image			: `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }/${ current_item.image.url }`,
							alt				: `slide-image-${ current_index + 1 }`,
							background_link	: current_item.background_link,
							loading			: current_index == 0 ? 'eager' : 'lazy',
							show			: current_item.show
						});
					}
					return previous_item;
				}, []);
			}, 3000);
		},
	}
}
</script>
