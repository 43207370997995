<template>
    <b-container v-if="getLoading" class="content-loading">
        <vc-loading :isLoading="getLoading"/>
    </b-container>
    <b-container v-else-if="getterAgent" class="agent-profile p-0">
        <b-row class="content-profile m-0">
            <b-col cols="12" class="col-header text-center p-0">
                <div class="bg-agent-profile">
                    <a href='/' class="logo-link" :title="getterCompanyData?.name" :aria-label="getterCompanyData?.description">
                        <h1>{{ getterCompanyData?.name }}</h1>
                        <svg id="Grupo_2970" data-name="Grupo 2970" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="270" height="66" viewBox="0 0 1574 400">
                            <g id="Grupo_2969" data-name="Grupo 2969">
                                <path id="Trazado_3383" data-name="Trazado 3383" d="M371.881,101.823c-31.568.322-52.338,20.306-54.615,30.929-.505,2.357-.044,3.926,1.368,4.666,2.482.5,4.264-1.492,7.677-5.561,6.26-7.463,16.733-19.955,45.57-19.955,31.635,1.376,40.746,27.288,41.155,42.381-.006.233-.424,12.832-.424,16.155,0,2.972-.413,6.2-5.338,6.812-46.878,5.259-102.736,20.99-102.736,66.084,0,25.344,21.115,44.455,49.117,44.455,21.387,0,34.533-5.717,46.132-10.76,3.331-1.449,6.481-2.818,9.642-3.968l1.62-.59,5.267,14.046h19.513V171.286c0-25.942-8.309-69.463-63.948-69.463m40.306,161.782-1.119.427c-14.306,5.47-23.936,8.04-44.8,11.951a28.124,28.124,0,0,1-5.038.466,24.5,24.5,0,0,1-15.978-5.845c-7.771-6.484-12.41-17.789-12.41-30.238,0-43.032,46.506-51.83,77.28-57.653l2.064-.391Z" fill="#FFFFFF"/>
                                <path id="Trazado_3384" data-name="Trazado 3384" d="M703.008,103.565c-31.568.322-52.338,20.306-54.615,30.929-.505,2.357-.044,3.926,1.368,4.666,2.484.5,4.266-1.491,7.677-5.561,6.26-7.463,16.733-19.955,45.57-19.955,31.635,1.376,40.746,27.288,41.155,42.381-.006.233-.424,12.832-.424,16.155,0,2.972-.413,6.2-5.338,6.812-46.878,5.259-102.736,20.99-102.736,66.084,0,25.344,21.115,44.455,49.117,44.455,21.387,0,34.533-5.717,46.132-10.76,3.331-1.449,6.481-2.818,9.642-3.968l1.62-.59,5.267,14.046h19.513V173.028c0-25.942-8.309-69.463-63.948-69.463m40.306,161.782-1.119.427c-14.306,5.47-23.936,8.04-44.8,11.951a28.123,28.123,0,0,1-5.038.466,24.5,24.5,0,0,1-15.978-5.845c-7.771-6.484-12.41-17.789-12.41-30.238,0-43.032,46.506-51.83,77.28-57.653l2.064-.391Z" fill="#FFFFFF"/>
                                <path id="Trazado_3385" data-name="Trazado 3385" d="M152.267,12.782A101.759,101.759,0,0,0,106.143,23.34,122.379,122.379,0,0,0,69.187,51.682a130.295,130.295,0,0,0-24.73,41.679,145.411,145.411,0,0,0-8.891,51.126,145.327,145.327,0,0,0,8.891,51.126,133.428,133.428,0,0,0,24.73,41.958,116.551,116.551,0,0,0,36.956,28.342,103.855,103.855,0,0,0,46.124,10.281,108.255,108.255,0,0,0,53.071-13.615,118.471,118.471,0,0,0,40.845-36.956l8.336,5a141.994,141.994,0,0,1-48.9,43.068q-29.462,15.839-64.465,15.838a133.527,133.527,0,0,1-55.015-11.392A142.66,142.66,0,0,1,41.4,247.019a147.579,147.579,0,0,1-30.287-46.125A143.737,143.737,0,0,1,0,144.487,144.092,144.092,0,0,1,86.137,11.394,133.64,133.64,0,0,1,141.152,0q35.012,0,64.465,15.839a144.333,144.333,0,0,1,48.9,42.513l-8.336,5A118.54,118.54,0,0,0,205.338,26.4a108.342,108.342,0,0,0-53.071-13.616" fill="#FFFFFF"/>
                                <path id="Trazado_3386" data-name="Trazado 3386" d="M1003.168,102.277a88.989,88.989,0,0,1,35.941,7.369,95.023,95.023,0,0,1,29.472,19.947,92.058,92.058,0,0,1,19.767,29.651,93.984,93.984,0,0,1-49.239,122.919,92.222,92.222,0,0,1-72.242,0,94.822,94.822,0,0,1-49.239-122.919A91.863,91.863,0,0,1,937.4,129.593a94.858,94.858,0,0,1,29.472-19.947,89.84,89.84,0,0,1,36.3-7.369m0,179.707q17.967,0,31.088-7.369a64.566,64.566,0,0,0,21.566-19.407,86.1,86.1,0,0,0,12.579-27.676,124.081,124.081,0,0,0,4.134-31.808A122.046,122.046,0,0,0,1068.4,164.1a88.605,88.605,0,0,0-12.579-27.676,63.987,63.987,0,0,0-21.566-19.588q-13.124-7.36-31.088-7.367t-31.09,7.367a65.65,65.65,0,0,0-21.744,19.588,86.135,86.135,0,0,0-12.76,27.676,121.871,121.871,0,0,0-4.132,31.627,123.9,123.9,0,0,0,4.132,31.808,83.754,83.754,0,0,0,12.76,27.676,66.269,66.269,0,0,0,21.744,19.407q13.116,7.373,31.09,7.369" fill="#FFFFFF"/>
                                <path id="Trazado_3387" data-name="Trazado 3387" d="M786.961,50.06V60.848l44.018-31.07V224.836h.174v64.7h27.876V0Z" fill="#FAA072"/>
                                <path id="Trazado_3388" data-name="Trazado 3388" d="M1297.922,252.664a3.466,3.466,0,0,1,3.61,3.61,4.031,4.031,0,0,1-1.012,2.745,3.3,3.3,0,0,1-2.6,1.154,3.941,3.941,0,0,1-3.9-3.9,3.3,3.3,0,0,1,1.154-2.6,4.031,4.031,0,0,1,2.745-1.012" fill="#FAA072"/>
                                <path id="Trazado_3389" data-name="Trazado 3389" d="M1357.845,188.263a26.425,26.425,0,0,0-11.985,2.745,31.78,31.78,0,0,0-9.6,7.363,33.9,33.9,0,0,0-6.426,10.83,39.366,39.366,0,0,0,0,26.568,34.683,34.683,0,0,0,6.426,10.9,30.281,30.281,0,0,0,9.6,7.365,26.986,26.986,0,0,0,11.985,2.671,28.141,28.141,0,0,0,13.789-3.537,30.789,30.789,0,0,0,10.613-9.6l2.166,1.3a36.893,36.893,0,0,1-12.707,11.191,36.072,36.072,0,0,1-31.044,1.154,37.045,37.045,0,0,1-11.625-8.086,38.319,38.319,0,0,1-7.869-11.985,37.7,37.7,0,0,1,19.494-49.238,36.072,36.072,0,0,1,31.044,1.154,37.524,37.524,0,0,1,12.707,11.046l-2.166,1.3a30.8,30.8,0,0,0-10.613-9.6,28.143,28.143,0,0,0-13.789-3.539" fill="#FAA072"/>
                                <path id="Trazado_3390" data-name="Trazado 3390" d="M1438.995,184.942a35.766,35.766,0,0,1,14.439,2.961,38.133,38.133,0,0,1,11.84,8.014,36.965,36.965,0,0,1,7.943,11.912,38.647,38.647,0,0,1,0,29.313,37.391,37.391,0,0,1-19.783,20.07,37.037,37.037,0,0,1-29.022,0,38.1,38.1,0,0,1-19.784-49.383,36.88,36.88,0,0,1,7.943-11.912,38.1,38.1,0,0,1,11.841-8.014,36.109,36.109,0,0,1,14.583-2.961m0,72.2a25.093,25.093,0,0,0,12.49-2.961,25.938,25.938,0,0,0,8.664-7.8,34.575,34.575,0,0,0,5.054-11.12,49.867,49.867,0,0,0,1.661-12.779,49.055,49.055,0,0,0-1.661-12.706,35.589,35.589,0,0,0-5.054-11.119,25.691,25.691,0,0,0-8.664-7.87,27.84,27.84,0,0,0-24.98,0,26.342,26.342,0,0,0-8.735,7.87,34.564,34.564,0,0,0-5.127,11.119,48.971,48.971,0,0,0-1.66,12.706,49.78,49.78,0,0,0,1.66,12.779,33.6,33.6,0,0,0,5.127,11.12,26.606,26.606,0,0,0,8.735,7.8,25.082,25.082,0,0,0,12.49,2.961" fill="#FAA072"/>
                                <path id="Trazado_3391" data-name="Trazado 3391" d="M1573.137,260.173h-9.1V192.162l-31.479,68.011h-3.176l-32.056-69.021v69.021h-2.887V184.942h10.252l29.6,64.256,29.746-63.967v-.289h9.1Z" fill="#FAA072"/>
                                <path id="Trazado_3392" data-name="Trazado 3392" d="M1170.9,238.033c-12.486,8.322-18.44,17.851-18.44,29.2,0,13.284,13.677,22.019,45.731,29.209l3.186.716-3.118.966c-42.631,13.205-54.9,37.186-52.174,55.223,3.294,21.76,29.537,45.181,79.373,45.181,55.153,0,88.081-23.041,88.081-61.634,0-32.28-12.918-47.181-47.659-54.974-7.52-1.612-17.262-3.087-26.686-4.513-12.952-1.959-26.345-3.986-34.865-6.477-16.929-4.854-27.309-8.321-27.309-18.01A17.448,17.448,0,0,1,1180,243.8c.514.278,1.037.545,1.559.812,1.5.769,3.015,1.509,4.576,2.189a76.051,76.051,0,0,0,59.593,0A78.141,78.141,0,0,0,1286.3,145.5a76.253,76.253,0,0,0-16.3-24.443,78.689,78.689,0,0,0-24.279-16.435,76.037,76.037,0,0,0-59.592,0,78.54,78.54,0,0,0-24.279,16.435,76.109,76.109,0,0,0-16.3,24.443,78.077,78.077,0,0,0,16.29,84.712,75.706,75.706,0,0,0,7.592,6.715q.726.563,1.467,1.106m38.4,61.305.259-.1.265.07c5.795,1.525,12.667,2.748,19.943,4.042,28.968,5.154,65.02,11.568,65.02,43.558,0,35.026-37.7,42.379-69.327,42.379-29.983,0-51.882-14.078-55.793-35.867-3.3-18.38,7.676-42.233,39.633-54.087m-21.169-64.031-5.313-4.1,0-.008c-.424-.373-.835-.76-1.246-1.147a51.668,51.668,0,0,1-6.654-7.575,66.065,66.065,0,0,1-10.011-21.728,98.527,98.527,0,0,1-3.272-25.181,96.94,96.94,0,0,1,3.27-25.031,68.005,68.005,0,0,1,10.019-21.741,51.449,51.449,0,0,1,16.949-15.267c6.753-3.79,14.893-5.711,24.195-5.711s17.436,1.921,24.192,5.711a50.07,50.07,0,0,1,16.794,15.252,69.979,69.979,0,0,1,9.885,21.756,97.051,97.051,0,0,1,3.272,25.031,98.682,98.682,0,0,1-3.274,25.181,67.984,67.984,0,0,1-9.874,21.742,50.509,50.509,0,0,1-16.8,15.115c-6.746,3.792-14.886,5.714-24.192,5.714s-17.452-1.922-24.195-5.714c-.8-.448-1.582-.918-2.352-1.4-.46-.289-.9-.6-1.352-.9l-.042,0" fill="#FFFFFF"/>
                                <path id="Trazado_3393" data-name="Trazado 3393" d="M1307.469,255.211a7.738,7.738,0,1,1-7.738-7.738,7.738,7.738,0,0,1,7.738,7.738" fill="#FAA072"/>
                                <path id="Trazado_3394" data-name="Trazado 3394" d="M534.151,242.464V69.842h78.5V61.211h-78.5V0H506V61.211H454.192v8.631H506V242.464h.04L506,255.873c.791,24.12,21.355,32.82,39.94,33.611,18.982.791,41.52-8.7,42.707-18.585.4-1.977,1.187-4.351-.792-5.536-1.185-.791-3.162-1.187-4.348.4-6.327,9.094-21.054,11.531-28.973,11.531-18.066,0-18.719-8.924-20.458-30.908v-3.918Z" fill="#FFFFFF"/>
                            </g>
                        </svg>
                        <p class="font-color-white">Fajas Colombianas Wholesaler</p>
                    </a>
                    <div class="content-customer-insigne justify-content-center agent-image">
                        <img v-if="getterAgent?.photo" class="profile-image rounded-circle" :alt="``" :src="getterAgent?.photo" width="150" height="150"/>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 22 22">
                            <g id="Grupo_4" data-name="Grupo 4" transform="translate(215.304 -267.035)" fill="#303030">
                                <path id="Trazado_5" data-name="Trazado 5" d="M-192.652,279.259c-.085.53-.136,1.065-.256,1.589a11.3,11.3,0,0,1-2.628,5.089c-.315.353-.66.673-1,1a.236.236,0,0,1-.375.018,2.3,2.3,0,0,0-.491-.3c-1.108-.553-2.209-1.118-3.324-1.656a1.645,1.645,0,0,1-1.008-1.611,2.69,2.69,0,0,1,.551-1.941,5.111,5.111,0,0,0,.862-1.758.438.438,0,0,1,.235-.271,1.056,1.056,0,0,0,.628-1.008c-.007-.5-.006-1,0-1.5a.973.973,0,0,0-.243-.691.473.473,0,0,1-.121-.342c0-.685,0-1.371,0-2.056a2.854,2.854,0,0,0-2.1-3.019,6.408,6.408,0,0,0-4.274.064,2.83,2.83,0,0,0-1.93,2.851c.005.707,0,1.415.006,2.122a.532.532,0,0,1-.141.4.835.835,0,0,0-.221.587c0,.56,0,1.12,0,1.68a.94.94,0,0,0,.341.717.769.769,0,0,1,.231.39,7.292,7.292,0,0,0,1.185,2.429.544.544,0,0,1,.111.348c0,.383-.006.767,0,1.149a1.349,1.349,0,0,1-.738,1.288c-.923.509-1.848,1.011-2.772,1.517a6.661,6.661,0,0,0-1,.6c-.116.093-.193.058-.286-.025a12.06,12.06,0,0,1-1.829-2.032,10.93,10.93,0,0,1-2-5.274,11.085,11.085,0,0,1,2.528-8.445,10.869,10.869,0,0,1,6.436-3.886,11.016,11.016,0,0,1,9.51,2.359,11.1,11.1,0,0,1,4.017,7.13c.038.239.064.48.1.72Z" transform="translate(0)" fill="#FFFFFF"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" class="col-body mb-4">
                <b-row class="justify-content-center">
                    <b-col cols="12">
                        <h2 class="text-center font-color-primary">{{ getterAgent?.name }}</h2>
                        <h3 class="text-center font-color-primary">SALES EXECUTIVE</h3>
                    </b-col>
                    <b-col v-if="getterAgent?.phone" cols="6" class="text-center my-2">
                        <a target="_blank" :href="`https://api.whatsapp.com/send?phone=${ getterAgent?.phone }`" :title="`We are on WhatsApp`">
                            <i class="fa-brands fa-whatsapp d-flex align-items-center justify-content-center"></i>
                        </a>
                        <span class="d-block mt-2 font-weight-bold">Whatsapp</span>
                    </b-col>
                    <b-col v-if="getterAgent?.phone" cols="6" class="text-center my-2">
                        <a target="_blank" :href="`tel:+${ getterAgent?.phone }`" :title="`Call us`">
                            <i class="fa-solid fa-phone d-flex align-items-center justify-content-center"></i>
                        </a>
                        <span class="d-block mt-2 font-weight-bold">Calls</span>
                    </b-col>
                    <b-col v-if="getSMSPhone" cols="6" class="text-center my-2">
                        <a target="_blank" :href="`sms:+${ getSMSPhone.link }`" :title="`Send your messages`">
                            <i class="fa-solid fa-envelope d-flex align-items-center justify-content-center"></i>
                        </a>
                        <span class="d-block mt-2 font-weight-bold">SMS</span>
                    </b-col>
                    <b-col v-if="getterAgent?.email" cols="6" class="text-center my-2">
                        <a target="_blank" :href="`mailto:${ getterAgent?.email }`" :title="`Send your messages`">
                            <i class="fa-solid fa-envelope d-flex align-items-center justify-content-center"></i>
                        </a>
                        <span class="d-block mt-2 font-weight-bold">Email</span>
                    </b-col>
                    <b-col v-if="getMainLocation" cols="6" class="text-center my-2">
                        <a target="_blank" :href="getMainLocation?.link" :title="`Find us in`">
                            <i class="fa-solid fa-map-location-dot d-flex align-items-center justify-content-center"></i>
                        </a>
                        <span class="d-block mt-2 font-weight-bold">Location</span>
                    </b-col>
                    <b-col cols="6" class="text-center my-2">
                        <a target="_blank" href="/account/register" :title="`Register now`">
                            <i class="fa-solid fa-pencil d-flex align-items-center justify-content-center"></i>
                        </a>
                        <span class="d-block mt-2 font-weight-bold">Register Now</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
		<footer id="footer-only-content" class="m-0">
			<div class="sub-footer">
				<div class="container">
					<div class="row">
						<div class="col-xl-6 col-md-6 col-sm-12">
							<div class="footer-end">
								<p>Copyright <i class="fa-solid fa-copyright"></i> {{ new Date().getFullYear() }} {{ getterCompanyData?.name }}. {{ getterCompanyData?.copyright }}.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
    </b-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    layout: 'only-content',
    components: {
        vcLoading		: () => import('../../components/pages/loading.vue' ),
    },
    data() {
        return {
            title_page: 'Cata1og.com | Sales Executive',
            isLoading: true,
            SMSPhone: null,
            mainLocation: null
        }
    },
    async mounted() {
        
        setTimeout(async () => {
            await this.getAgent({ handle: this.$route.params.handle }).then( (result) =>{

                this.isLoading = false;
                console.log( this.getterCompanyData );
                for (const item_phone of ( this.getterCompanyData?.contact_information.phones || [])) {
                    if( item_phone.sms ){

                        this.SMSPhone = item_phone;
                    }
                }
                for (const item_location of ( this.getterCompanyData?.contact_information.locations || [])) {
                    if( item_location.main ){

                        this.mainLocation = item_location;
                    }
                }
                this.title_page = `Cata1og.com | Sales Executive - ${ this.getterAgent?.name }`;
            });
        }, 500);
    },
    computed:{
        ...mapGetters({
            getterCompanyData: 'getterCompanyData',
            getterAgent: 'getterAgent'
        }),
        getSMSPhone(){
            console.log( this.SMSPhone );
            return this.SMSPhone;
        },
        getMainLocation(){
            console.log( this.mainLocation );
            return this.mainLocation;
        },
        getLoading(){
            return this.isLoading;
        },
        getTitlePage(){
            return this.title_page;
        }
    },
    methods: {
        ...mapActions({
            getAgent: 'getAgent'
        }),
    },
	head() {
		return {
			title: this.getTitlePage
		}
	}
}
</script>