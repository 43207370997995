<template>
	<section class="p-0">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="error-section">
						<h2>400</h2>
						<h3 v-if="typeErr == 'moa'">The total amount of the order does not meet the condition of the minimum amount required</h3>
						<h3 v-if="typeErr == 'balance'">The balance is not enough and/or you have overdue days</h3>
						<h3 v-if="typeErr == 'total'">This is strange but I notice that you have no products in your cart</h3>
						<h3 v-else>Something's wrong</h3>
						<nuxt-link :to="{ path: '/'}" class="btn btn-solid shadow-sm">back to home</nuxt-link>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	data(){
		return {
			typeErr: null
		}
	},
	mounted() {
		this.typeErr = this.$route.query.err || null
	},
}
</script>
