<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Faq"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">frecuently ask questions</h2>
		</div>
		<faq></faq>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
		faq: () => import('../../components/account/faq.vue')
	}
}
</script>
