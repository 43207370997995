<template>
    <div v-if="isLoading != null" class="content-loading p-relative">
        <vc-loading :isLoading="isLoading"></vc-loading>
    </div>
    <div v-else-if="![null, 'not-found'].includes( getProcessProduct )" class="container-product">
        <breadcrumbs type="product" :title="getProcessProduct.title" :subItemData="getProcessProduct.collection_data"></breadcrumbs>
        <div v-if="getterLogin && getProcessProduct.discounts.brand != null && getProcessProduct.discounts.brand >= 0" id="top-seleccion-color" :class="`top-seleccion-color ${ showTopSelection ? '' : ' d-none' }`">
            <b-row class="m-0">
                <b-col cols="12">
                    <h4 class="chunk-lines chunk-lines-1">{{ getProcessProduct.title ? getProcessProduct.title : '' }}</h4>
                </b-col>
                <b-col cols="6" class="col-sku-parrent d-flex align-items-center">
                    <h6 class="product-title size-text font-color-dark m-0">{{ getProcessProduct.sku_parent.variant.standard }}</h6>
                </b-col>
                <b-col cols="6" class="col-select-color d-flex align-items-center">
                    <span class="font-color-dark mr-1 mt-1">Color :</span>
                    <ul class="color-variant mt-1" v-if="existColors">
                        <li v-for="(color, colorIndex) in existColors.values" :key="colorIndex">
                            <a @click="changeVariantTable(color)" :class="[color.handle, 'color-default', { 'active': activeColor.name == color.name }]" :title="color.name">
                                <img :src="`${ back_url }${ color.url }`" onerror="this.onerror=null;this.src='';this.alt='';this.style='display:none;';" :alt="color.name" width="20" height="20"/>
                            </a>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </div>
        <div class="container my-4">
            <div class="row">
                <div id="slider-product" class="col-lg-6">
                    <b-carousel id="carousel-product" ref="carousel_product" :interval="0" controls label-next="" label-prev="">
                        <b-carousel-slide v-if="product" :class="`carousel-slide${ slide_item.type == 'video-link' ? ' video-slide' : ' image-slide' }`" v-for="(slide_item, slide_index) in getProcessProduct?.images" :key="slide_index">
                            <template #img>
                                <zoom-on-hover v-if="slide_item.type == 'image'" :imgNormal="slide_item.src_collection.replace('320x320', '800x800')" :imgZoom="slide_item.src" :scale="1" :altText="slide_item.alt"></zoom-on-hover>
                                <div v-else-if="slide_item.type == 'video-link'" class="video-container">
                                    <iframe class="youtube-iframe border-0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" :src="`${ slide_item.src.replace('/watch?v=', '/embed/').split('&')[0] }?modestbranding=true&amp;showinfo=false&amp;controls=false&amp;rel=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fcata1og.com&amp;widgetid=1`"></iframe>
                                </div>
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                    <div class="row">
                        <div class="col-12 slider-nav-images">
                            <b-carousel v-if="!getShowMobileCarousel" id="carousel-nav-product-desktop" :interval="0" controls label-next="" label-prev="">
                                <b-carousel-slide v-if="product" v-for="(group_slide_item, group_slide_index) in getProcessProduct.thumbnails.desktop" :key="group_slide_index">
                                    <template #img>
                                        <b-row class="justify-content-center mt-4">
                                            <b-col cols="4" v-for="(slide_item, slide_index) in group_slide_item" :key="slide_index" @click="slideTo(slide_index + ( group_slide_index * 3 ))">
                                                <div :class="`carousel-slide${ slide_item.type == 'video-link' ? ' video-slide d-flex align-items-center' : '' }`">
                                                    <img v-if="slide_item.type == 'image'" :src="slide_item.src_collection" :id="slide_item.shopify_id" class="img-fluid" :alt="slide_item.alt"/>
                                                    <div v-if="slide_item.type == 'video-link'" class="icon-content">
                                                        <i class="fa-solid fa-circle-play"></i>
                                                    </div>
                                                    <img v-if="slide_item.type == 'video-link'" :src="`https://i.ytimg.com/vi/${ slide_item.src.replace('/watch?v=', '/embed/').split('&')[0].split('/embed/')[1] }/hqdefault.jpg`" :id="slide_item.shopify_id" class="img-fluid" :alt="slide_item.alt"/>
                                                    <img v-if="slide_item.type == 'video-link'" :src="getProcessProduct?.images[0].src_collection" :id="'background-video'" class="img-fluid background-video" :alt="getProcessProduct?.images[0].alt"/>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </template>
                                </b-carousel-slide>
                            </b-carousel>
                            <b-carousel v-else id="carousel-nav-product-mobile" :interval="0" controls label-next="" label-prev="">
                                <b-carousel-slide v-if="product" v-for="(group_slide_item, group_slide_index) in getProcessProduct.thumbnails.mobile" :key="group_slide_index">
                                    <template #img>
                                        <b-row class="justify-content-center mt-4">
                                            <b-col cols="6" v-for="(slide_item, slide_index) in group_slide_item" :key="slide_index" @click="slideTo(slide_index + ( group_slide_index * 2 ))">
                                                <div :class="`carousel-slide${ slide_item.type == 'video-link' ? ' video-slide d-flex align-items-center' : '' }`">
                                                    <img v-if="slide_item.type == 'image'" :src="slide_item.src_collection" :id="slide_item.shopify_id" class="img-fluid" :alt="slide_item.alt"/>
                                                    <div v-if="slide_item.type == 'video-link'" class="icon-content">
                                                        <i class="fa-solid fa-circle-play"></i>
                                                    </div>
                                                    <img v-if="slide_item.type == 'video-link'" :src="`https://i.ytimg.com/vi/${ slide_item.src.replace('/watch?v=', '/embed/').split('&')[0].split('/embed/')[1] }/hqdefault.jpg`" :id="slide_item.shopify_id" class="img-fluid" :alt="slide_item.alt"/>
                                                    <img v-if="slide_item.type == 'video-link'" :src="getProcessProduct?.images[0].src_collection" :id="'background-video'" class="img-fluid background-video" :alt="getProcessProduct?.images[0].alt"/>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </template>
                                </b-carousel-slide>
                            </b-carousel>
                        </div>
                    </div>
                </div>
                <div id="details-product" class="col-lg-6 rtl-text">
                    <div v-if="getterLogin && getProcessProduct.discounts.brand != null && getProcessProduct.discounts.brand >= 0" class="product-right">
                        <div id="product-description" class="product-description pt-2 pb-2">
                            <h1 id="title-product">{{ getProcessProduct.title ? getProcessProduct.title : '' }}</h1>
                            <h6 class="pt-2">Brand: <nuxt-link v-if="getProcessProduct.brand.url" :to="{ path: getProcessProduct.brand.url }"><strong>{{ getProcessProduct.brand.name }}</strong></nuxt-link><strong v-else>{{ getProcessProduct.brand.name }}</strong></h6>
                            <vc-format-price type="collection" :discount_brand="getProcessProduct.discounts.brand" :price="getProcessProduct.price" :discount_price="getProcessProduct.discount_price" :discount_percentage="0" :discount_stock="null" :apply_discount_stock="false"></vc-format-price>
                            <div v-if="getProcessProduct.discounts.variant_stock" class="message-product message-discount mt-2 mb-2">
                                <p class="title-discount">
                                    <span>special offer</span>
                                </p>
                                <div class="content-message-product">
                                    <i class="fa-solid fa-tag d-flex align-items-center justify-content-start"></i>
                                    <div class="text-message-product">
                                        <p>Highlighted sizes have an additional <strong>{{ getDiscountStock.value }}%</strong> discount on the wholesale price when you purchase <strong>{{ getDiscountStock.min_stock }}</strong> or more units. The discount will be applied at checkout.</p>
                                        <p class="mt-3">For more information click this Collection  
                                            <nuxt-link :to="{ path: '/collections/plus-sizes-promo' }">
                                                <strong>Plus Size</strong>
                                            </nuxt-link>
                                            or 
                                            <nuxt-link :to="{ path: '/account/dashboard' }">
                                                <strong>Contact with your Sales Advisor</strong>
                                            </nuxt-link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="getProcessProduct.sales_limit > 0" class="message-product mt-2 mb-2">
                                <div class="content-message-product">
                                    <i class="fa-solid fa-exclamation-circle d-flex align-items-center justify-content-start"></i>
                                    <div class="text-message-product">
                                        <p><strong>Info</strong>: This product has a <strong>Sales Limit</strong> of <strong>{{ getProcessProduct.sales_limit }}</strong> Units</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="getProcessProduct.moq > 0" class="message-product mt-2 mb-2">
                                <div class="content-message-product">
                                    <i class="fa-solid fa-exclamation-circle d-flex align-items-center justify-content-start"></i>
                                    <div class="text-message-product">
                                        <p><strong>Info</strong>: This product has a <strong>Minimun Order Quantity (MOQ)</strong> of <strong>{{ getProcessProduct.moq }}</strong> Units</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="color-variant" v-if="existColors">
                            <li v-for="(color, colorIndex) in existColors.values" :key="colorIndex">
                                <a @click="changeVariantTable(color)" :class="[color.handle, 'color-default', { 'active': activeColor.name == color.name }]" :title="color.name">
                                    <img :src="`${ back_url }${ color.url }`" onerror="this.onerror=null;this.src='';this.alt='';this.style='display:none;';" :alt="color.name" width="20" height="20"/>
                                </a>
                            </li>
                        </ul>
                        <span v-if="activeColor">Selected color: {{activeColor.name}}</span>
                        <h6 class="product-title size-text">REFERENCE: {{ getProcessProduct.sku_parent.variant.standard }}</h6>
                        <div :class="`product-variants ${ mobileProductVariants ? 'mobile' : 'desktop' }`">
                            <div v-if="!mobileProductVariants" class="m-0 header-variants">
                                <b-col cols="3" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">Price</b-col>
                                <b-col cols="3" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">{{ variantsTableItems.length > 0 ? variantsTableItems[0].options.reduce( (previous_item, current_item) => { if( current_item.name != "Color" ){ previous_item.push( current_item.name ) } return previous_item; }, []).join(" / ") : '' }}</b-col>
                                <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">Stock</b-col>
                                <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">Order Now</b-col>
                                <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">Pre Oder</b-col>
                            </div>
                            <div v-if="!mobileProductVariants" class="body-variants">
                                <b-row v-for="(row_variant, index_variant) in variantsTableItems" v-if="row_variant.show" :key="`ROW-${ row_variant.sku }`" :class="`m-0${ row_variant.discount_stock.value > 0 ? ' selected-discount-stock' : '' }`">
                                    <b-col cols="3" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        <vc-format-price type="product" :discount_brand="getProcessProduct.discounts.brand" :price="row_variant.price" :discount_price="row_variant.discount_price" :discount_percentage="0" :discount_stock="null" :apply_discount_stock="false"></vc-format-price>
                                    </b-col>
                                    <b-col cols="3" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        {{ row_variant.options.reduce( (previous_item, current_item) => { if( current_item.name != "Color" ){ previous_item.push( current_item.value ) } return previous_item; }, []).join(" / ") }}
                                    </b-col>
                                    <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        {{ row_variant.inventory_quantity }}
                                    </b-col>
                                    <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        <input type="number" name="quantity" :id="`${ row_variant.variant_id }_qty`" class="form-control input-number" min=0 :max="row_variant.inventory_quantity" :disabled="row_variant.inventory_quantity == 0" v-model="row_variant.quantity" @change="changeInputValue(row_variant, 'quantity')" @keyup="changeInputValue(row_variant, 'quantity')" @keypress="isNumber($event, row_variant, 'qty')"/>
                                    </b-col>
                                    <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        <input type="number" name="quantity-pre" :id="`${ row_variant.variant_id }_pre`" class="form-control input-number" min=0 :disabled="row_variant.quantity < row_variant.inventory_quantity" v-model="row_variant.preorder" @change="changeInputValue(row_variant, 'preorder')" @keyup="changeInputValue(row_variant, 'preorder')" @keypress="isNumber($event, row_variant, 'pre')"/>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-else class="body-variants">
                                <b-row v-for="(row_variant, index_variant) in variantsTableItems" v-if="row_variant.show" :key="`ROW-${ row_variant.sku }`" :class="`m-0${ row_variant.discount_stock.value > 0 ? ' selected-discount-stock' : '' }`">
                                    <b-row class="m-0 header-variants col-12">
                                        <b-col cols="4" class="text-center font-weight-normal p-2">Price</b-col>
                                        <b-col cols="4" class="text-center font-weight-normal p-2">{{ variantsTableItems.length > 0 ? variantsTableItems[0].options.reduce( (previous_item, current_item) => { if( current_item.name != "Color" ){ previous_item.push( current_item.name ) } return previous_item; }, []).join(" / ") : '' }}</b-col>
                                        <b-col cols="4" class="text-center font-weight-normal p-2">Stock</b-col>
                                    </b-row>
                                    <b-col cols="4" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        <vc-format-price type="product" :discount_brand="getProcessProduct.discounts.brand" :price="row_variant.price" :discount_price="row_variant.discount_price" :discount_percentage="0" :discount_stock="null" :apply_discount_stock="false"></vc-format-price>
                                    </b-col>
                                    <b-col cols="4" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        {{ row_variant.options.reduce( (previous_item, current_item) => { if( current_item.name != "Color" ){ previous_item.push( current_item.value ) } return previous_item; }, []).join(" / ") }}
                                    </b-col>
                                    <b-col cols="4" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        {{ row_variant.inventory_quantity }}
                                    </b-col>
                                    <b-row class="m-0 col-12">
                                        <b-col cols="6" class="text-center font-weight-normal p-2">Order Now</b-col>
                                        <b-col cols="6" class="text-center font-weight-normal p-2">Pre Oder</b-col>
                                    </b-row>
                                    <b-col cols="6" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        <input type="number" name="quantity" :id="`${ row_variant.variant_id }_qty`" class="form-control input-number" min=0 :max="row_variant.inventory_quantity" :disabled="row_variant.inventory_quantity == 0" v-model="row_variant.quantity" @change="changeInputValue(row_variant, 'quantity')" @keyup="changeInputValue(row_variant, 'quantity')" @keypress="isNumber($event, row_variant, 'qty')"/>
                                    </b-col>
                                    <b-col cols="6" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">
                                        <input type="number" name="quantity-pre" :id="`${ row_variant.variant_id }_pre`" class="form-control input-number" min=0 :disabled="row_variant.quantity < row_variant.inventory_quantity" v-model="row_variant.preorder" @change="changeInputValue(row_variant, 'preorder')" @keyup="changeInputValue(row_variant, 'preorder')" @keypress="isNumber($event, row_variant, 'pre')"/>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <b-row>
                            <b-col cols="12">
                                <div class="collapse-block collapse-purchase-summary">
                                    <h3 class="collapse-block-title collapsed" v-b-toggle="'resume-cart'">Purchase Summary</h3>
                                    <b-collapse id="resume-cart" :accordion="'resume-cart'" role="tabpanel" style="display: none;">
                                        <div class="collapse-block-content product-variants">
                                            <b-row v-if="variantsTableItems.filter( (item) => item.quantity > 0 || item.preorder > 0 ).length > 0" class="header-variants m-0">
                                                <b-col cols="4" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">SKU</b-col>
                                                <b-col cols="4" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">Price</b-col>
                                                <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">Order Now</b-col>
                                                <b-col cols="2" class="text-center font-weight-normal p-2 d-flex align-items-center justify-content-center">Preorder</b-col>
                                            </b-row>
                                            <b-row v-for="(row_variant, index_variant) in variantsTableItems" :key="`select-variants-${ index_variant + 1 }`" v-if="row_variant.quantity || row_variant.preorder > 0" :class="`m-0${ row_variant.discount_stock.value > 0 ? ' selected-discount-stock' : '' }`">
                                                <b-col cols="4" class="py-2 text-center">{{ row_variant.sku }}</b-col>
                                                <b-col cols="4" class="py-2 text-center"><vc-format-price type="product" :discount_brand="getProcessProduct.discounts.brand" :price="row_variant.price" :discount_price="row_variant.discount_price" :discount_percentage="0" :discount_stock="null" :apply_discount_stock="false"></vc-format-price></b-col>
                                                <b-col cols="2" class="py-2 text-center">{{ row_variant.quantity }}</b-col>
                                                <b-col cols="2" class="py-2 text-center">{{ row_variant.preorder }}</b-col>
                                            </b-row>
                                        </div>
                                    </b-collapse>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="product-resume mt-3 mb-3">
                            <b-col cols="12" sm="6">
                                <h5>
                                    <strong>Order Now: {{ this.formatCurrency( totalOrder.cart.total, true ).format }}</strong>
                                </h5>
                                <h5>
                                    <strong>Items: {{ totalOrder.cart.items }}</strong>
                                </h5>
                            </b-col>
                            <b-col cols="12" sm="6">
                                <h5>
                                    <strong>Preorder: {{ this.formatCurrency( totalOrder.preorder.total, true ).format }}</strong>
                                </h5>
                                <h5>
                                    <strong>Items: {{ totalOrder.preorder.items }}</strong>
                                </h5>
                            </b-col>
                        </b-row>
                        <div class="product-buttons">
                            <button class="btn btn-solid shadow-sm" title="Add to cart" @click="addProductsToCart()" :disabled="( totalOrder.cart.items + totalOrder.preorder.items ) <= 0">Add To Cart</button>
                        </div>
                    </div>
                    <div v-else-if="getterLogin && getProcessProduct.discounts.brand == null" class="product-right h-100 d-flex align-items-center">
                        <div class="section-info-register-product box-shadow">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="content-text">
                                            <h3>Sorry, this product is <strong class="font-color-light">not available</strong> at the moment, For more information contact <strong class="font-color-light">your sales agent</strong></h3>
                                            <nuxt-link :to="{ path:'/account/dashboard' }" class="btn btn-solid my-2">Contac with your Sales Agent</nuxt-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="!getterLogin" class="product-right h-100 d-flex align-items-center">
                        <div class="section-info-register-product box-shadow">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="content-text">
                                            <h3>Work with the best <strong class="font-color-light">Fajas Colombianas Wholesale</strong> in the US</h3>
                                            <nuxt-link :to="{ path:'/account/register' }" class="btn btn-solid my-2">Start earning with cata1og now</nuxt-link>
                                            <nuxt-link :to="{ path:'/account/login' }" class="btn btn-solid my-2">go to login</nuxt-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section v-if="getProcessProduct.discounts.brand" class="tab-product m-0">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-lg-12">
                        <b-tabs card>
                            <b-tab title="Description" active class="container-product-description">
                                <b-card-text v-html="getProcessProduct.description" v-if="getProcessProduct"></b-card-text>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </section>
        <viewed-products :productId="getProcessProduct.id"></viewed-products>
    </div>
    <div v-else-if="getProcessProduct == 'not-found'" class="container-product align-items-center d-flex h-100 justify-content-center">
        <h3>Sorry, this product is not available at the moment</h3>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatData, calcDiscountPrice, arrayHelpers } from '../../constants/config.js';
export default {
    components: {
        vcLoading		: () => import("../../components/pages/loading.vue"),
        breadcrumbs     : () => import('../../components/pages/breadcrumbs.vue'),
        viewedProducts  : () => import( '../../components/product/viewed-products.vue' ),
        vcFormatPrice   : () => import( '../../components/product/format-price.vue' )
    },
    data() {
        return {
            isLoading: true,
            back_url: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
            product: null,
            existColors: null,
            variantsTableItems: [],
            totalOrder: {
                cart: {
                    items: 0,
                    total: 0
                },
                preorder: {
                    items: 0,
                    total: 0
                }
            },
            activeColor: null,
            mobileProductVariants: !window.innerWidth > 991,
            showTopSelection: false,
            show_mobile_carousel: false,
            discount_stock: {
                value: 0,
                min_stock: 0
            }
        };
    },
    async mounted() {
        window.addEventListener('resize', this.responsiveProduct);
        window.addEventListener('scroll', this.responsiveProduct);
        window.addEventListener('load', this.responsiveProduct);
        
        let request_body = {
            handle: this.$route.params.id
        };
        if( this.$route.params.handleCollection ){
            
            request_body.handle_collection = this.$route.params.handleCollection;
        }
        await this.getProduct(request_body).then( (result_product) => {
            
            let item_product = this.getterProduct;
            this.isLoading = null;
            if( this.getterProduct != "not-found" ){
                
                item_product.thumbnails = {
                    desktop: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( item_product.images ) ), 3 ),
                    mobile: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( item_product.images ) ), 2 )
                }
                this.addProductRecentlyViewd(item_product);
                this.existColors = item_product.options.find( (item)=> item.name == "Color" && item.values.length > 0 );
                this.existColors = this.existColors ? this.existColors : null;
                item_product.variants = item_product.variants.map( (item) => {
                    
                    if( item.discount_stock.value > 0 ){
                        this.discount_stock.value       = item.discount_stock.value;
                        this.discount_stock.min_stock   = item.discount_stock.min_stock;
                    }
                    return {
                        product_id			: item_product.shopify_id,
                        variant_id			: item.shopify_id,
                        sku					: item.sku,
                        options				: item.options,
                        image				: item.image,
                        price				: item.price,
                        discount_price		: item.discount_price,
                        discount_brand    : item_product.discounts.brand,
                        discount_stock      : item.discount_stock,
                        inventory_quantity	: item.inventory_quantity,
                        quantity			: 0,
                        preorder			: 0,
                        show                : false
                    };
                });
                
                this.variantsTableItems = item_product.variants;
                if( this.existColors ){
                    
                    this.activeColor = this.existColors.values[0];
                    setTimeout(() => {
                        
                        this.changeVariantTable(this.activeColor);
                    }, 300);
                }
                else{
                    item_product.variants = item_product.variants.map( (item) => {
                        
                        item.show = true;
                        return item;
                    })
                }
            }
            this.product = item_product;
            
        });
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
            getterProduct: "getterProduct", 
            getterNotificationProductCart: "getterNotificationProductCart",
        }),
        selectVariants(){
            
            return this.variantsTableItems.filter( (item) => item.quantity > 0 && item.preorder > 0 );
        },
        getShowMobileCarousel(){
            return this.show_mobile_carousel;
        },
        getProcessProduct(){
            console.log( this.product );
            return this.product;
        },
        getDiscountStock(){
            return this.discount_stock;
        },
        getApplyDiscountStock(){
            return this.apply_discount_stock;
        }
    },
    methods: {
        ...mapActions({
            getProduct: 'getProduct',
            addNotification: 'addNotification',
            actionCart: 'actionCart'
        }),
        formatCurrency(price, totalAmount = null){
            return formatData.currency(price, totalAmount)
        },
        getDiscountPrice(price, discount, quantity){
            
            return calcDiscountPrice(price, discount, quantity);
        },
        cleanCart(){
            this.totalOrder = {
                cart: {
                    items: 0,
                    total: 0
                },
                preorder: {
                    items: 0,
                    total: 0
                }
            };
            if( this.existColors ){
                
                this.activeColor = this.existColors.values[0];
                this.changeVariantTable( this.activeColor );
            }
            this.variantsTableItems = this.variantsTableItems.map( (item) => {
                
                item.quantity = 0;
                item.preorder = 0;
                
                return item;
            })
        },
        addProductsToCart() {
            
            if( this.product.moq == 0 || ( this.product.moq > 0 && this.variantsTableItems.reduce( (previous_item, current_item) => { previous_item += current_item.quantity; return previous_item; }, 0) >= this.product.moq ) ){
                
                this.actionCart({ action: 'add', products: this.variantsTableItems.filter( (item) => { return item.quantity + item.preorder > 0; }) } ).then( (result_cart) => {
                    
                    this.variantsTableItems = this.variantsTableItems.map( (item) => { item.quantity = 0; item.preorder = 0; return item; });
                    this.generateNotificationsProduct();
                });
            }
            else{
                
                this.addNotification({ 
                    content: { type: 'request' }, 
                    type: 'warning', 
                    data:{
                        title: `Product MOQ`, 
                        message: `This product has a Minimum Order Quantity (MOQ) of ${ this.product.moq } units` 
                    }
                });
            }
        },
        createNotificationGroup(status, type, message = '') {
            return {
                id      : status,
                products: this.getterNotificationProductCart.filter(item => item.status === status),
                type    : type,
                message : message
            }
        },
        generateNotificationsProduct(){
            
            let notification_groups = [
                this.createNotificationGroup('full-add', 'success'),
                this.createNotificationGroup('partial-add', 'warning', "These References were partially added, because they exceed the available stock"),
                this.createNotificationGroup('max-stock', 'error', "This products has its maximum stock in the cart")
            ];
            
            for (const item_notification of notification_groups) {
                
                if( item_notification.products.length > 0 ) {
                    
                    setTimeout(() => {
                        this.addNotification({ 
                            content : { 
                                type    : 'add-cart-product', 
                                image   : item_notification.products[0].image, 
                                brand   : item_notification.products[0].brand,
                                status  : item_notification.products[0].type, 
                                products: item_notification.products,
                            }, 
                            type    : item_notification.type, 
                            data    :{
                                title   : item_notification.id != 'max-stock' ? `Add to Cart Product` : 'Failed Add to Cart', 
                                message : item_notification.id == 'full-add' ? `${ item_notification.total_products } items successfully added` : item_notification.message
                            }
                        });
                    }, 500);
                }
            }
            this.cleanCart();
        },
        slideTo(index) {
            this.$refs["carousel_product"].setSlide(index);
        },
        changeVariantTable(selectColor) {
            
            this.activeColor = selectColor;
            this.variantsTableItems = this.variantsTableItems.map( (item_v) => {
                
                item_v.show = item_v.options.findIndex( (item) => item.name == "Color" && item.value == this.activeColor.name ) >= 0;
                return item_v;
            });
            let first_variant = this.product.variants.find( (item) => item.options.find( (item_o) => item_o.name == "Color" && item_o.value == this.activeColor.name ) );
            
            if( first_variant?.image ){
                
                let index_image = this.product.images.findIndex( (item) => item.shopify_id == first_variant.image.shopify_id );
                
                this.slideTo( index_image );
            }
        },
        isNumber(event, variant_item, type){
            
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57) || parseInt(document.getElementById(`${ variant_item.variant_id }_${ type }`).value) == 0 && parseInt(event.key) == 0 ) {
                
                event.preventDefault();
            }
            else if( parseInt(document.getElementById(`${ variant_item.variant_id }_${ type }`).value) == 0 && parseInt(event.key) > 0 ){
                
                document.getElementById(`${ variant_item.variant_id }_${ type }`).value = null;
            }
        },
        validNumber(number){
            return ( isNaN( parseInt(number) ) ? 0 : parseInt(number) );
        },
        changeInputValue(item_product, type_field) {
            
            if( type_field == 'quantity' ){
                
                item_product.quantity = ( this.product.sales_limit == 0 || ( this.product.sales_limit > 0 && item_product.quantity <= this.product.sales_limit ) ) ? item_product.quantity : this.product.sales_limit;
                
                if( item_product.quantity <= item_product.inventory_quantity ){
                    
                    document.getElementById(`${ item_product.variant_id }_qty`).value = this.validNumber(item_product.quantity);
                }
                else{
                    
                    document.getElementById(`${ item_product.variant_id }_qty`).value = this.validNumber(item_product.inventory_quantity);
                }
            }
            item_product[type_field] = this.validNumber( document.getElementById(`${ item_product.variant_id }_${ type_field == 'quantity' ? 'qty' : 'pre' }`).value );
            
            if( type_field == 'quantity' ){
                
                this.variantsTableItems[this.variantsTableItems.findIndex( (item_v) => item_v.sku == item_product.sku )].quantity = item_product.quantity;
            }
            this.totalOrder = this.variantsTableItems.reduce( (previous_item, current_item) => {
                
                previous_item.cart.items += this.validNumber(current_item.quantity);
                previous_item.cart.total += ( this.formatCurrency( this.getDiscountPrice( current_item.price, current_item.discount_brand, this.validNumber(current_item.quantity) ) * this.validNumber(current_item.quantity), false ).number );
                
                previous_item.preorder.items += this.validNumber(current_item.preorder);
                previous_item.preorder.total += ( this.formatCurrency( this.getDiscountPrice( current_item.price, current_item.discount_brand, this.validNumber(current_item.preorder) ) * this.validNumber(current_item.preorder), false ).number );
                
                return previous_item;
            }, { cart: { items: 0, total: 0 }, preorder: { items: 0, total: 0 } });
            
            if( this.validNumber( item_product.preorder ) + this.validNumber( item_product.quantity ) > 0 ) {
                
                item_product[type_field] = this.validNumber( item_product[type_field] );
            } 
        },
        addProductRecentlyViewd(item_product) {
            
            if(localStorage.getItem('recentlyProd')) {
                
                let recently_view_products = JSON.parse( localStorage.getItem('recentlyProd') );
                
                if( recently_view_products.find((item_r)=> item_r.id == item_product.id) ) {
                    
                    recently_view_products.push(item_product);
                    localStorage.setItem('recentlyProd', JSON.stringify(recently_view_products));
                }
            } 
            else {
                
                localStorage.setItem('recentlyProd', JSON.stringify([item_product]));
            }
        },
        responsiveProduct(){
            
            this.show_mobile_carousel = window.innerWidth <= 1200;
            
            this.mobileProductVariants = !window.innerWidth > 991;
            
            let section_breadcrumb      = document.getElementById('breadcrumb-section') ? document.getElementById('breadcrumb-section').offsetHeight : 0;
            let section_slider          = document.getElementById('slider-product') ? document.getElementById('slider-product').offsetHeight : 0;
            let section_description     = document.getElementById('product-description') ? document.getElementById('product-description').offsetHeight : 0;
            let section_top_selection   = document.getElementById('top-seleccion-color') ? document.getElementById('top-seleccion-color').offsetHeight : 0;
            let section_header          = document.getElementById('header-default') ? document.getElementById('header-default').offsetHeight : 0;
            
            let calc_top_selection = ( section_breadcrumb + section_slider + section_description - section_top_selection - section_header );
            
            if( window.top.scrollY >= calc_top_selection && calc_top_selection > 0 ){
                
                this.showTopSelection = true;
            }
            else{
                
                this.showTopSelection = false;
            }
        }
    }
};
</script>
