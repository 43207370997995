<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="catalogs"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">Catalogs</h2>
		</div>
		<b-container>
			<b-row class="row partition-collection">
				<b-col cols="6" lg="3" v-for="(item, index) in getterCatalogsElement" :key="index">
					<div class="collection-block mb-4">
						<a :href="item.background_link" target="_blank">
							<img class="img-fluid" :src="`${ back_url }${ item.image.url }`" :alt="item.image.alt" v-b-tooltip.hover :title="item.image.alt" onerror="this.onerror=null;this.src=`${ back_url }/images/products/product-default_320.webp`;" width="250" height="300"/>
						</a>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	},
	data() {
		return {
			back_url: process.env.VUE_APP_CATALOG_BACK_URL_FILE
		}
	},
	async mounted(){
		await this.getCatalogsElment();
	},
	computed: {
		...mapGetters({
			getterCatalogsElement: 'getterCatalogsElement'
		}),
	},
	methods:{
		...mapActions({
			getCatalogsElment: 'getCatalogsElment'
		})
	}
}
</script>

