<template>
    <div class="section-b-space pt-0 page-content p-relative">
        <breadcrumbs type="dashboard" title="Your Orders"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Your Orders</h2>
        </div>
        <b-container class="p-4 mt-2 box-shadow">
            <basic-table type_table="orders" :table_fields="table_fields" @openSideBar="showModal"></basic-table>
        </b-container>
        <b-modal id="orderDetails" ref="orderDetails" :title="`Order Details: ${ dataOrder != null ? dataOrder.name : '' }`" modal-class="p-0 modal-right modal-order-details">
            <b-form v-if="dataOrder != null">
                <b-row>
                    <b-col cols="6">
                        <b-row v-if="dataOrder.fulfillment_status" class="m-0">
                            <b-col cols="6" class="p-0"><strong>Fulfillment:</strong></b-col>
                            <b-col cols="6" class="p-0"><b-badge v-if="dataOrder.fulfillment_status" class="input-document text-capitalize">{{ ' ' + dataOrder.fulfillment_status }}</b-badge></b-col>
                        </b-row>
                        <b-row v-if="dataOrder.financial_status" class="m-0">
                            <b-col cols="6" class="p-0"><strong>Financial:</strong></b-col>
                            <b-col cols="6" class="p-0"><b-badge v-if="dataOrder.financial_status" class="input-document text-capitalize">{{ ' ' + dataOrder.financial_status }}</b-badge></b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6">
                        <b-row class="m-0">
                            <b-col cols="7" class="p-0"><strong>Subtotal:</strong></b-col>
                            <b-col cols="5" class="p-0 d-flex justify-content-end">{{ formatCurrency( dataOrder.total_line_items_price ).format }}</b-col>
                        </b-row>
                        <b-row class="m-0">
                            <b-col cols="7" class="p-0"><strong>Discounts:</strong></b-col>
                            <b-col cols="5" class="p-0 red d-flex justify-content-end">-{{ formatCurrency( dataOrder.total_discounts ).format }}</b-col>
                        </b-row>
                        <b-row class="m-0">
                            <b-col cols="7" class="p-0"><strong>Taxes:</strong></b-col>
                            <b-col cols="5" class="p-0 d-flex justify-content-end">{{ formatCurrency( dataOrder.total_tax ).format }}</b-col>
                        </b-row>
                        <b-row class="m-0">
                            <b-col cols="7" class="p-0"><strong>Shipping:</strong></b-col>
                            <b-col cols="5" class="p-0 d-flex justify-content-end">{{ formatCurrency( dataOrder.total_shipping_price ).format }}</b-col>
                        </b-row>
                        <b-row class="m-0">
                            <b-col cols="7" class="p-0"><strong>Total:</strong></b-col>
                            <b-col cols="5" class="p-0 d-flex justify-content-end font-weight-normal">{{ formatCurrency( dataOrder.total_price ).format }}</b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Customer" label-align-sm="top" label-size="sm" label-class="border-b font-weight-normal" class="mb-0">
                            <b-row class="mt-3 m-0">
                                <b-col cols="6" class="pl-0">
                                    <b-form-group v-if="dataOrder.customer.first_name" label="First Name:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.customer.first_name }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6" class="pl-0">
                                    <b-form-group v-if="dataOrder.customer.last_name" label="Last Name:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.customer.last_name }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6" class="pl-0">
                                    <b-form-group v-if="dataOrder.customer.email" label="Email:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.customer.email }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6" class="pl-0">
                                    <b-form-group v-if="dataOrder.customer.phone" label="Phone:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.customer.phone }}</p>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group label="Shipping Address" label-align-sm="top" label-size="sm" label-class="border-b font-weight-normal" class="mb-0">
                            <b-row class="mt-3 m-0">
                                <b-col class="col-6 col-md-3 pl-0">
                                    <b-form-group v-if="dataOrder.shipping_address.country" label="Country:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.shipping_address.country }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col class="col-6 col-md-3 pr-0">
                                    <b-form-group v-if="dataOrder.shipping_address.state" label="State:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.shipping_address.state }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col class="col-6 col-md-3 pl-0">
                                    <b-form-group v-if="dataOrder.shipping_address.city" label="City:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.shipping_address.city }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col class="col-6 col-md-3 pr-0">
                                    <b-form-group v-if="dataOrder.shipping_address.zip" label="Zip:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.shipping_address.zip }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" class="p-0">
                                    <b-form-group v-if="dataOrder.shipping_address.address_1" label="Address 1:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.shipping_address.address_1 }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" class="p-0">
                                    <b-form-group v-if="dataOrder.shipping_address.address_2" label="Address 2:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.shipping_address.address_2 }}</p>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" class="p-0">
                                    <b-form-group label="Shipping Type:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                        <p class="form-control form-control-sm m-0 border-0 pl-0 h-auto">{{ dataOrder.shipping_lines[0].title }}</p>
                                        <b-form-group label="Tracking:" label-align="top" label-size="sm" label-class="font-weight-normal p-0" class="mb-2">
                                            <p v-if="dataOrder.fulfillments.length > 0" class="form-control form-control-sm m-0 border-0 pl-0 h-auto d-flex">{{ dataOrder.fulfillments[0].tracking_company }}: <i class="fa-solid fa-truck ml-2 mr-2"></i>
                                                <a v-b-tooltip.hover :title="`${ dataOrder.fulfillments[0].tracking_company ? dataOrder.fulfillments[0].tracking_company : '' } Tracking Number: ${ dataOrder.fulfillments[0].tracking_number }`" target="_blank" :href="dataOrder.fulfillments[0].tracking_url">{{ dataOrder.fulfillments[0].tracking_number }}</a>
                                            </p>
                                            <p v-else class="form-control form-control-sm m-0 border-0 pl-0 h-auto">We are preparing your package, soon you will have more information</p>
                                        </b-form-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group v-if="dataOrder.note" label="Note" label-align-sm="top" label-size="sm" label-class="border-b font-weight-normal" class="mb-0">
                            <p class="form-control m-0 border-0 h-auto">{{ dataOrder.note }}</p>
                        </b-form-group> 
                    </b-col>
                    <b-col cols="12">
                        <b-table-simple hover small caption-top responsive class="table-line-items">
                            <b-thead>
                                <b-tr>
                                    <b-th>SKU</b-th>
                                    <b-th>Qty</b-th>
                                    <b-th>Price</b-th>
                                    <b-th>Subtotal</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="item in dataOrder.line_items" :key="item.id">
                                    <b-td>{{ item.sku }}</b-td>
                                    <b-td class="text-center">{{ item.quantity }}</b-td>
                                    <b-td class="text-center">{{ formatCurrency( item.price - ( item.total_discount / item.quantity ) ).format }}</b-td>
                                    <b-td class="text-center">{{ formatCurrency(( item.quantity * ( item.price ) ) - item.total_discount).format }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </b-form>
            <template v-if="dataOrder != null" slot="modal-footer">
                <button class="btn btn-solid-second shadow-sm" @click="hideModal" >close</button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatData } from "../../../constants/config.js";
import moment from "moment";
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue"),
        basicTable  : () => import("../../../components/pages/basic-table.vue")
    },
    data() {
        return {
            dataOrder: null,
            table_fields: [
            { key: 'created_at', label: 'Date', class: "w-15", formatter: (value, key, item) => { 
                return moment(value.toString().split(".")[0]).format('lll'); 
            } },
            { key: 'name', label: 'Order', class: "w-15" },
            { key: 'items', label: 'Items', class: "w-15 d-md-table-cell d-none", formatter: (value, key, item) => { 
                return item.line_items.reduce( (previous_item, current_item) => { previous_item += parseInt( current_item.quantity ); return previous_item; }, 0); 
            }, sortByFormatted: true, filterByFormatted: true },
            { key: 'total_price', label: 'Total', class: "w-15 d-md-table-cell d-none", formatter: (value, key, item) => { 
                return this.formatCurrency(value).format; 
            } },
            { key: 'financial_status', label: 'Financial Status', class: "w-15 d-none d-sm-table-cell" },
            { key: 'fulfillments', label: 'Fulfillment', class: "w-15 d-none d-sm-table-cell" },
            { key: 'actions', label: 'Actions', class: "w-10 hide-title" }
            ],
        };
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
        })
    },
    async mounted(){
        if( !this.getterLogin ){
            
            this.$router.push('/account/login');
        }
    },
    methods: {
        formatCurrency(value){
            return formatData.currency(value);
        },
        showModal(data) {
            this.dataOrder = data;
            this.$refs["orderDetails"].show();
        },
        hideModal() {
            this.$refs['orderDetails'].hide();
        },
    }
}
</script>