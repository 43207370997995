<template>
    <div class="container-cart pb-4">
        <vc-loading v-if="loadingTable != null" :isLoading="loadingTable"/>
        <b-row v-if="tableProducts && tableProducts.length > 0 && ( product_origins == 'cart' || product_origins == 'cart_later' )" class="pb-4">
            <b-col v-if="isLoadingMsg != null" cols="12" class="content-loading loading-cart">
                <vc-loading v-if="isLoadingMsg != null" :isLoading="isLoadingMsg"/>
            </b-col>
            <b-col v-if="showMessage" cols="12" class="message-cart">
                <div class="content-message-cart">
                    <i class="fa-solid fa-exclamation-circle d-flex align-items-center justify-content-start"></i>
                    <div class="text-message-cart">
                        <p v-for="(msg,index) in message" :key="index" v-html="msg"></p>
                        <p v-if="message?.length > 0">For more information check your 
                            <nuxt-link :to="{ path: '/account/dashboard' }">
                                <strong>Customer Statement or contact with your Sales Advisor</strong>
                            </nuxt-link>
                        </p>
                    </div>
                </div>
            </b-col>
            <b-col v-if="isLoadingMsg == null && getDiscountStock.quantity > 0" cols="12" class="message-cart message-discount">
                <p class="title-discount">
                    <span>special offer</span>
                </p>
                <div class="content-message-cart">
                    <i class="fa-solid fa-tag d-flex align-items-center justify-content-start"></i>
                    <div class="text-message-cart">
                        <p v-if="getDiscountStock.min_stock > 0">Take advantage of our special offers on <strong>selected references</strong>, when purchasing <strong>{{ getDiscountStock.min_stock }} units or more </strong> you get a <strong>{{ getDiscountStock.value }}%</strong> discount on the wholesale price.</p>
                        <p v-else>Take advantage of our special offers on <strong>selected references</strong> <strong>{{ getDiscountStock.value }}%</strong> discount on the wholesale price.</p>
                        <p v-if="getDiscountStock.quantity - getDiscountStock.min_stock < 0" class="text-center mt-3 add-more"><strong>Add {{ getDiscountStock.min_stock - getDiscountStock.quantity }} more units to get the special offer</strong></p>
                        <p class="mt-3">For more information click this Collection  
                            <nuxt-link :to="{ path: '/collections/plus-sizes-promo' }">
                                <strong>Plus Size</strong>
                            </nuxt-link>
                            or 
                            <nuxt-link :to="{ path: '/account/dashboard' }">
                                <strong>Contact with your Sales Advisor</strong>
                            </nuxt-link>
                        </p>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="p-0">
                <table v-if="tableProducts && tableProducts.length > 0" ref="cart-table-products" class="table cart-table table-responsive-xs">
                    <thead>
                        <tr v-if="!hideCellMobile && ['buy_again', 'preorder'].includes(product_origins)" class="table-top-head">
                            <th scope="col" colspan="3">
                                <b-form-group class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input id="filter-input" v-model="filter_products" placeholder="Type to Search in Table" @change="filterProducts"  @keyup="filterProducts"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter_products" @click="filter_products = ''; filterProducts();">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </th>
                        </tr>
                        <tr class="table-top-head">
                            <th scope="col" class="cell-head-actions" colspan="1">
                                <div v-if="['buy_again', 'preorder'].includes(product_origins) && tableProducts.length > 0" class="list-actions">
                                    <a class="action-link" @click="addToCartSelected()" v-b-tooltip.hover title="Add to Cart">
                                        <i class="fa-solid fa-cart-shopping"></i>
                                        <span class="cart-plus">+</span>
                                    </a>
                                </div>
                                <div v-if="['cart'].includes(product_origins) && tableProducts.length > 0" class="list-actions">
                                    <a class="action-link" @click="saveForLaterSelected()" v-b-tooltip.hover title="Save for Later">
                                        <i class="fa-solid fa-bookmark"></i>
                                    </a>
                                    <a class="action-link" @click="deleteItemsSelected()" v-b-tooltip.hover title="Delete Product">
                                        <i class="fa-solid fa-trash"></i>
                                    </a>
                                </div>
                                <div v-if="['cart_later'].includes(product_origins) && tableProducts.length > 0" class="list-actions">
                                    <a class="action-link" @click="removeSaveLaterSelected()" v-b-tooltip.hover title="Add to Cart">
                                        <i class="fa-solid fa-cart-shopping"></i>
                                        <span class="cart-plus">+</span>
                                    </a>
                                    <a class="action-link" @click="deleteItemsCartLater()" v-b-tooltip.hover title="Delete Product">
                                        <i class="fa-solid fa-trash"></i>
                                    </a>
                                </div>
                            </th>
                            <th v-if="hideCellMobile" scope="col" colspan="3">
                                <b-form-group v-if="['buy_again', 'preorder'].includes(product_origins)" class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input id="filter-input" v-model="filter_products" placeholder="Type to Search in Table" @change="filterProducts"  @keyup="filterProducts"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter_products" @click="filter_products = ''; filterProducts();">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </th>
                            <th v-if="['cart', 'cart_later'].includes(product_origins)" scope="col" colspan="2">
                                <div class="form-inline push-right list-actions">
                                    <a id="download_file" href="" class="d-none"></a>
                                    <a v-if="product_origins == 'cart'" class="btn btn-solid btn-file action-link shadow-sm" @click="showModal('modalFile')" v-b-tooltip.hover title="Upload cart">
                                        <i class="fa-solid fa-file-arrow-up"></i>
                                    </a>
                                    <a v-if="getterUser != null && tableProducts.length > 0" id="btn-download-file" class="btn btn-solid btn-file action-link shadow-sm" @click="downloadFile" v-b-tooltip.hover title="Download cart">
                                        <i class="fa-solid fa-file-arrow-down"></i>
                                    </a>
                                    <b-modal id="modalFile" ref="modalFile" title="Load Cart File" modal-class="modal-file">
                                        <b-form class="register-form">
                                            <b-form-group>
                                                <span>Please download the <a href="" @click="downloadTemplate" variant="link">template file (by clicking here)</a> where you can enter the SKUs and Quantities you want to order, then you can upload that file and finally click on load to cart</span>
                                                <b-form-file id="cart_file" ref="cart_file" name="cart_file" class="mt-4 mb-4" :placeholder="'Please Select a valid excel file (.xls, .xlsx)'" drop-placeholder="Drop file here..." browse-text="Browse" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
                                                <span><strong>WARNING:</strong> Each SKU is compared with the current stock of the store, the sku that are not available in the store or their stock is 0 will not be charged, the quantity can also be modified depending on the available stock</span>
                                            </b-form-group>
                                        </b-form>
                                        <template slot="modal-footer">
                                            <b-button type="button" class="btn btn-solid btn-alter shadow-sm" @click="hideModal('modalFile')">close</b-button>
                                            <b-button type="button" class="btn btn-solid file shadow-sm" @click="addNewDoc">Upload</b-button>
                                        </template>
                                    </b-modal>
                                </div>
                            </th>
                            <th v-if="['buy_again', 'preorder'].includes(product_origins)" scope="col" colspan="2" class="total-table text-right">
                                <span class="d-inline-flex mr-1">Total Products: </span>
                                <span class="d-inline-flex">{{ formatCurrency(getDetailsCart.total_cart, true).format }}</span>
                            </th>
                        </tr>
                        <tr class="table-head">
                            <th scope="col" class="w-15">
                                <b-form-checkbox v-if="product_origins != 'preorder' || ( product_origins == 'preorder' && tableProducts.some( (item) => parseInt(item.inventory_quantity) >= parseInt(item.quantity) ) )" class="select-all-products" name="select_product" id="select-all" v-model="allSelected" @change="selectAll">
                                    <svg class="hover-icon" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 26 26">
                                        <g id="Grupo_79" data-name="Grupo 79" transform="translate(1)" >
                                            <path id="Trazado_86" data-name="Trazado 86" d="M-376.953,1724.974h0a1,1,0,0,1-.707-.293l-2.623-2.623a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l1.916,1.916,5.521-5.521a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-6.228,6.228A1,1,0,0,1-376.953,1724.974Z" transform="translate(387.562 -1711.246)" fill="#303030"></path>
                                            <path id="Rectángulo_72" data-name="Rectángulo 72" d="M4,2A2,2,0,0,0,2,4V16.176a2,2,0,0,0,2,2H16.176a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H4M4,0H16.176a4,4,0,0,1,4,4V16.176a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(2.485)" fill="#303030"></path>
                                            <path id="Trazado_89" data-name="Trazado 89" d="M-340.235,1742.154h-11.93a4.7,4.7,0,0,1-3.223-1.5,5.077,5.077,0,0,1-1.245-3.188v-11.655a1,1,0,0,1,1-1,1,1,0,0,1,1,1v11.66a3.168,3.168,0,0,0,.746,1.862,2.7,2.7,0,0,0,1.745.824h11.907a1,1,0,0,1,1,1A1,1,0,0,1-340.235,1742.154Z" transform="translate(355.632 -1718.625)" fill="#303030"></path>
                                        </g>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 26 26">
                                        <g id="Grupo_78" data-name="Grupo 78" transform="translate(1)" >
                                            <path id="Rectángulo_72" data-name="Rectángulo 72" d="M4,2A2,2,0,0,0,2,4V16.176a2,2,0,0,0,2,2H16.176a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H4M4,0H16.176a4,4,0,0,1,4,4V16.176a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(2.485)" fill="#303030"></path>
                                            <path id="Trazado_88" data-name="Trazado 88" d="M-340.235,1742.154h-11.93a4.7,4.7,0,0,1-3.223-1.5,5.077,5.077,0,0,1-1.245-3.188v-11.655a1,1,0,0,1,1-1,1,1,0,0,1,1,1v11.66a3.168,3.168,0,0,0,.746,1.862,2.7,2.7,0,0,0,1.745.824h11.907a1,1,0,0,1,1,1A1,1,0,0,1-340.235,1742.154Z" transform="translate(355.632 -1718.625)" fill="#303030"></path>
                                        </g>
                                    </svg>
                                </b-form-checkbox>
                            </th>
                            <th v-if="!hideCellMobile" scope="col" colspan="2">Products</th>
                            <th v-if="hideCellMobile" scope="col" class="w-10">Image</th>
                            <th v-if="hideCellMobile" scope="col" class="w-25">Product Name</th>
                            <th v-if="hideCellMobile" scope="col" class="w-20">Price</th>
                            <th v-if="hideCellMobile" scope="col" class="w-10">Order</th>
                            <th v-if="hideCellMobile" scope="col" class="w-20">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in tableProducts" :key="index" :class="{ 'hide': !item.show, 'apply-discount-stock': item.all_discounts.stock.value > 0 && getApplyDiscountStock }">
                            <td class="cell-desktop cell-mobile cell-product-select cell-actions-1 pl-2">
                                <b-form-checkbox v-if="product_origins != 'preorder' || ( product_origins == 'preorder' && parseInt(item.inventory_quantity) >= parseInt(item.quantity) )" class="check-product" :id="`check_${ item.variant_id }`" name="select_product" @change="select(item)" :value="{ product_id: item.product_id, variant_id: item.variant_id, quantity: parseInt(item.quantity) }" ></b-form-checkbox>
                                <label v-else><strong>Out of Stock</strong></label>
                            </td>
                            <td v-if="!hideCellMobile" class="cell-mobile cell-product-mobile pr-2" colspan="2">
                                <b-row class="content-mobile-cart-product row">
                                    <b-col cols="4" class="mobile-cart-product-image">	
                                        <nuxt-link :to="{ path: `/products/${ item.handle }` }" >
                                            <img :src="(item.image ? item.image.src : null) || `${ back_url }/images/products/product-default_320.webp`" :alt="item.title_product" />
                                        </nuxt-link>
                                    </b-col>
                                    <b-col cols="8" class="mobile-cart-product-description pr-0">
                                        <div class="mobile-cart-product-name">
                                            <nuxt-link :to="{ path: `/products/${ item.handle }` }" >
                                                <span>{{ item.sku }}</span>
                                                <span>{{ item.brand }}</span>
                                                <span>{{ item.options.map( (item_o) => item_o.value ).join(" / ") }}</span>
                                            </nuxt-link>
                                        </div>
                                        <div class="mobile-product-cart-quantity">
                                            <div class="qty-box">
                                                <div class="input-group">
                                                    <input v-if="product_origins != 'preorder'" type="number" name="quantity" :id="`quantity_${ item.variant_id }`" class="form-control input-number" :max="item.inventory_quantity" :min="0" v-model="item.quantity" @change="changeQuantity('change', item)" @keyup="changeQuantity('keyup', item)" @keypress="isNumber($event, item)"/>
                                                    <label v-else :class="`form-control text-center preorder-quantity ${ item.inventory_quantity >= item.quantity ? 'in-stock' : 'out-stock' }`" :title="`${ item.inventory_quantity >= item.quantity ? 'Available Stock' : 'Out of Stock' }`">{{ item.quantity }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col cols="6" class="mobile-cart-product-price p-0 mt-1">
                                        <div class="content-cart-product-price">
                                            <span class="content-discount-price">
                                                Price: <vc-format-price type="cart" :discount_brand="item.all_discounts.brand" :discount_percentage="item.all_discounts.coupon.percentage" :price="item.price" :discount_price="item.discount_price" :quantity="1" :discount_stock="item.all_discounts.stock" :apply_discount_stock="getApplyDiscountStock"></vc-format-price>
                                            </span>
                                            <span v-if="item.all_discounts.brand >= 0" class="product-discount">
                                                Discount:
                                                <div v-if="item.discount_type == 'percentage'" class="discount-value">
                                                    {{ item.all_discounts.brand }}%
                                                </div>
                                                <div v-else class="discount-value">
                                                    {{ formatCurrency(item.all_discounts.brand).format }}
                                                </div>
                                            </span>
                                        </div>
                                    </b-col>
                                    <b-col cols="6" class="mobile-cart-product-subtotal pr-0 mt-1">
                                        <span class="content-discount-price">Total: 
                                            <vc-format-price type="cart-total" :discount_brand="item.all_discounts.brand" :discount_percentage="item.all_discounts.coupon.percentage" :price="item.price" :discount_price="item.discount_price" :quantity="item.quantity" :discount_stock="item.all_discounts.stock" :apply_discount_stock="getApplyDiscountStock"></vc-format-price>
                                        </span>
                                    </b-col>
                                </b-row>
                            </td>
                            <td v-if="hideCellMobile" class="cell-desktop cell-product-image">
                                <nuxt-link :to="{ path: `/products/${ item.handle }` }" >
                                    <img :src="(item.image ? item.image.src : null) || `${ back_url }/images/products/product-default_320.webp`" :alt="item.title_product" />
                                </nuxt-link>
                            </td>
                            <td v-if="hideCellMobile" class="cell-desktop cell-product-name">
                                <nuxt-link :to="{ path: `/products/${item.handle}` }">
                                    <span>{{ item.brand }}</span>
                                    <small>{{ item.sku }}</small>
                                </nuxt-link>
                                <p>{{ item.options.map( (item_o) => item_o.value ).join(" / ") }}</p>
                            </td>
                            <td v-if="hideCellMobile" class="cell-desktop cell-product-price">
                                <div class="content-cart-product-price">
                                    <vc-format-price type="cart" :discount_brand="item.all_discounts.brand" :discount_percentage="item.all_discounts.coupon.percentage" :price="item.price" :discount_price="item.discount_price" :quantity="['buy_again'].includes(product_origins) && item.quantity == 0 ? 1 : item.quantity" :discount_stock="item.all_discounts.stock" :apply_discount_stock="getApplyDiscountStock"></vc-format-price>
                                    <span v-if="item.all_discounts.total_percentage > 0 || item.all_discounts.total_fixed > 0" class="cart-discount-value box-shadow">
                                        <div v-if="['percentage', 'mixed_amount'].includes(item.discount_type)" :class="`content-discount${ item.all_discounts.coupon.fixed > 0 || item.all_discounts.coupon.percentage > 0 ? ' gift-coupon' : '' }`">
                                            <i v-if="( item.all_discounts.stock.value > 0 && getApplyDiscountStock ) || item.all_discounts.coupon.fixed > 0 || item.all_discounts.coupon.percentage > 0" class="fa-solid fa-gift icon-gift"></i>
                                            <span class="discount-value">{{ item.all_discounts.brand }}%</span>
                                            <span v-if="item.all_discounts.coupon.fixed > 0 || item.all_discounts.coupon.percentage > 0 || ( item.all_discounts.stock.value > 0 && getApplyDiscountStock )" :class="{ 'content-gift-coupon d-flex align-items-center justify-content-between': true, 'coupon-fixed': item.all_discounts.coupon.fixed > 0, 'coupon-percentage': item.all_discounts.coupon.percentage > 0, 'discount-stock': item.all_discounts.stock.value > 0 }">
                                                
                                                <span v-if="item.all_discounts.stock.value > 0 && getApplyDiscountStock" class="gift-text d-inline-block mb-1">
                                                    Additional: -{{ item.all_discounts.stock.value }}%
                                                </span>
                                                <span v-if="item.all_discounts.coupon.fixed > 0" class="gift-text d-inline-block mb-1">
                                                    Coupon: -{{ formatCurrency(item.all_discounts.coupon.fixed).format }}
                                                </span>
                                                <span v-else-if="item.all_discounts.coupon.percentage > 0" class="gift-text d-inline-block mb-1">
                                                    Coupon: -{{ item.all_discounts.coupon.percentage }}%
                                                </span>
                                            </span>
                                        </div>
                                        <div v-else>
                                            {{ formatCurrency(item.all_discounts.brand).format }}
                                        </div>
                                    </span>
                                </div>
                            </td>
                            <td v-if="hideCellMobile" class="cell-desktop cell-product-quantity">
                                <div class="qty-box">
                                    <div class="input-group">
                                        <input v-if="product_origins != 'preorder'" type="number" name="quantity" :id="`quantity_${ item.variant_id }`" class="form-control input-number" :max="item.inventory_quantity" :min="item.min_quantity" v-model="item.quantity" @change="changeQuantity('change', item)" @keyup="changeQuantity('keyup', item)" @keypress="isNumber($event, item)"/>
                                        <label v-else :class="`form-control text-center preorder-quantity ${ item.inventory_quantity >= item.quantity ? 'in-stock' : 'out-stock' }`" :title="`${ item.inventory_quantity >= item.quantity ? 'Available Stock' : 'Out of Stock' }`">{{ item.quantity }}</label>
                                    </div>
                                </div>
                            </td>
                            <td v-if="hideCellMobile" class="cell-desktop cell-product-subtotal">
                                <span class="td-color">{{ formatCurrency( getSubtotalCart(item.price, item.all_discounts.brand, item.all_discounts.stock, item.all_discounts.coupon.percentage, item.quantity) * parseInt(item.quantity), false ).format }}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="1" class="pl-2">
                                <div v-if="['cart'].includes(product_origins) && tableProducts.length > 0" class="list-actions">
                                    <a class="action-link" @click="saveForLaterSelected()" v-b-tooltip.hover title="Save for Later">
                                        <i class="fa-solid fa-bookmark"></i>
                                    </a>
                                    <a class="action-link" @click="deleteItemsSelected()" v-b-tooltip.hover title="Delete Product">
                                        <i class="fa-solid fa-trash"></i>
                                    </a>
                                </div>
                                <div v-if="['cart_later'].includes(product_origins) && tableProducts.length > 0" class="list-actions">
                                    <a v-if="tableProducts.length > 0" class="action-link" @click="removeSaveLaterSelected()" v-b-tooltip.hover title="Add to Cart">
                                        <i class="fa-solid fa-cart-shopping"></i>
                                        <span class="cart-plus">+</span>
                                    </a>
                                    <a  class="action-link" @click="deleteItemsCartLater()" v-b-tooltip.hover title="Delete Product">
                                        <i class="fa-solid fa-trash"></i>
                                    </a>
                                </div>
                                <div v-if="['buy_again', 'preorder'].includes(product_origins) && tableProducts.length > 0" class="list-actions">
                                    <a class="action-link" @click="addToCartSelected()" v-b-tooltip.hover title="Add to Cart">
                                        <i class="fa-solid fa-cart-shopping"></i>
                                        <span class="cart-plus">+</span>
                                    </a>
                                </div>
                            </td>
                            <td v-if="hideCellMobile" colspan="2"></td>
                            <td colspan="3" class="total-table text-left pr-2">
                                <b-row v-if="getDetailsCart.total_fixed > 0" class="m-0 pl-4">
                                    <b-col cols="0" sm="4"></b-col>
                                    <b-col cols="6" sm="4" class="p-0"><label class="m-0 font-color-default">Subtotal:</label></b-col>
                                    <b-col cols="6" sm="4" class="p-0 d-flex justify-content-end align-items-center"><label class="d-flex align-items-center m-0 font-color-default label-total-cart">{{ formatCurrency(getDetailsCart.total_cart, true).format }}</label></b-col>
                                </b-row>
                                <b-row v-if="availableCoupon" class="m-0 pl-4">
                                    <b-col cols="0" sm="4"></b-col>
                                    <b-col cols="4" sm="3" class="p-0 d-flex" v-b-tooltip.hover :title="applyCoupon">
                                        <div class="svg-icon svg-icon-info icon-coupon-info d-flex p-absolute">
                                            <i class="fa-solid fa-exclamation-circle d-flex align-items-center justify-content-start"></i>
                                        </div>
                                        <label class="m-0 font-color-default">Coupon:</label>
                                    </b-col>
                                    <b-col v-if="getDetailsCart.total_fixed > 0" cols="8" sm="5" class="p-0 d-flex justify-content-end align-items-center"><label class="d-flex align-items-center m-0 font-color-default label-total-cart">-{{ formatCurrency(getDetailsCart.total_fixed).format }}</label></b-col>
                                    <b-col v-else-if="getDetailsCart.total_percentage > 0" cols="8" sm="5" class="p-0 d-flex justify-content-end align-items-center"><label class="d-flex align-items-center m-0 font-color-default label-discount text-transform-none">+ {{ getDetailsCart.total_percentage }}% in selected SKUs</label></b-col>
                                </b-row>
                                <b-row class="m-0 pl-4">
                                    <b-col cols="0" sm="4"></b-col>
                                    <b-col cols="6" sm="4" class="p-0"><label class="m-0 font-color-default">Total Products:</label></b-col>
                                    <b-col cols="6" sm="4" class="p-0 d-flex justify-content-end align-items-center"><label class="d-flex align-items-center m-0 font-color-default label-total-cart">{{ formatCurrency(getDetailsCart.total_cart - getDetailsCart.total_fixed, true).format }}</label></b-col>
                                </b-row>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <b-col cols="12" v-else-if="tableProducts && loadingTable == false" class="empty-cart-cls text-center">
                    <img :src="require(`@/assets/images/icon-empty-cart_full.webp`)" class="img-fluid" alt="empty cart"/>
                    <h3 class="mt-3">
                        <strong>Your list is Empty</strong>
                    </h3>
                    <h4 class="mb-3">Buy something to make me happy :)</h4>
                    <b-col cols="12">
                        <nuxt-link :to="{ path: '/' }" class="btn btn-solid shadow-sm"><span>continue shopping</span></nuxt-link>
                    </b-col>
                </b-col>
            </b-col>
        </b-row>
        <b-row v-if="tableProducts && tableProducts.length > 0" class="mt-3 footer-actions-cart">
            <b-col v-if="product_origins != 'cart' || ( product_origins == 'cart' && hideCellMobile )" cols="6" :class="`${ ['buy_again', 'preoder'].includes(product_origins) ? '' : 'mt-3' }`">
                <nuxt-link :to="{ path: '/' }" :class="'btn btn-solid btn-actions shadow-sm'"><span>continue shopping</span></nuxt-link>
            </b-col>
            <b-col v-if="['cart'].includes(product_origins)" cols="12" sm="12" lg="6" class="mt-3 d-flex justify-content-end">
                <div class="content-coupon d-flex">
                    <b-input-group size="sm">
                        <b-form-input type="text" :class="`form-control coupon-input ${ availableCoupon ? 'active-coupon' : '' }`" name="coupon_text" id="coupon_text" placeholder="Enter your coupon" :disabled="availableCoupon" v-model="coupon_name"></b-form-input>
                        <b-input-group-append>
                            <button type="button" @click="addRemoveCoupon()" :class="`btn shadow-sm btn-solid btn-coupon-cart ${ availableCoupon ? 'remove-coupon': 'apply-coupon' }`">
                                <div class="h-100 align-items-center custom-content-icon icon-coupon">
                                    <i class="fa-solid fa-tags"></i>
                                </div>
                                <div class="h-100 align-items-center custom-content-icon icon-trash">
                                    <i class="fa-solid fa-trash"></i>
                                </div>
                            </button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </b-col>
            <b-col v-if="['cart'].includes(product_origins) && !hideCellMobile" cols="6" class="mt-3">
                <nuxt-link :to="{ path: '/' }" :class="'btn btn-solid btn-actions'"><span>continue shopping</span></nuxt-link>
            </b-col>
            <b-col v-if="['cart'].includes(product_origins)" cols="6" sm="6" lg="12" class="d-flex justify-content-end mt-3">
                <b-button :disabled="!nextCheckout" :class="`btn-solid btn-actions shadow-sm btn-checkout ${ nextCheckout }`" @click="goToCheckout"><span>checkout</span></b-button>
            </b-col>
            <b-col v-if="['buy_again', 'preoder'].includes(product_origins)" cols="6"></b-col>
            <b-col v-if="['cart_later'].includes(product_origins)" cols="6" class="d-flex justify-content-end mt-3">
                <b-button :disabled="!nextCheckout" :class="`btn-solid btn-actions shadow-sm btn-checkout ${ nextCheckout }`" @click="goToCheckout"><span>checkout</span></b-button>
            </b-col>
        </b-row>
        <div v-if="tableProducts && tableProducts.length > 0 && ['cart', 'cart_later'].includes(product_origins)" class="slide-6 no-arrow mt-4">
            <div class="page-title mt-3 mb-4">
                <h2>Best Sellers</h2>
            </div>
            <b-carousel v-if="!getShowMobileCarousel" id="carousel-best-sellers-desktop" :interval="4000" controls label-next="" label-prev="">
                <b-carousel-slide v-for="(group_slide_item, group_slide_index) in listBestSellers.desktop" :key="group_slide_index">
                    <template #img>
                        <b-row class="justify-content-center mt-4">
                            <b-col cols="3" v-for="(slide_item, slide_index) in group_slide_item" :key="slide_index" @click="slideTo(slide_index + ( group_slide_index * 4 ))">
                                <product-collection :product="slide_item" :index="slide_index" :imgList="false" :handleCollection="'best-sellers'" :hideDescription="false" :titleCollection="'Best Sellers'"></product-collection>
                            </b-col>
                        </b-row>
                    </template>
                </b-carousel-slide>
            </b-carousel>
            <b-carousel v-else id="carousel-best-sellers-mobile" :interval="4000" controls label-next="" label-prev="">
                <b-carousel-slide v-for="(group_slide_item, group_slide_index) in listBestSellers.mobile" :key="group_slide_index">
                    <template #img>
                        <b-row class="justify-content-center mt-4">
                            <b-col cols="6" v-for="(slide_item, slide_index) in group_slide_item" :key="slide_index" @click="slideTo(slide_index + ( group_slide_index * 2 ))">
                                <product-collection :product="slide_item" :index="slide_index" :imgList="false" :handleCollection="'best-sellers'" :hideDescription="false" :titleCollection="'Best Sellers'"></product-collection>
                            </b-col>
                        </b-row>
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatData, calcDiscountPrice, arrayHelpers } from "../../constants/config.js";
export default {
    props: ["product_origins"],
    components: {
        vcLoading           : () => import('../../components/pages/loading.vue' ),
        productCollection   : () => import('../../components/product/product-collection.vue' ),
        vcFormatPrice       : () => import('../../components/product/format-price.vue' )
    },
    data() {
        return {
            back_url			    : process.env.VUE_APP_CATALOG_BACK_URL_FILE,
            isLoadingMsg            : true,
            loadingTable            : true,
            customer                : null,
            statement               : null,
            changeCoupon            : false,
            applyCoupon             : null,
            coupon_name             : null, 
            details_cart            : { total_cart: 0, total_fixed: 0, total_percentage: 0, count_items: 0 },
            tableProducts           : null,
            select_products         : [],
            allSelected             : false,
            hideCellMobile          : true,
            
            showMessage             : false,
            
            nextCheckout            : false,
            
            filter_products         : "",
            
            listBestSellers         : [],
            message                 : null,
            balance_customer        : 0,
            pre_credit              : 0,
            expired_days            : 0,
            show_mobile_carousel    : false,
            applyDiscountStock      : false,
            discount_stock          : {
                value: 0,
                min_stock: 0,
                quantity: 0
            }
        };
    },
    async mounted() {
        
        window.addEventListener('load', this.resizeWindow);
        window.addEventListener('resize', this.resizeWindow);
        
        if( this.getterLogin && ['cart','cart_later'].includes(this.product_origins) ){
            
            await this.getCustomer({ id_email: this.getterUser.email }).then( async (result_customer) => {
                
                this.customer = this.getterCustomer;
                
                await this.getCart().then( async (result_cart) => {
                    
                    if( this.product_origins == 'cart' ){
                        
                        this.coupon_name        = this.couponName;
                        this.getListProductCart(this.getterCart.products);
                    }
                    else if( this.product_origins == 'cart_later' ){
                        
                        this.getListProductCart(this.getterCartSaveLater.products);
                    }
                    await this.getStatementByCustomer().then( async (result_statement) => {
                        
                        this.formatStatement();
                    });
                });
            });
            await this.getListAddBestSellers({ product_origin: 'cart' }).then( (result_best_sellers) => {
                
                this.listBestSellers = {
                    desktop: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( this.getterListAddBestSellers ) ), 4 ),
                    mobile: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( this.getterListAddBestSellers ) ), 2 )
                };
            });
        }
        else if( this.product_origins == 'buy_again' ){
            
            await this.listBuyAgainProducts().then( (result_buy_again) => {
                
                this.getListProductCart(this.getterListBuyAgain);
            });
        }
        else if( this.product_origins == 'preorder' ){
            
            await this.listProductPreorders().then( (result_preorder) => {
                
                this.getListProductCart(this.getterPreorders);
            });
        }
    },
    computed: {
        ...mapGetters({
            getterLogin             : "getterLogin",
            getterUser              : "getterUser",
            getterCart              : "getterCart",
            getterCartSaveLater     : "getterCartSaveLater",
            getterListBuyAgain      : "getterListBuyAgain",
            getterPreorders         : "getterPreorders",
            getterCustomer          : "getterCustomer",
            getterCoupon            : "getterCoupon",
            getterSatement          : "getterSatement",
            getterDownloadCartFile  : 'getterDownloadCartFile',
            getterListAddBestSellers: 'getterListAddBestSellers'
        }),
        getShowMobileCarousel(){
            return this.show_mobile_carousel;
        },
        availableCoupon(){
            return !!this.getterCoupon?.name;
        },
        couponName(){
            return this.getterCoupon?.name ? this.getterCoupon.name : null;
        },
        getDetailsCart(){
            return this.details_cart;
        },
        getApplyDiscountStock(){
            return this.applyDiscountStock;
        },
        getDiscountStock(){
            return this.discount_stock;
        }
    },
    methods: {
        ...mapActions({
            getCart                 : "getCart",
            listBuyAgainProducts    : "listBuyAgainProducts",
            listProductPreorders    : "listProductPreorders",
            getCustomer             : "getCustomer",
            getStatementByCustomer  : "getStatementByCustomer",
            getListAddBestSellers   : "getListAddBestSellers",
            actionCart              : "actionCart",
            addNotification         : "addNotification",
            addCouponCart           : "addCouponCart",
            getCoupon               : "getCoupon",
            uploadCartFile          : "uploadCartFile",
            downloadCartFile        : "downloadCartFile",
            deleteCartFile          : "deleteCartFile",
        }),
        async formatStatement(){
            
            this.calcTotalCart();
            this.balance_customer = this.getterSatement.balance;
            this.pre_credit = this.getterSatement.preApprovedCredit;
            this.expired_days = this.getterSatement.expiredDays;
            
            if( this.customer ){ 
                
                let num_items = this.tableProducts ? this.tableProducts.reduce( (previous_item, current_item) => {
                    
                    previous_item += current_item.quantity;
                    return previous_item;
                }, 0) : 0;
                this.validNextCheckout( this.balance_customer, this.pre_credit, this.expired_days, this.getDetailsCart.total_cart, num_items, this.customer.first_order, this.customer.valid_moa );
            }
        },
        async formatCoupon(){
            
            this.loadingTable = false;
            this.coupon_name = this.getterCoupon?.name ? this.getterCoupon.name : '';
            this.applyCoupon = null;
            if( this.availableCoupon ){
                
                this.applyCoupon = this.getterCoupon.applyEligibility.type.indexOf('brand') ? this.getterCoupon.applyEligibility.brand.map( (item) => item.name ) : this.getterCoupon.applyEligibility.product.map( (item) => item.sku );
                this.applyCoupon = `This coupon is applicable to Selected ${ this.getterCoupon.applyEligibility.type.indexOf('brand') ? 'Brands' : 'SKUs' }, for more information contact your Sales Agent`;
            }
            if( this.changeCoupon ){
                
                this.changeCoupon = false;
                this.isLoadingMsg = true;
                this.showMessage = false;
                this.addCouponCart({ coupon_id: this.getterCoupon ? this.getterCoupon._id : null }).then( async (result_coupon) => {
                    
                    await this.formatCoupon();
                });
            }
            else{
                
                this.isLoadingMsg = null;
            }
            this.getListProductCart(this.getterCart.products);
            this.calcTotalCart();
        },
        async formatFileCart(){
            
            if( ![null, ""].includes( this.getterDownloadCartFile ) ){
                
                document.getElementById("download_file").href = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ this.getterDownloadCartFile }`;
                document.getElementById("download_file").download = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ this.getterDownloadCartFile }`.substring( `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ this.getterDownloadCartFile }`.lastIndexOf('/') + 1 );
                document.getElementById("download_file").click();
                setTimeout(() => {
                    
                    this.deleteCartFile({ url_file: this.getterDownloadCartFile });
                }, 3000);
            }
        },
        resizeWindow(){
            this.hideCellMobile = window.innerWidth > 991;
            
            this.show_mobile_carousel = window.innerWidth <= 1200;
        },
        selectAll() {
            this.select_products = [];
            
            for (const item of this.tableProducts) {
                if( parseInt(item.quantity) > 0 ){
                    if (this.allSelected) {
                        document.getElementById(`check_${ item.variant_id }`).checked = true;
                        this.select_products.push({
                            product_id  : item.product_id,
                            variant_id  : item.variant_id,
                            quantity    : parseInt(item.quantity)
                        });
                    }
                    else{
                        this.select_products = this.select_products.filter( (item_v) => item_v.variant_id != item.variant_id );
                        document.getElementById(`check_${ item.variant_id }`).checked = false;
                    }
                }
            }
            this.allSelected = this.select_products.length > 0;
            
        },
        select(variant_item) {
            
            this.allSelected = false;
            if( document.getElementById(`check_${ variant_item.variant_id }`).checked ){
                
                if( variant_item.quantity > 0 ){
                    
                    let index_product_selected = this.select_products.findIndex( (item) => item.variant_id == variant_item.variant_id );
                    if( index_product_selected >= 0 ){
                        
                        this.select_products[index_product_selected].quantity = variant_item.quantity;
                    }
                    else{
                        
                        this.select_products.push({
                            product_id  : variant_item.product_id,
                            variant_id  : variant_item.variant_id,
                            quantity    : parseInt(variant_item.quantity)
                        });
                    }
                    document.getElementById(`check_${ variant_item.variant_id }`).checked = true;
                }
                else{
                    
                    document.getElementById(`check_${ variant_item.variant_id }`).checked = false;
                }
            }
            else{
                
                this.select_products = this.select_products.filter( (item) => item.variant_id != variant_item.variant_id );
            }
        },
        async changeQuantity(event_name, variant_item){
            
            let index_product = this.tableProducts.findIndex( (item) => item.variant_id == variant_item.variant_id );
            
            if( index_product >= 0 ){
                
                variant_item.quantity = ( this.tableProducts[index_product].sales_limit == 0 || ( this.tableProducts[index_product].sales_limit > 0 && variant_item.quantity <= this.tableProducts[index_product].sales_limit ) ) ? variant_item.quantity : this.tableProducts[index_product].sales_limit;
                
                this.tableProducts[index_product].quantity = this.validNumber( variant_item.quantity <= this.tableProducts[index_product].inventory_quantity ? variant_item.quantity : this.tableProducts[index_product].inventory_quantity);
                
                if( this.product_origins == 'buy_again' && this.tableProducts[index_product].quantity > 0  ){
                    
                    document.getElementById(`check_${ variant_item.variant_id }`).click();
                    this.select( variant_item );
                }
                document.getElementById(`quantity_${ variant_item.variant_id }`).value = this.validNumber(this.tableProducts[index_product].quantity);
                
                this.calcTotalCart();
                
                let count_stock_products    = 0;
                let min_stock_high          = 0;
                for (const item_variant of this.tableProducts) {
                    
                    if( item_variant.all_discounts.stock.value > 0 ){
                        
                        min_stock_high              = min_stock_high < item_variant.all_discounts.stock.min_stock ? item_variant.all_discounts.stock.min_stock : min_stock_high;
                        count_stock_products        += item_variant.quantity;
                    }
                }
                this.applyDiscountStock         = count_stock_products > 0 && min_stock_high > 0 ? ( count_stock_products >= min_stock_high ) : false;
                this.discount_stock.quantity    = count_stock_products;
                console.log( this.discount_stock );
                if( event_name == "change" ){
                    let data_products = {
                        products: [{
                            product_id: this.tableProducts[index_product].product_id,
                            variant_id: this.tableProducts[index_product].variant_id,
                            quantity: parseInt( this.tableProducts[index_product].quantity )
                        }],
                        action: this.product_origins == 'cart' ? 'update-product' : 'update-save-later', 
                    };
                    await this.actionCart(data_products).then( async (result_cart) => {
                        
                        await this.formatCoupon();
                    });
                    
                    if( ( ( this.getDetailsCart.total_cart / 100 ) > 0 ) && ( ( ( this.pre_credit + this.balance_customer ) >= ( this.getDetailsCart.total_cart / 100 ) && this.expired_days == 0 ) || !( ( this.pre_credit + this.balance_customer ) >= ( this.getDetailsCart.total_cart / 100 ) && this.expired_days == 0 ) ) ){
                        
                        this.isLoadingMsg = this.showMessage ? true : null;
                        this.showMessage = false;
                        await this.getStatementByCustomer().then( async (result_statement) => {
                            
                            this.formatStatement();
                        });
                    }
                }
            }
        },
        formatCurrency(price, totalAmount = null) {
            
            return formatData.currency(price, totalAmount);
        },
        getSubtotalCart(price, discount_brand, discount_stock, discount_percentage, quantity){
            
            if( discount_stock.value > 0 && this.getApplyDiscountStock ){
                
                discount_brand = ( discount_brand + ( ( discount_stock.value * ( 100 - discount_brand ) ) / 100 ) );
            }
            discount_brand = ( discount_percentage ? ( discount_brand + ( ( discount_percentage * ( 100 - discount_brand ) ) / 100 ) ) : discount_brand );
            return calcDiscountPrice(price, discount_brand, quantity);
        },
        async addToCartSelected() {
            
            if (this.select_products.length > 0) {
                
                await this.actionCart({ action: 'add', products: this.select_products }).then( async (result_cart) => {
                    
                    await this.formatCoupon();
                });
            } 
            else {
                
                this.addNotification({ content: { type: 'request' }, type: 'info', data: { title: "Info: Action Cart", message: "Please first select at least one item" } });
            }
        },
        deleteItemsSelected() {
            
            this.actionsCart("remove");
        },
        deleteItemsCartLater() {
            
            this.actionsCart("delete-save-later");
        },
        saveForLaterSelected() {
            
            this.actionsCart("add-save-later");
        },
        removeSaveLaterSelected() {
            
            this.actionsCart("remove-save-later");
        },
        async addRemoveCoupon(){
            
            this.changeCoupon = true;
            this.loadingTable = true;
            if( this.availableCoupon ){
                
                await this.addCouponCart({ coupon_id: null }).then( async (result_coupon) => {
                    
                    await this.formatCoupon();
                });
            }
            else{
                
                let justBrand = this.tableProducts.map((a) => { return a.brand; });
                let justProduct = this.tableProducts.map((a) => { return a.sku; });
                this.calcTotalCart();
                const payload = {
                    data: {
                        name: this.coupon_name,
                        cant_items: this.getDetailsCart.count_items,
                        total_amount: this.formatCurrency( this.getDetailsCart.total_cart, true ).number,
                        id_customer: this.customer._id,
                        id_brand: justBrand,
                        id_product: justProduct,
                    },
                };
                await this.getCoupon(payload).then( async (result_coupon) => {
                    
                    await this.formatCoupon();
                });
            }
        },
        validNextCheckout(balance_customer, pre_credit, expired_days, total_cart, num_items, first_order, valid_moa) {
            
            this.message = [];
            this.nextCheckout = false;
            this.showMessage = false;
            if ( ( num_items > 0 ) && ( ( pre_credit + balance_customer ) >= total_cart && expired_days == 0 ) && ( ( first_order == null && ( total_cart > 1000 || valid_moa ) ) || ( first_order != null ) ) ) {
                
                this.nextCheckout = true;
                this.showMessage = false;
            }
            else if( ( num_items > 0 ) && ( ( ( pre_credit + balance_customer ) >= total_cart && expired_days == 0 ) || !( ( pre_credit + balance_customer ) >= total_cart && expired_days == 0 ) ) ){
                
                if( ( first_order == null ) && !( total_cart > 1000 || valid_moa ) ){
                    
                    this.message.push(`Since we are wholesalers, the <strong class="font-color-light">Minimum Order Amount (MOA)</strong> of your first order must be a total of <strong class="font-color-light">$1,000.00</strong>.`);
                }
                else{
                    
                    this.message.push(`Your available Maximum Purchase Limit is: <strong class="total-credit font-color-light">${ this.formatCurrency(pre_credit + balance_customer).format }</strong>, the total amount of your cart can not exceed this amount.<br> Your debt is <strong class="total-days font-color-light">${ expired_days }</strong> days past due.`);
                }
                this.nextCheckout = false;
                this.showMessage = true;
            }
            
            this.isLoadingMsg = null;
        },
        isNumber(event, variant_item){
            
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57) || parseInt(document.getElementById(`quantity_${ variant_item.variant_id }`).value) == 0 && parseInt(event.key) == 0 ) {
                
                event.preventDefault();
            }
            if( parseInt(document.getElementById(`quantity_${ variant_item.variant_id }`).value) == 0 && parseInt(event.key) > 0 ){
                
                document.getElementById(`quantity_${ variant_item.variant_id }`).value = null;
                document.getElementById(`check_${ variant_item.variant_id }`).checked = false;
            }
        },
        validNumber(number){
            
            return ( isNaN( parseInt(number) ) ? 0 : parseInt(number) );
        },
        calcTotalCart(){
            
            if( this.tableProducts ){
                
                this.details_cart = this.tableProducts.reduce( (previous_item, current_item) => { 
                    
                    if( this.product_origins != 'preorder' || ( this.product_origins == 'preorder' && parseInt(current_item.inventory_quantity) >= parseInt(current_item.quantity) ) ){
                        
                        previous_item.count_items += parseInt( current_item.quantity );
                        let discount_brand = current_item.all_discounts.brand;
                        
                        if( current_item.all_discounts.stock.value > 0 && this.getApplyDiscountStock ){
                            
                            discount_brand                = ( discount_brand + ( ( current_item.all_discounts.stock.value * ( 100 - discount_brand ) ) / 100 ) );
                        }
                        if( current_item.all_discounts.coupon.fixed > 0 ){
                            
                            previous_item.total_fixed   += current_item.all_discounts.coupon.fixed;
                        }
                        else if( current_item.all_discounts.coupon.percentage > 0 ){
                            
                            previous_item.total_percentage 	= current_item.all_discounts.coupon.percentage;
                            discount_brand                = ( discount_brand + ( ( current_item.all_discounts.coupon.percentage * ( 100 - discount_brand ) ) / 100 ) );
                        }
                        previous_item.total_cart    	    += this.formatCurrency( calcDiscountPrice(current_item.price, discount_brand, current_item.quantity) * parseInt(current_item.quantity), false ).number;
                    }
                    return previous_item; 
                }, { total_cart: 0, total_fixed: 0, total_percentage: 0, count_items: 0 } );
            }
        },
        getListProductCart(data_products){
            
            this.tableProducts          = [];
            let count_stock_products    = 0;
            let min_stock_high          = 0;
            for (const item_variant of data_products) {
                
                if( document.getElementById(item_variant.variant_id) ){
                    
                    if( this.product_origins == "buy_again" ){
                        
                        item_variant.quantity = 0;
                    }
                    document.getElementById(item_variant.variant_id).checked = false;
                }
                if( item_variant.all_discounts.stock.value > 0 ){
                    
                    this.discount_stock.value   = item_variant.all_discounts.stock.value;
                    min_stock_high          = min_stock_high < item_variant.all_discounts.stock.min_stock ? item_variant.all_discounts.stock.min_stock : min_stock_high;
                    count_stock_products    += item_variant.quantity;
                }
                item_variant.min_quantity       = item_variant.moq > 0 ? item_variant.moq : 1;
                item_variant.inventory_quantity = item_variant.sales_limit > 0 ? item_variant.sales_limit : item_variant.inventory_quantity;
                item_variant.show               = true;
                
                this.tableProducts.push( item_variant );
            }
            this.applyDiscountStock         = count_stock_products > 0 && min_stock_high > 0 ? ( count_stock_products >= min_stock_high ) : false;
            this.discount_stock.min_stock   = min_stock_high;
            this.discount_stock.quantity    = count_stock_products;
            console.log( this.discount_stock );
            this.calcTotalCart();
            this.loadingTable           = false;
        },
        async actionsCart(action_cart){
            
            if (this.select_products.length > 0) {
                
                this.tableProducts = [];
                this.loadingTable = true;
                this.isLoadingMsg = true;
                this.showMessage = false;
                await this.actionCart({ action: action_cart, products: this.select_products }).then( async (result_cart) => {
                    
                    await this.formatCoupon();
                });
            } 
            else {
                
                this.addNotification({ content: { type: 'request' }, type: 'info', data: { title: "Info: Action Cart", message: "Please first select at least one item" } });
            }
        },
        filterProducts(){
            
            let regexp_search = new RegExp(this.filter_products.trim().toLowerCase(), 'i');
            
            this.tableProducts = this.tableProducts.map( (item) =>{
                
                if( this.filter_products.trim() == "" || regexp_search.test(`${ item.sku } ${ item.brand } ${ item.product_category } ${ item.title_product }`) ){
                    
                    item.show = true;
                }
                else{
                    item.show = false;
                }
                return item;
            });
        },
        goToCheckout(){
            
            this.$router.push(`/checkout/${ this.product_origins }`);
        },
        async addNewDoc(event) {
            
            if( document.getElementById("cart_file").files[0] ){
                
                let data_upload = {
                    file: document.getElementById("cart_file").files[0],
                    old_file_path: "",
                    max_size: 512000,
                    input_name: "cart_file",
                    extra_data: {
                        category_file: "excel",
                        body: [
                        {
                            name: "is_file",
                            value: JSON.stringify( true )
                        },
                        {
                            name: "multi_sheets", 
                            value: JSON.stringify( false ) 
                        },
                        { 
                            name: "sheets", 
                            value: JSON.stringify({ 
                                name: "Cart List", 
                                read_options: {
                                    max_columns: 3,
                                    first_reg: 3,
                                    fields: [
                                    { name: "sku"    	        , type: "string", format: "uppercase" }, 
                                    { name: "quantity"          , type: "number", format: "normal" }, 
                                    { name: "discount_price"    , type: "number", format: "money-ft" }
                                    ]
                                }
                            })
                        }
                        ]
                    }
                };
                this.tableProducts = [];
                this.loadingTable = true;
                this.isLoadingMsg = true;
                this.showMessage = false;
                await this.uploadCartFile(data_upload).then( async (result_cart) => {
                    
                    await this.formatCoupon();
                });
                //document.getElementById("cart_file").reset();
                this.hideModal("modalFile");
            }
        },
        showModal(refName) {
            this.$refs[refName].show();
        },
        hideModal(refName) {
            this.$refs[refName].hide();
        },
        async downloadFile(event) {
            event.preventDefault();
            await this.downloadCartFile({ new_file: false, origin: this.product_origins, customer: this.customer._id.toString() }).then( async (result_file) =>{
                
                this.formatFileCart();
            });
        },
        async downloadTemplate(event) {
            event.preventDefault();
            await this.downloadCartFile({ new_file: true, customer: this.customer._id.toString() }).then( async (result_file) =>{
                
                this.formatFileCart();
            });
        },
    }
};
</script>