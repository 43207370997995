<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="How does it work"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">How does it work</h2>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<b-card class="shadow-sm bg-white" style="border: unset">
						<p>Enjoying our services is very easy. All you just have to do is register as a new user and become part of the high quality wholesale clothing business. Your account will be approved once our team verifies your data. After your account is confirmed, you will see the available inventory and price of each product in real time. Every time you make a purchase of our high quality wholesale clothing from the comfort of your home, you will be to streamline your purchase order process, payments, commercial procedures and receiving immediate dispatches, given that our team begins the process of packing the products from the moment you place an order, taking care of all the customs processes in order to get the product out of our warehouses directly to your business.</p>
						<p>After making your first high quality wholesale clothing order, the representatives of each brand will be able to schedule meetings to offer you better commercial terms such as incentives, exclusive areas, credits, and advertising support and sustain mutual success.</p>
						<p>On the other hand, the brands that wish to offer their high-quality wholesale clothing on our platform have the opportunity to contact us through the various means available at our website (phones, mail, and chats), establishing direct contact with the company to show their products and business proposals.</p>
						<p>We will carry out an evaluation process and if the brands comply with our quality parameters, we will have the valuable opportunity to offer their high-quality wholesale clothing in our virtual market with great projection in Mexico and USA.</p>
						<p>We invite you to be part of our family, you will not get any excuses for not sustaining your success. Whether you are a buyer or supplier, you will have the opportunity to be seen in the e-commerce world, a market of great growth and popularity. What are you waiting for? Join Cata1og.com PLN Distributions and start selling high-quality wholesale clothing online.</p>
					</b-card>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
	}
}
</script>
