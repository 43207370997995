<template>
    <section class="section-blog p-0">
        <breadcrumbs type="blog" :title="getterItemBlog?.main_title" :subItemData="{ handle: '', title: 'blog' }"></breadcrumbs>
        <div class="content-blog content-loading p-relative">
            <vc-loading :isLoading="isLoading"></vc-loading>
            <div v-if="getterItemBlog" class="full-banner parallax text-center" v-bind:style="{ 'background-image': `url(${ backCmsUrl }${ getterItemBlog?.main_image.items[0]._image[0].attachment._urls.full })` }">
                <b-container class="banner-contain">
                    <h1>{{getterItemBlog?.main_title}}</h1>
                </b-container>
            </div>
            <b-container v-if="getterItemBlog">
                <b-row>
                    <b-col cols="12" class="my-4">
                        <b-card class="box-shadow bg-white" style="border: unset">
                            <div v-for="(item_content, index_content) in getterItemBlog?.content.items" :key="`content-${ index_content }`" class="content-blog-page-item d-flex my-4">
                                <div v-if="item_content.type == '@apostrophecms/rich-text'" class="content-text" v-html="item_content.content"></div>
                                <img v-if="item_content.type == '@apostrophecms/image'" class="content-image" :src="`${ backCmsUrl }${ item_content._image[0].attachment._urls.full }`" :alt="item_content._image[0].alt != '' ? item_content._image[0].alt : item_content._image[0].slug" :width="item_content._image[0].attachment.width" :height="item_content._image[0].attachment.height"/>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
        vcLoading: () => import('../../components/pages/loading.vue')
    },
    data() {
        return {
            backCmsUrl: process.env.VUE_APP_CMS_BACK_URL_FILE,
            isLoading: true
        }
    },
    async mounted(){
		let handle = this.$route.params.handle;
		window.addEventListener('load', this.resizePage);
		window.addEventListener('resize', this.resizePage);

        await this.getItemBlog({ handle: handle }).then( (result) => {

            this.isLoading = false;
        });
    },
    computed:{
        ...mapGetters({
            getterItemBlog: 'getterItemBlog'
        })
    },
    methods:{
        ...mapActions({
            getItemBlog: 'getItemBlog'
        }),
		resizePage(event){
			this.show_mobile_carousel = window.innerWidth <= 991;
		},
    }
}
</script>
<!-- 
<script type="application/ld+json">
{
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    "headline": "Título del artículo",
    "image": ["URL de la imagen del artículo"],
    "author": {
        "@type": "Person",
        "name": "Nombre del autor",
        "url": "URL del perfil del autor"
    },
    "publisher": {
        "@type": "Organization",
        "name": "Nombre del blog o la organización",
        "url": "URL del blog",
        "logo": {
            "@type": "ImageObject",
            "url": "URL del logo de la organización",
            "width": "Ancho del logo",
            "height": "Altura del logo"
        }
    },
    "datePublished": "2024-07-11T08:00:00+00:00",
    "dateModified": "2024-07-11T09:00:00+00:00",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "URL del artículo"
    },
    "description": "Descripción breve del artículo",
    "articleBody": "Cuerpo completo del artículo para mayor relevancia SEO"
}
</script>
<script type="application/ld+json">
{
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Título de la página",
    "description": "Descripción breve de la página",
    "url": "URL de la página",
    "mainEntity": {
        "@type": "Thing",
        "name": "Nombre principal del contenido de la página"
    }
}
</script>
<script type="application/ld+json">
{
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Nombre del producto",
    "image": ["URL de la imagen del producto"],
    "description": "Descripción del producto",
    "sku": "SKU del producto",
    "brand": {
        "@type": "Brand",
        "name": "Nombre de la marca"
    },
    "category": "Tipo de producto",
    "material": "Material 1, Material 2",
    "additionalProperty": [
    {
        "@type": "PropertyValue",
        "name": "Color",
        "value": "Color 1",
        "valueReference": {
            "@type": "QualitativeValue",
            "value": "Color 1",
            "additionalProperty": {
                "@type": "PropertyValue",
                "name": "Swatch",
                "value": "URL del swatch del color 1"
            }
        }
    },
    {
        "@type": "PropertyValue",
        "name": "Color",
        "value": "Color 2",
        "valueReference": {
            "@type": "QualitativeValue",
            "value": "Color 2",
            "additionalProperty": {
                "@type": "PropertyValue",
                "name": "Swatch",
                "value": "URL del swatch del color 2"
            }
        }
    },
    {
        "@type": "PropertyValue",
        "name": "Tamaño",
        "value": "Tamaño 1"
    },
    {
        "@type": "PropertyValue",
        "name": "Tamaño",
        "value": "Tamaño 2"
    },
    {
        "@type": "PropertyValue",
        "name": "Usos",
        "value": "Uso 1"
    },
    {
        "@type": "PropertyValue",
        "name": "Usos",
        "value": "Uso 2"
    },
    {
        "@type": "PropertyValue",
        "name": "Características",
        "value": "Característica 1"
    },
    {
        "@type": "PropertyValue",
        "name": "Características",
        "value": "Característica 2"
    }
    ],
    "offers": [
    {
        "@type": "Offer",
        "url": "URL de la página del producto",
        "priceCurrency": "Moneda",
        "price": "Precio",
        "priceValidUntil": "2025-07-11",
        "itemCondition": "http://schema.org/NewCondition",
        "availability": "http://schema.org/InStock",
        "seller": {
            "@type": "Organization",
            "name": "Nombre del vendedor"
        },
        "sku": "SKU de la variación",
        "color": "Color del producto",
        "size": "Tamaño del producto"
    }
    ],
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.5",
        "reviewCount": "24"
    },
    "review": [
    {
        "@type": "Review",
        "author": {
            "@type": "Person",
            "name": "Nombre del revisor"
        },
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5"
        },
        "reviewBody": "Texto de la reseña"
    }
    ]
}
</script>
<script type="application/ld+json">
{
    "@context": "http://schema.org",
    "@type": "CollectionPage",
    "name": "Título de la colección",
    "description": "Descripción de la colección",
    "url": "URL de la página de la colección",
    "mainEntity": [
    {
        "@type": "Product",
        "name": "Nombre del producto 1",
        "image": ["URL de la imagen del producto 1"],
        "description": "Descripción del producto 1",
        "sku": "SKU del producto 1",
        "brand": {
            "@type": "Brand",
            "name": "Nombre de la marca"
        },
        "category": "Tipo de producto",
        "material": "Material 1, Material 2",
        "additionalProperty": [
        {
            "@type": "PropertyValue",
            "name": "Color",
            "value": "Color 1",
            "valueReference": {
                "@type": "QualitativeValue",
                "value": "Color 1",
                "additionalProperty": {
                    "@type": "PropertyValue",
                    "name": "Swatch",
                    "value": "URL del swatch del color 1"
                }
            }
        },
        {
            "@type": "PropertyValue",
            "name": "Color",
            "value": "Color 2",
            "valueReference": {
                "@type": "QualitativeValue",
                "value": "Color 2",
                "additionalProperty": {
                    "@type": "PropertyValue",
                    "name": "Swatch",
                    "value": "URL del swatch del color 2"
                }
            }
        },
        {
            "@type": "PropertyValue",
            "name": "Tamaño",
            "value": "Tamaño 1"
        },
        {
            "@type": "PropertyValue",
            "name": "Tamaño",
            "value": "Tamaño 2"
        },
        {
            "@type": "PropertyValue",
            "name": "Usos",
            "value": "Uso 1"
        },
        {
            "@type": "PropertyValue",
            "name": "Usos",
            "value": "Uso 2"
        },
        {
            "@type": "PropertyValue",
            "name": "Características",
            "value": "Característica 1"
        },
        {
            "@type": "PropertyValue",
            "name": "Características",
            "value": "Característica 2"
        }
        ],
        "offers": [
        {
            "@type": "Offer",
            "url": "URL de la página del producto 1",
            "priceCurrency": "Moneda",
            "price": "Precio",
            "itemCondition": "http://schema.org/NewCondition",
            "availability": "http://schema.org/InStock",
            "seller": {
                "@type": "Organization",
                "name": "Nombre del vendedor"
            },
            "sku": "SKU de la variación",
            "color": "Color del producto",
            "size": "Tamaño del producto"
        }
        ]
    },
    {
        "@type": "Product",
        "name": "Nombre del producto 2",
        "image": ["URL de la imagen del producto 2"],
        "description": "Descripción del producto 2",
        "sku": "SKU del producto 2",
        "brand": {
            "@type": "Brand",
            "name": "Nombre de la marca"
        },
        "category": "Tipo de producto",
        "material": "Material 1, Material 2",
        "additionalProperty": [
        {
            "@type": "PropertyValue",
            "name": "Color",
            "value": "Color 1",
            "valueReference": {
                "@type": "QualitativeValue",
                "value": "Color 1",
                "additionalProperty": {
                    "@type": "PropertyValue",
                    "name": "Swatch",
                    "value": "URL del swatch del color 1"
                }
            }
        },
        {
            "@type": "PropertyValue",
            "name": "Color",
            "value": "Color 2",
            "valueReference": {
                "@type": "QualitativeValue",
                "value": "Color 2",
                "additionalProperty": {
                    "@type": "PropertyValue",
                    "name": "Swatch",
                    "value": "URL del swatch del color 2"
                }
            }
        },
        {
            "@type": "PropertyValue",
            "name": "Tamaño",
            "value": "Tamaño 1"
        },
        {
            "@type": "PropertyValue",
            "name": "Tamaño",
            "value": "Tamaño 2"
        },
        {
            "@type": "PropertyValue",
            "name": "Usos",
            "value": "Uso 1"
        },
        {
            "@type": "PropertyValue",
            "name": "Usos",
            "value": "Uso 2"
        },
        {
            "@type": "PropertyValue",
            "name": "Características",
            "value": "Característica 1"
        },
        {
            "@type": "PropertyValue",
            "name": "Características",
            "value": "Característica 2"
        }
        ],
        "offers": [
        {
            "@type": "Offer",
            "url": "URL de la página del producto 2",
            "priceCurrency": "Moneda",
            "price": "Precio",
            "itemCondition": "http://schema.org/NewCondition",
            "availability": "http://schema.org/InStock",
            "seller": {
                "@type": "Organization",
                "name": "Nombre del vendedor"
            },
            "sku": "SKU de la variación",
            "color": "Color del producto",
            "size": "Tamaño del producto"
        }
        ]
    }
    ]
}
</script> 
-->
