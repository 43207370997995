<template>
	<div class="layout-only-content">
		<nuxt id="body-only-content"></nuxt>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
	loading: false,
	head() {
		return {
			title: 'Cata1og.com | Wholesale Portal'
		}
	},
	async mounted() {
		
		await this.getGeneralSettings();
		
	},
	computed: {
		...mapGetters({
		}),
	},
	methods: {
		...mapActions({
			getGeneralSettings	: 'getGeneralSettings'
		}),
    }
}
</script>