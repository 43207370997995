<template>
	<div class="container-page">
		<register-advertising-page :banner="'banners/banner-form-leads-jeans_full.webp'" :title="'The Best Colombian Jeans Wholesale'" :message= "'Sign up now and boost your business!'" :source="'ADS-jeans-colombianos'"></register-advertising-page>
	</div>
</template>
<script>
export default {
	components:{
		registerAdvertisingPage: () => import('../../components/account/register-page.vue')
	}
}
</script>
