<template>
	<section :class="`default-slider p-0`">
        <video id="video-player-1" playsinline="playsinline" autoplay="autoplay" loop="loop" muted="muted" preload="metadata">
            <source media="(min-width: 768px)" src="/cata1og-video-desktop.mp4" type="video/mp4"/>
            <source media="(max-width: 767px)" src="/cata1og-video-mobile.mp4" type="video/mp4"/>
        </video>
	</section>
</template>
<script type="text/javascript">
export default {
	data() {
		return {
		}
	},
	async mounted() {

	},
	computed: {
	},
	methods:{
	}
}
</script>