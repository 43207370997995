<template>
	<div :class="`product-box ${ handleCollection == 'price-catalog' ? 'product-price-catalog ' : '' }mb-2`">
		<div class="img-wrapper">
			<div class="label-block">
				<span v-if="product.new" class="label-new" >new</span>
				<span v-if="product.discounts.brand > 0" class="label-discount">-{{ product.discounts.brand }}%</span>
				<span v-if="product.sale" class="label-sale">on sale</span>
				<span v-if="product.discounts.variant_stock" class="label-special-offer">Special Offer</span>
				<span v-if="['price-catalog', 'customer-price-catalog'].includes(handleCollection)" class="label-details" title="Show Variant information" @click="showModalVariants()"><i class="fa-solid fa-eye"></i></span>
			</div>
			<div class="front container-product-image">
				<nuxt-link v-if="!['price-catalog'].includes(handleCollection)" :to="{ path: `/collections/${ ['customer-price-catalog'].includes(handleCollection) ? 'best-sellers' : handleCollection }/products/${ product.handle }` }" class="d-flex justify-content-between align-items-start flex-wrap">
					<img loading="eager" class='img-fluid product-image' width="320px" height="320px" :alt="`${ product.handle }_1`" :src="getCollectionImage01" :onerror="`this.onerror=null;this.src='${ back_url }/images/products/product-default_320.webp';this.alt='product-default';`" sizes="(max-width: 576px) 180px, (max-width: 767px) 320px, (max-width: 991px) 320px, (max-width: 1399px) 320px, (max-width: 3820px) 320px" :srcset="`${ getCollectionImage01 } 300w, ${ getCollectionImage01 } 300w, ${ getCollectionImage01 } 960w, ${ getCollectionImage01 } 1180w, ${ getCollectionImage01 } 1400w`"/> 
					<img loading="lazy" class="img-fluid hover-product-image" width="320px" height="320px" :alt="`${ product.handle }_2`" :src="getCollectionImage02" :onerror="`this.onerror=null;this.src='${ back_url }/images/products/product-default_320.webp';this.alt='product-default';`" sizes="(max-width: 576px) 180px, (max-width: 767px) 320px, (max-width: 991px) 320px, (max-width: 1399px) 320px, (max-width: 3820px) 320px" :srcset="`${ getCollectionImage02 } 300w, ${ getCollectionImage02 } 300w, ${ getCollectionImage02 } 960w, ${ getCollectionImage02 } 1180w, ${ getCollectionImage02 } 1400w`"/> 
					<h3 v-if="!getterLogin" class="chunk-lines chunk-lines-2 title-product">{{ product.title }}</h3>
				</nuxt-link>
				<div v-else class="d-flex justify-content-between align-items-start flex-wrap">
					<img loading="eager" class='img-fluid product-image' width="320px" height="320px" :alt="`${ product.handle }_1`" :src="getCollectionImage01" :onerror="`this.onerror=null;this.src='${ back_url }/images/products/product-default_320.webp';this.alt='product-default';`" sizes="(max-width: 576px) 180px, (max-width: 767px) 320px, (max-width: 991px) 320px, (max-width: 1399px) 320px, (max-width: 3820px) 320px" :srcset="`${ getCollectionImage01 } 300w, ${ getCollectionImage01 } 300w, ${ getCollectionImage01 } 960w, ${ getCollectionImage01 } 1180w, ${ getCollectionImage01 } 1400w`"/> 
					<img loading="lazy" class="img-fluid hover-product-image" width="320px" height="320px" :alt="`${ product.handle }_2`" :src="getCollectionImage02" :onerror="`this.onerror=null;this.src='${ back_url }/images/products/product-default_320.webp';this.alt='product-default';`" sizes="(max-width: 576px) 180px, (max-width: 767px) 320px, (max-width: 991px) 320px, (max-width: 1399px) 320px, (max-width: 3820px) 320px" :srcset="`${ getCollectionImage02 } 300w, ${ getCollectionImage02 } 300w, ${ getCollectionImage02 } 960w, ${ getCollectionImage02 } 1180w, ${ getCollectionImage02 } 1400w`"/> 
				</div>
			</div>
		</div>
		<div v-if="getterLogin || handleCollection == 'price-catalog'" class="product-detail">
			<nuxt-link v-if="!['price-catalog'].includes(handleCollection)" :to="{ path: `/collections/${ ['customer-price-catalog'].includes(handleCollection) ? 'best-sellers' : handleCollection }/products/${ product.handle }` }">
				<h3 class="chunk-lines chunk-lines-2 title-product">{{ product.title }}</h3>
			</nuxt-link>
			<div v-else>
				<h3 class="chunk-lines chunk-lines-2 title-product">{{ product.title }}</h3>
			</div>
			
			<vc-format-price :type="['price-catalog', 'customer-price-catalog'].includes(handleCollection) ? 'price-catalog' : 'collection'" :discount_brand="product.discounts.brand" :discount_percentage="0" :price="product.price" :discount_price="product.discount_price" :discount_stock="null" :apply_discount_stock="false"></vc-format-price>
			<ul v-if="product.option_colors" class="color-variant">
				<li v-for="(item_color, index_color) in product.option_colors.values" :key="index_color">
					<a :class="[item_color.handle, 'white']" :title="item_color.name">
						<img :src="`${ back_url }${ item_color.url }`" onerror="this.onerror=null;this.src='';this.alt='';this.style='display:none;';" :alt="item_color.name" width="20" height="20"/>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: ["product", "index", "imgList", "handleCollection", "hideDescription"],
	components: {
		vcLoading		: () => import('../../components/pages/loading.vue'),
		vcFormatPrice	: () => import('../../components/product/format-price.vue')
	},
	data() {
		return {
			back_url: process.env.VUE_APP_CATALOG_BACK_URL_FILE,
			default_images: [
				{ width: 180, alt: this.product.title, src: '/images/products/product-default_320.webp' },
				{ width: 320, alt: this.product.title, src: '/images/products/product-default_320.webp' }
			]
		};
	},
	computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
        }),
		getCollectionImage01(){

			return this.product.images?.length > 0 ? this.product.images[0].src_collection : `${ this.back_url }${ this.default_images[0].src }`;
		},
		getCollectionImage02(){

			return this.product.images?.length > 1 ? this.product.images[1].src_collection : `${ this.back_url }${ this.default_images[1].src }`;
		}
	},
	methods:{
		showModalVariants(){
			this.$emit('showModalVariants', this.product);
		}
	}
};
</script>
