<template>
	<div class="section-t-space pt-0">
		<breadcrumbs title="Contact US"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">Contact Us</h2>
		</div>
		<section class="container service small-section">
			<b-row class="justify-content-center m-0">
				<b-col cols="12">
					<p>Need help? Our agents are ready to give you the best support. We are pleased to help you! That is why we offer you different ways to respond to each of your requests in a way that best fits your needs, for more information:</p>
				</b-col>
				<b-col cols="12" xl="3">
					<div class="default-block w-100 px-2">
						<div class="media default-border service-border box-shadow">
							<div class="media-body">
								<div class="custom-content-icon">
									<i class="fa-solid fa-envelope"></i>
								</div>
								<span class="title-block">You can write us</span>
								<p>
									<a href="mailto:info.us@cata1og.com" target="_blank">info.us@cata1og.com</a>
								</p>
							</div>
						</div>
					</div>
				</b-col>
				<b-col cols="12" xl="3">
					<div class="default-block w-100 px-2">
						<div class="media default-border service-border box-shadow">
							<div class="media-body">
								<div class="custom-content-icon">
									<i class="fa-solid fa-phone"></i>
								</div>
								<span class="title-block">For Calls only:</span>
								<p>
									<a href="tel:+17865743472">+1 786 574 3472</a>
								</p>
							</div>
						</div>
					</div>
				</b-col>
				<b-col cols="12" xl="6">
					<div class="default-block w-100 px-2">
						<div class="media default-border box-shadow">
							<div class="media-body">
								<div class="custom-content-icon">
									<i class="fa-brands fa-whatsapp"></i>
								</div>
								<span class="title-block">Contact your Commercial Advisor:</span>
								<div class="register-form">
									<b-input-group size="sm">
										<b-form-input id="filter-input" type="text" v-model="searchLead" placeholder="Type your email"></b-form-input>
										<b-input-group-append>
											<b-button class="btn-solid px-3" :disabled="searchLead == ''" @click="searchAgent">
												<i class="fa-solid fa-magnifying-glass"></i>
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-modal id="modal_agent" ref="modal_agent" class="modal-agent"  size="xl" centered :hide-footer="true" :hide-header="true">
				<div class="modal-body">
					<div class="container-fluid p-0">
						<div class="row">
							<div class="col-12">
								<div class="modal-bg">
									<b-row v-if="getAgent">
										<b-col cols="12" class="title-default mb-4">
											<h3 class="title-inner-default"><strong>{{ getAgent?.name }}</strong> is your assigned agent</h3>
										</b-col>
										<b-col cols="12" lg="4" class="p-0">
											<div class="content-customer-insigne justify-content-center agent-image">
												<img :class="`profile-image rounded-circle${ hide_profile ? ' hide' : '' }`" :alt="`${ getAgent?.name || 'agent-image' }`" :src="profile_image"/>
											</div>
										</b-col>
										<b-col cols="12" lg="8">
											<b-row>
												<b-col cols="12" md="6" class="form-group">
													<div class="field-label mb-2 label-info">Name</div>
													<span class="form-control show-data">{{ getAgent?.name || '' }}</span>
												</b-col>
												<b-col cols="12" md="6" class="form-group">
													<div class="field-label mb-2 label-info">Email</div>
													<span class="form-control show-data">{{ getAgent?.email || '' }}</span>
												</b-col>
												<b-col cols="12" md="6" class="form-group">
													<div class="field-label mb-2 label-info">Phone</div>
													<span class="form-control show-data">{{ getAgent?.phone || '' }}</span>
												</b-col>
												<b-col cols="12" md="6" class="form-group" v-if="getAgent?.phone">
													<div class="field-label mb-2 label-info whatsapp-agent">
														<a :href="getAgent?.whatsappLink">Click here! and Contact us on WhatsApp<i class="fa-brands fa-whatsapp"></i></a>
													</div>
												</b-col>
												<b-col cols="12" md="6" class="form-group" v-if="( getAgent?.language || [] ).length > 0">
													<div class="field-label mb-2 label-info">Languages</div>
													<span v-for="(item_language, index_language) in ( getAgent?.language || [] )" :key="index_language" class="form-control show-data">{{ item_language.name }}</span>
												</b-col>
												<b-col cols="12" md="6" class="form-group" v-if="( getAgent?.categoryProduct || [] ).length > 0">
													<div class="field-label mb-2 label-info">Specialist in</div>
													<span v-for="(item_category, index_category) in ( getAgent?.categoryProduct || [] )" :key="index_category" class="form-control show-data">{{ item_category.name }}</span>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
									<b-row v-else>
										<b-col cols="12" class="title-default mb-4">
											<h3 class="title-inner-default">You do not have an Assigned Agent</h3>
										</b-col>
										<b-col lg="6">
											<div class="default-block w-100 h-100 px-2">
												<div class="media default-border service-border box-shadow">
													<div class="media-body">
														<div class="custom-content-icon">
															<i class="fa-solid fa-phone"></i>
														</div>
														<span class="title-block">If you are registered with us, call us at</span>
														<p class="mt-4">
															<a href="tel:+17865743472">+1 786 574 3472</a>
														</p>
													</div>
												</div>
											</div>
										</b-col>
										<b-col lg="6">
											<div class="default-block w-100 h-100 px-2">
												<div class="media default-border service-border box-shadow">
													<div class="media-body">
														<div class="custom-content-icon">
															<i class="fa-solid fa-pencil"></i>
														</div>
														<span class="title-block">If you have not registered, we invite you to do so at the following link</span>
														<p class="mt-4">
															<nuxt-link :to="{ path: '/account/register' }" title="Register Now" class="btn btn-solid">Register Now</nuxt-link>
														</p>
													</div>
												</div>
											</div>
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-modal>
		</section>
	</div>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	},
	data(){
		return {
			searchLead: "",
			findAgent: null,
			profile_image: null,
			true: false
		}
	},
	mounted(){
	},
	computed: {
		...mapGetters({
			getterCustomerLeadAgent: 'getterCustomerLeadAgent'
		}),
		getAgent(){
			return this.findAgent;
		}
	},
	methods: {
		...mapActions({
			findCustomerLeadAgent: 'findCustomerLeadAgent'
		}),
		async searchAgent(){
			
			await this.findCustomerLeadAgent({ email: this.searchLead }).then( (result_agent) => {
				
				this.findAgent = this.getterCustomerLeadAgent?.agent;
				if( this.findAgent ){
					
					if( this.findAgent == null || this.findAgent.photo == '' ){
						
						this.profile_image = `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }/images/svg-store/svg-profile-image/svg-secondary.svg`;
					}
					else{
						
						this.profile_image = `${ process.env.VUE_APP_AGENT_API_URL.replace('/api', '') }/${ this.findAgent.photo.replace('public/', '') }`;
					}
					this.hide_profile = false;
					this.findAgent.phone = this.findAgent.phone ? this.findAgent.phone : '';
					this.findAgent.whatsappLink = this.findAgent.phone != '' ? `https://api.whatsapp.com/send?phone=${ this.findAgent.phone.replace(/ +/g, '').replace('+', '') }` : '';
				}
				this.$refs['modal_agent'].show();
			});
		},
		cleanSearchAgent(){
			this.searchLead = "";
		}
	}
}
</script>
