<template>
    <div class="section-b-space pt-0 page-content loading-page content-loading p-relative">
        <vc-loading v-if="isLoading != null" :isLoading="isLoading"></vc-loading>
        <breadcrumbs type="dashboard" title="Your Statement"></breadcrumbs>
        <div class="title-default my-4">
            <h2 class="title-inner-default">Your Statement</h2>
        </div>
        <b-container class="mt-2 box-shadow p-4">
            <b-row>
                <b-col cols="12" md="6">
                    <div class="page-title mt-3 mb-4">
                        <h3 class="font-weight-bold">Statement</h3>
                        <div class="welcome-msg mb-2">
                            <p>This is your updated balance with all your transactions.</p>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end">
                    <a v-if="getterSatement" class="btn btn-sm btn-solid shadow-sm" target="_blank" :href="`/landing/pay-balance?id=${ getterSatement?.customer._id }`">Make a Payment</a>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>Inital Balance</h4>
                    <h6>{{ formatCurrency(getterSatement?.initialBalance || 0).format }}</h6>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>invoices</h4>
                    <h6>{{ formatCurrency(getterSatement?.invoicesAccumulated || 0).format }}</h6>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>payments</h4>
                    <h6>{{ formatCurrency(getterSatement?.paymentsAccumulated || 0).format }}</h6>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>expiring days</h4>
                    <h6>{{ getterSatement?.expiredDays || 0 }}</h6>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>Wallet days</h4>
                    <h6>{{ getterSatement?.walletDays || 0 }}</h6>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>balance expired</h4>
                    <h6>{{ formatCurrency(getterSatement?.balanceExpired || 0).format }}</h6>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>balance</h4>
                    <h6>{{ formatCurrency(getterSatement?.balance || 0).format }}</h6>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <h4>Maximum Purchase Limit</h4>
                    <h6>{{ formatCurrency(getterSatement?.preApprovedCredit || 0).format }}</h6>
                </b-col>
                <b-col cols="12">
                    <div class="page-title mt-3 mb-4">
                        <h3 class="font-weight-bold">Transactions Information</h3>
                    </div>
                </b-col>
            </b-row>
            <basic-table type_table="transactions" :table_fields="table_fields"></basic-table>
        </b-container>
    </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../../constants/config.js";
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue"),
        basicTable  : () => import("../../../components/pages/basic-table.vue")
    },
    data() {
        return {
            isLoading           : true,
            table_fields	    : [
            { 
                key			: 'document_date', 
                label		: 'Date', 
                class		: "text-left w-15 d-none d-sm-table-cell", 
                formatter	: (value, key, item) => { 
                    
                    return moment(value.toString().split(".")[0]).format('lll');
                } 
            },
            { 
                key			: 'reference', 
                label		: 'Reference', 
                class		: "text-center w-15", 
                formatter	: (value, key, item) => { 
                    if( item.document == 'Invoice' ){
                        
                        return item.invoice?.order ? item.invoice.order.name : "";
                    }
                    else if( item.document == "Payment" ){
                        
                        return item.payment.note;
                    }
                    else if( item.document == "CreditNote" ){
                        
                        return item.creditNote.order ? item.creditNote.order.name : item.creditNote.reference;
                    }
                    else{
                        
                        return "";
                    }
                } 
            },
            { 
                key			: 'document', 
                label		: 'Document', 
                class		: "text-center w-15 d-md-table-cell d-none" 
            },
            { 
                key			: 'amount', 
                label		: 'Amount', 
                class		: "text-center w-15" 
            },
            { 
                key			: 'balance', 
                label		: 'Balance', 
                class		: "text-center w-15 d-none d-sm-table-cell" 
            },
            { 
                key			: 'status_name', 
                label		: 'Status', 
                class		: "text-center w-15 d-md-table-cell d-none" 
            },
            { 
                key			: 'actions', 
                label		: 'Actions', 
                class		: "text-center w-10 hide-title" 
            }
            ],
        };
    },
    computed: {
        ...mapGetters({
            getterLogin     : 'getterLogin',
            getterSatement  : "getterSatement",
        })
    },
    async mounted(){
        if( !this.getterLogin ){
            
            this.$router.push('/account/login');
        }
        else{
            
            await this.getStatementByCustomer().then( (result_statement) => {
                
                this.isLoading = null;
            });
        }
    },
    methods:{
        ...mapActions({
            getStatementByCustomer: "getStatementByCustomer"
        }),
        formatCurrency(price){
            return formatData.currency(price);
        }
    }
}
</script>