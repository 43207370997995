<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Shipping Policies"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">International Shipping Policies</h2>
		</div>
		<b-container>
			<b-row>
				<b-col cols="12">
					<b-card class="box-shadow bg-white">
						<div class="accordion theme-accordion mt-4" id="accordionExample">
							<h5>
								The company's policies for international purchases at PalNegocio.com are listed as follows:
							</h5> 
							<b-card-text>
								<div class="page-content rte">
									<ul>
										<li><span>The value of each international purchase cannot be less than $1.500</span></li>
										<li><span>Returns are accepted during the first 30 days after receiving the package if the product presents manufacturing defects. From this point forward, all shipping expenses will be borne by the client.</span></li>
										<li><span>&nbsp;Returns due to stock rotation warranty will be accepted during the first 6 months after the customer receive the package. From this point forward, all shipping expenses will be borne by the client. </span></li>
										<li><span>In case of exchanges, keep in mind that all garments must be new, with no stains and odor free. All products will go through a quality control process, which could take up to 5 business days. After that, all the garments you would like to change will be shipped or store credit will be given if required. </span></li>
										<li><span>Import tariff, taxes, and any other expenses are not included in the price of the product or in the delivery cost. Those expenses have to be covered by the customer. Please check with your local customs office all the additional charges and requirements before placing your order.</span></li>
										<li><span>It is recommended that you attach the bill and payment receipt to the order you would like to return or exchange.</span></li>
									</ul>
									<h2></h2>
									<p><span>Please contact our Customer Service Department (+1 855-425-7140 / +1 855-315-8224) to provide us the Tracking Number of the shipment of the returning product so the warehouse can confirm its reception in order to start the process.</span></p>
								</div>
							</b-card-text>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	}
}
</script>
