<template>
	<div class="home">
		<slider-video></slider-video>
		<logo-slider-white></logo-slider-white>
		<b-row class="home-section section-info-register m-0">
			<b-col cols="12" md="6" lg="6" class="col-text justify-content-center p-0 my-4">
				<div class="content-text">
					<h2>We are the <strong>world‘s</strong> leading B2B Colombian shapewear sellers.</h2>
					<div class="content-btn w-100">
						<nuxt-link :to="{ path: '/account/register' }" title="Register Now" class="btn btn-solid m-0">Register Now</nuxt-link>
					</div>
				</div>
			</b-col>
			<b-col cols="12" md="6" lg="6" class="d-flex justify-content-center image-product-home p-0">
				<img src="/cata1og-home-banner.webp" loading="eager" width="980" height="980" alt="cata1og-home-banner" class="product-image-info-register" sizes="(max-width: 600px) 980px, (max-width: 700px) 980px, (max-width: 800px) 980px, (max-width: 1000px) 980px, (max-width: 1200px) 980px, (max-width: 1400px) 980px, (max-width: 1600px) 980px, (max-width: 1800px) 980px, (max-width: 2000px) 980px, (max-width: 2200px) 980px, (max-width: 2400px) 980px, (max-width: 2600px) 980px, (max-width: 2800px) 980px, (max-width: 3000px) 980px" :srcset="`/cata1og-home-banner.webp 600w, /cata1og-home-banner.webp 700w, /cata1og-home-banner.webp 800w, /cata1og-home-banner.webp 1000w, /cata1og-home-banner.webp 1200w, /cata1og-home-banner.webp 1400w, /cata1og-home-banner.webp 1600w, /cata1og-home-banner.webp 1800w, /cata1og-home-banner.webp 2000w, /cata1og-home-banner.webp 2200w, /cata1og-home-banner.webp 2400w, /cata1og-home-banner.webp 2600w, /cata1og-home-banner.webp 2800w, /cata1og-home-banner.webp 3000w`"/>
			</b-col>
		</b-row>
		<b-row class="home-section section-dark m-0">
			<b-col cols="12" class="p-0">
				<div class="content-text">
					<span class="d-block w-100">Expand your sales portfolio selling <strong class="font-color-light">Fajas Colombianas Wholesale</strong></span>
					<span class="d-block w-100">Work with the best portfolio</span>
				</div>
			</b-col>
		</b-row>
		<div class="section-product-tabs">
			<div class="title-default section-t-space">
				<span>Special Offer</span>
				<h2 class="title-inner-default">Best Sellers</h2>
			</div>
			<section class="pt-0 my-5 p-relative">
				<b-row id="slider-top-products" class="p-relative justify-content-center m-0">
					<b-col cols="12" lg="3" v-for="(item_product, index_product) in getterCollectionHome" :key="`product-${ index_product }`"  :id="`item-carousel-top-products-${ index_product + 1 }`" :class="`p-0 d-flex justify-content-center col-carousel ${ index_product == 0 ? 'show-item-carousel' : 'hide-item-carousel' }`">
						<product-collection :product="item_product" :hideDescription="true" :handleCollection="'best-sellers'" :id="`product-${ index_product }`" :class="`px-2`"></product-collection>
					</b-col>
				</b-row>
			</section>
		</div>
		<b-row class="m-0 grid-banner-collection">
			<b-col cols="12" md="6" lg="6" class="p-relative grid-item p-0 d-flex justify-content-end">
				<nuxt-link :to="{ path: '/collections/denim-fashion-al-por-mayor' }" title="Collection Denim" class="link-section-1"></nuxt-link>
				<nuxt-link :to="{ path: '/collections/colombian-body-shapers-wholesale' }" title="Collection Shapewear" class="link-section-2"></nuxt-link>
				<img src="/cata1og-grid-banner-1-denim-shapewear.webp" loading="lazy" width="960" height="1080" alt="cata1og-grid-banner-1-denim-shapewear" class="img-fluid w-100" sizes="(max-width: 600px) 960px, (max-width: 700px) 960px, (max-width: 800px) 960px, (max-width: 1000px) 960px, (max-width: 1200px) 960px, (max-width: 1400px) 960px, (max-width: 1600px) 960px, (max-width: 1800px) 960px, (max-width: 2000px) 960px, (max-width: 2200px) 960px, (max-width: 2400px) 960px, (max-width: 2600px) 960px, (max-width: 2800px) 960px, (max-width: 3000px) 960px" :srcset="`/cata1og-grid-banner-1-denim-shapewear.webp 600w, /cata1og-grid-banner-1-denim-shapewear.webp 700w, /cata1og-grid-banner-1-denim-shapewear.webp 800w, /cata1og-grid-banner-1-denim-shapewear.webp 1000w, /cata1og-grid-banner-1-denim-shapewear.webp 1200w, /cata1og-grid-banner-1-denim-shapewear.webp 1400w, /cata1og-grid-banner-1-denim-shapewear.webp 1600w, /cata1og-grid-banner-1-denim-shapewear.webp 1800w, /cata1og-grid-banner-1-denim-shapewear.webp 2000w, /cata1og-grid-banner-1-denim-shapewear.webp 2200w, /cata1og-grid-banner-1-denim-shapewear.webp 2400w, /cata1og-grid-banner-1-denim-shapewear.webp 2600w, /cata1og-grid-banner-1-denim-shapewear.webp 2800w, /cata1og-grid-banner-1-denim-shapewear.webp 3000w`"/>
			</b-col>
			<b-col cols="12" md="6" lg="6" class=" grid-item p-0 d-flex justify-content-start">
				<nuxt-link :to="{ path: '/collections/ropa-interior-colombiana-al-por-mayor' }" title="Collection Underwear" class="link-section-1"></nuxt-link>
				<nuxt-link :to="{ path: '/collections/shoes-wholesale' }" title="Collection Shoes" class="link-section-2"></nuxt-link>
				<img src="/cata1og-grid-banner-2-underwear-shoes.webp" loading="lazy" width="960" height="1080" alt="cata1og-grid-banner-2-underwear-shoes" class="img-fluid w-100" sizes="(max-width: 600px) 960px, (max-width: 700px) 960px, (max-width: 800px) 960px, (max-width: 1000px) 960px, (max-width: 1200px) 960px, (max-width: 1400px) 960px, (max-width: 1600px) 960px, (max-width: 1800px) 960px, (max-width: 2000px) 960px, (max-width: 2200px) 960px, (max-width: 2400px) 960px, (max-width: 2600px) 960px, (max-width: 2800px) 960px, (max-width: 3000px) 960px" :srcset="`/cata1og-grid-banner-2-underwear-shoes.webp 600w, /cata1og-grid-banner-2-underwear-shoes.webp 700w, /cata1og-grid-banner-2-underwear-shoes.webp 800w, /cata1og-grid-banner-2-underwear-shoes.webp 1000w, /cata1og-grid-banner-2-underwear-shoes.webp 1200w, /cata1og-grid-banner-2-underwear-shoes.webp 1400w, /cata1og-grid-banner-2-underwear-shoes.webp 1600w, /cata1og-grid-banner-2-underwear-shoes.webp 1800w, /cata1og-grid-banner-2-underwear-shoes.webp 2000w, /cata1og-grid-banner-2-underwear-shoes.webp 2200w, /cata1og-grid-banner-2-underwear-shoes.webp 2400w, /cata1og-grid-banner-2-underwear-shoes.webp 2600w, /cata1og-grid-banner-2-underwear-shoes.webp 2800w, /cata1og-grid-banner-2-underwear-shoes.webp 3000w`"/>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { effectSlider }  from '../constants/config.js';
export default {
	components: {
		sliderVideo			: () => import( '../components/home/slider-video.vue' ),
		productCollection	: () => import('../components/product/product-collection.vue'),
		logoSliderWhite		: () => import( '../components/home/logo-slider-white.vue' ),
		faq					: () => import( '../components/account/faq.vue' )
	},
	data() {
		return {
			isLoading					: true,
			collection_products			: [],
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadPage);
		
		await this.getCollectionHome();
	},
	computed: {
		...mapGetters({
			getterCollectionHome: 'getterCollectionHome',
		}),
	},
	methods: {
		...mapActions({
			getCollectionHome: 'getCollectionHome'
		}),
		loadPage(event){
			setInterval(() => {
				if( this.getterCollectionHome ){
					
					effectSlider('slider-top-products', this.getterCollectionHome.length, 1500 );
				}
				effectSlider('slider-services', 4, 1500 );
				effectSlider('slider-benefits', 4, 1500 );
				effectSlider('slider-first-order', 2, 1500 );
			}, 3000);
		}
	}
}
</script>