<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="About us"></breadcrumbs>
		<div class="title-default mt-4">
			<h2 class="title-inner-default">About Us</h2>
		</div>
		<b-container>
			<b-row>
				<b-col cols="12">
					<div class="banner-section">
						<img :src="aboutbanner" class="img-fluid" alt />
					</div>
				</b-col>
				<b-card class="box-shadow bg-white" style="border: unset">
					<b-col cols="12">
						<h4>About Us</h4>
						<p>We are a company dedicated to the wholesale market, distributing recognized products of the highest quality around the world. We are focused on the commercial development of our clients, offering the best prices and guarantees on our products to ensure that every seller can establish their business and become their customers top choice. In this sense, Cata1og guarantees success in the shortest time possible with the highest profit rate. By choosing us as your business ally, we offer you the widest convenience so that your home-made orders reach the door of your business without having to worry about the procedures related to export and import, placing at your disposal an excellent work team and customer care that will follow the entire business process to the end.</p>
					</b-col>
					<b-col cols="12">
						<h4>Mision</h4>
						<p>Be the best-positioned company in the Hispanic (Latino) market for curve lovers, style and high quality, thanks to the excellent teamwork with companies that develop products with a great reputation worldwide. Guarantee commercial fairness and provide the best wholesale trade service. Offer the best guarantees to project the businesses of all the people who choose our service and put within reach all the advantages of e-commerce in a single web portal.</p>
						<h4>Vision</h4>
						<p>Be a global company allied with the best brands in the Hispanic market to be the first bridge that successfully links the wholesale trade between Latin America and the world. Giving each of our customers the best guarantees in online purchases to always generate greater satisfaction, success, and attention when making a purchase.</p>
					</b-col>
					<b-col cols="12" class="pt-4">
						<iframe  width="100%" height="600" src="https://www.youtube.com/embed/bZAVbVDSFxU" title="Institutional Video Cata1log" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</b-col>
				</b-card>
			</b-row>
		</b-container>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	},
	data() {
		return {
			aboutTitle: 'About Us',
			misionText: '',
			visionText: '',
			aboutText:
			''
		}
	}
}
</script>
