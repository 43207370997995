<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Returns"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">Returns</h2>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="accordion theme-accordion mt-4" id="accordionExample">
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-1' + index" variant="info">IF THE ITEM DID NOT FIT, OR IF THERE HAS BEEN AN IRREGULARITY, HOW CAN YOU DO TO GET TO CHANGE IT TO THE RIGHT OR A DIFFERENT PRODUCT?</b-button>
							</b-card-header>
							<b-collapse id="accordion-1" visible accordion="accordion-1" role="tabpanel">
								<b-card-body>
									<ul>
										<li>If for any reason you receive a defective product, you have 10 business days to notify and return the unused merchandise, with all the labels still attached.</li>
										<li>Please, remember that you have up to 90 days to return the merchandise that complies with our return policies (unused garments with all labels attached). We will add the product's value as a credit to your account once we receive it in our warehouse, so you can place a new order.</li>
									</ul>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="box-shadow mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block href="#" v-b-toggle="'accordion-1' + index" variant="info">IF YOU HAVE AN ISSUE WITH YOUR ORDER, SHIPPING PROCESS, PAYMENT METHOD, OR STATE OF THE PRODUCT; WHERE CAN YOU CONTACT US?</b-button>
							</b-card-header>
							<b-collapse id="accordion-1" visible accordion="accordion-1" role="tabpanel">
								<b-card-body>
									<p>You can write us to the email:</p>
									<ul>
										<li class=""><a href="mailto:info.us@cata1og.com" target="_blank" class="text-email" rel="noopener noreferrer">info.us@cata1og.com</a></li>
									</ul>
									<p>You can also call or write to us:</p>
									<P>
										<span class="title-phone-number">
											<strong>USA:</strong>
											<strong>For Calls only:</strong>
										</span>
									</P>
									<ul>
										<li class="item-contact">
											<a href="tel:+17865743472" target="_blank" class="text-phone-number align-icon-contact-numbers"><span class="phone-number">+1 786 574 3472</span></a>
										</li>
									</ul>
								</b-card-body>
							</b-collapse>
						</b-card>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import("../../components/pages/breadcrumbs.vue"),
	},
};
</script>
