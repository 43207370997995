<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="products"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">ALWAYS OFFER THE BEST TO YOUR CUSTOMERS</h2>
		</div>
		<b-container>
			<b-row>
				<b-col cols="12">
					<b-card class="shadow-sm bg-white">
						<p>Enjoying our services is very easy, you just have to enter our web portal and register as a new user. Our team will verify your information and approve your account. Once you're registered and verified in our platform, you can view the available inventory of each product of our wholesale clothing in real time along with the cost of each garment.</p>
						<p>When you buy your wholesale clothing from the comfort of home you will also enjoy a number of advantages, such as your orders will be processed fast, payments and business transactions without paying international freight and receiving immediate dispatches, as our team starts the process of packing the products from the moment place the order, taking care of all the customs processes to carry our wholesale clothing from our warehouses to your business.</p>
						<p>After making your first wholesale clothing orders, the representatives of each brand can schedule meetings to offer you better commercial conditions (terms, incentives, exclusive areas, credits, advertising support, among others) and sustain mutual success. On the other hand, brands that wish to offer their wholesale clothing products on our platform have the opportunity to contact us through the various means we have available on our website (phones, emails, chats), establishing direct contact with the company to present their products and business proposals.</p>
						<p>We will carry out an evaluation process. If you comply with our quality parameters, you will have the valuable opportunity to offer your products in our wholesale clothing virtual market, with great projection in Mexico and USA. We invite you to be part of our family. Whether you are a buyer or wholesale clothing supplier, you will have the opportunity to be seen in a market of great growth and popularity as e-commerce. What are you waiting for? Start getting recognition all around the world, join Palnegocio.com PLN Distributions.</p>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
	}
}
</script>
