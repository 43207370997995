<template>
	<section class="section-b-space pt-0">
		<breadcrumbs title="Terms and Conditions"></breadcrumbs>
		<div class="title-default my-4">
			<h2 class="title-inner-default">Terms and Conditions</h2>
		</div>
		<b-container>
			<b-row>
				<b-col cols="12">
					<terms-and-conditions></terms-and-conditions>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script>
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
		termsAndConditions: () => import('../../components/pages/terms-and-conditions.vue')
	}
}
</script>
