<template>
	<div class="sidebar-section">
		<div v-if="getterFiltersCollection.length > 0" class="float-sidebar-btn" @click="openLayoutSidebar()">
			<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 30 30">
				<g id="Grupo_64" data-name="Grupo 64" transform="translate(-1577.304 -100)">
					<path id="Trazado_75" data-name="Trazado 75" d="M208.329,6.192a3.1,3.1,0,1,1,3.1-3.1,3.1,3.1,0,0,1-3.1,3.1m0-6.111A3.015,3.015,0,1,0,211.344,3.1,3.018,3.018,0,0,0,208.329.081" transform="translate(1389.657 105)" fill="#303030"></path>
					<path id="Trazado_75_-_Contorno" data-name="Trazado 75 - Contorno" d="M208.329,7.192a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,208.329,7.192Zm0-6.111A2.015,2.015,0,1,0,210.344,3.1,2.017,2.017,0,0,0,208.329,1.081Z" transform="translate(1389.657 105)" fill="#303030"></path>
					<rect id="Rectángulo_30" data-name="Rectángulo 30" width="6.064" height="0.081" transform="translate(1601.042 108.055)" fill="#303030"></rect>
					<path id="Rectángulo_30_-_Contorno" data-name="Rectángulo 30 - Contorno" d="M0-1H6.064a1,1,0,0,1,1,1V.081a1,1,0,0,1-1,1H0a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1Z" transform="translate(1601.042 108.055)" fill="#303030"></path>
					<rect id="Rectángulo_31" data-name="Rectángulo 31" width="16.627" height="0.081" transform="translate(1578.304 108.055)" fill="#303030"></rect>
					<path id="Rectángulo_31_-_Contorno" data-name="Rectángulo 31 - Contorno" d="M0-1H16.627a1,1,0,0,1,1,1V.081a1,1,0,0,1-1,1H0a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1Z" transform="translate(1578.304 108.055)" fill="#303030"></path>
					<path id="Trazado_76" data-name="Trazado 76" d="M208.329,213.493a3.1,3.1,0,1,1,3.1-3.1,3.1,3.1,0,0,1-3.1,3.1m0-6.111a3.015,3.015,0,1,0,3.015,3.015,3.018,3.018,0,0,0-3.015-3.015" transform="translate(1389.657 -85.547)" fill="#303030"></path>
					<path id="Trazado_76_-_Contorno" data-name="Trazado 76 - Contorno" d="M208.329,214.493a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,208.329,214.493Zm0-6.111a2.015,2.015,0,1,0,2.015,2.015A2.017,2.017,0,0,0,208.329,208.382Z" transform="translate(1389.657 -85.547)" fill="#303030"></path>
					<rect id="Rectángulo_32" data-name="Rectángulo 32" width="6.064" height="0.081" transform="translate(1601.042 124.809)" fill="#303030"></rect>
					<path id="Rectángulo_32_-_Contorno" data-name="Rectángulo 32 - Contorno" d="M0-1H6.064a1,1,0,0,1,1,1V.081a1,1,0,0,1-1,1H0a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1Z" transform="translate(1601.042 124.809)" fill="#303030"></path>
					<rect id="Rectángulo_33" data-name="Rectángulo 33" width="16.627" height="0.081" transform="translate(1578.304 124.809)" fill="#303030"></rect>
					<path id="Rectángulo_33_-_Contorno" data-name="Rectángulo 33 - Contorno" d="M0-1H16.627a1,1,0,0,1,1,1V.081a1,1,0,0,1-1,1H0a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1Z" transform="translate(1578.304 124.809)" fill="#303030"></path>
					<rect id="Rectángulo_34" data-name="Rectángulo 34" width="16.627" height="0.081" transform="translate(1590.523 116.432)" fill="#303030"></rect>
					<path id="Rectángulo_34_-_Contorno" data-name="Rectángulo 34 - Contorno" d="M0-1H16.627a1,1,0,0,1,1,1V.081a1,1,0,0,1-1,1H0a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1Z" transform="translate(1590.523 116.432)" fill="#303030"></path>
					<path id="Trazado_77" data-name="Trazado 77" d="M78.172,109.842a3.1,3.1,0,1,1,3.1-3.1,3.1,3.1,0,0,1-3.1,3.1m0-6.111a3.015,3.015,0,1,0,3.015,3.015,3.018,3.018,0,0,0-3.015-3.015" transform="translate(1509.295 9.727)" fill="#303030"></path>
					<path id="Trazado_77_-_Contorno" data-name="Trazado 77 - Contorno" d="M78.172,110.842a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,78.172,110.842Zm0-6.111a2.015,2.015,0,1,0,2.015,2.015A2.017,2.017,0,0,0,78.172,104.731Z" transform="translate(1509.295 9.727)" fill="#303030"></path>
					<rect id="Rectángulo_35" data-name="Rectángulo 35" width="6.064" height="0.081" transform="translate(1578.304 116.432)" fill="#303030"></rect>
					<path id="Rectángulo_35_-_Contorno" data-name="Rectángulo 35 - Contorno" d="M0-1H6.064a1,1,0,0,1,1,1V.081a1,1,0,0,1-1,1H0a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1Z" transform="translate(1578.304 116.432)" fill="#303030"></path>
				</g>
			</svg>
		</div>
		<div v-if="getterFiltersCollection.length > 0" id="collection-filter-sidebar" class="col p-0 float-sidebar-box" :class="{ 'float-sidebar-open': openSidebar, 'fload-sidebar-fixed': fixedSidebar }">
			<div class="float-sidebar-box-body">
				<b-row class="m-0">
					<b-col cols="12" class="p-3 sidebar-back text-right" @click="closeLayoutSidebar()">
						<i class="fa-solid fa-times"></i>
					</b-col>
				</b-row>
				<div class="float-sidebar-body">
					<div class="collection-filter custom-scrollbar" :style="`height: calc( 100vh - ${ heightSidebar }px)`">
						<div v-for="(item_filter, index_filter) in getterFiltersCollection" :key="`container-${ item_filter.handle }-${ index_filter + 1 }`" :class="'filter-collapse'">
							<div v-if="item_filter.handle == 'price' && item_filter.show" :key="`${ item_filter.handle }-${ index_filter + 1 }`" class="filter-collapse-block pl-4 pr-4 open">
								<h3 class="collapse-block-title d-flex justify-content-between" v-b-toggle="`${ item_filter.handle }`">{{ item_filter.name }}<i class="fa-solid fa-angle-down"></i></h3>
								<b-collapse :id="item_filter.handle" visible :accordion="`${ item_filter.handle }-${ index_filter + 1 }`" class="filter-collapse-block-content" role="tabpanel">
									<div class="collection-type-filter price-range-picker price-filter">
										<vue-slider id="filter_price" ref="filter_price" v-model="getProductPriceFilter" :min="item_filter.values.min_price" :max="item_filter.values.max_price" :interval="0.01" :enable-cross="false" :lazy="true" :tooltip-placement="['bottom','top']" @change="appliedFilter">
											<template v-slot:tooltip="{ value, index  }">
												<div :class="`vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-${ index == 0 ? 'bottom' : 'top' }`">
													<span class="vue-slider-dot-tooltip-text">{{ formatCurrency(value).format }}</span>
												</div>
											</template>
										</vue-slider>
									</div>
								</b-collapse>
							</div>
							<div v-else-if="!['variants', 'tags'].includes( item_filter.handle ) && item_filter.values.length > 1 && item_filter.show" :key="`filter-${ item_filter.handle }-${ index_filter + 1 }`" :class="`filter-collapse-block px-4 ${ item_filter.values.length == 0 ? 'hide' : 'open' }`">
								<h3 class="collapse-block-title d-flex justify-content-between" v-b-toggle="`filter-${ item_filter.handle }`">{{ item_filter.name }}<i class="fa-solid fa-angle-down"></i></h3>
								<b-collapse :id="`filter-${ item_filter.handle }`" visible :accordion="`${ item_filter.handle }-${ index_filter + 1 }`" class="filter-collapse-block-content" role="tabpanel">
									<div class="collection-type-filter">
										<b-form-checkbox v-for="(item_value, index_value) in  item_filter.values" :key="`filter-${ item_filter.handle }-${ item_value.handle }-${ index_value + 1 }`" :class="{ 'selected': isActive(item_value.name) }" :v-model="item_value.checked" :id="`${ item_filter.handle }-${ item_value.handle }`" @change="appliedFilter({ name: item_filter.name, handle: item_filter.handle, sub_filter: null, value: { name: item_value.name, handle: item_value.handle, checked: item_value.checked } })">
											{{ item_value.name }}
										</b-form-checkbox>
									</div>
								</b-collapse>
							</div>
							<div v-else-if="['variants', 'tags'].includes( item_filter.handle ) && item_filter.show" v-for="(item_sub_filter, index_sub_filter) in item_filter.values" :key="`filter-${ item_filter.handle }-${ index_filter + 1 }-${ item_sub_filter.handle }-${ index_sub_filter + 1 }`" :class="`filter-collapse-block px-4 ${ item_sub_filter.count.isShow + item_sub_filter.count.isChecked == 0 ? 'hide' : 'open' }`">
								<h3 v-if="item_sub_filter.show && ( item_sub_filter.count.isShow + item_sub_filter.count.isChecked ) > 0 && item_sub_filter.handle != 'size'" class="collapse-block-title d-flex justify-content-between" v-b-toggle="`filter-${ item_filter.handle }-${ item_sub_filter.handle }`">{{ item_sub_filter.name }}<i class="fa-solid fa-angle-down"></i></h3>
								<b-collapse v-if="item_sub_filter.show && ( item_sub_filter.count.isShow + item_sub_filter.count.isChecked ) > 0 && item_sub_filter.handle != 'size'" :id="`filter-${ item_filter.handle }-${ item_sub_filter.handle }`" visible :accordion="`${ item_filter.handle }-${ index_filter + 1 }-${ item_sub_filter.handle }-${ index_sub_filter + 1 }`" class="filter-collapse-block-content" role="tabpanel">
									<div :class="`collection-type-filter${ item_sub_filter.handle == 'color' ? ' color-filter' : '' }`">
										<b-form-checkbox v-for="(item_value, index_value) in item_sub_filter.values" :key="`${ item_filter.handle }-${ item_sub_filter.handle }-${ item_value.handle }-${ index_value + 1 }`" v-if="item_value.show || item_value.checked" :class="{ 'selected': isActive(item_value.name) }" :v-model="item_value.checked" :id="`${ item_filter.handle }-${ item_sub_filter.handle }-${ item_value.handle }`" @change="appliedFilter({ name: item_filter.name, handle: item_filter.handle, sub_filter: { name: item_sub_filter.name, handle: item_sub_filter.handle }, value: { name: item_value.name, handle: item_value.handle, checked: item_value.checked } })">
											<span v-if="item_sub_filter.handle == 'color'" :class="`${ item_sub_filter.handle }-${ item_value.handle } color-variant d-flex mt-0 mr-2`">
												<img :src="`${ back_url }/images/products/colors/color-${ item_value.handle }.webp`" onerror="this.onerror=null;this.src='';this.alt='';this.style='display:none;';" :alt="`Color ${ item_value.name }`" width="20" height="20"/>
											</span>
											{{ item_value.name }}
										</b-form-checkbox>
									</div>
								</b-collapse>
							</div>
						</div>
					</div>
				</div>
			</div>
			<a href="javascript:void(0)" class="overlay" @click="closeLayoutSidebar()"></a>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatData } from "../../constants/config.js";
import 'vue-slider-component/theme/antd.css';
export default {
	data() {
		return {
			back_url			: process.env.VUE_APP_CATALOG_BACK_URL_FILE, 
			heightSidebar	: 0,
			openSidebar		: false,
			fixedSidebar	: false,
		};
	},
	components: {
		vueSlider: () => import("vue-slider-component"),
	},
	async mounted() {
		window.addEventListener('scroll', this.positionSidebar);
	},
	computed: {
		...mapGetters({
			getterFiltersCollection: "getterFiltersCollection",
		}),
		getProductPriceFilter(){
			return [this.getterFiltersCollection[0].values.min_price, this.getterFiltersCollection[0].values.max_price];
		}
	},
	methods: {
		async positionSidebar( event ) {
			this.fixedSidebar 	= window.top.scrollY >= 273;
			this.heightSidebar 	= ( window.innerWidth >= 768 && window.top.scrollY < 273 ) ? 193 - window.top.scrollY : 40;
		},
		openLayoutSidebar() {
			this.openSidebar = true;
			this.positionSidebar();
		},
		closeLayoutSidebar() {
			this.openSidebar = false
		},
		isActive(filterItem) {
			return false;
		},
		appliedFilter(select_filter) {
			
			if( select_filter.name && !select_filter.value.checked ){
				
				select_filter.id = `filter-${ select_filter.handle }-${ select_filter.sub_filter ? select_filter.sub_filter.handle : '' }-${ select_filter.value.handle }`.replace( "--", "-" );
				this.$emit("addFilter", select_filter);
			}
			else if( select_filter.value?.checked ){
				
				select_filter.id = `filter-${ select_filter.handle }-${ select_filter.sub_filter ? select_filter.sub_filter.handle : '' }-${ select_filter.value.handle }`.replace( "--", "-" );
				this.$emit("removeFilter", select_filter);
			} 
			else{
				
				this.$emit("addFilter", { id: 'filter-price', name: 'Price', handle: 'price', sub_filter: null, value: select_filter });
			}
		},
		toggleSidebarBlock() {
			this.openBlock = !this.openBlock;
		},
		formatCurrency(price, totalAmount = null){
			return formatData.currency(price, totalAmount);
		}
	}
};
</script>
